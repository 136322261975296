import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CountriesPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const CountriesPreview = ({
    placeholder = '',
    selected = false
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="countries-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div
                className="countries-preview-label"
                style={{ color: theme.foregroundColorFaded }}>
                {placeholder}
            </div>
            <select
                className="countries-preview-input"
                disabled
                style={{
                    color: theme.foregroundColor,
                    fontFamily: profile.fontFamily,
                }}
                tabIndex={-1}>
                <option value="USA">United States</option>
            </select>
        </div>
    );
};

export default CountriesPreview;
