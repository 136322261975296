import React, { useEffect, useState } from 'react';

// Styles
import './FormField.css';

// Components
import Barcode from './fields/barcode/Barcode';
import Checkbox from './fields/checkbox/Checkbox';
import Checklist from './fields/checklist/Checklist';
import Comments from './fields/comments/Comments';
import Countries from './fields/countries/Countries';
import Currency from './fields/currency/Currency';
import DateField from './fields/date/DateField';
import Day from './fields/day/Day';
import Documents from './fields/documents/Documents';
import Gallery from './fields/gallery/Gallery';
import Location from './fields/location/Location';
import Lookup from './fields/lookup/Lookup';
import Month from './fields/month/Month';
import Number from './fields/number/Number';
import ObjectChecklist from './fields/objectchecklist/ObjectChecklist';
import Password from './fields/password/Password';
import Phone from './fields/phone/Phone';
import Relationships from './fields/relationships/Relationships';
import States from './fields/states/States';
import Text from './fields/text/Text';
import Time from './fields/time/Time';
import UserList from './fields/userlist/UserList';
import VideoGallery from './fields/videogallery/VideoGallery';
import Year from './fields/year/Year';

const componentMap = {
    barcode: Barcode,
    checkbox: Checkbox,
    checklist: Checklist,
    comments: Comments,
    countries: Countries,
    currency: Currency,
    date: DateField,
    day: Day,
    documents: Documents,
    gallery: Gallery,
    location: Location,
    lookup: Lookup,
    month: Month,
    number: Number,
    objectchecklist: ObjectChecklist,
    password: Password,
    phone: Phone,
    relationships: Relationships,
    states: States,
    text: Text,
    time: Time,
    userlist: UserList,
    videogallery: VideoGallery,
    year: Year,
};

/**
 * Serves as a router to determine which component to display in the form.
 */
const FormField = ({ object, field, onUpdate, showFieldMenu = true, readOnly = true }) => {

    // Extract the type and prefix/suffix if applicable
    const typePrefix = field.type.includes('-') ? field.type.split('-')[0] : field.type;

    // Get the corresponding component from the component map
    const Component = componentMap[typePrefix.toLowerCase()];

    // Return null if no component is found
    if (!Component) {
        return null;
    }

    // Properties for specific components
    let extraProps = {};
    if (field.type === 'lookup') {
        extraProps.options = field.options;
    }
    if (field.type === 'comments') {
        extraProps.fieldKey = field.key;
    }
    if (field.type === 'documents') {
        extraProps.fieldKey = field.key;
    }

    /**
     * Hide the field menu if the field is read-only.
     */
    if (readOnly) {
        showFieldMenu = false;
    }

    /**
     * Only show the menu for the following field types:
     * - lookup
     * - gallery
     */
    const fieldTypesWithMenu = [
        'lookup',
        'gallery',
        'videogallery',
        'objectchecklist',
        'checklist',
    ];

    if (!fieldTypesWithMenu.includes(typePrefix.toLowerCase())) {
        showFieldMenu = false;
    }

    return (
        <div key={field.key}>
            <Component
                object={object}
                field={field}
                onUpdate={onUpdate}
                showFieldMenu={showFieldMenu}
                readOnly={readOnly}
                {...extraProps}
            />
        </div>
    );
};

export default FormField;
