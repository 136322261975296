import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getFolderPath } from '../../utilities/Folders';

/**
 * Prepares the folder path keys when a folder is selected, for
 * use in the breadcrumb navigation.
 */
const useSetFolderPath = ({ profileFolders, selectedFolder, setPathKeys }) => {

    // Global
    const {
        profileItems
    } = useContext(Global);

    useEffect(() => {
        if (selectedFolder) {
            const keys = getFolderPath(profileFolders, selectedFolder);
            setPathKeys(keys);
        } else {
            setPathKeys([]);
        }
    }, [profileFolders, selectedFolder]);

};

export default useSetFolderPath;
