import React from 'react';

const PreviewIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M212.31-140q-29.83 0-51.07-21.24Q140-182.48 140-212.31v-535.38q0-29.83 21.24-51.07Q182.48-820 212.31-820h535.38q29.83 0 51.07 21.24Q820-777.52 820-747.69v535.38q0 29.83-21.24 51.07Q777.52-140 747.69-140H212.31Zm0-60h535.38q5.39 0 8.85-3.46t3.46-8.85V-680H200v467.69q0 5.39 3.46 8.85t8.85 3.46ZM480-300q-73.54 0-131.88-38.92-58.35-38.93-86.2-101.08 27.85-62.15 86.2-101.08Q406.46-580 480-580q73.54 0 131.88 38.92 58.35 38.93 86.2 101.08-27.85 62.15-86.2 101.08Q553.54-300 480-300Zm0-47.69q51.77 0 95.27-24.39 43.5-24.38 70.65-67.92-27.15-43.54-70.65-67.92-43.5-24.39-95.27-24.39-51.77 0-95.27 24.39-43.5 24.38-70.65 67.92 27.15 43.54 70.65 67.92 43.5 24.39 95.27 24.39Zm0-92.31Zm.09 52.31q21.83 0 37.02-15.29 15.2-15.28 15.2-37.11t-15.29-37.02q-15.28-15.2-37.11-15.2t-37.02 15.29q-15.2 15.28-15.2 37.11t15.29 37.02q15.28 15.2 37.11 15.2Z" />
  </svg>

);

export default PreviewIcon;
