import React from 'react';

const CutIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M767.69-129.23 481.54-415.39 372.15-306q8.39 15 11.96 31.81 3.58 16.81 3.58 34.19 0 60.29-42.92 103.22T241.58-93.85q-60.27 0-103.23-42.92T95.39-239.96q0-60.27 42.93-103.23t103.22-42.96q17.38 0 34.19 3.57 16.81 3.58 31.81 11.97L416.92-480 307.54-589.39q-15 8.39-31.81 11.97-16.81 3.57-34.19 3.57-60.29 0-103.22-42.92T95.39-719.96q0-60.27 42.92-103.23t103.19-42.96q60.27 0 103.23 42.93T387.69-720q0 17.38-3.58 34.19Q380.54-669 372.15-654l492.46 492.46v32.31h-96.92ZM592.31-526.15l-64.62-64.62 240-240h96.92v32.31l-272.3 272.31Zm-350.77-107.7q35.54 0 60.85-25.3 25.3-25.31 25.3-60.85 0-35.54-25.3-60.85-25.31-25.3-60.85-25.3-35.54 0-60.85 25.3-25.31 25.31-25.31 60.85 0 35.54 25.31 60.85 25.31 25.3 60.85 25.3Zm240 163.85q4 0 7-3t3-7q0-4-3-7t-7-3q-4 0-7 3t-3 7q0 4 3 7t7 3Zm-240 316.15q35.54 0 60.85-25.3 25.3-25.31 25.3-60.85 0-35.54-25.3-60.85-25.31-25.3-60.85-25.3-35.54 0-60.85 25.3-25.31 25.31-25.31 60.85 0 35.54 25.31 60.85 25.31 25.3 60.85 25.3Z" />
  </svg>

);

export default CutIcon;
