import React, { useContext, useState } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';

// Components
import Login from '../common/login/Login.js';

// Custom Hooks
import useConfirmLoginStatus from './hooks/ConfirmLoginStatus.js';
import usePreloadProfileFolders from './hooks/PreloadProfileFolders.js';
import usePreloadProfileFiles from './hooks/PreloadProfileFiles.js';
import usePreloadProfileItems from './hooks/PreloadProfileItems.js';
import usePreloadProfileEvents from './hooks/PreloadProfileEvents.js';
import usePreloadProfileCollections from './hooks/PreloadProfileCollections.js';
import usePreloadProfileFields from './hooks/PreloadProfileFields.js';
import usePreloadProfileObjects from './hooks/PreloadProfileObjects.js';

/*
import useLoadAppPermissions from './hooks/LoadAppPermissions.js';

import usePreloadProfileChannels from './hooks/PreloadProfileChannels.js';
import usePreloadProfileCollections from './hooks/PreloadProfileCollections.js';
import usePreloadProfileNewsfeeds from './hooks/PreloadProfileNewsfeeds.js';
import usePreloadProfileRoles from './hooks/PreloadProfileRoles.js';
import usePreloadProfileStories from './hooks/PreloadProfileStories.js';
import usePreloadMembers from './hooks/PreloadMembers.js';
import usePreloadMemberBookmarks from './hooks/PreloadMemberBookmarks.js';
import usePreloadMemberFavorites from './hooks/PreloadMemberFavorites.js';
import usePreloadMemberNotifications from './hooks/PreloadMemberNotifications.js';
import usePreloadConversations from './hooks/PreloadConversations.js';
*/

// Styles
import './Root.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Desktop from '../desktop/Desktop';
import Mobile from '../mobile/Mobile';
import Progress from '../common/components/progress/Progress';

// Dynamic Styles
import { RootStyles } from './RootStyles';

const Root = () => {

  // Theme
  const {
    defaultThemes,
    theme
  } = useTheme();

  // Global
  const {
    loginOpen,
    setLoginOpen,
    coreTheme,
    profile,
    screen,
  } = useContext(Global);

  // HOOKS

  /**
   * useConfirmLoginStatus
   * 
   * Detects the user's login status and sets the profile state variable
   * accordingly. If the user is logged in, the profile is fetched from
   * the database and set. If the user is not logged in, the profile is
   * set to null.
   */
  useConfirmLoginStatus();

  /**
   * usePreloadProfileFolders
   * usePreloadProfileFiles
   * 
   * These hooks preload the folders and files for the current profile.
   */
  usePreloadProfileFolders();
  usePreloadProfileFiles();
  usePreloadProfileItems();
  usePreloadProfileEvents();
  usePreloadProfileCollections();
  usePreloadProfileFields();
  usePreloadProfileObjects();




  // App Data Preload
  /*
  usePreloadProfileChannels();
  usePreloadProfileNewsfeeds();
  usePreloadProfileRoles();
  usePreloadProfileStories();
  usePreloadMemberBookmarks();
  usePreloadMemberFavorites();
  usePreloadMemberNotifications();
  usePreloadMembers();
  usePreloadConversations();

  // App Permissions
  useLoadAppPermissions();

  // Theme Preference
  useLoadThemePreference();
  */

  return (
    <>
      {/* LOGIN/SIGNUP */}
      <Login
        isOpen={loginOpen}
        setOpen={setLoginOpen}
      />

      <RootStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        placeholderColor={theme.foregroundColorFaded}
        scrollbarColor={theme.foregroundColorFaded}
      />

      {/* RUN */}
      {isMobile ? (
        <>
          <Mobile />
        </>
      ) : (
        <>
          <Desktop />
        </>
      )}


      {/* PROGRESS PANEL */}
      <Progress />

    </>
  );
};

export default Root;

