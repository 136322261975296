import React from 'react';

// Styles
import './ShareButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import ShareIcon from '../../../../../../common/svg/ShareIcon';

const ShareButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="share-button-container">
            <ShareIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default ShareButton;
