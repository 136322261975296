import React from 'react';

// Config
import { maskOpacity } from '../../../../../Config';

// Utilities
import { ThemeMode } from '../../../../utilities/Enums';

// Styles
import './GridItemMask.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * GridItemMask
 * 
 * Component to serve as a mask for grid items.
 * 
 * @returns {JSX.Element} GridItemMask component.
 */
const GridItemMask = () => {

    // Theme
    const { currentThemeMode } = useTheme();

    return (

        <div className="grid-item-mask-container"
            style={{
                backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
                opacity: maskOpacity
            }}></div>

    );
};

export default GridItemMask;
