import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Managers
import { activity } from '../../managers/ActivityManager';

// Styles
import './NewsfeedDelete.css';

// Components
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalMessage from '../../components/modal/message/ModalMessage';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * NewsfeedDelete Component
 * 
 * This component confirms a delete prior to deleting the newsfeed.
 * 
 * @param {object} newsfeed - The newsfeed object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NewsfeedDelete = ({ newsfeed, isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        profileNewsfeeds,
        profile,
        setFormFields,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the newsfeed.
     */
    const handleDelete = async () => {

        showProgress("Deleting newsfeed...");

        try {
            const newsfeedKey = newsfeed.key;

            await dataManager.delete(
                collections.newsfeeds, 
                profile.key, 
                newsfeedKey
            );

            // Update local newsfeeds state to delete the deleted newsfeed
            const updatedFields = profileNewsfeeds.filter(item => item.key !== newsfeedKey);

            setFormFields(updatedFields);

            // Log the activity
            activity.log(profile.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting newsfeed: ", error);
        }

        setOpen(false);

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Newsfeed" width={400} isOpen={isOpen} onClose={() => setOpen(false)}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="This will permanently delete all data in this newsfeed. Continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default NewsfeedDelete;
