import React, { createContext, useCallback, useState } from 'react';

// Utilities
import { CalendarType, LoginMode, MenuType, ObjectsView, Section } from './common/utilities/Enums';

// Settings Images
import FontIcon from './common/svg/FontIcon';
import IconIcon from './common/svg/IconIcon';
import LogoIcon from './common/svg/LogoIcon';
import LogoutIcon from './common/svg/LogoutIcon';
import PublicIcon from './common/svg/PublicIcon';
import SkullIcon from './common/svg/SkullIcon';
import ThemeIcon from './common/svg/ThemeIcon';
import WallpaperIcon from './common/svg/WallpaperIcon';

export const Global = createContext();

export const GlobalProvider = ({ children }) => {

  /**
   * The profile object contains user and user preference information.
   */
  const [profile, setProfile] = useState(null);

  /**
   * The following profile-specific state variables are preloaded when the 
   * profile is loaded, tied to the profile, and updated using real-time updates.
   */
  const [profileEvents, setProfileEvents] = useState([]);
  const [profileFields, setProfileFields] = useState([]);
  const [profileFiles, setProfileFiles] = useState([]);
  const [profileFolders, setProfileFolders] = useState([]);
  const [profileObjects, setProfileObjects] = useState([]);
  const [profileSpaces, setProfileSpaces] = useState([]);
  const [profileStories, setProfileStories] = useState([]);
  const [profileItems, setProfileItems] = useState([]);

  /**
   * Used to determine whether the login modal is open.
   */
  const [loginOpen, setLoginOpen] = useState(false);








  /**
   * Core theme for appdeck.
   */
  const coreTheme = {
    backgroundColor: "#0f0f0f",
    backgroundColorFaded: "#1F1F1F",
    highlightBackgroundColor: '#d2360d', //#5E3023',
    highlightForegroundColor: "#FFFFFF", //"#8d4834", 
    foregroundColor: "#FFFFFF",
    foregroundColorFaded: "#999999"
  };

  /** 
   * The app domain if it exists.
   */
  const [domain, setDomain] = useState(null);

  /** 
   * The currently logged in user.
   */
  const [currentUser, setCurrentUser] = useState(null);

  /** 
   * For You items.
   */
  const [foryouEvents, setForYouEvents] = useState([]);
  const [foryouFiles, setForYouFiles] = useState([]);

  /** 
   * Spaces the user has created or is a member of.
   */
  const [userSpaces, setUserSpaces] = useState([]);



  const [profileChannels, setProfileChannels] = useState([]);
  const [profileMembers, setProfileMembers] = useState([]);
  const [profileModels, setProfileModels] = useState([]);
  const [profileNewsfeeds, setProfileNewsfeeds] = useState([]);
  const [profileRoles, setProfileRoles] = useState([]);
  const [profilePublicRole, setProfilePublicRole] = useState(null);

  /** 
   * Notifications for a user across all apps.
   */
  const [userNotifications, setUserNotifications] = useState([]);

  /**
   * Website.
   **/
  const [selectedSiteElement, setSelectedSiteElement] = useState(null);

  /**
   * The following app user-specific state variables are preloaded when the first 
   * app is loaded or the current app changes.
   */
  const [memberBookmarks, setMemberBookmarks] = useState([]);
  const [memberFavorites, setMemberFavorites] = useState([]);
  const [memberNotifications, setMemberNotifications] = useState([]);

  /**
   * Menu type in desktop mode:
   * - TOP
   * - LEFT
   */
  const [menuType, setMenuType] = useState(MenuType.TOP);

  /**
   * Indicates whether the menu is visible on mobile.
   */
  const [menuVisible, setMenuVisible] = useState(false);

  /**
   * Back button navigation.
   * 
   * Handles click events on the back button.
   * - Sets visibility of the back button.
   * - Sets the function to execute on back button click.
   */
  const [backVisible, setBackVisible] = useState(false);
  const [backButtonAction, setBackButtonAction] = useState(null);

  /**
   * High-level screens:
   * 
   * - SPLASH - initial splash screen
   * - MAIN - logged in home screen
   * - WELCOME - welcome (logged out) screen
   * - RUN - screen in which individual apps are run
   */
  const [screen, setScreen] = useState(Screen.SPLASH);

  /**
   * Section of the app currently being viewed.
   * 
   * - ACCOUNT
   * - BOOKMARKS
   * - CALENDAR
   * - CHAT
   * - FAVORITES
   * - FORUM
   * - MEMBERS
   * - MESSAGES
   * - NOTIFICATIONS
   * - SETTINGS
   * - VIDEOCHAT
   */
  const [currentSection, setCurrentSection] = useState(Section.FOLDERS);

  /**
   * Resets app arrays.
   */
  const resetApp = () => {
    setProfileMembers([]);
    setProfileChannels([]);
    setProfileEvents([]);
    setProfileFields([]);
    setProfileFiles([]);
    setProfileFolders([]);
    setProfileModels([]);
    setProfileNewsfeeds([]);
    setProfileObjects([]);
    setProfileRoles([]);
    setProfileSpaces([]);
    setProfileStories([]);
    setConversations([]);
    setMemberBookmarks([]);
    setMemberFavorites([]);
    setMemberNotifications([]);
    setProfileMembers([]);
  };

  /**
   * Resets all lists and selections in preparation for a new selection.
   */
  const resetSelections = useCallback(() => {
    setMenuVisible(false);

    setCalendarCollection(null);
    setSelectedChannel(null);
    setSelectedConversation(null);
    setSelectedForumPost(null);
    setSelectedMember(null);
    setSelectedModel(null);
    setSelectedFolder(null);
    setSelectedNewsfeed(null);
    setSelectedObject(null);
    setSelectedSetting(null);
    setSelectedSpace(null);
  }, []); // No external variables

  /**
   * Settings for each app.
   */
  const settings = [
    { type: 'SETTING', key: 'GENERAL', icon: LogoIcon, title: 'About', description: 'My profile information' },
    { type: 'SETTING', key: 'THEME', icon: ThemeIcon, title: 'Theme', description: 'Colors for dark/light modes.' },
    { type: 'SETTING', key: 'WALLPAPER', icon: WallpaperIcon, title: 'Wallpaper', description: 'Background wallpaper image.' },
    { type: 'SETTING', key: 'ICON', icon: IconIcon, title: 'Icon', description: 'App icons for dark/light modes.' },
    { type: 'SETTING', key: 'FONT', icon: FontIcon, title: 'Font', description: 'Font family to use throughout the app.' },
    { type: 'SETTING', key: 'WEBSITE', icon: PublicIcon, title: 'Website', description: 'Website design and settings.' },
    { type: 'SETTING', key: 'SIGNOUT', icon: LogoutIcon, title: 'Sign Out', description: 'Sign out of your profile.' },
    { type: 'SETTING', key: 'DELETE', icon: SkullIcon, title: 'Delete Profile', description: 'Permanently delete your profile.' },
  ];
  const [selectedSetting, setSelectedSetting] = useState(null);

  /**
   * Permissions for all roles within an app.
   */
  const [appPermissions, setAppPermissions] = useState({});

  /**
   * The app member profile currently being viewed.
   */
  const [selectedMember, setSelectedMember] = useState(null);

  /**
   * Model-related state variables.
   */
  const [changesMade, setChangesMade] = useState(false);
  const [currentObject, setCurrentObject] = useState({});
  const [elements, setElements] = useState([]);
  const [fieldFilter, setFieldFilter] = useState('');
  const [fieldVisible, setFieldVisible] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [lastCamera, setLastCamera] = useState(""); // Barcode Scanning
  const [modelAdd, setModelAddOpen] = useState(false);
  const [modelView, setModelView] = useState(ObjectsView.GRID);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedFormField, setSelectedFormField] = useState(null);
  const [selectedTableField, setSelectedTableField] = useState(null);
  const [selectedTileElement, setSelectedTileElement] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedObjectTags, setSelectedObjectTags] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [userElements, setUserElements] = useState([]);

  /**
   * Channel-related state variables.
   */
  const [channelAddOpen, setChannelAddOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedForumPost, setSelectedForumPost] = useState(null);
  const [selectedForumPostBlock, setSelectedForumPostBlock] = useState(null);
  const [selectedForumTags, setSelectedForumTags] = useState([]);

  /**
   * Story-related state variables.
   */
  const [selectedStory, setSelectedStory] = useState(null);
  const [selectedStoryBlock, setSelectedStoryBlock] = useState(null);

  /**
   * Calendar-related state variables.
   */
  const [activeDate, setActiveDate] = useState(new Date());
  const [calendarCollection, setCalendarCollection] = useState(null);
  const [calendarTitle, setCalendarTitle] = useState('');
  const [currentDay, setCurrentDay] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [defaultEventTag, setDefaultEventTag] = useState(false);
  const [eventCollection, setEventCollection] = useState(null);
  const [eventObject, setEventObject] = useState(null);
  const [eventVisible, setEventVisible] = useState(null);
  const [monthPickerVisible, setMonthPickerVisible] = useState(false);
  const [selectedCalendarTags, setSelectedCalendarTags] = useState([]);
  const [selectedCalendarType, setSelectedCalendarType] = useState(CalendarType.DAY);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  /** 
   * File and folder related state variables.
   */
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedFolderItems, setSelectedFolderItems] = useState([]);

  /** 
   * Newsfeed-related state variables.
   */
  const [newsfeedAddOpen, setNewsfeedAddOpen] = useState(false);
  const [selectedNewsfeed, setSelectedNewsfeed] = useState(null);

  /** 
   * Space-related state variables.
   */
  const [spaceAddOpen, setSpaceAddOpen] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState(null);

  /**
   * Progress-related state variables and functions.
   */
  const [progressMessage, setProgressMessage] = useState("Ready.");
  const [progressVisible, setProgressVisible] = useState(false);

  // Show
  const showProgress = (message) => {
    setProgressMessage(message);
    setProgressVisible(true);
  };

  // Hide
  const hideProgress = () => {
    setProgressMessage("Ready.");
    setProgressVisible(false);
  };

  /**
   * Conversation-related state variables.
   */
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);

  /**
   * Account-related state variables.
   */
  const [accountAboutVisible, setAccountAboutVisible] = useState(false);
  const [accountBillingVisible, setAccountBillingVisible] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);

  /**
   * User search form visibility.
   */
  const [userSearchVisible, setUserSearchVisible] = useState(false);

  // Financials
  const [targetApp, setTargetApp] = useState("ALL");
  const [timespan, setTimespan] = useState("LAST24"); // CURRENT, LAST60, LAST24, LAST7, LAST30

  return (
    <Global.Provider value={{

      // Profile
      profile, setProfile,

      // Profile Preloads
      profileEvents, setProfileEvents,
      profileFields, setProfileFields,
      profileFiles, setProfileFiles,
      profileFolders, setProfileFolders,
      profileObjects, setProfileObjects,
      profileSpaces, setProfileSpaces,
      profileStories, setProfileStories,
      profileItems, setProfileItems,

      // Login Open
      loginOpen, setLoginOpen,











      // Core Theme
      coreTheme,

      // Domain
      domain, setDomain,

      // Current User
      currentUser, setCurrentUser,

      // For You items
      foryouEvents, setForYouEvents,
      foryouFiles, setForYouFiles,

      // User Apps
      userSpaces, setUserSpaces,

      // User Notifications
      userNotifications, setUserNotifications,

      // Website
      selectedSiteElement, setSelectedSiteElement,

      // Space Preloads
      profileChannels, setProfileChannels,
      profileModels, setProfileModels,
      profileMembers, setProfileMembers,
      profileNewsfeeds, setProfileNewsfeeds,
      profileRoles, setProfileRoles,
      profilePublicRole, setProfilePublicRole,

      // App Settings
      settings,

      // Back Button Navigation
      backVisible, setBackVisible,
      backButtonAction, setBackButtonAction,

      // Screen
      screen, setScreen,

      // Section
      currentSection, setCurrentSection,

      // Reset Functions
      resetApp,
      resetSelections,

      // App
      appPermissions, setAppPermissions,

      // Menu
      menuType, setMenuType,
      menuVisible, setMenuVisible,

      // Selected Tags
      selectedForumTags, setSelectedForumTags,

      // Bookmarks
      memberBookmarks, setMemberBookmarks,

      // Favorites
      memberFavorites, setMemberFavorites,

      // Notifications
      memberNotifications, setMemberNotifications,

      // Calendars
      activeDate, setActiveDate,
      calendarCollection, setCalendarCollection,
      calendarTitle, setCalendarTitle,
      currentDay, setCurrentDay,
      currentMonth, setCurrentMonth,
      currentWeek, setCurrentWeek,
      currentYear, setCurrentYear,
      defaultEventTag, setDefaultEventTag,
      eventCollection, setEventCollection,
      eventObject, setEventObject,
      eventVisible, setEventVisible,
      monthPickerVisible, setMonthPickerVisible,
      selectedCalendarTags, setSelectedCalendarTags,
      selectedCalendarType, setSelectedCalendarType,
      selectedEvent, setSelectedEvent,
      selectedTime, setSelectedTime,

      // Models
      changesMade, setChangesMade,
      modelAdd, setModelAddOpen,
      currentObject, setCurrentObject,
      elements, setElements,
      fieldFilter, setFieldFilter,
      fieldVisible, setFieldVisible,
      formFields, setFormFields,
      lastCamera, setLastCamera,
      modelView, setModelView,
      selectedBlock, setSelectedBlock,
      selectedModel, setSelectedModel,
      selectedFormField, setSelectedFormField,
      selectedTableField, setSelectedTableField,
      selectedTileElement, setSelectedTileElement,
      selectedObject, setSelectedObject,
      selectedObjectTags, setSelectedObjectTags,
      sortField, setSortField,
      sortDirection, setSortDirection,
      userElements, setUserElements,

      // Folders
      selectedFolder, setSelectedFolder,
      selectedFolderItems, setSelectedFolderItems,

      // Newsfeeds
      newsfeedAddOpen, setNewsfeedAddOpen,
      selectedNewsfeed, setSelectedNewsfeed,

      // Spaces
      spaceAddOpen, setSpaceAddOpen,
      selectedSpace, setSelectedSpace,

      // Progress
      progressVisible, showProgress, hideProgress,
      progressMessage, setProgressMessage,

      // Conversations
      conversations, setConversations,
      selectedConversation, setSelectedConversation,

      // Financials
      targetApp, setTargetApp,
      timespan, setTimespan,

      // Account
      accountAboutVisible, setAccountAboutVisible,
      accountBillingVisible, setAccountBillingVisible,
      accountOpen, setAccountOpen,

      // User Search
      userSearchVisible, setUserSearchVisible,

      // App Users
      selectedMember, setSelectedMember,

      // Channels
      selectedChannel, setSelectedChannel,
      channelAddOpen, setChannelAddOpen,
      selectedForumPost, setSelectedForumPost,
      selectedForumPostBlock, setSelectedForumPostBlock,

      // Stories
      selectedStory, setSelectedStory,
      selectedStoryBlock, setSelectedStoryBlock,

      // Settings
      selectedSetting, setSelectedSetting,

    }}>
      {children}
    </Global.Provider>
  );
};
