import React from 'react';

// Styles
import './SiteCanvasButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const SiteCanvasButton = ({ title, icon: IconComponent, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="site-canvas-button-container"
            onClick={onClick}
            title={title}
            style={{
                backgroundColor: theme.backgroundColorFaded,
                color: theme.foregroundColor,
            }}>
            <IconComponent
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
            <div className="site-canvas-button-title">
                {title}
            </div>
        </div>
    );
};

export default SiteCanvasButton;

