import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Translation
import { useTranslation } from 'react-i18next';

// Utilities
import { isValidEmail } from '../../../common/utilities/Validators';

// Firebase
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, collections, db } from '../../../firebaseConfig';

// Styles
import './SignupForm.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import LoginButton from '../button/LoginButton';
import LoginInput from '../input/LoginInput';
import LoginLabel from '../label/LoginLabel';

// Managers
import NotificationManager from '../../../common/managers/NotificationManager';
import ProfileManager from '../../../common/managers/ProfileManager';

const notificationManager = new NotificationManager();
const profileManager = new ProfileManager();

/**
 * SignupForm Component
 * 
 * This component renders a signup form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function SignupForm() {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    coreTheme,
    hideProgress,
    profile,
    setProfile,
    showProgress
  } = useContext(Global);

  // Translation
  const { t } = useTranslation(['Common', 'Login']);

  // Local State
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthdateMonth, setBirthdateMonth] = useState('JAN');
  const [birthdateDay, setBirthdateDay] = useState('1');
  const [birthdateDays, setBirthdateDays] = useState([]);
  const [birthdateYear, setBirthdateYear] = useState(2025);
  const [birthdayYears, setBirthdateYears] = useState([]);
  const [email, setEmail] = useState(''); // The editable email field
  const [password, setPassword] = useState(''); // Password (required)
  const [formValid, setFormValid] = useState(false);

  /**
   * Pre-populate the months list.
   */
  const birthdateMonths = [
    { value: "JAN", label: t('Common:month_jan') },
    { value: "FEB", label: t('Common:month_feb') },
    { value: "MAR", label: t('Common:month_mar') },
    { value: "APR", label: t('Common:month_apr') },
    { value: "MAY", label: t('Common:month_may') },
    { value: "JUN", label: t('Common:month_jun') },
    { value: "JUL", label: t('Common:month_jul') },
    { value: "AUG", label: t('Common:month_aug') },
    { value: "SEP", label: t('Common:month_sep') },
    { value: "OCT", label: t('Common:month_oct') },
    { value: "NOV", label: t('Common:month_nov') },
    { value: "DEC", label: t('Common:month_dec') },
  ];

  /**
   * Pre-populate password requirements.
   */
  const passwordRequirements = [
    t('Login:password_char_length'),
    t('Login:password_uppercase'),
    t('Login:password_lowercase'),
    t('Login:password_number'),
    t('Login:password_special_char')
  ];

  /**
   * Hook to validate the form.
   */
  useEffect(() => {
    setFormValid(
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      isValidEmail(email) &&
      password.length > 0 &&
      2025 - birthdateYear >= 5
    );
  }, [firstName, lastName, birthdateMonth, birthdateDay, birthdateYear, email, password, setFormValid]);

  /**
   * Calculates the number of days in a given month and year.
   * 
   * @param {number} month The month.
   * @param {number} year The year.
   * 
   * @returns {Array} The days in the month.
   */
  const getDaysInMonth = (month, year) => {
    const monthIndex = birthdateMonths.findIndex(m => m.value === month) + 1;
    const daysInMonth = new Date(year, monthIndex, 0).getDate();
    return Array.from({ length: daysInMonth }, (_, i) => ({ value: `${i + 1}`, label: `${i + 1}` }));
  };

  /**
   * Populates the year list dynamically.
   */
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: currentYear - 1905 + 1 }, (_, i) => ({
      value: currentYear - i,
      label: `${currentYear - i}`,
    }));
    setBirthdateYears(yearOptions);
  }, []);

  /**
   * Updates the days list whenever month or year changes.
   */
  useEffect(() => {
    setBirthdateDays(getDaysInMonth(birthdateMonth, birthdateYear));
  }, [birthdateMonth, birthdateYear]);

  /**
   * Handles the submit of the signup form.
   */
  const handleSubmit = async (e) => {

    // If the form isn't valid, return
    if (!formValid) return;

    showProgress(t('Login:progress_signing_up'));

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    // Current timestamp
    const now = Timestamp.now();

    const data = {
      key: user.uid,
      firstName,
      lastName,
      birthdateMonth,
      birthdateDay,
      birthdateYear,
      email,
      dateCreated: now,
      dateModified: now,
    };

    await setDoc(doc(db, collections.profiles, user.uid), data);

    // Set the profile
    const fetchedUser = await profileManager.getProfile(auth.currentUser.uid);
    setProfile(fetchedUser);

    // Request notification permission after successful login
    await notificationManager.requestNotificationPermission(fetchedUser);

    hideProgress();
  };

  /**
   * Method to handle first name change.
   */
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value.trim());
  };

  /**
   * Method to handle last name change.
   */
  const handleLastNameChange = (e) => {
    setLastName(e.target.value.trim());
  };

  /**
   * Method to handle birthdate month change.
   */
  const handleBirthdateMonthChange = (e) => {
    setBirthdateMonth(e.target.value);
  };

  /**
   * Method to handle birthdate day change.
   */
  const handleBirthdateDayChange = (e) => {
    setBirthdateDay(e.target.value);
  };

  /**
   * Method to handle birthdate year change.
   */
  const handleBirthdateYearChange = (e) => {
    setBirthdateYear(e.target.value);
  };

  /**
   * Method to handle email change.
   */
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  /**
   * Method to handle password change.
   */
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (

    <div className="signup-form-container">

      {/* NAME */}
      <div className="signup-form-name-container">

        {/* FIRST NAME */}
        <div className="login-field-container">

          {/* LABEL */}
          <LoginLabel
            text={t('Login:label_name_first')}
          />

          {/* INPUT */}
          <LoginInput
            type="text"
            onChange={handleFirstNameChange}
            required={true}
          />

        </div>

        {/* LAST NAME */}
        <div className="login-field-container">

          {/* LABEL */}
          <LoginLabel
            text={t('Login:label_name_last')}
          />

          {/* INPUT */}
          <LoginInput
            type="text"
            onChange={handleLastNameChange}
            required={true}
          />

        </div>

      </div>

      {/* BIRTHDATE */}
      <div className="signup-form-birthdate-container">

        {/* MONTH */}
        <div className="login-field-container">

          {/* LABEL */}
          <LoginLabel
            text={t('Login:label_birthdate')}
          />

          {/* INPUT */}
          <LoginInput
            type="select"
            onChange={handleBirthdateMonthChange}
            options={birthdateMonths}
            required={true}
          />

        </div>

        {/* DAY */}
        <div className="login-field-container"
          style={{
            justifyContent: "flex-end",
            width: "120px"
          }}>

          {/* INPUT */}
          <LoginInput
            type="select"
            onChange={handleBirthdateDayChange}
            options={birthdateDays}
            required={true}
          />

        </div>

        {/* YEAR */}
        <div className="login-field-container"
          style={{
            justifyContent: "flex-end",
            width: "160px"
          }}>

          {/* INPUT */}
          <LoginInput
            type="select"
            onChange={handleBirthdateYearChange}
            options={birthdayYears}
            required={true}
          />

        </div>

      </div>

      {/* EMAIL */}
      <div className="login-field-container">

        {/* LABEL */}
        <LoginLabel
          text={t('Login:label_email')}
        />

        {/* INPUT */}
        <LoginInput
          type="text"
          onChange={handleEmailChange}
          required={true}
        />

      </div>

      {/* PASSWORD */}
      <div className="login-field-container">

        {/* LABEL */}
        <LoginLabel
          text={t('Login:label_password')}
        />

        {/* INPUT */}
        <LoginInput
          type="password"
          onChange={handlePasswordChange}
          required={true}
        />

      </div>
      
      {/* PASSWORD REQUIREMENTS*/}
      <div className="signup-form-password-requirements"
          style={{
            color: profile ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded,
          }}>
            {passwordRequirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
        </div>

      {/* SUBMIT */}
      <LoginButton
        label={t('Login:button_sign_up')}
        enabled={formValid}
        onClick={handleSubmit}
      />

    </div>

  );
}

export default SignupForm;
