import React, { useEffect, useRef, useState } from 'react';

// Utilities      
import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './ContextMenuPopup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ContextMenuPopup Component
 * 
 * This component renders a popup for displaying context menu items.
 * 
 * @param {boolean} visible - Indicates whether the popup is visible.
 * @param {function} setVisible - The function to call to set the visibility of the popup.
 * @param {string} backgroundColor - The background color of the popup.
 * @param {object} position - The position of the popup with optional keys: top, left, bottom, right.
 * @param {integer} width - The width of the popup.
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuPopup = ({ visible, setVisible, backgroundColor, position, width, children }) => {
  // Theme
  const { currentThemeMode } = useTheme();

  const menuRef = useRef(null);

  // State to store the adjusted position
  const [adjustedPosition, setAdjustedPosition] = useState(position);

  useEffect(() => {
    if (visible && menuRef.current) {
      const menuHeight = menuRef.current.offsetHeight;
      const menuWidth = menuRef.current.offsetWidth;

      const newPosition = { ...position };

      // Adjust vertical positioning (top or bottom)
      if (position.top !== undefined) {
        if (position.top + menuHeight > window.innerHeight) {
          newPosition.top = Math.max(0, window.innerHeight - menuHeight - 10);
        }
      } else if (position.bottom !== undefined) {
        if (window.innerHeight - position.bottom < menuHeight) {
          newPosition.bottom = Math.max(10, window.innerHeight - menuHeight - 10);
        }
      }

      // Adjust horizontal positioning (left or right)
      if (position.left !== undefined) {
        if (position.left + menuWidth > window.innerWidth) {
          newPosition.left = Math.max(0, window.innerWidth - menuWidth - 10);
        }
      } else if (position.right !== undefined) {
        if (window.innerWidth - position.right < menuWidth) {
          newPosition.right = Math.max(10, window.innerWidth - menuWidth - 10);
        }
      }

      // Update the state with the adjusted position
      setAdjustedPosition(newPosition);
    }
  }, [visible, position]);

  return (
    <>
      {visible && (
        <>
          {/* MASK */}
          <div
            className="context-menu-mask"
            style={{
              backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
            }}
            onClick={() => setVisible(false)}
          ></div>

          {/* POPUP */}
          <div
            ref={menuRef}
            className="context-menu-popup-container"
            style={{
              ...adjustedPosition, // Dynamically apply the adjusted position
              width: width,
              backgroundColor: backgroundColor,
              position: 'fixed', // Ensure alignment to viewport
            }}
          >
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default ContextMenuPopup;
