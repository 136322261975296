import React, { useContext, useState, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Utilities
import { getEventTimespan } from '../../../utilities/Events';

// Styles
import './CalendarCollectionEvent.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Managers
import EventManager from '../../../managers/EventManager';

const eventManager = new EventManager();

// Inline styles for the event fade
const InlineStyles = useStyle`
  .calendar-collection-event-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: linear-gradient(to bottom, transparent, ${(props) => props.gradientColor});
    z-index: 1;
  }
`;

const CalendarCollectionEvent = ({
  event,
  sourceObject,
  top,
  left,
  width,
  height,
  rowHeight,
  segmentLength,
  useDrag
}) => {

  // Theme (from your ThemeContext)
  const { theme } = useTheme();

  // Global (from your Global context)
  const {
    profile,
    setEventVisible,
    setSelectedEvent
  } = useContext(Global);

  // Local State for resizing
  const [resizeHeight, setResizeHeight] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  // We'll use a ref for our container, rather than using document.querySelector
  const containerRef = useRef(null);

  // Drag
  let dragRef = null;
  let isDragging = false;

  const eventRef = useRef(event);

  // Track the latest sourceObject dynamically
  const sourceObjectRef = useRef(sourceObject);

  // Update the event and sourceObject refs whenever they change
  useEffect(() => {
    eventRef.current = event;
  }, [event]);

  useEffect(() => {
    sourceObjectRef.current = sourceObject;
  }, [sourceObject]);

  const [{ isDragging: dndIsDragging }, drag] = useDrag(() => ({
    type: 'event',
    item: () => ({
      event: eventRef.current,
      sourceObject: sourceObjectRef.current, // Always use the latest sourceObject after previous drops
    }),
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  dragRef = drag;
  isDragging = dndIsDragging;

  /**
   * Show event details in your side panel, modal, or wherever
   */
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventVisible(true);
  };

  /**
   * Called when the user presses down on the "resize handle"
   */
  const handleResizeStart = (e) => {
    e.preventDefault();
    setIsResizing(true);
  };

  useEffect(() => {
    /**
     * On mouse move, we calculate the new height
     */
    const handleResize = (e) => {
      if (isResizing && containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const newHeight = e.clientY - rect.top; // distance from the top of the container to the mouse
        setResizeHeight(newHeight);
      }
    };

    /**
     * On mouse up, we're done resizing, so we do the final update
     */
    const handleResizeEnd = async () => {
      if (isResizing) {
        setIsResizing(false);

        // If we never actually moved, just bail out
        if (!resizeHeight) return;

        // Calculate time length from the new height
        const startTime = event.startDate.toDate();
        const hoursAdded = resizeHeight / rowHeight;
        const endTime = new Date(startTime.getTime() + hoursAdded * 60 * 60 * 1000);

        // Snap to the nearest segmented interval
        const roundedMinutes = Math.round(endTime.getMinutes() / segmentLength) * segmentLength;
        endTime.setMinutes(roundedMinutes);
        endTime.setSeconds(0);

        // Update the event in Firestore
        const newEndTimestamp = Timestamp.fromDate(endTime);
        const data = { endDate: newEndTimestamp };

        console.log('Post-resize:', newEndTimestamp.toDate());
        await eventManager.update(profile.key, event.key, data);
      }
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, resizeHeight, profile.key, event, segmentLength]);

  return (
    <>
      {/* Inline style with gradient */}
      <InlineStyles gradientColor={theme.highlightBackgroundColor} />

      {/* MAIN EVENT CONTAINER */}
      <div
        ref={dragRef ? dragRef(containerRef) : containerRef}
        // If you're using react-dnd, pass containerRef into 'dragRef'
        // otherwise just use "ref={containerRef}"
        className="calendar-collection-event-container"
        onClick={() => handleEventClick(event)}
        style={{
          position: 'absolute',
          top: `${top}px`,
          left: `${left}px`,
          width: `${width - 10}px`, // Add a little space to the right
          // IMPORTANT: when resizing, we override the original height
          height: isResizing && resizeHeight
            ? `${resizeHeight}px`
            : `${height}px`,
          backgroundColor: theme.highlightBackgroundColor,
          borderColor: theme.backgroundColor,
          overflow: 'hidden',
          opacity: isDragging ? 0.5 : 1
        }}
      >
        {/* EVENT TITLE */}
        <div
          className="calendar-collection-event-title"
          style={{ color: theme.highlightForegroundColor }}
        >
          {event.title}
        </div>

        {/* EVENT TIME */}
        <div
          className="calendar-collection-event-time"
          style={{ color: theme.highlightForegroundColor }}
        >
          {getEventTimespan(event)}
        </div>

        {/* ATTACHMENTS & FORM DATA */}
        {event.attachments && (
          <div
            className="calendar-collection-event-details"
            style={{ color: theme.highlightForegroundColor }}
          >
            {event.attachments.map((item, index) => (
              <div
                key={index}
                className="calendar-collection-event-detail"
              >
                {item.objectTitle}
              </div>
            ))}
            {Object.entries(event.formData)
              .filter(([key]) => {
                const field = event.fields.find(f => f.key === key);
                return field && field.type !== 'checklist';
              })
              .map(([key, value], index) => (
                <div key={index} className="calendar-collection-event-detail">
                  {Array.isArray(value)
                    ? value.map(item => (
                      <div key={item.key}>
                        {item.note || ''}
                      </div>
                    ))
                    : value}
                </div>
              ))}
          </div>
        )}

        {/* FADE */}
        <div className="calendar-collection-event-fade"></div>

        {/* RESIZE HANDLE */}
        <div
          className="calendar-collection-event-resize-handle"
          onMouseDown={handleResizeStart}
          style={{
            backgroundColor: theme.backgroundColor,
            cursor: 'row-resize',
            opacity: isDragging ? 0.4 : 0.2
          }}
        ></div>
      </div>
    </>
  );
};

export default CalendarCollectionEvent;
