import React from 'react';

// Styles
import './FolderShareCount.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * FolderShareCount
 * 
 * This component renders the share count for a folder.
 * 
 * @param {object} count - The count of shares.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const FolderShareCount = ({ count }) => {

    // Theme
    const { theme } = useTheme();

    if (count < 1) return null;

    return (
        <div className="share-count-container"
            style={{
                color: theme.foregroundColorFaded,
            }}>

            <div className="share-count-circle">
                {`${count}`}
            </div>

        </div>
    );
};

export default FolderShareCount;
