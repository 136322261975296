import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load collections for a specific app.
 */
const usePreloadProfileCollections = () => {

  // Global
  const {
    profile, 
    setProfileModels,
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!profile || !profile.key) {
      setProfileModels([]);
      return;
    }

    // Function to load models
    const loadModels = (profileKey) => {
      const handleUpdate = (items) => {
        setProfileModels(items);
        activity.log(profileKey, 'reads', items.length);
      };

      const sortFields = [
        "sort", // Ascending by default
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.models,
        profileKey,
        handleUpdate,
        [],
        sortFields
      );

      return unsubscribe;
    };

    // Subscribe to models
    const unsubscribeModels = loadModels(profile.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeModels) {
        unsubscribeModels();
      }
    };
  }, [profile, setProfileModels]);

};

export default usePreloadProfileCollections;
