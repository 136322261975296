import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Config
import { validSummaryTextTypes } from '../../../Config';

// Styles
import './Properties.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AccordionSection from '../../../common/components/accordion/AccordionSection';
import ColorPopup from './inputs/colorpopup/ColorPopup';
import DecimalUnitInput from './inputs/decimalunitinput/DecimalUnitInput';
import IntegerInput from './inputs/integerinput/IntegerInput';
import IntegerUnitInput from './inputs/integerunitinput/IntegerUnitInput';
import OpacityInput from './inputs/opacityinput/OpacityInput';
import StylePicker from './inputs/stylepicker/StylePicker';
import ImageFilterControl from './inputs/imagefiltercontrol/ImageFilterControl';

// Helpers
import PropertiesHelper from './PropertiesHelper';

const propertiesHelper = new PropertiesHelper();

const Properties = ({ rows, setRows }) => {
    const { theme } = useTheme();
    const {
        selectedTileElement,
        setElements,
        setSelectedTileElement
    } = useContext(Global);

    // Local State
    const [needsSave, setNeedsSave] = useState(false);
    const [styles, setStyles] = useState({});

    const [isVisible, setIsVisible] = useState(false); // Controls visibility
    const [isFading, setIsFading] = useState(false);   // Controls the fading state
    const previousKeyRef = useRef(null);               // To store the previous key

    // Fade in/out effect when the selected element changes
    useEffect(() => {
        if (!selectedTileElement) return;

        //if (selectedTileElement.type !== 'field' && selectedTileElement.type !== 'static') return;

        setIsFading(true);
        setIsVisible(false);

        // Field
        if ((selectedTileElement.type === 'field' && selectedTileElement?.field.key && selectedTileElement.field.key !== previousKeyRef.current)) {
            const timeoutId = setTimeout(() => {
                setIsFading(false);
                setIsVisible(true);
                previousKeyRef.current = selectedTileElement.field.key;
            }, 200);

            return () => clearTimeout(timeoutId); // Clean up the timeout
        }

        // Static
        const timeoutId = setTimeout(() => {
            setIsFading(false);
            setIsVisible(true);
        }, 200);

        return () => clearTimeout(timeoutId); // Clean up the timeout

    }, [selectedTileElement]);

    /**
     * Initialize the styles when the selected element changes.
     */
    useEffect(() => {
        if (!selectedTileElement) return;

        // Initialize style sets
        setStyles(selectedTileElement.styles || {});

    }, [selectedTileElement]);

    /**
     * Sets a style when it changes.
     */
    const handleStyleChange = (e, property) => {
        const value = e.target.value;

        // Update the styles for the correct element in the rows
        const updatedRows = rows.map(row => ({
            ...row,
            blocks: row.blocks.map(block => ({
                ...block,
                content: block.content.map(contentElement => {
                    // Find the element that matches the elementKey and update its styles
                    if (contentElement.key === selectedTileElement.key) {
                        return {
                            ...contentElement,
                            styles: {
                                ...contentElement.styles, // Retain existing styles
                                [property]: value, // Update the specific style property
                            },
                        };
                    }
                    return contentElement;
                }),
            })),
        }));

        // Update the rows state
        setRows(updatedRows);
        setNeedsSave(true); // Trigger save when needed
    };

    /**
     * Gets the options for a specific property.
     */
    const getOptionsForProperty = (property) => {
        return PropertiesHelper.stylePickerMapping[property] || [];
    };

    /**
     * Renders the inputs for a set of properties.
     */
    const getInputs = (propertySet) => {
        let properties = [...propertySet];

        return properties.map((property) => (
            <div
                className="properties-font-container"
                style={{ flexDirection: "column" }}
                key={property}>
                <div
                    className="properties-font-label"
                    style={{
                        color: theme.foregroundColorFaded,
                        marginBottom: "8px",
                    }}>
                    {propertiesHelper.formatLabel(property)}:
                </div>
                <div
                    className="properties-font-input-wrapper"
                    style={{
                        marginBottom: "8px",
                    }}>
                    {PropertiesHelper.colorProperties.includes(property) ? (
                        <ColorPopup
                            theme={theme}
                            value={styles[property] || theme.foregroundColor}
                            onSelect={(color) => {
                                handleStyleChange({ target: { value: color } }, property);
                                setNeedsSave(true);
                            }}
                        />
                    ) : PropertiesHelper.integerProperties.includes(property) ? (
                        <IntegerInput
                            className="properties-font-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                            }}
                            value={styles[property] || ''}
                            onChange={(e) => handleStyleChange(e, property)}
                            onBlur={save}
                        />
                    ) : PropertiesHelper.decimalUnitProperties.includes(property) ? (
                        <DecimalUnitInput
                            className="properties-font-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                            }}
                            value={styles[property] || '1em'}
                            onChange={(value) => {
                                handleStyleChange({ target: { value } }, property);
                                setNeedsSave(true);
                            }}
                            property={property}
                            onBlur={save}
                        />
                    ) : (property === "opacity") ? (
                        <OpacityInput
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                            }}
                            value={styles[property] || 2.0} // Default to 1.0 if value is not provided
                            onChange={(newOpacity) => handleStyleChange({ target: { value: newOpacity } }, property)}
                            onBlur={(newOpacity) => {
                                handleStyleChange({ target: { value: newOpacity } }, property);
                                setNeedsSave(true);
                            }}
                        />
                    ) : (property === "filter") ? (
                        <ImageFilterControl
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                            }}
                            value={styles['filter'] || ''}
                            onChange={(newFilter) => handleStyleChange({ target: { value: newFilter } }, 'filter')}
                            onBlur={(newFilter) => {
                                handleStyleChange({ target: { value: newFilter } }, 'filter');
                                setNeedsSave(true);
                            }}
                        />
                    ) : PropertiesHelper.integerUnitProperties.includes(property) ? (
                        <IntegerUnitInput
                            className="properties-font-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                            }}
                            value={styles[property] || ''}
                            onChange={(value) => {
                                handleStyleChange({ target: { value } }, property);
                                setNeedsSave(true);
                            }}
                            property={property}
                            onBlur={save}
                        />
                    ) : property in PropertiesHelper.stylePickerMapping ? (
                        <StylePicker
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded
                            }}
                            value={styles[property] || 'normal'}
                            styleName={property}
                            options={getOptionsForProperty(property)}
                            onChange={(value) => {
                                handleStyleChange({ target: { value } }, property);
                                setNeedsSave(true);
                            }}
                        />
                    ) : (
                        <input
                            type="text"
                            className="properties-font-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                borderStyle: ""
                            }}
                            value={styles[property] || ''}
                            onChange={(e) => handleStyleChange(e, property)}
                            onBlur={save}
                        />
                    )}
                </div>
            </div>
        ));
    };

    /**
     * Memoizes the data object to avoid unnecessary re-renders
     */
    const updatedElement = useMemo(() => {
        return {
            ...selectedTileElement,
            styles: propertiesHelper.sanitizeCSS(styles)
        };
    }, [styles, selectedTileElement]);

    /**
     * Saves the updated element to the elements array and updates the selected element.
     */
    const save = useCallback(async () => {

        setSelectedTileElement(updatedElement);

        // Update the elements array by replacing the updated element
        setElements(prevElements =>
            prevElements.map(el => el.key === updatedElement.key ? updatedElement : el)
        );

        // Reset the flag after saving
        setNeedsSave(false);

    }, [updatedElement, setElements, setSelectedTileElement]);

    /**
     * Effect available for inputs that require a delay before saving.
     */
    useEffect(() => {
        if (needsSave) {
            save();
        }
    }, [needsSave, save]);

    return (
        <>
            {/* CONTAINER */}
            <div className="properties-container"
                style={{
                    backgroundColor: theme.backgroundColor,
                    borderLeftColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    borderRightColor: theme.backgroundColorFaded,
                }}>

                {selectedTileElement &&
                    <>
                        <div className="properties-model"
                            style={{
                                opacity: isVisible ? 1 : 0,
                                transition: isFading ? 'none' : 'opacity 0.1s linear'
                            }}>
                            <>
                                {/* TEXT SECTION */}
                                {((selectedTileElement.type === "field" && (validSummaryTextTypes.includes(selectedTileElement.field.type))) ||
                                    (selectedTileElement.type === "static" && selectedTileElement.elementType === 'created') ||
                                    (selectedTileElement.type === "static" && selectedTileElement.elementType === 'viewcount') ||
                                    (selectedTileElement.type === "static" && selectedTileElement.elementType === 'tags') ||
                                    (selectedTileElement.type === "static" && selectedTileElement.elementType === 'username') ||
                                    (selectedTileElement.type === "button" && selectedTileElement.buttonType === 'rate')) &&
                                    <AccordionSection title="Text" expanded={true}>
                                        {getInputs(PropertiesHelper.textProperties)}
                                    </AccordionSection>
                                }

                                {/* IMAGE SECTION */}
                                {selectedTileElement.type === "field" && selectedTileElement.field.type === 'gallery' &&
                                    <AccordionSection title="Image" expanded={true}>
                                        {getInputs(PropertiesHelper.imageProperties)}
                                    </AccordionSection>
                                }

                                {/* POSITION SECTION 
                                <AccordionSection title="Position" expanded={true}>
                                    {getInputs(PropertiesHelper.positionProperties)}
                                </AccordionSection>
                                */}

                                {/* ELEMENT CONTAINER */}
                                <AccordionSection title="Container" expanded={true}>
                                    {getInputs(PropertiesHelper.containerProperties)}
                                </AccordionSection>
                            </>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default Properties;
