import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { ref } from 'firebase/storage';
import { collections, storage } from '../../../firebaseConfig';

// Styles
import './FieldRemove.css';

// Components
import Modal from '../../../common/components/modal/Modal';
import ModalButton from '../../../common/components/modal/buttons/ModalButton';
import ModalButtons from '../../../common/components/modal/buttons/ModalButtons';
import ModalMessage from '../../../common/components/modal/message/ModalMessage';

// Managers
import DataManager from '../../../common/managers/DataManager';
import StorageManager from '../../../common/managers/StorageManager';

const dataManager = new DataManager();
const storageManager = new StorageManager();

const FieldRemove = ({
    field,
    confirmOpen,
    setConfirmOpen,
}) => {

    // Global
    const {
        formFields,
        hideProgress,
        profile,
        selectedModel,
        setFormFields,
        showProgress,
    } = useContext(Global);

    const handleDelete = async () => {

        showProgress("Deleting field...");

        try {
            const fieldKey = field.key;

            await dataManager.delete(collections.fields, profile.key, fieldKey);

            // Remove the field from the model's fieldSort array
            const updatedFieldSort = selectedModel.fieldSort.filter(key => key !== fieldKey);

            // Update the model with the new fieldSort array
            const updatedModel = { ...selectedModel, fieldSort: updatedFieldSort };

            // Update the model in the database
            await dataManager.update(
                collections.models,
                profile.key,
                selectedModel.key,
                updatedModel
            );

            // Update local fields state to delete the deleted field
            const updatedFields = formFields.filter(item => item.key !== fieldKey);
            setFormFields(updatedFields);

            // If the field is a gallery, delete the gallery data
            // Path: galleries/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'gallery') {
                const storageRef = ref(storage, `galleries/${profile.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);
            }

            // If the field is a videogallery, delete the videogallery data
            // Path: videogalleries/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'videogallery') {
                const storageRef = ref(storage, `videogalleries/${profile.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);
            }

            // If the field is a document archive, delete the archive data
            // Path: documents/APPKEY/MODELKEY/FIELDKEY/
            if (field.type === 'documents') {
                const storageRef = ref(storage, `documents/${profile.key}/${selectedModel.key}/${field.key}`);
                await storageManager.deleteFolderContents(storageRef);
            }

        } catch (error) {
            console.error("Error deleting field: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {selectedModel &&

                <Modal title="Remove Field" width={400} isOpen={confirmOpen} onClose={() => setConfirmOpen(false)}>

                    {/* CONFIRM MESSAGE */}
                    <ModalMessage text={`This will permanently delete this field and the information within for all ${selectedModel.title}. Continue?`} />

                    {/* BUTTONS */}
                    <ModalButtons>

                        {/* REMOVE BUTTON */}
                        <ModalButton
                            onClick={handleDelete}
                            label="Remove"
                        />

                    </ModalButtons>

                </Modal>

            }

        </>

    );
};

export default FieldRemove;
