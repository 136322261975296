import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ChatPostFormInput.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ChatPostFormInput = ({ postContent, onFocus, onChange }) => {
  const { theme } = useTheme();
  const {
      profile,
      selectedChannel
  } = useContext(Global);

  // References
  const textareaRef = useRef(null);

  /** 
   * Sets the height of the textarea.
   */
  useEffect(() => {
      if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
          textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

          if (textareaRef.current.scrollHeight > 200) {
              textareaRef.current.style.height = '200px';
          }
      }
  }, [postContent]);

  return (
    <>
      <textarea
        name="post"
        className="chat-post-form-input"
        placeholder={`Message # ${selectedChannel ? selectedChannel.title : 'this channel.'}`}
        style={{
          backgroundColor: theme.backgroundColorFaded,
          borderColor: theme.backgroundColorFaded,
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily,
          maxHeight: '200px' // Set max height
        }}
        onFocus={onFocus}
        onChange={onChange}
        value={postContent}
        rows={1}
        ref={textareaRef}
      />
    </>
  );
};

export default ChatPostFormInput;
