import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ObjectGridItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../svg/CheckOffIcon';
import CheckOnIcon from '../../../svg/CheckOnIcon';

// Components
import GridItem from '../../../components/grid/griditem/GridItem';
import GridItemField from '../../../components/grid/griditem/field/GridItemField';
import GridItemPrivacy from '../../../components/grid/griditem/privacy/GridItemPrivacy';
import GridItemTitle from '../../../components/grid/griditem/title/GridItemTitle';

/**
 * ObjectGridItem
 * 
 * A grid item for displaying objects.
 * 
 * @param {Object} object The object item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} ObjectGridItem component.
 */
const ObjectGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileFields,
        profileModels,
        selectedObject,
    } = useContext(Global);

    // Local State
    const [titleFieldKey, setTitleFieldKey] = useState(null);
    const [model, setModel] = useState(null);

    // Defines which field types are considered simple "text-like" fields
    const simpleTypes = [
        'currency',
        'number',
        'phone',
        'text',
        'time',
        'year'
    ];

    // On component mount or when dependencies change, find the model and set the titleFieldKey
    useEffect(() => {
        if (!profileFields || !profileModels || !item) return;
        const m = profileModels.find((model) => model.key === item.modelKey);
        if (m) {
            setModel(m);
            setTitleFieldKey(m.titleFieldKey);
        }
    }, [profileFields, profileModels, item]);

    /**
     * Retrieves the field metadata for a given key.
     * @param {string} key - The key of the field to retrieve.
     * @returns {object|null} - The field metadata or null if not found.
     */
    function getField(key) {
        return profileFields.find((field) => field.key === key) || null;
    }

    /**
     * Sort fields according to the model's fieldSort array.
     * Fields not found in the sorting array appear at the end.
     * @param {Array} fields - The fields to sort.
     * @param {Array} fieldSort - The order to sort fields by.
     * @returns {Array} Sorted fields.
     */
    const sortFieldsByModelFieldSort = (fields, fieldSort) => {
        if (!fieldSort || !Array.isArray(fieldSort)) {
            // If fieldSort doesn't exist, just return fields as-is.
            return fields;
        }
        return fields.sort((a, b) => {
            const indexA = fieldSort.indexOf(a.field.key);
            const indexB = fieldSort.indexOf(b.field.key);

            // Fields not in fieldSort will appear at the end.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    };

    // Prepare and sort fields
    let sortedFields = [];
    if (model) {
        // Convert the object's entries into an array of { key, value, field }.
        // Filter out entries that don't have a corresponding field definition.
        const fieldsArray = Object.entries(item)
            .map(([key, value]) => {
                const field = getField(key);
                return field ? { key, value, field } : null;
            })
            .filter(Boolean);

        sortedFields = sortFieldsByModelFieldSort(fieldsArray, model.fieldSort);
    }

    // Find the first gallery field that has at least one image
    const galleryField = sortedFields.find(({ field, value }) =>
        field.type === 'gallery' && Array.isArray(value) && value.length > 0 && value[0].url
    );

    // If a gallery field with images is found, we will display the first image at the top.
    // We also will filter that gallery field out of the main rendering since we've already displayed it.
    let fieldsToRender = sortedFields;
    if (galleryField) {
        fieldsToRender = sortedFields.filter((f) => f !== galleryField);
    }

    return (

        <GridItem
            selected={selected}
            shared={item.shared || false}
            onClick={(event) => onClick(event, item)}
            onDoubleClick={() => onDoubleClick(item)}
            onDragStart={(event) => onDragStart(event, item)}>

            <div
                className="object-grid-item-container"
                title="List Item">

                {/* IMAGE */}
                {galleryField && galleryField.value[0] && (
                    <div className="object-grid-item-image-wrapper">
                        <img
                            src={galleryField.value[0].url}
                            alt="Gallery Preview"
                            className="object-grid-item-image"
                        />
                    </div>
                )}

                {/* PRIVACY */}
                <GridItemPrivacy
                    selected={selected}
                    shared={item.shared || false}
                    label="Private"
                />

                {/* Now render all other fields except the gallery field we handled above */}
                {fieldsToRender.map(({ key, value, field }) => {
                    const textColor = selected
                        ? theme.highlightForegroundColor
                        : (key === titleFieldKey ? theme.foregroundColor : theme.foregroundColorFaded);

                    if (simpleTypes.includes(field.type)) {
                        return (
                            <div
                                key={`field-${key}`} // Ensure unique key for each field
                                className={key === titleFieldKey ? "object-grid-item-title" : "object-grid-item-field"}
                                style={{ color: textColor }}
                            >
                                {value && value.toString().split('\n').map((line, index) => (
                                    <React.Fragment key={`line-${key}-${index}`}>
                                        {key === titleFieldKey ? (
                                            <GridItemTitle
                                                key={`title-${key}-${index}`}
                                                title={line}
                                                selected={selected}
                                            />
                                        ) : (
                                            <GridItemField
                                                key={`field-${key}-${index}`}
                                                value={line}
                                                selected={selected}
                                            />
                                        )}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        );
                    }

                    if (field.type === 'lookup') {
                        const optionTitle = field.options.find((option) => option.key === value)?.title;
                        return (
                            <GridItemField
                                key={`lookup-${key}`}
                                value={optionTitle}
                                selected={selected}
                            />
                        );
                    }

                    if (field.type === 'password') {
                        return (
                            <GridItemField
                                key={`password-${key}`}
                                value="*********"
                                selected={selected}
                            />
                        );
                    }

                    if (field.type === 'checklist') {
                        return (
                            <div
                                key={`checklist-${key}`}
                                className="object-grid-item-checklist"
                            >
                                {value.map((checklistItem, index) => (
                                    <div
                                        key={`checklist-item-${key}-${index}`}
                                        className="object-grid-item-checklist-item"
                                        style={{ color: textColor }}
                                    >
                                        {checklistItem.checked ? (
                                            <CheckOnIcon
                                                color={(selectedObject && selectedObject.key === item.key)
                                                    ? theme.highlightForegroundColor
                                                    : theme.backgroundColorFaded}
                                                width="9"
                                                height="9"
                                            />
                                        ) : (
                                            <CheckOffIcon
                                                color={(selectedObject && selectedObject.key === item.key)
                                                    ? theme.highlightForegroundColor
                                                    : theme.backgroundColorFaded}
                                                width="9"
                                                height="9"
                                            />
                                        )}
                                        {checklistItem.title}
                                    </div>
                                ))}
                            </div>
                        );
                    }

                    return null; // Fallback for unhandled field types
                })}

            </div>

        </GridItem >
    );
};

export default ObjectGridItem;
