import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AccountAboutSaveButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * AccountAboutSaveButton Component
 * 
 * This component renders a button to save changes to the user account.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountAboutSaveButton = ({ onClick, enabled }) => {
  const { theme } = useTheme();
  const {
    profile,
    coreTheme
  } = useContext(Global);

  return (

    <div className="account-about-save-button-container"
      onClick={onClick}
      style={{
        color: enabled
          ? (profile ? theme.highlightForegroundColor : coreTheme.highlightForegroundColor)
          : (profile ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded),
        backgroundColor: enabled
          ? (profile ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor)
          : (profile ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded)
      }}>
      Save Changes
    </div>

  );
};

export default AccountAboutSaveButton;
