import React from 'react';

// Styles
import './UserListPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const UserListPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="user-list-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div
                className="user-list-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <div
                className="user-list-preview-item"
                style={{
                    color: theme.foregroundColor
                }}>
                Member 1
            </div>
            <div
                className="user-list-preview-item"
                style={{
                    color: theme.foregroundColor
                }}>
                Member 2
            </div>
        </div>
    );
};

export default UserListPreview;
