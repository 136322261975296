import React, { useContext } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './LookupSummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const LookupSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();
    const {
        profileFields,
    } = useContext(Global);

    const field = profileFields.find(field => field.key === element.field.key);

    if (!field) return null;

    const option = field.options.find(option => option.key === object[element.field.key]);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    return (
        <>
            <div
                className={`lookup-summary-container`}
                style={{ ...processed, height }}>
                {(option && option.title) || ""}
            </div>
        </>
    );
};

export default LookupSummary;
