import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../svg/CheckOffIcon';
import CheckOnIcon from '../../../svg/CheckOnIcon';
import DeleteIcon from '../../../svg/DeleteIcon';
import PencilIcon from '../../../svg/PencilIcon';
import TagIcon from '../../../svg/TagIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

const ForumPostMenu = ({ post, onEditClick, onDeleteClick, onTagChange }) => {
    const { theme } = useTheme();
    const {
        selectedChannel,
        selectedForumPost,
    } = useContext(Global);

    // Local State
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
    const [checkedTags, setCheckedTags] = useState([]);

    useEffect(() => {
        if (selectedForumPost && selectedForumPost.tags) {
            setCheckedTags(selectedForumPost.tags);
        }
    }, [selectedForumPost]);

    /**
     * Toggles visibility of the context menu.
     */
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ left: event.clientX, top: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Checks if a tag is currently applied to the object.
     * 
     * @param {string} tag - The tag to check.
     * @returns {boolean} - True if the tag is checked, false otherwise.
     */
    const tagChecked = (tag) => {
        return checkedTags.includes(tag);
    };

    /**
     * Toggles a tag in the selectedForumPost's tags array.
     * 
     * @param {string} tag - The tag to toggle.
     */
    const toggleTag = (tag) => {
        let updatedTags;

        if (tagChecked(tag)) {
            // Uncheck (remove tag)
            updatedTags = checkedTags.filter(t => t !== tag);
        } else {
            // Check (add tag)
            updatedTags = [...checkedTags, tag];
        }

        setCheckedTags(updatedTags);

        onTagChange(updatedTags);
    };

    /**
     * Handles click on the delete option.
     */
    const handleDelete = async () => {
        setMenuVisible(false);
        onDeleteClick();
    };

    /**
     * Handles click on the edit mode option.
     */
    const handleEdit = async () => {
        setMenuVisible(false);
        onEditClick();
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="forum-post-menu-outer-container">

                {/* MENU BUTTON */}
                <div className="forum-post-menu-container">
                    <ContextMenuButton
                        title="Forum Post Options"
                        onToggle={toggleMenu}
                    />
                </div>

                {/* MENU */}
                <ContextMenuPopup
                    visible={menuVisible}
                    setVisible={setMenuVisible}
                    backgroundColor={theme.backgroundColorFaded}
                    width={190}
                    position={menuPosition}>

                    {/* POST OPTIONS */}
                    <ContextMenuGroup title="Post Options">

                        {/* EDIT */}
                        <ContextMenuOption
                            icon={PencilIcon}
                            text="Edit"
                            onClick={handleEdit}
                        />

                        {/* DELETE */}
                        <ContextMenuOption
                            icon={DeleteIcon}
                            text="Delete"
                            onClick={handleDelete}
                        />

                    </ContextMenuGroup>

                    {/* POST TAGS */}
                    <ContextMenuGroup title="Tags">
                        {selectedChannel.tags && selectedChannel.tags.map((tag, index) => (
                            <div key={index} className="forum-post-menu-tag-wrapper">
                                {/* TAG */}
                                <div className="forum-post-menu-tag-text-wrapper">
                                    <ContextMenuOption
                                        icon={TagIcon}
                                        text={tag}
                                    />
                                </div>
                                <div className="forum-post-menu-tag-checkbox-wrapper"
                                    onClick={() => toggleTag(tag)}>
                                    {tagChecked(tag) ? (
                                        <CheckOnIcon
                                            color={theme.highlightBackgroundColor}
                                            checkColor={theme.highlightForegroundColor}
                                            width="16"
                                            height="16"
                                        />
                                    ) : (
                                        <CheckOffIcon color={theme.foregroundColor} width="16" height="16" />
                                    )}
                                </div>
                            </div>
                        ))}
                    </ContextMenuGroup>

                </ContextMenuPopup>

            </div>
        </>
    );
};

export default ForumPostMenu;
