import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ObjectTiles.css';

// Components
import ObjectTile from './tile/ObjectTile';
import Grid from '../../components/grid/Grid';

const ObjectTiles = () => {

    // Global
    const {
        profileObjects,
    } = useContext(Global);

    return (
        <>
            <div className={isMobile ?
                "objects-list-mobile" : "objects-list"}>

                <Grid
                    items={profileObjects}
                    renderItem={(object) => (
                        <ObjectTile
                            key={object.key}
                            object={object}
                        />
                    )}
                    loading={false}
                    minCols={1}
                    colWidth={300}
                />

            </div>
        </>
    );
};

export default ObjectTiles;

