import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './TimespanSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DotIcon from '../../svg/DotIcon';
import SelectIcon from '../../svg/SelectIcon';

// Components
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

const TimespanSelector = () => {
    const { theme } = useTheme();
    const {
        setTimespan,
        timespan
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 })

    // Function to return the label based on the timespan value
    const getLabel = () => {
        switch (timespan) {
            case 'CURRENT': return 'Current Billing Period';
            case 'LAST60': return 'Last 60 Days';
            case 'LAST24': return 'Last 24 Hours';
            case 'LAST7': return 'Last 7 Days';
            case 'LAST30': return 'Last 30 Days';
            default: return '';
        }
    };

    const handleTimespanChange = (billing) => {
        setTimespan(billing);
        setMenuVisible(false);
    };

    // Toggle visibility function
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ left: event.clientX, top: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    }

    return (
        <>
            <div className="timespan-selector-container"
                onClick={toggleMenu}>
                <div className="timespan-selector-triangle">
                    <SelectIcon
                        color={theme.foregroundColor}
                        width="16"
                        height="16"
                    />
                </div>
                <span
                    style={{ color: theme.foregroundColor }}>{getLabel()}</span>
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width="auto"
                position={menuPosition}>

                {/* TIME SPANS */}
                <ContextMenuOption
                    key="ALL"
                    icon={DotIcon}
                    text="All Time"
                    onClick={() => handleTimespanChange('ALL')}
                    iconSize="7"
                />

                {/* TIME SPANS */}
                <ContextMenuOption
                    key="CURRENT"
                    icon={DotIcon}
                    text="Current Billing Period"
                    onClick={() => handleTimespanChange('CURRENT')}
                    iconSize="7"
                />

                <ContextMenuOption
                    key="LAST60"
                    icon={DotIcon}
                    text="Last 60 Days"
                    onClick={() => handleTimespanChange('LAST60')}
                    iconSize="7"
                />

                <ContextMenuOption
                    key="LAST24"
                    icon={DotIcon}
                    text="Last 24 Hours"
                    onClick={() => handleTimespanChange('LAST24')}
                    iconSize="7"
                />

                <ContextMenuOption
                    key="LAST7"
                    icon={DotIcon}
                    text="Last 7 Days"
                    onClick={() => handleTimespanChange('LAST7')}
                    iconSize="7"
                />

                <ContextMenuOption
                    key="LAST30"
                    icon={DotIcon}
                    text="Last 30 Days"
                    onClick={() => handleTimespanChange('LAST30')}
                    iconSize="7"
                />

            </ContextMenuPopup>
        </>
    );
};

export default TimespanSelector;
