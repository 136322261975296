import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostFormTitle.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ForumPostFormTitle = ({ title, onChange, focused }) => {
  const { theme } = useTheme();
  const { profile } = useContext(Global);

  // References
  const textareaRef = useRef(null);

  /** 
   * Sets the height of the textarea.
   */
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

      if (textareaRef.current.scrollHeight > 200) {
        textareaRef.current.style.height = '200px';
      }
    }
  }, [title]);

  /** 
   * Focus the textarea if 'focused' is true.
   */
  useEffect(() => {
    if (focused && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [focused]);

  return (
    <>
      <textarea
        name="post"
        className="forum-post-form-title"
        placeholder="Title..."
        style={{
          backgroundColor: theme.backgroundColor,
          borderColor: theme.backgroundColor,
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily,
          maxHeight: '200px' // Set max height
        }}
        onChange={onChange}
        value={title}
        rows={1}
        ref={textareaRef}
      />
    </>
  );
};

export default ForumPostFormTitle;
