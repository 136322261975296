import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './PhoneCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * PhoneCell Component
 * 
 * This component renders an input field that accepts only numeric values and formats them as a phone number.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const PhoneCell = ({ object, field, handleBlur }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      profile
  } = useContext(Global);

  // Local State
  const [value, setValue] = useState(object[field.key] || ''); 
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [foregroundColor, setForegroundColor] = useState(theme.foregroundColor || "transparent");

  // Effect to update value when object or field changes
  useEffect(() => {
    setValue(object[field.key] || '');
  }, [object, field]);

  // Function to format phone number
  function formatPhoneNumber(value) {
      const numbers = value.replace(/\D/g, '');
      const phoneNumber = numbers.substring(0, 10); // Limit to 10 digits
      const areaCode = phoneNumber.substring(0, 3);
      const middle = phoneNumber.substring(3, 6);
      const last = phoneNumber.substring(6, 10);

      let formattedValue = '';
      if (phoneNumber.length > 6) {
          formattedValue = `(${areaCode}) ${middle}-${last}`;
      } else if (phoneNumber.length > 3) {
          formattedValue = `(${areaCode}) ${middle}`;
      } else if (phoneNumber.length > 0) {
          formattedValue = `(${areaCode}`;
      }
      return formattedValue;
  }

  /**
   * Handle input change
   * 
   * This function formats the input to allow only numbers and formats them as a phone number,
   * and updates the value state.
   * 
   * @param {Object} e - The event object.
   */
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = formatPhoneNumber(inputValue);
    setValue(formattedValue);
  };

  /**
   * Handle input blur
   * 
   * This function resets the background and foreground colors,
   * and calls the handleBlur function passed as a prop.
   * 
   * @param {Object} e - The event object.
   */
  const handleFieldBlur = (e) => {
    setBackgroundColor("transparent");
    setForegroundColor(theme.foregroundColor);
    const formattedValue = formatPhoneNumber(value);
    handleBlur(object.key, field.key, formattedValue);
  }

  /**
   * Handle input focus
   * 
   * This function sets the background and foreground colors to highlight colors
   * when the input is focused.
   * 
   * @param {Object} e - The event object.
   */
  const handleFocus = (e) => {
    setBackgroundColor(theme.backgroundColorFaded);
    setForegroundColor(theme.backgroundColorFaded);
  }

  return (
    <div className="phone-cell-container"
      style={{
        backgroundColor: backgroundColor,
        color: foregroundColor,
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
      }}
    >
      <input
        type="text"
        className="phone-cell-input"
        style={{
          color: theme.foregroundColor,
          fontFamily: profile.fontFamily
        }}
        value={value}
        onFocus={handleFocus}
        onChange={handleChange}
        onBlur={handleFieldBlur}
      />
    </div>
  );
};

export default PhoneCell;
