import React, { useEffect, useRef, useState } from 'react';

// Styles
import './Location.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import GoogleIcon from '../../../../common/svg/GoogleIcon';
import AppleIcon from '../../../../common/svg/AppleIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

/**
 * Location Component
 * 
 * This component renders a multi-line text input field for a location.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Location = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {
    // Theme
    const { theme } = useTheme();

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value

    // References
    const inputRef = useRef(null);

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    /**
     * Method to focus the input when the container is clicked.
     * 
     * @param {string} e - Click event.
     */
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    /**
     * Method to handle the blur of the field, which sends the update.
     */
    const handleBlur = () => {
        onUpdate(field, value);
    };

    /**
     * Method to handle the change in value.
     * 
     * @param {string} e - Change event.
     */
    const handleChange = (e) => {
        setValue(e.target.value);
    };

    /**
     * Opens google maps.
     */
    const handleGoogleClick = () => {
        const trimmedValue = value.trim();
        if (!trimmedValue) return;

        const isLatLong = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/.test(trimmedValue);
        let url;

        if (isLatLong) {
            // Handle latitude/longitude
            const [latitude, longitude] = trimmedValue.split(',').map(coord => coord.trim());
            url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        } else {
            // Handle address
            const address = encodeURIComponent(trimmedValue.replace(/\n/g, ' '));
            url = `https://www.google.com/maps/search/?api=1&query=${address}`;
        }

        window.open(url, '_blank');
    };

    /**
     * Opens apple maps.
     */
    const handleAppleClick = () => {
        const trimmedValue = value.trim();
        if (!trimmedValue) return;

        const isLatLong = /^-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?$/.test(trimmedValue);
        let url;

        if (isLatLong) {
            // Handle latitude/longitude
            const [latitude, longitude] = trimmedValue.split(',').map(coord => coord.trim());
            url = `https://maps.apple.com/?q=${latitude},${longitude}`;
        } else {
            // Handle address
            const address = encodeURIComponent(trimmedValue.replace(/\n/g, ' '));
            url = `https://maps.apple.com/?q=${address}`;
        }

        window.open(url, '_blank');
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                />
                <div className="location-wrapper">
                    <div className="location-address">

                        {/* INPUT */}
                        <textarea
                            ref={inputRef}
                            className="location-input"
                            style={{
                                color: theme.foregroundColor
                            }}
                            value={value}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            maxLength="10000"
                            rows="2"
                        />
                    </div>
                </div>
                <div
                    className="location-directions-buttons">
                    <div
                        className="location-directions-button"
                        style={{ color: theme.foregroundColorFaded }}
                        onClick={handleGoogleClick}>
                        <GoogleIcon
                            color={theme.foregroundColorFaded}
                            width="13"
                            height="13"
                        />
                    </div>
                    <div
                        className="location-directions-button"
                        style={{ color: theme.foregroundColorFaded }}
                        onClick={handleAppleClick}>
                        <AppleIcon
                            color={theme.foregroundColorFaded}
                            width="17"
                            height="17"
                        />
                    </div>
                </div>
            </FieldContainer>
        </>
    );
};

export default Location;
