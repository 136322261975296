import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DotIcon from '../../svg/DotIcon';
import SelectIcon from '../../svg/SelectIcon';

// Components
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

const AppSelector = () => {
    const { theme } = useTheme();
    const {
        setTargetApp,
        targetApp,
        userSpaces
    } = useContext(Global);

    // State Variables
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });

    // Function to return the label based on the targetApp value
    const getLabel = () => {
        if (targetApp === "ALL") return "All Apps";
        const app = userSpaces.find(app => app.key === targetApp);
        return app.title;
    };

    const handleAppChange = (app) => {
        setTargetApp(app);
        setMenuVisible(false);
    };

    // Toggle visibility function
    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ left: event.clientX, top: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    }

    return (
        <>
            <div className="app-selector-container"
                onClick={toggleMenu}>
                <div className="app-selector-triangle">
                    <SelectIcon
                        color={theme.foregroundColor}
                        width="16"
                        height="16"
                    />
                </div>
                <span
                    style={{ color: theme.foregroundColor }}>{getLabel()}</span>
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width="auto"
                position={menuPosition}>


                {/* APPS */}
                {userSpaces.map((app, index) => (

                    <ContextMenuOption
                        key={app.key}
                        icon={DotIcon}
                        text={app.title}
                        onClick={() => handleAppChange(app.key)}
                        iconSize="7"
                    />

                ))}

            </ContextMenuPopup>
        </>
    );
};

export default AppSelector;
