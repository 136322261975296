import React, { useEffect, useState, useMemo } from 'react';

// Utilities
import { hexToRgba } from '../../../../../common/utilities/Colors';

// Styles
import './VideoGallerySummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import BackIcon from '../../../../../common/svg/BackIcon';
import ForwardIcon from '../../../../../common/svg/ForwardIcon';

/**
 * VideoGallerySummary Component
 * 
 * This component renders a gallery in a summary view.
 * 
 * @param {object} field - The field metadata.
 * @param {object} object - The object containing field values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VideoGallerySummary = ({ element, object }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [currentIndex, setCurrentIndex] = useState(0);

    // Destructure values from element and object
    const { styles = {} } = element || {};
    const { key: fieldKey } = element.field || {};
    
    // Memoize the videos array
    const videos = useMemo(() => object?.[fieldKey] || [], [object, fieldKey]);

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    /**
     * Sets the currentIndex to the last video index if the videos array changes.
     */
    useEffect(() => {
        if (!videos.length) return;

        // Ensure currentIndex is within the bounds of the videos array
        if (currentIndex >= videos.length) {
            setCurrentIndex(videos.length - 1);
        }
    }, [videos, currentIndex]);

    /**
     * Handles navigating to the previous item.
     * 
     * @param {event} event - Event object.
     */
    const handlePrevious = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    /**
     * Handles navigating to the next item.
     * 
     * @param {event} event - Event object.
     */
    const handleNext = (event) => {
        event.stopPropagation();
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, videos.length - 1));
    };

    /**
     * There's no need to render if there are no videos.
     */
    if (!videos.length) {
        return null;
    }

    return (
        <div className="video-gallery-summary-container" style={filteredStyles}>

            {/* VIDEO */}
            <video controls
                src={videos[currentIndex]?.url}
                className="video-gallery-summary-video"
                style={{ width: '100%' }}
            />

            {/* PREVIOUS BUTTON */}
            {currentIndex > 0 && (
                <div
                    className="video-gallery-summary-nav-button previous-button"
                    style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                    onClick={handlePrevious}>
                    <BackIcon
                        color={theme.foregroundColor}
                        width="15"
                        height="15"
                    />
                </div>
            )}

            {/* NEXT BUTTON */}
            {currentIndex < videos.length - 1 && (
                <div
                    className="video-gallery-summary-nav-button next-button"
                    style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                    onClick={handleNext}>
                    <ForwardIcon
                        color={theme.foregroundColor}
                        width="15"
                        height="15"
                    />
                </div>
            )}

            {/* COUNT */}
            {videos.length > 1 && (
                <div className="video-gallery-summary-video-count"
                    style={{
                        color: theme.foregroundColor,
                        backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                    }}>
                    {`${currentIndex + 1} of ${videos.length}`}
                </div>
            )}
        </div>
    );
};

export default VideoGallerySummary;
