import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { ThemeMode } from '../../common/utilities/Enums';

// Styles
import './Table.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CheckOnIcon from '../../common/svg/CheckOnIcon';
import CheckOffIcon from '../../common/svg/CheckOffIcon';
import DeleteIcon from '../../common/svg/DeleteIcon';

// Components
import CheckboxCell from './cells/CheckboxCell';
import CountriesCell from './cells/CountriesCell';
import CurrencyCell from './cells/CurrencyCell';
import DateFieldCell from './cells/DateFieldCell';
import PlaceholderCell from './cells/PlaceholderCell';
import LookupCell from './cells/LookupCell';
import MonthCell from './cells/MonthCell';
import NumberCell from './cells/NumberCell';
import PhoneCell from './cells/PhoneCell';
import StatesCell from './cells/StatesCell';
import TextCell from './cells/TextCell';
import TimeCell from './cells/TimeCell';
import FeatureModal from './FeatureModal';
import YearCell from './cells/YearCell';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

const componentMap = {
  barcode: PlaceholderCell,
  checkbox: CheckboxCell,
  checklist: PlaceholderCell,
  comments: PlaceholderCell,
  countries: CountriesCell,
  currency: CurrencyCell,
  date: DateFieldCell,
  documents: PlaceholderCell,
  location: PlaceholderCell,
  gallery: PlaceholderCell,
  lookup: LookupCell,
  month: MonthCell,
  number: NumberCell,
  phone: PhoneCell,
  relationships: PlaceholderCell,
  states: StatesCell,
  text: TextCell,
  time: TimeCell,
  userlist: PlaceholderCell,
  videogallery: PlaceholderCell,
  year: YearCell,
};

const allTypes = [
  'barcode',
  'checkbox',
  'checklist',
  'comments',
  'countries',
  'currency',
  'date',
  'documents',
  'gallery',
  'location',
  'lookup',
  'month',
  'number',
  'phone',
  'relationships',
  'states',
  'text',
  'time',
  'userlist',
  'videogallery',
  'year'
];

const placeholderTypes = [
  'barcode',
  'checklist',
  'comments',
  'documents',
  'gallery',
  'location',
  'relationships',
  'userlist',
  'videogallery',
];

const Table = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    profileFields,
    profile,
    selectedTableField,
    selectedModel,
    selectedObject,
    setSelectedTableField,
    setSelectedObject,
    fieldVisible,
    setFieldVisible
  } = useContext(Global);

  const [modelFields, setModelFields] = useState([]);
  const [profileObjects, setProfileObjects] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (!selectedModel) return;

    const fieldsForModel = profileFields
      .filter(field => field.modelKey === selectedModel.key && allTypes.includes(field.type))
      .sort((a, b) => a.sort - b.sort);

    setModelFields(fieldsForModel);
  }, [selectedModel, profileFields]);

  useEffect(() => {
    if (!profile || !selectedModel) return;

    const list = async () => {
      try {
        const results = await objectManager.list(profile.key, selectedModel.key);

        setProfileObjects(results);
      } catch (error) {
        console.error('Failed to fetch documents:', error);
      }
    };

    list();
  }, [profile, selectedModel]);

  const handleBlur = async (objectKey, fieldKey, newValue) => {
    try {
      await objectManager.updateField(selectedModel.key, objectKey, fieldKey, newValue);
    } catch (error) {
      console.error('Failed to update document:', error);
    }
  };

  const handlePlaceholderClick = async (object, field) => {
    setSelectedObject(object);
    setSelectedTableField(field);
    setFieldVisible(true);
  };

  const handleFeatureClose = async () => {
    setSelectedObject(null);
    setFieldVisible(false);
  };

  const handleUpdate = async (field, value) => {
    await handleBlur(selectedObject.key, field.key, value);
    setSelectedObject(prevState => ({
      ...prevState,
      [field.key]: value
    }));
    setProfileObjects(prevObjects =>
      prevObjects.map(obj =>
        obj.key === selectedObject.key
          ? { ...obj, [field.key]: value }
          : obj
      )
    );
  };

  /**
   * Handles selection of an item checkbox.
   */
  const handleSelectItem = (objectKey) => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(objectKey)) {
        return prevSelectedItems.filter(key => key !== objectKey);
      } else {
        return [...prevSelectedItems, objectKey];
      }
    });
  };

  /**
   * Handle multi-deletion of the selected items.
   */
  const handleDeleteSelected = async () => {
    if (selectedItems.length > 0 && profile && selectedModel) {
      try {
        // Delete the objects from the database
        await objectManager.deleteMultiple(profile.key, selectedItems);

        // Remove deleted items from the objects state
        setProfileObjects(prevObjects => prevObjects.filter(obj => !selectedItems.includes(obj.key)));

        // Clear the selected items array
        setSelectedItems([]);
      } catch (error) {
        console.error('Failed to delete selected objects:', error);
      }
    }
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="table-container">

        {/* MASK */}
        <div className="table-mask"
          style={{
            backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
          }}></div>

        {/* TABLE */}
        <div className="table-table-wrapper">
          <table className="table-table">
            {profileObjects.length > 0 && (
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'bottom' }}>
                    <div className="table-header-left"
                      style={{
                        borderBottomColor: theme.backgroundColorFaded
                      }}
                      title="Delete Selected Items">
                      {selectedItems.length > 0 && (
                        <div onClick={handleDeleteSelected} className="table-header-left-button">
                          <DeleteIcon
                            color={theme.foregroundColorFaded}
                            width="18"
                            height="18"
                          />
                        </div>
                      )}
                    </div>
                  </th>
                  {modelFields.map(field => (
                    <th key={field.key}>
                      <div className="table-header-cell"
                        style={{
                          borderBottomColor: theme.backgroundColorFaded,
                          color: theme.foregroundColorFaded,
                          justifyContent: placeholderTypes.includes(field.type) ? 'center' : 'flex-start',
                        }}>
                        {field.title}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {profileObjects.map((object, index) => (
                <tr key={index}>
                  <td style={{ position: 'relative' }}>
                    <div className="table-row-left"
                      style={{
                        borderRightColor: theme.backgroundColorFaded,
                        borderBottomColor: theme.backgroundColorFaded,
                      }}
                      onClick={() => handleSelectItem(object.key)}>
                      {selectedItems.includes(object.key) ? (
                        <CheckOnIcon
                          checkColor={theme.highlightForegroundColor}
                          color={theme.highlightBackgroundColor}
                          width="14"
                          height="14"
                        />
                      ) : (
                        <CheckOffIcon
                          color={theme.foregroundColorFaded}
                          width="14"
                          height="14"
                        />
                      )}
                    </div>
                  </td>
                  {modelFields.map((field) => {
                    const Component = componentMap[field.type];
                    if (!Component) return null;

                    const commonProps = {
                      id: field.key,
                      object: object,
                      field: field,
                      handleBlur: handleBlur,
                      value: object[field.key] || '',
                    };

                    const extraProps = placeholderTypes.includes(field.type) ? {
                      onClick: () => handlePlaceholderClick(object, field),
                    } : {};

                    return (
                      <td key={field.key}>
                        <Component {...commonProps} {...extraProps} />
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FeatureModal
        object={selectedObject}
        field={selectedTableField}
        isOpen={fieldVisible}
        onClose={handleFeatureClose}
        onUpdate={handleUpdate}
      />
    </>
  );
};

export default Table;
