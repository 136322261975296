import React from 'react';

// Styles
import './BookmarksToolbar.css';

// Theme
import { useTheme } from '../../ThemeContext';

const BookmarksToolbar = () => {
    const { theme } = useTheme();

    return (
        <>
            <div className="bookmarks-toolbar-container">
                <div className="bookmarks-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}>
                    Bookmarks
                </div>
                <div className="bookmarks-toolbar-right" >
                    
                </div>
            </div>
        </>
    );
};

export default BookmarksToolbar;
