import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './CalendarMonth.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import CalendarMonthDay from './CalendarMonthDay';
import CalendarMonthDayEvent from './CalendarMonthDayEvent';
import CalendarMonthHeader from './CalendarMonthHeader';

const CalendarMonth = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        profileEvents,
        profile,
        selectedCalendarTags,
    } = useContext(Global);

    // Define days of the week labels
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const [days, setDays] = useState([]);
    const [displayEvents, setDisplayEvents] = useState([]);
    const [dayHeight, setDayHeight] = useState(0);
    const [dayWidth, setDayWidth] = useState(100);
    const daysRef = useRef(null);

    // Utilities for event height and margin
    const DAY_HEADER_HEIGHT = 30; // Adjust as needed for day header height
    const EVENT_HEIGHT = 18; // Adjust as needed for event height
    const EVENT_MARGIN = 3; // Adjust as needed for margin below each event

    useEffect(() => {
        if (profileEvents && activeDate) {
            const year = activeDate.getFullYear();
            const month = activeDate.getMonth();

            const firstOfMonth = new Date(year, month, 1);
            const lastOfMonth = new Date(year, month + 1, 0);

            const filteredEvents = profileEvents.filter(event => {
                if (selectedCalendarTags.length > 0 && !event.tags.some(tag => selectedCalendarTags.includes(tag))) return false;

                const eventStart = event.startDate.toDate();
                const eventEnd = event.endDate ? event.endDate.toDate() : eventStart;

                // Ensure this event falls at least partially within the current month
                return eventEnd >= firstOfMonth && eventStart <= lastOfMonth;
            });

            setDisplayEvents(filteredEvents);
        }
    }, [profileEvents, selectedCalendarTags, activeDate]);

    const getMonthDays = (year, month) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

    const generateDays = useCallback((year, month) => {
        const daysInMonth = getMonthDays(year, month);
        const firstDayOfMonth = getFirstDayOfMonth(year, month);
        const daysInPreviousMonth = getMonthDays(year, month - 1);
        const daysArray = [];

        for (let i = firstDayOfMonth - 1; i >= 0; i--) {
            daysArray.push({ day: daysInPreviousMonth - i, month: 'prev' });
        }
        for (let i = 1; i <= daysInMonth; i++) {
            daysArray.push({ day: i, month: 'current' });
        }
        let nextMonthDays = 1;
        while (daysArray.length % 7 !== 0) {
            daysArray.push({ day: nextMonthDays++, month: 'next' });
        }

        return daysArray;
    }, []); // Include any needed dependencies here

    useEffect(() => {
        if (activeDate) {
            const year = activeDate.getFullYear();
            const month = activeDate.getMonth();
            const generatedDays = generateDays(year, month);
            setDays(generatedDays);

            if (daysRef.current) {
                const rows = Math.ceil(generatedDays.length / 7);
                const containerHeight = daysRef.current.offsetHeight;
                setDayHeight(containerHeight / rows);
            }
        }
    }, [activeDate, profile, displayEvents, daysRef, generateDays, setDays, setDayHeight]);

    const recalculatePositions = useCallback(() => {
        if (daysRef.current) {
            const containerWidth = daysRef.current.offsetWidth;
            const containerHeight = daysRef.current.offsetHeight;
            const rows = Math.ceil(days.length / 7);

            // Just in case we want to position events accurately
            const newDayWidth = containerWidth / 7;
            setDayWidth(newDayWidth);
            setDayHeight(containerHeight / rows);
        }
    }, [days.length]);

    useEffect(() => {
        if (daysRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                recalculatePositions();
            });

            resizeObserver.observe(daysRef.current);

            // Cleanup observer on unmount
            return () => resizeObserver.disconnect();
        }
    }, [recalculatePositions, daysRef]);

    const formatDayKey = (day) => `${day.day}-${day.month}`;

    const initializeSlotTracker = () => {
        const slots = {};
        days.forEach((day) => {
            if (day.month === 'current') {
                const dateKey = formatDayKey(day);
                slots[dateKey] = [];
            }
        });
        return slots;
    };

    const getAvailableSlot = (start, end, slotTracker) => {
        let slotIndex = 0;

        while (true) {
            let isSlotAvailable = true;

            let currentDay = new Date(start);
            while (currentDay <= end) {
                const dayKey = formatDayKey({ day: currentDay.getDate(), month: 'current' });
                if (!slotTracker[dayKey]) {
                    slotTracker[dayKey] = [];
                }

                const daySlots = slotTracker[dayKey];
                if (daySlots[slotIndex]) {
                    isSlotAvailable = false;
                    break;
                }

                currentDay.setDate(currentDay.getDate() + 1);
            }

            if (isSlotAvailable) {
                currentDay = new Date(start);
                while (currentDay <= end) {
                    const dayKey = formatDayKey({ day: currentDay.getDate(), month: 'current' });
                    slotTracker[dayKey][slotIndex] = true;
                    currentDay.setDate(currentDay.getDate() + 1);
                }
                return slotIndex;
            }

            slotIndex++;
        }
    };

    const calculateEventPosition = (start, end, slotTracker) => {
        const totalDays = Math.round((end - start) / (1000 * 60 * 60 * 24)) + 1;
        const startDayIndex = days.findIndex(day =>
            day.day === start.getDate() && day.month === 'current'
        );

        const row = Math.floor(startDayIndex / 7);
        const column = startDayIndex % 7;

        // Since we're using grid, dayWidth and dayHeight are stable now
        const width = totalDays * dayWidth;
        const left = column * dayWidth;

        const slotIndex = getAvailableSlot(start, end, slotTracker);
        const top = row * dayHeight + slotIndex * (EVENT_HEIGHT + EVENT_MARGIN) + DAY_HEADER_HEIGHT;

        return { width, top, left };
    };

    const splitEventByWeeks = (event) => {
        const startDate = event.startDate.toDate();
        const endDate = event.endDate ? event.endDate.toDate() : startDate;

        let currentStart = new Date(startDate);
        const segments = [];

        while (currentStart <= endDate) {
            const weekEnd = new Date(currentStart);
            weekEnd.setDate(weekEnd.getDate() + (6 - currentStart.getDay()));
            const segmentEnd = endDate < weekEnd ? endDate : weekEnd;

            segments.push({
                start: new Date(currentStart),
                end: new Date(segmentEnd),
            });

            currentStart = new Date(segmentEnd);
            currentStart.setDate(currentStart.getDate() + 1);
        }

        return segments;
    };

    return (
        <div className="calendar-month-container">
            <div className="calendar-month-header"
                style={{
                    borderBottomColor: theme.backgroundColorFaded,
                }}>
                <CalendarMonthHeader daysToShow={daysOfWeek} />
            </div>
            <div ref={daysRef} className="calendar-month-days-wrapper">
                <div className="calendar-month-days">
                    {days.map((day, index) => {
                        return (
                            <CalendarMonthDay
                                key={index}
                                day={day}
                                dayWidth={dayWidth}
                                dayHeight={dayHeight}
                                dayHeaderHeight={DAY_HEADER_HEIGHT}
                            />
                        );
                    })}
                </div>
                <div className="calendar-month-events-overlay">
                    {(() => {
                        const slotTracker = initializeSlotTracker();

                        return displayEvents.map((event, index) => {
                            const segments = splitEventByWeeks(event);

                            return segments.map((segment, segmentIndex) => {
                                const { width, top, left } = calculateEventPosition(segment.start, segment.end, slotTracker);

                                return (
                                    <CalendarMonthDayEvent
                                        key={`${index}-${segmentIndex}`}
                                        event={event}
                                        style={{
                                            width: `${width}px`,
                                            top: `${top}px`,
                                            left: `${left}px`,
                                            height: `${segments.length > 1 ? EVENT_HEIGHT + `px` : "90%"}`,
                                            position: 'absolute',
                                        }}
                                    />
                                );
                            });
                        });
                    })()}
                </div>
            </div>
        </div>
    );
};

export default CalendarMonth;
