import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Styles
import './AccountAbout.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AccountAboutSaveButton from './AccountAboutSaveButton';
import AccountForm from '../components/accountform/AccountForm';

// Managers
import MemberManager from '../../common/managers/MemberManager';
import ProfileManager from '../../common/managers/ProfileManager';

const memberManager = new MemberManager();
const profileManager = new ProfileManager();

const AccountAbout = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        profile,
        setProfileMembers,
        setCurrentUser
    } = useContext(Global);

    // Local State
    const [username, setUsername] = useState(''); // Username (required)
    const [email, setEmail] = useState(''); // Username (required)
    const [fullName, setFullName] = useState(''); // Full Name (optionally required)
    const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
    const [handle, setHandle] = useState(''); // Handle (optionally required)
    const [message, setMessage] = useState(null); // Success or error message

    // Validation States
    const [formValid, setFormValid] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleSubmit = async () => {

        // Update the user's information in the database
        const data = {
            username: username,
            fullName: fullName,
            displayName: displayName,
            handle: handle,
            email: email
        }

        // Update the user's information in the database
        await profileManager.update(currentUser.userKey, data);

        // Update the current user state
        setCurrentUser(prev => ({ ...prev, ...data }));

        // Update the user's information in the members collection if the app is selected
        if (profile) {
            await memberManager.updateUserInfo(profile.key, currentUser.userKey, data);

            // Update the user's information in the search index
            //await userIndexManager.update(profile.key, currentUser.userKey, newFirstName + " " + newLastName);

            // Update the global members state array
            setProfileMembers(prevMembers => prevMembers.map(member =>
                member.userKey === currentUser.userKey
                    ? { ...member, ...data }
                    : member
            ));

            setMessage("Your information has been updated.");

            // Log the activity
            activity.log(profile.key, 'writes', 1);
        }
    };

    /**
     * Method to handle submit of the signup form.
     * 
     * @param {string} e - Click event.
     */
    const handleFormChange = async (
        username, email, fullName, displayName, handle, password, isValid
    ) => {
        setUsername(username);
        setEmail(email);
        setFullName(fullName);
        setDisplayName(displayName);
        setHandle(handle);
        setFormValid(isValid);
    };

    /**
     * Enable the submit button only if all required fields are valid.
     */
    useEffect(() => {
        setSubmitEnabled(formValid);
    }, [formValid]);

    return (

        <div className="account-about-container">

            <div className="account-about-form">

                {/* FORM */}
                <AccountForm
                    onChange={handleFormChange}
                    user={currentUser}
                    requirePassword={false}
                    showLabels={false}
                />

                {/* MESSAGE */}
                {message &&
                    <div className="account-about-message"
                        style={{
                            borderColor: theme.backgroundColorFaded,
                            color: theme.foregroundColor
                        }}>
                        {message}
                    </div>
                }

                {/* SUBMIT */}
                <AccountAboutSaveButton onClick={handleSubmit} enabled={submitEnabled} />

            </div>

        </div>
    );
};

export default AccountAbout;
