import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountForm.css';

// Components
import AccountEmail from '../accountemail/AccountEmail';
import AccountFormLabel from '../accountformlabel/AccountFormLabel';
import AccountPassword from '../accountpassword/AccountPassword';
import AccountRequiredField from '../accountrequiredfield/AccountRequiredField';
import AccountUsername from '../accountusername/AccountUsername';

/**
 * AccountForm Component
 * 
 * This component renders an account form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function AccountForm({ onChange, user = null, requirePassword = true, showLabels = false }) {
  const {
    profile
  } = useContext(Global);

  // Local State
  const [formValid, setFormValid] = useState(false);
  const [email, setEmail] = useState(''); // The editable email field
  const [username, setUsername] = useState(''); // Username (required)
  const [fullName, setFullName] = useState(''); // Full Name (optionally required)
  const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
  const [handle, setHandle] = useState(''); // Handle (optionally required)
  const [password, setPassword] = useState(''); // Password (required)

  // Validation States
  const [isUsernameValid, setIsUsernameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [isDisplayNameValid, setIsDisplayNameValid] = useState(true);
  const [isHandleValid, setIsHandleValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  /**
   * Handles changes in any field.
   */
  useEffect(() => {
    onChange(username, email, fullName, displayName, handle, password, formValid);
  }, [username, email, fullName, displayName, handle, password, formValid, onChange]);

  /**
   * Prepopulate values is a user is supplied.
   */
  useEffect(() => {
    if (!user) return;
    setUsername(user.username);
    setEmail(user.email);
    setFullName(user.fullName);
    setDisplayName(user.displayName);
    setHandle(user.handle);

    // These are valid initially because they are prepopulated, although this
    // can change if the user edits the field.
    setIsUsernameValid(true);
    setIsEmailValid(true);
    setIsFullNameValid(true);
    setIsDisplayNameValid(true);
    setIsHandleValid(true);
    setIsPasswordValid(true);
  }, [user]);

  /**
   * Enable the submit button only if all required fields are valid.
   */
  useEffect(() => {
    const allValid = isUsernameValid && isEmailValid && isPasswordValid;
    const fullNameValid = profile?.requiredFields?.fullname ? isFullNameValid : true;
    const displayNameValid = profile?.requiredFields?.displayname ? isDisplayNameValid : true;
    const handleValid = profile?.requiredFields?.handle ? isHandleValid : true;

    setFormValid(allValid && fullNameValid && displayNameValid && handleValid);
  }, [isUsernameValid, isEmailValid, isPasswordValid, isFullNameValid, isDisplayNameValid, isHandleValid, profile]);

  /**
   * Method to handle username change.
   * @param {string} value - The current username value.
   * @param {boolean} isValid - Whether the username is valid.
   */
  const handleUsernameChange = async (value, isValid) => {
    setUsername(value);
    setIsUsernameValid(isValid);
  };

  /**
   * Method to handle email change.
   * @param {string} value - The current email value.
   * @param {boolean} isValid - Whether the email is valid.
   */
  const handleEmailChange = async (value, isValid) => {
    setEmail(value);
    setIsEmailValid(isValid);
  };

  /**
   * Method to handle full name change.
   * @param {string} value - The current full name value.
   * @param {boolean} isValid - Whether the full name is valid.
   */
  const handleFullNameChange = async (value, isValid) => {
    setFullName(value);
    setIsFullNameValid(isValid);
  };

  /**
   * Method to handle display name change.
   * @param {string} value - The current display name value.
   * @param {boolean} isValid - Whether the display name is valid.
   */
  const handleDisplayNameChange = async (value, isValid) => {
    setDisplayName(value);
    setIsDisplayNameValid(isValid);
  };

  /**
   * Method to process handle change.
   * @param {string} value - The current handle value.
   * @param {boolean} isValid - Whether the handle is valid.
   */
  const handleHandleChange = async (value, isValid) => {
    setHandle(value);
    setIsHandleValid(isValid);
  };

  /**
   * Method to handle password change.
   * @param {string} value - The current password value.
   * @param {boolean} isValid - Whether the password is valid.
   */
  const handlePasswordChange = async (value, isValid) => {
    setPassword(value);
    setIsPasswordValid(isValid);
  };

  return (
    <div className="account-form-container">

      {/* USERNAME */}
      {showLabels &&
        <AccountFormLabel label="Username" />
      }
      <AccountUsername
        onChange={handleUsernameChange}
        defaultValue={username}
      />

      {/* EMAIL */}
      {showLabels &&
        <AccountFormLabel label="Email Address" />
      }
      <AccountEmail
        onChange={handleEmailChange}
        defaultValue={email}
      />

      {/* REQUIRED USER FIELDS */}
      {profile && (
        <>
          {profile.requiredFields.fullname && (
            <>
              {showLabels &&
                <AccountFormLabel label="Full Name" />
              }
              <AccountRequiredField
                fieldKey="fullname"
                fieldName="Full Name"
                onChange={handleFullNameChange}
                defaultValue={fullName}
                allowSpaces={true}
              />
            </>
          )}

          {profile.requiredFields.displayname && (
            <>
              {showLabels &&
                <AccountFormLabel label="Display Name" />
              }
              <AccountRequiredField
                fieldKey="displayname"
                fieldName="Display Name"
                onChange={handleDisplayNameChange}
                defaultValue={displayName}
              />
            </>
          )}

          {profile.requiredFields.handle && (
            <>
              {showLabels &&
                <AccountFormLabel label="Handle" />
              }
              <AccountRequiredField
                fieldKey="handle"
                fieldName="Handle"
                onChange={handleHandleChange}
                defaultValue={handle}
              />
            </>
          )}
        </>
      )}

      {/* PASSWORD */}
      {requirePassword &&
        <>
          {showLabels &&
            <AccountFormLabel label="Password" />
          }
          <AccountPassword onChange={handlePasswordChange} />
        </>
      }

    </div>
  );
}

export default AccountForm;
