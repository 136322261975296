import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Notifications.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import NewMember from './templates/newmember/NewMember';
import NewMessage from './templates/newmessage/NewMessage';
import NotificationsToolbar from './toolbar/NotificationsToolbar';

const Notifications = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    memberNotifications,
  } = useContext(Global);

  return (
    <div className={(isMobile || isTablet) ? "notifications-container-mobile" : "notifications-container"}>

      {/* TOOLBAR */}
      <NotificationsToolbar />

      <div className="notifications-content">

        {/* NOTIFICATIONS */}
        {memberNotifications.map((notification, index) => (

          <React.Fragment key={notification.key}>

            <div className="notification-wrapper">

              {/* MASK */}
              <div className="notification-mask"
                style={{
                  backgroundColor: notification.read === false ? theme.highlightBackgroundColor : "transparent",
                }}></div>

              {/* NEW MESSAGE */}
              {notification.type === 'NEW_MESSAGE' &&
                <NewMessage notification={notification} />
              }

              {/* NEW MEMBER */}
              {notification.type === 'NEW_MEMBER' &&
                <NewMember notification={notification} />
              }

            </div>

          </React.Fragment>

        ))}

      </div>

    </div>
  );
};

export default Notifications;
