import React from 'react';

const SeparatorIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M320-200v-560l440 280-440 280Z" />
  </svg>

);

export default SeparatorIcon;
