import React from 'react';

// Styles
import './PrintButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import PrintIcon from '../../../../../../common/svg/PrintIcon';

const PrintButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="print-button-container">
            <PrintIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default PrintButton;
