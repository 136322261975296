import React from 'react';

const SwapIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg 
    height={height}
    viewBox="0 -960 960 960" 
    width={width}>
      <path fill={color} d="M280-160 80-360l200-200 56 57-103 103h287v80H233l103 103-56 57Zm400-240-56-57 103-103H440v-80h287L624-743l56-57 200 200-200 200Z"/>
  </svg>

);

export default SwapIcon;
