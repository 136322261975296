import React from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ModalButtons.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ModalButtons Component
 * 
 * This component renders a section container for modal buttons.
 * 
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalButtons = ({ children }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      {/* BUTTONS */}
      <div className="modal-buttons-container"
        style={{
          backgroundColor: theme.backgroundColor,
        }}>
        {children}
      </div>

      {/* MASK 
      <div className="modal-buttons-mask"
        style={{
          backgroundColor: theme.backgroundColor
        }}>
      </div>
      */}
    </>
  );
};

export default ModalButtons;
