import React from 'react';

// Styles
import './GridItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import GridItemFooter from './footer/GridItemFooter';
import GridItemMask from './mask/GridItemMask';

/**
 * GridItem
 * 
 * Component to serve as a wrapper for various
 * types of grid items.
 * 
 * @param {boolean} selected - Whether the item is selected.
 * @param {boolean} shared - Whether the item is shared.
 * @param {function} onClick - Click event handler.
 * @param {function} onDoubleClick - Double click event handler.
 * @param {any} children - Child components.
 * 
 * @returns {JSX.Element} GridItem component.
 */
const GridItem = ({ selected = false, shared = false, onClick, onDoubleClick, onDragStart, children }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="grid-item-container"
            onClick={(event) => onClick(event)}
            onDoubleClick={onDoubleClick}
            onDragStart={(event) => onDragStart(event)}
            draggable
            style={{
                backgroundColor: selected
                    ? theme.highlightBackgroundColor
                    : 'transparent',
            }}>

            {/* MASK */}
            {!selected && <GridItemMask />}

            {children}

            {/* FOOTER */}
            {shared &&
                <GridItemFooter
                    selected={selected}
                    comments={240}
                    favorites={120}
                />
            }

        </div>

    );
};

export default GridItem;
