import React, { useEffect, useState } from 'react';

// Styles
import './Lookup.css';

// Images
import ListIcon from '../../../../common/svg/ListIcon';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import LookupEditor from './LookupEditor';
import Select from '../../../../common/components/select/Select';

const Lookup = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    options = [],
}) => {

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value
    const [lookupEditorVisible, setLookupEditorVisible] = useState(false);

    // Map options to the format required by the Select component
    const selectOptions = options.map(option => ({
        value: option.key,    // Map 'key' to 'value'
        label: option.title   // Map 'title' to 'label'
    }));

    /**
     * Set the initial value of the field.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    /**
     * Method to handle the change in value.
     * 
     * @param {string} val - New value.
     */
    const handleChange = (val) => {
        setValue(val);
        onUpdate(field, val);
    };

    /**
     * Method to handle opening the options dialog.
     */
    const handleOptionsClick = () => {
        setLookupEditorVisible(true);
    };

    /**
     * Menu options for the field header.
     */
    const menuOptions = [
        {
            text: 'Edit Options',
            icon: ListIcon,
            onClick: () => {
                handleOptionsClick();
            }
        }
    ];

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field} >

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                    menuOptions={menuOptions}
                />

                {/* INPUT */}
                <Select
                    options={selectOptions}  // Pass the mapped options here
                    value={value}
                    onChange={(value) => handleChange(value)}
                />

            </FieldContainer>

            {/* LOOKUP MODAL */}
            <LookupEditor
                field={field}
                isOpen={lookupEditorVisible}
                setOpen={setLookupEditorVisible}
            />
        </>
    );
};

export default Lookup;
