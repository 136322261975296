import React, { useRef } from 'react';

// Drag and Drop
import { useDrag, useDrop } from 'react-dnd';

// Styles
import './ObjectChecklistItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DragIcon from '../../../../common/svg/DragIcon';
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';

// Components
import ObjectChecklistItemMenu from './ObjectChecklistItemMenu';

const ObjectChecklistItem = ({
    item,
    index,
    moveItem,
    onChange,
    onRemoveClick,
    onViewClick,
    onNoteClick,
}) => {
    const { theme } = useTheme();

    const ref = useRef(null);

    // Drag and Drop Setup
    const [, drop] = useDrop({
        accept: 'CHECKLIST_ITEM',
        hover: (draggedItem) => {
            if (!ref.current) return;
            const dragIndex = draggedItem.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;

            moveItem(dragIndex, hoverIndex);
            draggedItem.index = hoverIndex;
        },
        canDrop: () => true,
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'CHECKLIST_ITEM',
        item: { type: 'CHECKLIST_ITEM', index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => true,
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className="object-checklist-item-container"
            style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>

            {/* DRAG HANDLE */}
            <div className="object-checklist-item-drag-handle">
                <DragIcon
                    color={theme.foregroundColorFaded}
                    width="21"
                    height="21"
                />
            </div>

            <div className="object-checklist-item-checkbox" onClick={() => onChange(item.objectKey)}>
                {item.checked ? (
                    <CheckOnIcon
                        color={theme.highlightBackgroundColor}
                        checkColor={theme.highlightForegroundColor}
                        width="21"
                        height="21"
                    />
                ) : (
                    <CheckOffIcon
                        color={theme.foregroundColor}
                        width="21"
                        height="21"
                    />
                )}
            </div>

            <div className="object-checklist-item-middle">

                {/* TITLE */}
                <div
                    className="object-checklist-item-title"
                    onClick={() => onViewClick(item)}
                    style={{ color: theme.foregroundColor }}
                >
                    {item.objectTitle}
                </div>

                {/* NOTES */}
                <div
                    className="object-checklist-item-instructions"
                    style={{ color: theme.foregroundColorFaded }}
                    onClick={() => onNoteClick(item)}>
                    {item.note}
                </div>

            </div>

            <div className="object-checklist-item-menu-container">
                <ObjectChecklistItemMenu
                    item={item}
                    onRemoveClick={() => onRemoveClick(item)}
                    onViewClick={() => onViewClick(item)}
                    onNoteClick={() => onNoteClick(item)}
                />
            </div>

        </div>
    );
};

export default ObjectChecklistItem;
