import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './CalendarHeader.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CalendarMenu from '../menu/CalendarMenu';
import CalendarToolbar from '../toolbar/CalendarToolbar';

const CalendarHeader = () => {
  const { theme } = useTheme();
  const {
    profile
  } = useContext(Global);

  return (
    <div className="calendar-header-container">
      <div className="calendar-header-left"
        style={{
          color: theme.foregroundColorFaded,
          fontFamily: profile.fontFamily,
        }}>
          <>
            <div className="calendar-header-title">
              Calendar
            </div>
            <CalendarToolbar />
          </>
      </div>
      <div className="calendar-header-menu" >
        <CalendarMenu />
      </div>
    </div>
  );
};

export default CalendarHeader;
