import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Navigation
import { useNavigate } from 'react-router-dom';

// Utilities
import { LoginMode, MemberThumbDirection, Screen } from '../../common/utilities/Enums';

// Styles
import './TitleBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ProfileTitle from '../profiletitle/ProfileTitle';
import NavBar from '../../common/components/navbar/NavBar';
import ThemeToggle from '../../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../components/userbutton/UserButton';
import MenuBar from '../menu/menubar/MenuBar';

/**
 * TitleBar Component
 * 
 * This component renders the title bar at the top of the screen.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TitleBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileMembers,
        currentUser,
        profile,
        resetSelections,
        setProfile,
        setLoginMode,
        setScreen,
    } = useContext(Global);

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* CONTAINER */}
            <div className="title-bar-container">

                {/* LEFT */}
                <div className="title-bar-left">

                    {!isMobile &&
                        <>
                            {/* PROFILE INFO */}
                            {profile &&
                                <div className="title-bar-app"
                                    style={{
                                        cursor: currentUser ? 'pointer' : 'default'
                                    }}>

                                    {/* PROFILE TITLE */}
                                    <ProfileTitle />

                                </div>
                            }

                            {/* BUTTONS */}
                            {!isMobile && profile &&
                                <div className="title-bar-buttons">

                                    {/* MENU BAR */}
                                    <MenuBar />

                                </div>
                            }
                        </>
                    }
                </div>

                {/* RIGHT */}
                <div className="title-bar-right">

                    {/* THEME */}
                    {profile &&
                        <ThemeToggle />
                    }

                </div>

            </div>

        </>
    );
};

export default TitleBar;
