import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Account.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AccountAbout from './about/AccountAbout';
import AccountBilling from './billing/AccountBilling';
import AccountMenu from './menu/AccountMenu';
import AccountMenuBar from './menu/AccountMenuBar';
import Modal from '../common/components/modal/Modal';

const Account = ({ isOpen, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        accountAboutVisible,
        setAccountAboutVisible,
        setAccountBillingVisible,
    } = useContext(Global);

    const handleClick = () => {
        setAccountAboutVisible(false);
        setAccountBillingVisible(false);
        setOpen(false);
    }

    return (
        <Modal
            title="My Account"
            height="90%"
            isOpen={isOpen}
            onClose={handleClick}
            width={isMobile ? "90%" : "900px"}>

            <div className={isMobile ? "account-container-mobile" : "account-container"}>
                {isMobile ? (
                    <>
                        <div className="account-mobile-top"
                            style={{
                                color: theme.foregroundColor,
                            }}>
                            <AccountMenuBar />
                        </div>
                        <div className="account-mobile-bottom">
                            {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
                        </div>
                    </>
                ) : (
                    <>
                        <AccountMenu />
                        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
                    </>
                )}
            </div>
            
        </Modal>
    );
};

export default Account;
