import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../../../../Global';

// Styles
import './RateButton.css';

// Theme
import { useTheme } from '../../../../../../../ThemeContext';

// Images
import RateOnIcon from '../../../../../../svg/RateOnIcon';

// Components
import RateModal from './RateModal';

const RateButton = ({ object }) => {
    const { theme } = useTheme();
    const { currentUser } = useContext(Global);
    const [rateModalOpen, setRateModalOpen] = useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        setRateModalOpen(true);
    };

    const updateRating = (newRating) => {
        // Update object with new average rating and vote count
        object.averageRating = newRating.average;
        object.totalVotes = newRating.votes;
    };

    // Provide default values if averageRating or totalVotes are undefined
    const displayAverageRating = object.averageRating !== undefined ? object.averageRating.toFixed(1) : "0.0";
    const displayTotalVotes = object.totalVotes !== undefined ? object.totalVotes : 0;

    return (
        <>
            <div className="rate-button-container" onClick={(e) => handleClick(e)}>
                <div className="rate-button-leading-text">
                    {displayAverageRating}
                </div>
                <RateOnIcon color={theme.highlightBackgroundColor} width="14" height="14" />
                <div className="rate-button-trailing-text">
                    ({displayTotalVotes} ratings)
                </div>
            </div>

            <RateModal
                isOpen={rateModalOpen}
                setOpen={setRateModalOpen}
                currentRating={
                    object.ratings?.find(r => r.userKey === currentUser.userKey)?.rating || 0
                }
                objectKey={object.key}
                modelKey={object.modelKey}
                onRatingUpdate={updateRating}
            />
        </>
    );
};


export default RateButton;
