import React from 'react';

// Styles
import './FieldContainer.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
    .field-container:hover {
      border-color: ${(props) => props.hoverBorderColor} !important;
    }
`;

const FieldContainer = ({ onClick, children }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      <InlineStyles
        hoverBorderColor={theme.highlightBackgroundColor}
      />
      <div
        className="field-container"
        onClick={onClick ? onClick : undefined}
      >
        {children}
      </div>
    </>
  );
};

export default FieldContainer;
