import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Enums
import { ThemeMode } from '../../common/utilities/Enums';

// Utilities
import { defaultFont } from '../utilities/Defaults.js';

// Firebase
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Styles
import './AppSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppDelete from './appdelete/AppDelete.js';
import AppSettingsFont from './AppSettingsFont';
import AppSettingsGeneral from './general/AppSettingsGeneral';
import AppSettingsIcon from './AppSettingsIcon';
import AppSettingsPermissions from './permissions/AppSettingsPermissions';
import AppSettingsSaveButton from './AppSettingsSaveButton.js';
import AppSettingsTheme from './AppSettingsTheme';
import AppSettingsWallpaper from './AppSettingsWallpaper';
import UserFields from './userfields/UserFields';
import Roles from './roles/Roles.js';
import StatusMessage from '../../common/components/statusmessage/StatusMessage';

// Managers
import { activity } from '../../common/managers/ActivityManager';
import AppManager from '../../common/managers/AppManager';
import DataManager from '../../common/managers/DataManager';

const appManager = new AppManager();
const dataManager = new DataManager();

/**
 * AppSettings Component
 * 
 * This component allows the user to edit the settings of an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettings = () => {

    // Theme
    const { defaultThemes, theme } = useTheme();

    // Global
    const {
        profile,
        selectedSetting,
        setProfile,
    } = useContext(Global);

    // Local State

    // Title
    const [title, setTitle] = useState('');

    // Description
    const [description, setDescription] = useState('');

    // Domain
    const [domain, setDomain] = useState('');

    // Delete
    const [deleteVisible, setDeleteVisible] = useState('');

    // Icon
    const [selectedDarkIcon, setSelectedDarkIcon] = useState(null);
    const [selectedLightIcon, setSelectedLightIcon] = useState(null);
    const [iconSize, setIconSize] = useState(1.0);

    // Wallpaper
    const [selectedDarkWallpaper, setSelectedDarkWallpaper] = useState(null);
    const [selectedLightWallpaper, setSelectedLightWallpaper] = useState(null);
    const [clearedDarkWallpaper, setClearedDarkWallpaper] = useState(false);
    const [clearedLightWallpaper, setClearedLightWallpaper] = useState(false);

    // Themes
    const [themes, setThemes] = useState(defaultThemes);
    const [fontFamily, setFontFamily] = useState('');

    // Define state for required fields and displayKey
    const [requiredFields, setRequiredFields] = useState({
        fullname: false,
        displayname: false,
        handle: false,
        birthdate: false,
    });
    const [displayKey, setDisplayKey] = useState('username'); // Default to 'username'

    // Status
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);

    /** 
     * Updates the state when the selected app changes.
     */
    useEffect(() => {
        if (profile) {
            setTitle(profile.title || '');
            setDescription(profile.description || '');
            setDomain(profile.domain || '');
            setThemes(profile.themes || defaultThemes);
            setIconSize(profile.iconSize || 1.0);
            setFontFamily(profile.fontFamily || defaultFont);
            setRequiredFields(profile.requiredFields || {
                fullname: false,
                displayname: false,
                handle: false,
                birthdate: false,
            });
            setDisplayKey(profile.displayKey || 'username');
        }
    }, [profile, defaultThemes]);

    /**
     * Method to handle an icon size change.
     * 
     * @param {decimal} size - Slider value, on a scale of 0 to 1.
     */
    const handleIconSizeChange = (size) => {
        setIconSize(size);
    };

    /**
     * Method to handle a theme update.
     * 
     * @param {object} updatedThemes - JSON object containing the updated themes.
     */
    const handleUpdateThemes = (updatedThemes) => {
        setThemes(updatedThemes);
    };

    /**
     * Method to handle a font family change.
     * 
     * @param {string} updatedFontFamily - Updated font family.
     */
    const handleFontChange = (updatedFontFamily) => {
        setFontFamily(updatedFontFamily);
    };

    /**
     * Method to handle saving of the app settings.
     */
    const handleSave = async () => {
        // Validate the title
        if (!title.trim()) {
            alert('Please enter an app title.');
            return;
        }

        // Validate the domain existence
        if (!domain.trim()) {
            alert('Please enter an app domain.');
            return;
        }

        // Ensure the domain is alphanumeric and contains no spaces
        const alphanumericRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/; // Allows alphanumeric with optional hyphens
        if (!alphanumericRegex.test(domain.trim().toLowerCase())) {
            alert('The domain must be alphanumeric and cannot contain spaces or special characters (except hyphens).');
            return;
        }

        // Validate the domain is not taken by a different app
        const normalizedDomain = domain.trim().toLowerCase();
        const domainExists = await appManager.domainExists(normalizedDomain);
        if (domainExists && profile.domain !== normalizedDomain) {
            alert('This domain is in use. Please choose another.');
            return;
        }

        // Upload resources if selected
        if (selectedDarkIcon) await uploadIcon(ThemeMode.DARK);
        if (selectedLightIcon) await uploadIcon(ThemeMode.LIGHT);
        if (selectedDarkWallpaper) await uploadWallpaper(ThemeMode.DARK);
        if (selectedLightWallpaper) await uploadWallpaper(ThemeMode.LIGHT);

        // Current timestamp
        const now = Timestamp.now();

        // Prepare data for update
        const data = {
            title: title.trim(),
            description: description.trim(),
            domain: normalizedDomain,
            themes,
            iconSize,
            fontFamily,
            requiredFields,
            displayKey,
            dateModified: now,
        };

        // Conditionally include wallpaper attributes
        if (clearedDarkWallpaper) data.wallpaperDark = null;
        if (clearedLightWallpaper) data.wallpaperLight = null;

        // Update the database
        const result = await dataManager.update(
            collections.apps,
            profile.key,
            profile.key,
            data
        );

        if (result.success) {
            // Update the profile state
            setProfile((prevApp) => {
                const updatedApp = {
                    ...prevApp,
                    ...data,
                };
                if (clearedDarkWallpaper) updatedApp.wallpaperDark = null;
                if (clearedLightWallpaper) updatedApp.wallpaperLight = null;
                return updatedApp;
            });

            // Reset input fields and show success message
            setTitle('');
            setDescription('');
            setMessage("App settings saved.");
            setMessageVisible(true);
        } else {
            alert('Failed to save app settings. Please try again.');
        }
    };

    /**
     * Method to upload an icon to Firebase Storage.
     * 
     * @param {string} mode - "DARK" or "LIGHT".
     */
    const uploadIcon = async (mode) => {
        const selectedFile = mode === ThemeMode.DARK ? selectedDarkIcon : selectedLightIcon;
        const fileExtension = selectedFile.name.split('.').pop();

        const createResizedImageBlob = (file) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.onload = () => {
                    const maxDimension = 1000;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const ratio = Math.min(maxDimension / img.width, maxDimension / img.height);
                    const width = img.width * ratio;
                    const height = img.height * ratio;

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob); // Resolve the promise with the blob
                        } else {
                            reject(new Error("Failed to create blob"));
                        }
                    }, `image/${fileExtension}`);
                };
                img.onerror = reject; // Handle any errors that occur when loading the image
                img.src = URL.createObjectURL(file); // Set the image source to the selected file
            });
        };

        try {
            const resizedBlob = await createResizedImageBlob(selectedFile); // Resize the image

            const logoFolderRef = ref(storage, `spaces/${profile.key}/logos/${mode.toLowerCase()}/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(logoFolderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(profile.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `spaces/${profile.key}/logos/${mode.toLowerCase()}/${profile.key}.${fileExtension}`);
            await uploadBytes(storageRef, resizedBlob); // Upload the resized blob
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            // Update the selected app object with the new logo
            const updatedApp = {
                ...profile,
                [mode === ThemeMode.DARK ? 'logoDark' : 'logoLight']: fileUrl,
            };

            setProfile(updatedApp); // Update app state

            // Update Firestore document
            await updateDoc(doc(db, collections.apps, profile.key), updatedApp);

            activity.log(profile.key, 'writes', 1);
        } catch (error) {
            console.error("Error handling app logo upload:", error);
        }
    };

    /**
 * Method to upload a wallpaper to Firebase Storage.
 *
 * @param {string} mode - "DARK" or "LIGHT".
 */
    const uploadWallpaper = async (mode) => {
        const selectedFile = mode === ThemeMode.DARK ? selectedDarkWallpaper : selectedLightWallpaper;
        const fileExtension = selectedFile.name.split('.').pop();

        try {
            const logoFolderRef = ref(storage, `spaces/${profile.key}/wallpapers/${mode.toLowerCase()}/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(logoFolderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(profile.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `spaces/${profile.key}/wallpapers/${mode.toLowerCase()}/${profile.key}.${fileExtension}`);
            await uploadBytes(storageRef, selectedFile); // Upload the original file
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            // Update the selected app object with the new wallpaper
            const updatedApp = {
                ...profile,
                [mode === ThemeMode.DARK ? 'wallpaperDark' : 'wallpaperLight']: fileUrl,
            };

            setProfile(updatedApp); // Update app state

            // Update Firestore document
            await updateDoc(doc(db, collections.apps, profile.key), updatedApp);

            activity.log(profile.key, 'writes', 1);
        } catch (error) {
            console.error("Error handling app wallpaper upload:", error);
        }
    };

    /**
     * Method to handle a dark icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleDarkIconSelect = (file) => {
        setSelectedDarkIcon(file);
    };

    /**
     * Method to handle a light icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleLightIconSelect = (file) => {
        setSelectedLightIcon(file);
    };

    /**
     * Method to handle a dark wallpaper selection.
     * 
     * @param {file} file - The selected file url.
     */
    const handleDarkWallpaperSelect = (file) => {
        setSelectedDarkWallpaper(file);
    };

    /**
     * Method to handle a light wallpaper selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleLightWallpaperSelect = (file) => {
        setSelectedLightWallpaper(file);
    };

    /**
     * Method to handle clearing of the dark wallpaper.
     **/
    const handleDarkClear = async () => {
        setClearedDarkWallpaper(true);
    };

    /**
     * Method to handle clearing of the dark wallpaper.
     **/
    const handleLightClear = async () => {
        setClearedLightWallpaper(true);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-settings-container">

                {/* TITLEBAR */}
                <div className="app-settings-title"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    {selectedSetting && selectedSetting.title}

                    {/* STATUS */}
                    <div className="app-settings-status-wrapper">
                        <StatusMessage
                            message={message}
                            visible={messageVisible}
                            setVisible={setMessageVisible}
                        />
                    </div>

                </div>

                {/* CONTENT */}
                <div className="app-settings-content">

                    {/* GENERAL */}
                    {selectedSetting.key === 'GENERAL' && (
                        <AppSettingsGeneral
                            title={title}
                            setTitle={setTitle}
                            description={description}
                            setDescription={setDescription}
                            domain={domain}
                            setDomain={setDomain}
                        />
                    )}

                    {/* USER FIELDS */}
                    {selectedSetting.key === 'USERFIELDS' && (
                        <UserFields
                            requiredFields={requiredFields}
                            setRequiredFields={setRequiredFields}
                            displayKey={displayKey}
                            setDisplayKey={setDisplayKey}
                        />
                    )}

                    {/* ROLES */}
                    {selectedSetting.key === 'ROLES' && (
                        <Roles />
                    )}

                    {/* PERMISSIONS */}
                    {selectedSetting.key === 'PERMISSIONS' && (
                        <AppSettingsPermissions />
                    )}

                    {/* ICON */}
                    {selectedSetting.key === 'ICON' && (
                        <AppSettingsIcon
                            themes={themes}
                            defaultSize={iconSize}
                            onDarkSelect={handleDarkIconSelect}
                            onLightSelect={handleLightIconSelect}
                            onSizeChange={handleIconSizeChange}
                        />
                    )}

                    {/* WALLPAPER */}
                    {selectedSetting.key === 'WALLPAPER' && (
                        <AppSettingsWallpaper
                            onDarkSelect={handleDarkWallpaperSelect}
                            onLightSelect={handleLightWallpaperSelect}
                            onDarkClear={handleDarkClear}
                            onLightClear={handleLightClear}
                        />
                    )}

                    {/* THEME */}
                    {selectedSetting.key === 'THEME' && (
                        <AppSettingsTheme themes={themes} onUpdate={handleUpdateThemes} />
                    )}

                    {/* FONT */}
                    {selectedSetting.key === 'FONT' && (
                        <AppSettingsFont fontFamily={fontFamily} onChange={handleFontChange} />
                    )}
                </div>

                {/* SAVE BUTTON */}
                <div className="app-settings-save-button-wrapper"
                    style={{
                        bottom: isMobile ? '70px' : '20px',
                    }}>
                    <AppSettingsSaveButton
                        onClick={handleSave}
                    />
                </div>

            </div>

            {/* DELETE */}
            <AppDelete
                isOpen={deleteVisible}
                setOpen={setDeleteVisible}
            />

        </>
    );
};

export default AppSettings;
