import React from 'react';

// Styles
import './ModalButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ModalButton Component
 * 
 * This component renders a button for a modal dialog.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalButton = ({ label, highlighted = false, width = "auto", onClick }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div 
      onClick={onClick} 
      className="modal-button-container"
      style={{
        backgroundColor: highlighted ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
        color: theme.foregroundColor,
        width: width
      }}>
      {label}
    </div>
  );
};

export default ModalButton;
