import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Styles
import './ChannelEdit.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ForumTags from '../forum/forumtags/ForumTags';
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalInput from '../../components/modal/input/ModalInput';
import ModalLabel from '../../components/modal/label/ModalLabel';
import ModalSection from '../../components/modal/section/ModalSection';
import ModalSwitch from '../../components/modal/switch/ModalSwitch';
import ModalTextarea from '../../components/modal/textarea/ModalTextarea';

// Utilities
import { ChannelType } from '../../utilities/Enums';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * ChannelEdit Component
 * 
 * This component allows editing a channel.
 * 
 * @param {object} channel - The channel object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelEdit = ({ channel, isOpen, setOpen }) => {
    const { theme } = useTheme();
    const {
        profile
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [isPrivate, setPrivate] = useState(false);
    const [tags, setTags] = useState([]);

    /** 
     * Initializes the channel title when the component mounts.
     */
    useEffect(() => {
        if (!channel) return;
        setTitle(channel.title);
        setDescription(channel.description || '');
        setPrivate(channel.private);
        setTags(channel.tags || []); // Initialize tags if present, else set as an empty array
    }, [channel]);

    /** 
     * Save changes to the channel.
     */
    const handleSave = async () => {
        const data = {
            title: title,
            description: description,
            private: isPrivate,
            tags: tags,
        };

        try {
            // Update the channel in the database
            await dataManager.update(collections.channels, profile.key, channel.key, data);

            // Close the modal
            setOpen(false);
        } catch (error) {
            console.error('Error updating channel:', error);
        }
    };

    /**
     * Handles toggle of the private setting.
     */
    const handleTogglePrivate = async () => {
        setPrivate(!isPrivate);
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Edit Channel"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px">

                {/* TITLE */}
                <ModalSection>

                    <ModalLabel text="Title" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* PRIVATE */}
                <ModalSection>

                    <ModalSwitch
                        label="Private (Invite Only)"
                        value={isPrivate}
                        onChange={handleTogglePrivate}
                    />

                    {/* PRIVACY DESCRIPTION */}
                    <div className="channel-edit-privacy-description"
                        style={{ color: theme.foregroundColorFaded }}>
                        {isPrivate ?
                            "Only selected app members can participate." :
                            "All app members can participate."
                        }
                    </div>

                </ModalSection>

                {/* TAGS */}
                {channel.type === ChannelType.FORUM &&

                    <ModalSection>

                        <ModalLabel text="Tags" />

                        <ForumTags
                            initialTags={tags}
                            onTagsChange={(updatedTags) => setTags(updatedTags)}
                        />

                    </ModalSection>

                }

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save Changes"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default ChannelEdit;
