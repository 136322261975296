import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Enums
import { ThemeMode } from '../../common/utilities/Enums';

// Styles
import './AppLogoEdit.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * AppLogoEdit Component
 * 
 * This component allows the user to select a dark or light mode icon.
 * 
 * @param {object} title - Title to display above the icon.
 * @param {string} backgroundColor - Background color of the icon.
 * @param {string} borderColor - Border color of the icon.
 * @param {string} mode - "DARK" or "LIGHT".
 * @param {decimal} size - Size of the icon.
 * @param {function} onSelect - Function to call when the icon is selected.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppLogoEdit = ({ title, backgroundColor, borderColor, mode, size, onSelect }) => {

    // Theme
    const { theme } = useTheme();
    
    // Global
    const { profile } = useContext(Global);

    // Local State
    const [previewUrl, setPreviewUrl] = useState(null);

    /**
     * Method to handle file selection and pass it to the parent component.
     * 
     * @param {event} e - The file selection event.
     */
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPreviewUrl(fileUrl);
            if (onSelect) {
                onSelect(file);
            }
        }
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-logo-edit-container">

                {/* TITLE */}
                <div
                    className="app-logo-edit-title"
                    style={{ 
                        color: theme.foregroundColorFaded, 
                        fontFamily: profile.fontFamily
                    }}>
                    {title}
                </div>

                {/* LOGO WRAPPER */}
                <div className="app-logo-edit-circle-wrapper">
                    <label htmlFor={`logo-upload-${profile.key}-${mode}`}>
                        <div className={isMobile ? "app-logo-edit-circle-mobile" : "app-logo-edit-circle"}
                            style={{
                                backgroundColor: backgroundColor,
                                borderColor: borderColor
                            }}>

                            {/* IMAGE */}
                            {(previewUrl || (profile && (mode === ThemeMode.DARK ? profile.logoDark : profile.logoLight))) && (
                                <img 
                                    style={{
                                        width: size
                                    }}
                                    alt="App Logo"
                                    // Display the preview if available, otherwise the existing logo
                                    src={previewUrl || (mode === ThemeMode.DARK ? profile.logoDark : profile.logoLight)}
                                />
                            )}
                        </div>
                    </label>
                </div>

                {/* BACKGROUND INPUT */}
                <input 
                    id={`logo-upload-${profile.key}-${mode}`} 
                    type="file" 
                    onChange={handleFileChange} // Only set the file, no upload
                    style={{ display: 'none' }} 
                />
            </div>
        </>
    );
};

export default AppLogoEdit;
