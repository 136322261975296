import React, { useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ChecklistAddForm.css';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';

// Components
import Modal from '../../../../common/components/modal/Modal';
import ModalButton from '../../../../common/components/modal/buttons/ModalButton';
import ModalButtons from '../../../../common/components/modal/buttons/ModalButtons';
import ModalInput from '../../../../common/components/modal/input/ModalInput';
import ModalLabel from '../../../../common/components/modal/label/ModalLabel';
import ModalSection from '../../../../common/components/modal/section/ModalSection';
import ModalTextarea from '../../../../common/components/modal/textarea/ModalTextarea';

const ChecklistAddForm = ({
    modalOpen,
    setModalOpen,
    onAdd
}) => {

    // Local State
    const [itemTitle, setItemTitle] = useState('');
    const [itemInstructions, setItemInstructions] = useState('');

    /**
     * Method to add a checklist item to a checklist.
     */
    const handleAdd = () => {
        const key = generateKey();
        const newItem = {
            key,
            title: itemTitle,
            instructions: itemInstructions
        };

        onAdd(newItem);
    }

    return (

        <Modal title="Checklist Item" width={isMobile ? "90%" : "350px"} isOpen={modalOpen} onClose={() => setModalOpen(false)}>

            {/* TITLE */}
            <ModalSection>

                <ModalLabel text="Title" />

                <ModalInput
                    value={itemTitle}
                    onChange={(e) => setItemTitle(e.target.value)}
                    maxLength="255"
                    autoFocus={true}
                />

            </ModalSection>

            {/* INSTRUCTIONS */}
            <ModalSection>

                <ModalLabel text="Instructions" />

                <ModalTextarea
                    value={itemInstructions}
                    onChange={(e) => setItemInstructions(e.target.value)}
                    maxLength="1000"
                    lines="3"
                />

            </ModalSection>

            {/* ADD BUTTON */}
            <ModalButtons>

                {/* ADD BUTTON */}
                <ModalButton
                    onClick={handleAdd}
                    label="Add"
                />

            </ModalButtons>

        </Modal>

    );
};

export default ChecklistAddForm;
