import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * Determines the contents of the foryou feed.
 */
const useSetForYouFiles = () => {

    // Global
    const {
        profile,
        setForYouFiles
    } = useContext(Global);

    useEffect(() => {
        if (!profile) {
            setForYouFiles([]);
            return;
        }

        // Function to load userSpaces
        const loadItems = () => {
            const handleUpdate = (items) => {
                setForYouFiles(items);
            };

            const params = [
                { field: "shared", operator: "==", value: true },
                { field: "profileKey", operator: "!=", value: profile.key },
            ];

            const unsubscribe = dataManager.listAndSubscribe(
                collections.files,
                profile.key,
                handleUpdate,
                params,
                [],
                true // Global among all spaces
            );

            return unsubscribe;
        };

        // Subscribe to userSpaces
        const unsubscribeApps = loadItems();

        // Cleanup when component unmounts or dependencies change
        return () => {
            if (unsubscribeApps) {
                unsubscribeApps();
            }
        };
    }, [profile]);

};

export default useSetForYouFiles;
