import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load files for a specific app.
 */
const usePreloadProfileFiles = () => {

  // Global
  const {
    profile,
    setProfileFiles
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!profile || !profile.key) {
      setProfileFiles([]);
      return;
    }

    // Function to load models
    const loadFiles = (profileKey) => {
      const handleUpdate = (items) => {
        setProfileFiles(items);
        activity.log(profileKey, 'reads', items.length);
      };

      const params = [
        { field: "trashed", operator: "!=", value: true }
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.files,
        profileKey,
        handleUpdate,
        params,
        []
      );

      return unsubscribe;
    };

    // Subscribe to models
    const unsubscribeFiles = loadFiles(profile.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeFiles) {
        unsubscribeFiles();
      }
    };
  }, [profile, setProfileFiles]);

};

export default usePreloadProfileFiles;
