import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Styles
import './Conversations.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ConversationsToolbar from './ConversationsToolbar';
import ConversationAddButton from './ConversationAddButton';
import ConversationSummary from './summary/ConversationSummary';
import Grid from '../../common/components/grid/Grid';

// Managers
import DataManager from '../../common/managers/DataManager';

const dataManager = new DataManager();

const Conversations = () => {
    const { theme } = useTheme();
    const {
        conversations,
        currentUser,
        setSelectedConversation,
        profile,
    } = useContext(Global);

    const handleClick = async (conversation) => {
        setSelectedConversation(conversation);

        // Update the conversation with the latest message
        await dataManager.update(collections.conversations, profile.key, conversation.key, { lastMessageRead: true });
    };

    // Filter conversations where the current user is a participant
    const filteredConversations = conversations.filter(conversation =>
        conversation.participants.some(participant => participant.userKey === currentUser.userKey)
    );

    // Sort conversations by the date the last message was created, in descending order
    const sortedConversations = filteredConversations.sort((a, b) => {
        const dateA = new Date(a.lastMessage?.dateCreated);
        const dateB = new Date(b.lastMessage?.dateCreated);
        return dateB - dateA; // Sort descending: most recent first
    });

    return (
        <>
            <div className="conversations-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                }}>
                <ConversationsToolbar />

                <div className="conversations-list">

                    <Grid
                        items={sortedConversations}
                        renderItem={(conversation) => (
                            <ConversationSummary
                                key={conversation.key}
                                conversation={conversation}
                                onClick={() => handleClick(conversation)}
                            />
                        )}
                        loading={false}
                        minCols={1}
                        colWidth={280}
                    />

                    {/* Display a list of conversations 
                    {sortedConversations.map((conversation, index) => (
                        <div className="conversations-conversation-wrapper"
                            key={conversation.key}
                            onClick={() => handleClick(conversation)}>
                            <ConversationSummary
                                conversation={conversation}
                            />
                            <Divider />
                        </div>
                    ))}
                    */}


                </div>

                <div className="conversations-add-button">
                    <ConversationAddButton />
                </div>
            </div>
        </>
    );
};

export default Conversations;
