import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getUserDisplayValue } from '../../utilities/Users';

// Styles
import './MemberSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserThumb from '../../components/userthumb/UserThumb';

const MemberSummary = ({ member }) => {
    const { theme } = useTheme();
    const {
        profile,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="member-summary-container">

                {/* USER THUMB */}
                <div className="member-summary-dot">
                    <UserThumb
                        user={member}
                        size="26"
                    />
                </div>

                {/* NAME/ROLE */}
                <div className="member-summary-user">

                    {/* NAME */}
                    <div className="member-summary-name"
                        style={{
                            color: theme.foregroundColor
                        }}>
                        {getUserDisplayValue(profile.displayKey, member)}
                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default MemberSummary;
