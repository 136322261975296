import React from 'react';
import { useDrop } from 'react-dnd';
import { useTheme } from '../../../../ThemeContext';

/**
 * Segment Component
 * Represents a single segment in the hour block.
 * Handles drag-and-drop and hover effects.
 * 
 * @param {object} props - Component props.
 * @param {number} segmentIndex - The index of the segment.
 * @param {number} segmentHeight - Height of the segment in percentage.
 * @param {function} onDrop - Callback to handle drop events.
 * @param {function} onMouseEnter - Callback for mouse enter events.
 * @param {function} onMouseLeave - Callback for mouse leave events.
 * @param {boolean} isHovered - Whether the segment is hovered.
 * @param {function} onSegmentClick - Handles clicks on the segment to open an event.
 * 
 * @returns {JSX.Element} The rendered segment.
 */
const Segment = ({
    segmentIndex,
    segmentHeight,
    onDrop,
    onMouseEnter,
    onMouseLeave,
    isHovered,
    onSegmentClick,
}) => {

    // Theme
    const { theme } = useTheme();

    // Drop functionality for the segment
    const [, drop] = useDrop(() => ({
        accept: 'event',
        drop: (item) => onDrop(item, segmentIndex),
    }));

    return (
        <div
            ref={drop}
            className="calendar-collection-segment"
            onMouseEnter={() => onMouseEnter(segmentIndex)}
            onMouseLeave={onMouseLeave}
            onClick={() => onSegmentClick(segmentIndex)}
            style={{
                height: `${segmentHeight}%`,
                opacity: "0.2",
                backgroundColor: isHovered ? theme.backgroundColor : 'transparent',
                borderTop: segmentIndex > 0 ? `1px dotted ${theme.backgroundColor}` : 'none',
                cursor: 'pointer',
            }}
        />
    );
};

export default Segment;
