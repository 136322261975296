import React from 'react';

// Utilities
import { getElapsedTime } from '../../../../../common/utilities/DateTime';

// Styles
import './TextSummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const CreatedSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    return (
        <div className="created-summary-container" 
            style={processed}>
            {getElapsedTime(object.dateCreated)}
        </div>
    );
};

export default CreatedSummary;
