import React, { useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Roles.css';

// Components
import RoleAdd from './roleadd/RoleAdd';
import RoleChart from './rolechart/RoleChart';

const Roles = () => {

    // Local State
    const [addVisible, setAddVisible] = useState(false);

    return (
        <>
            <div className={isMobile ? "roles-container-mobile" : "roles-container"}>
                <RoleChart />   
            </div>

            <RoleAdd
                isVisible={addVisible}
                setVisible={setAddVisible}
            />
        </>
    );
};

export default Roles;
