import React from 'react';

// Styles
import './GridItemPrivacy.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import DotIcon from '../../../../svg/DotIcon';
import LockIcon from '../../../../svg/LockIcon';

/**
 * GridItemPrivacy
 * 
 * Component to display the privacy status of a grid item.
 * 
 * @param {boolean} selected - Whether the item is selected.
 * @param {boolean} shared - Whether the item is shared.
 * 
 * @returns {JSX.Element} GridItemPrivacy component.
 */
const GridItemPrivacy = ({ selected = false, shared = false }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="grid-item-privacy-container">

            {shared ? (
                <div className="grid-item-privacy-shared">
                    Public
                </div>
            ) : (
                <>
                    <div className="grid-item-privacy-lock">
                        <LockIcon
                            color={selected
                                ? theme.highlightForegroundColor
                                : theme.foregroundColorFaded}
                            width="10"
                            height="10"
                        />
                    </div>
                    <div className="grid-item-privacy-text"
                        style={{
                            color: selected
                                ? theme.highlightForegroundColor
                                : theme.foregroundColorFaded,
                        }}>
                        Private
                    </div>
                </>
            )}
        </div>
    );
};

export default GridItemPrivacy;
