import React from 'react';

// Styles
import './MembersToolbar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const MembersToolbar = () => {
    const { theme } = useTheme();

    return (

        <div className="members-toolbar-container">
            <div className="members-toolbar-buttons">
                <div className="members-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}>
                    Members
                </div>
                <div className="members-toolbar-right" >
                    
                </div>
            </div>
        </div>
    );
};

export default MembersToolbar;
