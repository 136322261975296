import React, { useRef } from 'react';

// Styles
import './TextSummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const TextSummary = ({ element, object }) => {
    // Theme
    const { getProcessedStyles } = useTheme();

    // References
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    // Get the text value and split by line breaks
    const textValue = object[element.field.key] || '';
    const lines = textValue.split('\n'); // Split the text into lines by '\n'

    return (
        <div
            className="text-summary-container"
            ref={containerRef}
            style={{ ...processed, height }}>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    {index < lines.length - 1 && <br />}
                </React.Fragment>
            ))}
        </div>
    );
};

export default TextSummary;
