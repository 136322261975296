import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../svg/PlusIcon';

const AddButton = ({ onClick }) => {
  const { theme } = useTheme();
  const {
      selectedModel,
  } = useContext(Global);

  return (
    <>
      <div className="add-button-container"
        style={{ backgroundColor: theme.highlightBackgroundColor }}
        title={`Add to ${selectedModel && selectedModel.title}`}
        onClick={onClick}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>
    </>
  );
};

export default AddButton;
