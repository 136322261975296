import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { generateKey } from '../../common/utilities/Keys';
import { getUserDisplayValue } from '../../common/utilities/Users';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Styles
import './Conversation.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import SendIcon from '../../common/svg/SendIcon';

// Components
import Message from './Message';

// Managers
import ConversationManager from '../../common/managers/ConversationManager';
import ConversationToolbar from './ConversationToolbar';
import DataManager from '../../common/managers/DataManager';
import NotificationManager from '../../common/managers/NotificationManager';

const conversationManager = new ConversationManager();
const dataManager = new DataManager();
const notificationManager = new NotificationManager();

const Conversation = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        profile,
        selectedConversation
    } = useContext(Global);

    // Local State
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState(''); // Track textarea value

    // References
    const messagesEndRef = useRef(null);
    const newMessageRef = useRef(null);
    const unsubscribeEventsRef = useRef(null);

    useEffect(() => {
        const fetchMessages = () => {

            const handleUpdate = (items) => {
                setMessages(items);
            };

            const unsubscribe = conversationManager.listMessagesAndSubscribe(profile.key, selectedConversation.key, handleUpdate);
            return unsubscribe;
        };

        if (!selectedConversation) {
            setMessages([]);
            return;
        }

        unsubscribeEventsRef.current = fetchMessages();

        return () => {
            if (unsubscribeEventsRef.current) {
                unsubscribeEventsRef.current();
            }
        };
    }, [profile.key, selectedConversation]);

    // Scroll to the bottom whenever messages update
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [selectedConversation]);

    const handleAdd = async () => {

        // Current timestamp
        const now = Timestamp.now();

        const key = generateKey();

        const message = {
            profileKey: profile.key,
            conversationKey: selectedConversation.key,
            key: key,
            text: newMessage,
            userKey: currentUser.userKey,
            userName: currentUser.firstName + ' ' + currentUser.lastName,
            dateCreated: now
        };

        await dataManager.add(collections.messages, profile.key, key, message);

        setNewMessage(''); // Clear the input value

        const textarea = newMessageRef.current;
        textarea.style.height = 'auto'; // Reset the height

        // Update the conversation with the latest message
        await dataManager.update(collections.conversations, profile.key, selectedConversation.key, { lastMessage: message, lastMessageRead: false });

        // Send a notification to the other users in the conversation
        for (const participant of selectedConversation.participants) {

            const participantKey = participant.userKey;

            if (participantKey !== currentUser.userKey) {
                const title = getUserDisplayValue(profile.displayKey, participant);
                const body = newMessage;

                try {
                    // Save the notification to the database
                    const notificationKey = generateKey();

                    // Send a notification
                    await notificationManager.sendNotification(
                        'NEW_MESSAGE',
                        profile.key,
                        participantKey, 
                        title, 
                        body, 
                        '/icons/chat.png', 
                        profile.domain
                    );

                    // Save a notification to the database
                    const data = {
                        type: 'NEW_MESSAGE',
                        profileKey: profile.key,
                        userKey: participantKey,
                        key: notificationKey,
                        read: false,
                        title: `New message from ${getUserDisplayValue(profile.displayKey, currentUser)}`,
                        conversationKey: selectedConversation.key,
                        description: body,
                        dateCreated: now
                    }

                    await notificationManager.saveNotification(
                        'NEW_MESSAGE',
                        profile.key,
                        notificationKey,
                        participantKey, 
                        data
                    );
                } catch (error) {
                    console.error('Failed to send notification:', error);
                }
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleAdd();
        }
    };

    const adjustTextareaHeight = () => {
        const textarea = newMessageRef.current;
        textarea.style.height = 'auto'; // Reset the height
        textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Set the height with a max value
    };

    const handleInput = (event) => {
        setNewMessage(event.target.value); // Update state with the input value
        adjustTextareaHeight();
    };

    return (
        <div className="conversation-container">
            {selectedConversation &&
                <>
                    <div className="conversation-toolbar">
                        <ConversationToolbar />
                    </div>
                    <div className="conversation-messages">
                        {messages.slice().map((message, index) => (
                            <Message message={message} key={message.key} />
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="conversation-footer">
                        <textarea
                            ref={newMessageRef}
                            value={newMessage} // Controlled component
                            style={{
                                backgroundColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor
                            }}
                            className="conversation-input"
                            placeholder="Type a message..."
                            onKeyDown={handleKeyDown}
                            onInput={handleInput}
                            rows="1" // Start with a single row
                        />
                        <div
                            className="conversation-send-button"
                            onClick={handleAdd}
                            style={{
                                cursor: newMessage ? 'pointer' : 'default' // Optional: change cursor style
                            }}>
                            <SendIcon
                                color={newMessage ? theme.foregroundColor : theme.foregroundColorFaded} // Conditional color
                                width="30"
                                height="30"
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Conversation;
