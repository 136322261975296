import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { defaultFont } from '../../../common/utilities/Defaults.js';

// Styles
import './ContextMenuOption.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
  .context-menu-option-container:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

/**
 * ContextMenuOption Component
 * 
 * This component renders an option for a context menu.
 * 
 * @param {object} icon - The icon component.
 * @param {string} text - The text to display.
 * @param {function} onClick - The function to call when the option is clicked.
 * @param {integer} iconSize - The size of the icon.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuOption = ({ icon: IconComponent, text, onClick, iconSize = 13 }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
  } = useContext(Global);

  // Local State
  const [isHovered, setIsHovered] = useState(false);

  /**
   * Handles the mouse enter event, only if onClick is provided.
   */
  const handleMouseEnter = () => {
    if (onClick) {
      setIsHovered(true);
    }
  };

  /**
   * Handles the mouse leave event, only if onClick is provided.
   */
  const handleMouseLeave = () => {
    if (onClick) {
      setIsHovered(false);
    }
  };

  /**
   * Handles the click event, only if onClick is provided.
   */
  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      {/* INLINE STYLES */}
      <InlineStyles
        hoverColor={onClick ? theme.highlightBackgroundColor : 'transparent'}
        hoverTextColor={onClick ? theme.highlightForegroundColor : theme.foregroundColor}
      />

      {/* CONTAINER */}
      <div
        onClick={handleClick}
        className="context-menu-option-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: onClick ? 'pointer' : 'default',
          color: isHovered ? theme.highlightForegroundColor : theme.foregroundColor,
          fontFamily: (profile && profile.fontFamily) || defaultFont,
        }}>

        {/* ICON */}
        <div className="context-menu-option-icon">
          <IconComponent
            color={isHovered ? theme.highlightForegroundColor : theme.foregroundColorFaded}
            width={iconSize}
            height={iconSize}
          />
        </div>

        {/* TEXT */}
        {text}

      </div>
    </>
  );
};

export default ContextMenuOption;
