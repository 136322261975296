import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './SiteListElements.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DotIcon from '../../../../common/svg/DotIcon';

// Components
//import Element from '../element/Element';
import SiteListElement from '../listelement/SiteListElement';
import SiteListGroup from '../listgroup/SiteListGroup';

const SiteListElements = ({ useDrag }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileModels,
    profile,
    selectedModel
  } = useContext(Global);

  // Local State
  const [collectionElements, setCollectionElements] = useState([]);

  /**
   * Populates the collection elements based on the selected model.
   */
  const populateCollectionElements = useCallback(() => {
    if (!selectedModel) return;

    const newCollectionElements = profileModels.map(collection => ({
      type: 'collection',
      title: collection.title,
      icon: DotIcon,
      collectionKey: collection.key,
      collectionTitle: collection.title,
    }));

    setCollectionElements(newCollectionElements);
  }, [selectedModel]);

  /**
   * Gathers fields, static elements, and buttons that can be added to the canvas.
   */
  useEffect(() => {
    populateCollectionElements();
  }, [
    profileModels,
    profile,
    selectedModel, 
    populateCollectionElements, 
  ]);

  return (
    <>
      {/* CONTAINER */}
      <div className="site-list-elements-container"
        style={{
          borderRightColor: theme.backgroundColorFaded,
        }}>

        {/* SCROLLER */}
        <div className="site-list-elements-scroller">

          {/* COLLECTIONS */}
          <SiteListGroup title="Collections">

            {collectionElements.map((element, index) => (

              <SiteListElement
                key={index}
                element={element}
                icon={element.icon}
                title={element.title}
                useDrag={useDrag}
              />

            ))}

          </SiteListGroup>

        </div>

      </div>

    </>
  );

};

export default SiteListElements;
