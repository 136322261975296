import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load events for a specific app.
 */
const usePreloadProfileEvents = () => {

  // Global
  const { 
    profile, 
    setProfileEvents 
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!profile || !profile.key) {
      setProfileEvents([]);
      return;
    }

    // Function to load events
    const loadEvents = (profileKey) => {
      const handleUpdate = (items) => {
        setProfileEvents(items);
        activity.log(profileKey, 'reads', items.length);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        collections.events,
        profileKey,
        handleUpdate
      );

      return unsubscribe;
    };

    // Subscribe to events
    const unsubscribeEvents = loadEvents(profile.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeEvents) {
        unsubscribeEvents();
      }
    };
  }, [profile, setProfileEvents]);

};

export default usePreloadProfileEvents;
