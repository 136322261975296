import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppSettingsPermissions.css';

// Components
import AppPermissions from './apppermissions/AppPermissions';
import CollectionPermissions from './collectionpermissions/CollectionPermissions';

const AppSettingsPermissions = () => {
    const {
        profileModels,
    } = useContext(Global);

    return (

        <div className="app-settings-permissions-container">

            <AppPermissions />

            {profileModels.map((model) => (

                <CollectionPermissions
                    key={model.key}
                    model={model}
                />

            ))}

        </div>

    );
};

export default AppSettingsPermissions;
