import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { Section } from '../../common/utilities/Enums';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Utilities
import { defaultSummaryTitleStyles } from '../utilities/Defaults';
import { generateKey } from '../utilities/Keys';

// Styles
import './PromptModal.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../components/modal/Modal';
import ModalButton from '../components/modal/buttons/ModalButton';
import ModalButtons from '../components/modal/buttons/ModalButtons';

// Managers
import DataManager from '../managers/DataManager';
import IndexManager from '../managers/IndexManager';
import PromptManager from '../managers/PromptManager';

const dataManager = new DataManager();
const indexManager = new IndexManager();
const promptManager = new PromptManager();

const PromptModal = ({ isOpen, setOpen }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileFields,
    currentUser,
    hideProgress,
    profileModels,
    profile,
    setActiveDate,
    setCurrentObject,
    setCurrentSection,
    setEventVisible,
    setSelectedEvent,
    setSelectedModel,
    setSelectedObject,
    showProgress,
  } = useContext(Global);

  const [prompt, setPrompt] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    showProgress("Saving note...");

    // Start building the initial prompt
    const initialPrompt = `
The purpose of this query is to store the user's unstructured input, decide upon a structure for it, and ultimately
store the data in a structured format in the database. Your responsibility is to analyze the input and determine the
appropriate data structure in which to store the data, using models and fields.

### Key Instructions:
1. If the input specifies additional details or requests more information, always attempt to provide such details based on publicly available or general knowledge. For example:
    - If the input mentions a well-known workout (e.g., "Murphy CrossFit workout"), include all known exercises and their specifics.
    - If the input references a movie, include details such as the director, cast, and release date.
    - If the input pertains to an event, provide context or background details relevant to the event.
2. Always include a note if you are unsure whether the additional details are complete, and ensure that they are relevant to the user's query.
3. Ancillary or complementary details that you derive should be included as extra fields in the structured data or as part of the notes section.
4. If multiple models are required to store the data, create the necessary models and fields to capture all relevant information.

You must also detect if the input describes a calendar event. Calendar events require the following:
    - A title field describing the event.
    - A start date and time in the format YYYY-MM-DDTHH:mm:ss (e.g., 2024-11-18T13:00:00 for 1 PM on November 18, 2024).
    - An optional end date and time in the format YYYY-MM-DDTHH:mm:ss (if not provided, assume the event lasts one hour).
    - Additional details (if present) should be added as notes.

If the input describes a calendar event or a reminder of something that occurs at a certain time, output a structured 
calendar event object instead of a model.

If multiple events are described in the input, output a structured calendar event object for each event.

The following calendars exist. Please return the calendar title, as supplied, for the destination of the event. If you
cannot determine the appropriate calendar, return any one of the calendar titles.

If you do not know the value of a field, don't return the field at all.

Given the following field types:

    - checkbox (boolean: true/false)
    - checklist (array of strings for items to check off)
    - comments (string for freeform text)
    - countries (string representing a country name)
    - currency (number formatted as a decimal)
    - date (must be formatted as YYYY-MM-DDTHH:mm:ss, defaulting to midnight if no time is provided)
    - day (integer: 0-6, where 0 = Sunday, 1 = Monday, etc.)
    - month (must be formatted as YYYY-MM-DDTHH:mm:ss, defaulting to midnight if no time is provided)
    - year (must be formatted as YYYY-MM-DDTHH:mm:ss, defaulting to midnight if no time is provided)
    - documents (array of strings representing document URLs)
    - gallery (array of strings representing image URLs)
    - image (string representing an image URL)
    - lookup (string referencing another record)
    - number (number)
    - password (string)
    - phone (string)
    - states (string representing a U.S. state name)
    - text (string)
    - time (string formatted as YYYY-MM-DDTHH:mm:ss)

${profileModels.length > 0
        ? `Here are the existing models and their fields. Please try to reuse existing models and fields where possible by using
existing titles where it makes sense.

Models and Fields:
${profileModels
          .map((model) => {
            const modelFields = profileFields.filter((field) => field.modelKey === model.key);
            return `    - "${model.title}"
          Fields:
${modelFields
                .map(
                  (field) =>
                    `            - "${field.title}" (type: ${field.type})`
                )
                .join("\n")}`;
          })
          .join("\n")}`
        : ""
      }

### Additional Instructions for Dates:
- Valid response JSON is required. Do not include comments or extraneous characters or information in the response.
- If the input contains relative dates (e.g., "today," "yesterday," "in two days"), include a placeholder token such as:
    - \`<<today>>\`
    - \`<<yesterday>>\`
    - \`<<relative_days:-2>>\` (for "2 days ago").
    - \`<<relative_days:+2>>\` (for "in 2 days").
- The following current date and time should be used as the reference point for relative dates: ${new Date().toISOString()}.
  You may need to determine offsets based upon this reference point. For example, if the current date is 2024-11-21 (which
  you would need to determine is a Thursday), then
  you should calculate "Friday" as an offset of 1 day.
- Dates with times specified must always include any optional time within the \`<<relative_days>>\` token. For example:
- \`<<relative_days:+2T19:00:00>>\` is valid.
- \`<<relative_days:+2>>T19:00:00\` is invalid.
- Dates must always be formatted as YYYY-MM-DD before being stored.
- If the input specifies an invalid or ambiguous date (e.g., "2-24-2022"), provide \`<<invalid_date>>\` in the response for later review.
- Include a field value with the correctly formatted date, and explicitly state the intended meaning of the date (e.g., "Meeting date," "Reminder date").
- Always include a short title field value for the model that summarizes the contents of the object record.

### Special Instruction:
- If the user's input references a known concept, activity, or entity, infer and include relevant details to enrich the structured data. The additional fields should improve the usefulness and completeness of the database entry.

Please analyze the input and determine:
    - The model's title (should be plural).
    - The model's description.
    - A list of fields that should belong to the model in order to store the data. Include:
          - The field's title.
          - The field's description.
          - The field's type.
          - The value to be stored in this field.

Here is the input:
${prompt}
`;

    // Prepare the payload
    const preparedPrompt = await promptManager.processPrompt(initialPrompt);

    // Send the request to the API
    const responseString = await promptManager.send(preparedPrompt);

    // Parse the response
    const response = JSON.parse(responseString);

    // Process calendar events
    if (response.events && Array.isArray(response.events)) {
      for (const event of response.events) {
        // Ensure the event object is valid before processing
        if (event && event.title && event.startDateTime) {
          await createEvent(event);
        } else {
          console.warn("Invalid event object:", event);
        }
      }
    }

    // Process models
    if (response.models && Array.isArray(response.models)) {
      for (const model of response.models) {
        // Ensure the model object is valid before processing
        if (model && model.title && model.fields) {
          await processModel(model);
        } else {
          console.warn("Invalid model object:", model);
        }
      }
    }

    // Clear the prompt
    setPrompt("");

    // Hide the progress panel
    hideProgress();

    // Hide the modal
    setOpen(false);

  };

  const createEvent = async (event) => {

    // Title
    const title = event.title;

    // Start Date/Time
    const startTimestamp = processDates(event.startDateTime);

    // End Date/Time
    let endTimestamp;
    if (!event.endDateTime) {
      // Add one hour to the startTimestamp
      const oneHourLater = startTimestamp.toDate(); // Convert Firestore Timestamp to JS Date
      oneHourLater.setHours(oneHourLater.getHours() + 1); // Add one hour
      endTimestamp = Timestamp.fromDate(oneHourLater); // Convert back to Firestore Timestamp
    } else {
      endTimestamp = processDates(event.endDateTime);
    }

    const key = generateKey();

    const data = {
      profileKey: profile.key,
      key: key,
      title: title,
      startDate: startTimestamp,
      endDate: endTimestamp,
    };

    await dataManager.add(collections.events, profile.key, key, data);

    setCurrentSection(Section.CALENDARS);
    setSelectedEvent(data);
    setEventVisible(true);

    setActiveDate(startTimestamp.toDate());

    return data;
  };

  const processModel = async (model) => {

    // Find an existing model by its title (case insensitive)
    const existingModel = profileModels.find(
      (m) => typeof m.title === "string" && m.title.toLowerCase() === model.title.toLowerCase()
    );

    let processedModel = null;

    if (!existingModel) {

      // Create a new model
      processedModel = await createModel(model);

    } else {

      // Validate the key for the existing model
      if (!existingModel.key) {
        existingModel.key = generateKey();
      }

      processedModel = existingModel;
    }

    // Process the fields for the model
    await processFields(processedModel, model);

    // Create an object with the field values
    const newObject = await createObject(processedModel, model);

    // Update the UI state (assumes UI state functions exist)
    setSelectedModel(processedModel);
    setCurrentObject(newObject);
    setSelectedObject(newObject);

  };

  const processFields = async (processedModel, originalModel) => {
    let sortIndex = 1; // Initialize sort index for field sorting (start from 1 because 0 is for the title field)

    for (const field of originalModel.fields) {
      // Skip the title field (already handled elsewhere)
      if (field.title === "Title") {
        continue;
      }

      // Check if the field already exists in the database
      const existingField = profileFields.find(
        (f) =>
          f.title.toLowerCase() === field.title.toLowerCase() &&
          f.modelKey === processedModel.key
      );

      if (!existingField) {
        // Generate a key for the new field
        const fieldKey = generateKey();
        field.key = fieldKey; // Assign the key to the originalModel's field

        // Create the field in the database
        await createField(
          { ...field, key: fieldKey },
          processedModel.key,
          sortIndex++
        );
      } else {
        // Field already exists, so just re-use the key
        field.key = existingField.key;
      }
    }
  };

  const createObject = async (processedModel, originalModel) => {

    const objectKey = generateKey();
    const now = Timestamp.now();

    const data = {
      key: objectKey,
      profileKey: profile.key,
      modelKey: processedModel.key,
      userKey: currentUser.userKey,
      tags: [],
      dateCreated: now,
      dateModified: now,
    };

    for (const field of originalModel.fields) {

      if (field.type === "date" && field.value) {
        // Process date field value
        const processedDate = processDates(field.value);
        if (processedDate) {

          data[field.key] = processedDate;
        }
      } else if (field.type === "checklist") {
        const options = [];
        if (field.value && Array.isArray(field.value)) {
          // Iterate over each option
          field.value.forEach((optionTitle) => {
            // Generate a key for each option
            const optionKey = generateKey();
            // Add the option with key and title to the options array
            options.push({ key: optionKey, instructions: "", title: optionTitle });
          });
        }
        data[field.key] = options;
      } else {
        // For all other fields, use the value
        if (field.key && field.key !== undefined) {
          data[field.key] = field.value;
        }
      }
    }

    if (processedModel.titleFieldKey && processedModel.titleFieldKey !== undefined) {
      data[processedModel.titleFieldKey] = originalModel.titleFieldValue;
    }

    // Add an index record for the object
    const objectTitle = data[processedModel.titleFieldKey];
    const objectTags = data.tags || [];

    await indexManager.add(profile.key, processedModel.key, objectKey, objectTitle, objectTags);

    return await dataManager.add(
      processedModel.key, // collection name
      profile.key,
      objectKey,
      data
    );

  };

  const createField = async (field, modelKey, sortNumber) => {
    const key = field.key || generateKey();

    const fieldData = {
      profileKey: profile.key,
      key: key,
      modelKey: modelKey,
      title: field.title,
      description: field.description || "",
      type: field.type,
      sort: sortNumber, // Assign the sort order
    };

    // Add the field to the database
    await dataManager.add(
      collections.fields,
      profile.key,
      key,
      fieldData
    );

    return fieldData;
  };

  const createModel = async (model) => {

    const userKey = currentUser.userKey;
    const profileKey = profile.key;

    const newModelKey = generateKey(); // Generate key for the new model

    // Current timestamp
    const now = Timestamp.now();

    // Find the highest sort number
    const highestSortNumber = profileModels.reduce((max, model) => Math.max(max, model.sort || 0), 0);

    const titleFieldKey = generateKey();

    const titleFieldValue = model.titleFieldValue;

    // Add a default title field if one doesn't already exist
    const existingTitleField = model.fields.find(field => field.title === "Title");
    if (!existingTitleField) {
      const titleFieldData = {
        profileKey: profileKey,
        key: titleFieldKey,
        title: "Title",
        description: "Title of the item.",
        type: "text",
        sort: 1,
        modelKey: newModelKey,
        modelTitle: model.title,
        modelDescription: "",
        options: [],
      };

      await dataManager.add(
        collections.fields,
        profileKey,
        titleFieldKey,
        titleFieldData
      );

      model.fields.push({ ...titleFieldData, value: titleFieldValue }); // Add to fields list for the object record
    }

    // Create the titleFieldBlock for UI layout
    const titleFieldContentKey = generateKey();
    const titleFieldBlock = {
      id: generateKey(),
      content: [
        {
          id: titleFieldContentKey,
          key: titleFieldKey, // Reference the title field key
          title: "Title",
          field: { key: titleFieldKey, title: "Title", type: "text" }, // Keep a lightweight field reference
          type: "field",
        },
      ],
      align: "left",
    };

    // Create the model data
    const modelData = {
      key: newModelKey,
      profileKey: profileKey,
      userKey: userKey,
      title: model.title,
      description: model.description,
      sort: highestSortNumber + 1,
      fieldSort: [titleFieldKey], // Include the title field key in the field sort order
      tags: [],
      titleFieldKey: titleFieldKey,
      rows: [{ id: generateKey(), blocks: [titleFieldBlock] }], // Include the titleFieldBlock in rows
      styles: defaultSummaryTitleStyles,
      dateCreated: now,
      dateModified: now,
    };

    // Add the model to the database
    await dataManager.add(
      collections.models,
      profileKey,
      newModelKey,
      modelData
    );

    return modelData;

  };

  const processDates = (dateValue) => {
    const now = new Date();

    if (dateValue.startsWith("<<today>>")) {
      const timeMatch = dateValue.match(/T(\d{2}):(\d{2}):(\d{2})/);
      if (timeMatch) {
        now.setHours(parseInt(timeMatch[1], 10));
        now.setMinutes(parseInt(timeMatch[2], 10));
        now.setSeconds(parseInt(timeMatch[3], 10));
      }
      return Timestamp.fromDate(now);
    }

    if (dateValue.startsWith("<<yesterday>>")) {
      const yesterday = new Date();
      yesterday.setDate(now.getDate() - 1);
      const timeMatch = dateValue.match(/T(\d{2}):(\d{2}):(\d{2})/);
      if (timeMatch) {
        yesterday.setHours(parseInt(timeMatch[1], 10));
        yesterday.setMinutes(parseInt(timeMatch[2], 10));
        yesterday.setSeconds(parseInt(timeMatch[3], 10));
      }
      return Timestamp.fromDate(yesterday);
    }

    if (dateValue.startsWith("<<relative_days:")) {
      // Match relative days with optional time embedded
      const relativeDaysMatch = dateValue.match(/<<relative_days:([+-]?\d+)T(\d{2}):(\d{2}):(\d{2})>>/);
      if (relativeDaysMatch) {
        const offset = parseInt(relativeDaysMatch[1], 10);
        const hours = parseInt(relativeDaysMatch[2], 10);
        const minutes = parseInt(relativeDaysMatch[3], 10);
        const seconds = parseInt(relativeDaysMatch[4], 10);

        const relativeDate = new Date();
        relativeDate.setDate(now.getDate() + offset); // Apply the relative days offset
        relativeDate.setHours(hours); // Apply hours
        relativeDate.setMinutes(minutes); // Apply minutes
        relativeDate.setSeconds(seconds); // Apply seconds

        return Timestamp.fromDate(relativeDate);
      }
    }

    if (dateValue === "<<invalid_date>>") {
      console.warn("Invalid date encountered. Skipping processing.");
      return null;
    }

    // Assume it's a standard ISO 8601 date-time string
    return Timestamp.fromDate(new Date(dateValue));
  };

  return (

    <>
      {/* MODAL */}
      <Modal title="Quick Note"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        width={isMobile ? "90%" : "400px"}>

        <div className="prompt-modal-container">

          <textarea
            className="prompt-modal-text"
            style={{
              color: theme.foregroundColor
            }}
            value={prompt}
            onChange={(e) => { setPrompt(e.target.value); }}
            placeholder="Enter note..."
            rows="9"
            autoFocus={true}
          />

          {/* BUTTONS */}
          <ModalButtons>

            {/* DELETE BUTTON */}
            <ModalButton
              onClick={handleSubmit}
              label="Save"
            />

          </ModalButtons>

        </div>

      </Modal>

    </>
  );
};

export default PromptModal;

