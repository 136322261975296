import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './Relationships.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Components
import Search from '../../../../common/search/Search';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

// Managers
import ObjectManager from '../../../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

const Relationships = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {
    const { theme } = useTheme();
    const {
        profileModels,
        profile,
        setSelectedModel,
        setSelectedObject
    } = useContext(Global);

    // Local State
    const [value, setValue] = useState([]); // Default ADD mode value
    const [groupedEntries, setGroupedEntries] = useState({});
    const [searchVisible, setSearchVisible] = useState(false);

    useEffect(() => {
        setValue(object?.[field.key] || []);
    }, [object, field.key]);

    useEffect(() => {
        if (!value) return;

        const entries = value.reduce((acc, entry) => {
            if (!acc[entry.modelKey]) {
                acc[entry.modelKey] = [];
            }
            acc[entry.modelKey].push(entry);
            return acc;
        }, {});

        setGroupedEntries(entries);

    }, [value]);

    const handleAdd = () => {
        setSearchVisible(true);
    };

    const onSearch = (entry) => {
        setValue((prevEntries) => {
            // Check if the entry is already in the selected entries
            if (prevEntries.some((e) => e.objectKey === entry.objectKey)) {
                // Call onUpdate with the current value if no new entry is added
                //onUpdate(field, prevEntries);
                return prevEntries;
            }
            const newEntries = [...prevEntries, entry];
            // Call onUpdate with the new entries
            onUpdate(field, newEntries);
            return newEntries;
        });
        setSearchVisible(false);
    };

    const handleRemove = (e, entryToRemove) => {
        e.stopPropagation();
        setValue((prevEntries) =>
            prevEntries.filter((entry) => entry.id !== entryToRemove.id)
        );
    };

    const handleForward = async (entry) => {
        // Find the model in the profileModels array and set it to active
        const appCollection = profileModels.find(appCollection => appCollection.key === entry.modelKey);
        setSelectedModel(appCollection);

        // Fetch the selected object and set it to active
        const object = await objectManager.fetch(profile.key, entry.objectKey);
        setSelectedObject(object);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                onClick={handleAdd}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                />

                {value.length > 0 &&
                    <>
                        {/* LIST */}
                        <div className="relationships-list-wrapper">
                            {Object.keys(groupedEntries).map((modelKey) => {
                                return (
                                    <div key={modelKey} className="relationships-group">
                                        {groupedEntries[modelKey].map((entry) => (

                                            <div key={entry.id}
                                                className="relationships-entry-wrapper"
                                                style={{ color: theme.foregroundColor }}>

                                                <div
                                                    className="relationships-entry-icon"
                                                    onClick={(e) => handleRemove(e, entry)}>
                                                    <RemoveIcon
                                                        color={theme.foregroundColorFaded}
                                                        width="18"
                                                        height="18"
                                                    />
                                                </div>

                                                <div className="relationships-entry-title"
                                                    onClick={() => handleForward(entry)}>
                                                    {entry.objectTitle}
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                );
                            })}
                        </div>
                    </>
                }

                {/* NO ITEMS */}
                {value.length === 0 &&
                    <div
                        className="relationships-no-items"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {value.length === 0 && 'No items added'}
                    </div>
                }

                {/* SEARCH MODAL */}
                <Search
                    onSearch={onSearch}
                    isVisible={searchVisible}
                    setVisible={setSearchVisible}
                    modelKey={field.relatedModelKey}
                />

            </FieldContainer>
        </>
    );
};

export default Relationships;
