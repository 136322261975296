import React from 'react';

// Utilities
import { formatEventDates } from '../../../utilities/Formatters';
import { truncateFileName } from '../../../utilities/Strings';

// Styles
import './EventGridItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CalendarIcon from '../../../svg/CalendarIcon';

// Components
import GridItem from '../../../components/grid/griditem/GridItem';
import GridItemField from '../../../components/grid/griditem/field/GridItemField';
import GridItemPrivacy from '../../../components/grid/griditem/privacy/GridItemPrivacy';
import GridItemTitle from '../../../components/grid/griditem/title/GridItemTitle';

/**
 * EventGridItem
 * 
 * A grid item for displaying events.
 * 
 * @param {Object} item The event item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} EventGridItem component.
 */
const EventGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart }) => {

    // Theme
    const { currentThemeMode, theme } = useTheme();

    // Format the dates and times
    const { formattedDates, formattedTimes } = formatEventDates(item.startDate, item.endDate);

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => onClick(event, item)} // Forward the event and item
            onDoubleClick={() => onDoubleClick(item)}
            onDragStart={(event) => onDragStart(event, item)}>

            <div
                className="event-grid-item-container"
                title={item.title}>

                {/* ICON */}
                <div className="event-grid-item-icon-container"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}>
                    <CalendarIcon
                        color={theme.highlightBackgroundColor}
                        width="70"
                        height="70"
                    />
                </div>

                {/* PRIVACY */}
                <GridItemPrivacy
                    selected={selected}
                    shared={item.shared}
                    label="Private"
                />

                {/* TITLE */}
                <GridItemTitle
                    title={truncateFileName(item.title, 20)}
                    selected={selected}
                />

                {/* DATES */}
                <GridItemField
                    value={formattedDates}
                    selected={selected}
                />

                {/* TIMES (if single-day event) */}
                {formattedTimes && (
                    <GridItemField
                        value={formattedTimes}
                        selected={selected}
                    />
                )}

            </div>

        </GridItem>
    );
};

export default EventGridItem;
