import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './FieldSelectorHeader.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const FieldSelectorHeader = () => {
    const { theme } = useTheme();
    const {
        profile,
        setFieldFilter,
    } = useContext(Global);

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            setFieldFilter("");
        }
    }, [setFieldFilter]);

    const handleFilter = (event) => {
        setFieldFilter(event.target.value);
    };

    return (
        <div className="field-selector-header-background">
            <input
                type="text"
                name="title"
                className="field-selector-header-input"
                ref={inputRef} // Reference to the input element
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColor,
                    borderColor: theme.backgroundColorFaded,
                    fontFamily: profile.fontFamily
                }}
                onChange={handleFilter} // Handle input changes
                placeholder="Search Fields..."
            />
        </div>
    );
};

export default FieldSelectorHeader;
