import React from 'react';

// Styles
import './NotificationsToolbar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const NotificationsToolbar = () => {
    const { theme } = useTheme();

    return (
        <>
            <div className="bookmarks-toolbar-container">
                <div className="bookmarks-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}>
                    Notifications
                </div>
                <div className="bookmarks-toolbar-right" >
                    
                </div>
            </div>
        </>
    );
};

export default NotificationsToolbar;
