/**
 * Checks whether the current user has the specified permission for a model.
 *
 * @param {Object} profile - The selected app.
 * @param {Object|null} currentUser - The current user object, or null for public users.
 * @param {Object} model - The model object (e.g., Tasks).
 * @param {string} action - The action to check (e.g., 'create', 'read', 'update', 'delete').
 * @param {string|null} publicRoleKey - The key for the public role, used when currentUser is null.
 * @param {Object|null} record - The record to check, if applicable.
 * @returns {boolean} True if the user has the permission, otherwise false.
 */
export const hasCollectionPermission = (profile, currentUser, model, action, publicRoleKey = null, record = null) => {
    if (!model || !action) return false; // Ensure model and action are provided

    const permissions = model.permissions || {};

    // Handle public users
    if (!currentUser) {
        if (!publicRoleKey) return false; // Public role key is required for public users

        const publicRolePermissions = permissions.roles?.[publicRoleKey] || {};
        return publicRolePermissions[action] !== false; // Default to true if not explicitly false
    }

    const userRoles = currentUser.roles || [];

    // Grant full access to the app creator (using profile.userKey)
    if (profile?.userKey && currentUser.userKey === profile.userKey) {
        return true;
    }

    // Handle creator-only special case
    if (permissions.creatorOnly && (action === 'update' || action === 'delete')) {
        if (record?.creatorKey && record.creatorKey === currentUser.userKey) {
            return true; // Grant permission to the record creator
        }
        return false; // Deny to others if creatorOnly is enforced
    }

    // Fallback to role-based permissions for logged-in users
    return userRoles.some(roleKey => {
        const rolePermissions = permissions.roles?.[roleKey] || {};
        return rolePermissions[action] !== false; // Default to true if not explicitly false
    });
};


/**
 * Checks whether the current user has the specified app-level permission.
 *
 * @param {Object} profile - The selected app.
 * @param {Object} currentUser - The current user object.
 * @param {string} action - The app-level action to check.
 * @returns {boolean} True if the user has the permission, otherwise false.
 */
export const hasAppPermission = (profile, currentUser, action) => {
    if (!currentUser || !profile || !action) {
        return false;
    }

    const userRoles = currentUser.roles || [];
    const permissions = profile.permissions || {};

    // Grant full access to the app creator
    if (profile?.userKey && currentUser.userKey === profile.userKey) {
        return true;
    }

    // Fallback to role-based permissions
    const hasPermission = userRoles.some((roleKey) => {
        const rolePermissions = permissions.roles?.[roleKey] || {};
        return rolePermissions[action] !== false; // Default to true if not explicitly false
    });

    return hasPermission;
};




