import React from 'react';

// Styles
import './CartButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CartIcon from '../../../../../../common/svg/CartIcon';

const CartButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="cart-button-container">
            <CartIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default CartButton;
