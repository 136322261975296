import React, { useContext, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType } from '../utilities/Enums';
import { getCollectionName, getShareCount, getStringPathFromKeys } from '../../common/utilities/Folders';

// Helpers
import { handleDragStart, handleDragOver, handleDragEnter, handleDragLeave, handleDropItem } from './helpers/FolderDragHelper.js';

// Styles
import './Folder.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Confirm from '../components/confirm/Confirm.js';
import EventForm from '../../common/calendar/event/EventForm';
import FolderAdd from './add/FolderAdd';
import FolderAddButton from './addbutton/FolderAddButton';
import FolderEdit from './edit/FolderEdit';
import FolderItem from './item/FolderItem.js';
import FolderToolbar from './toolbar/FolderToolbar';
import Form from '../../common/form/Form';
import ObjectAdd from '../../common/collection/addobject/ObjectAdd';
import Grid from '../../common/components/grid/Grid';
import Search from '../../common/search/Search';
import Story from '../../common/story/view/Story';
import StoryForm from '../../common/story/form/StoryForm';

// Hooks
import useSetFolderItems from './hooks/SetFolderItems.js';
import useSetFolderPath from './hooks/SetFolderPath.js';

// Managers
import DataManager from '../../common/managers/DataManager';
import FolderManager from '../../common/managers/FolderManager';

const dataManager = new DataManager();
const folderManager = new FolderManager();

const Folder = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        hideProgress,
        selectedFolder,
        selectedFolderItems,
        setEventVisible,
        setSelectedModel,
        setSelectedEvent,
        setSelectedFolder,
        setSelectedFolderItems,
        setSelectedObject,
        setSelectedStory,
        showProgress,
        profileEvents,
        profileFiles,
        profileFolders,
        profileObjects,
        profileStories,
    } = useContext(Global);

    // Items in this folder
    const [folderItems, setFolderItems] = useState([]);

    const [pathKeys, setPathKeys] = useState([]); // Array of keys
    const [isDragging, setIsDragging] = useState(false);

    // Add
    const [addSearchOpen, setAddSearchOpen] = useState(false);
    const [addFolderOpen, setAddFolderOpen] = useState(false);
    const [storyOpen, setStoryOpen] = useState(false);
    const [addObjectOpen, setAddObjectOpen] = useState(false);

    // View
    const [viewStoryOpen, setViewStoryOpen] = useState(false);

    // Edit
    const [editFolderOpen, setEditFolderOpen] = useState(false);
    const [editObjectOpen, setEditObjectOpen] = useState(false);

    // Delete
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    // References
    const dragCounter = useRef(0);
    const fileInputRef = useRef(null);
    const containerRef = useRef(null);

    /**
     * Determines the contents of the folder when it is selected.
     */
    useSetFolderItems({ selectedFolder, setFolderItems });

    /**
     * Prepares the breadcrumb folder path keys.
     */
    useSetFolderPath({ profileFolders, selectedFolder, setPathKeys });

    /**
     * ADD MENU FUNCTIONS
     */
    const handleSearchClick = () => {
        setAddSearchOpen(true);
    };

    // Folder
    const handleAddFolderClick = () => {
        setAddFolderOpen(true);
    };

    // Files
    const handleAddFilesClick = () => {
        fileInputRef.current.click();
    };

    // Event
    const handleAddEventClick = () => {
        setEventVisible(true);
    };

    // Object
    const handleAddObjectClick = (collection) => {
        setSelectedModel(collection);
        setAddObjectOpen(true);
    };

    // Story
    const handleAddStoryClick = () => {
        setSelectedStory(null);
        setStoryOpen(true);
    };

    /**
     * Handles selection of an object in the search dialog.
     */
    const handleSearch = async (entry) => {
        // Fetch the selected object and set it to active
        const object = profileObjects.find(object => object.key === entry.objectKey);
        const updatedObject = { ...object, folderKey: selectedFolder.key };
        await dataManager.update(collections.objects, profile.key, updatedObject.key, updatedObject);

        // Close the search dialog
        setAddSearchOpen(false);
    };

    /**
     * Handles single click on an item.
     */
    const handleItemClick = (event, item) => {
        event.stopPropagation();

        if (event.ctrlKey || event.metaKey || event.shiftKey) {
            // If the Control (Windows) or Command (macOS) key is held, toggle the item's selection
            if (selectedFolderItems.some((selected) => selected.key === item.key)) {
                setSelectedFolderItems(selectedFolderItems.filter((selected) => selected.key !== item.key));
            } else {
                setSelectedFolderItems([...selectedFolderItems, item]);
            }
        } else {
            // If no modifier key is held, select only the clicked item and clear other selections
            if (!selectedFolderItems.some((selected) => selected.key === item.key)) {
                setSelectedFolderItems([item]);
            }
            // Do nothing if the item is already in the array
        }
    };

    /**
     * Deselects all items when the container is clicked.
     */
    const handleContainerClick = () => {
        setSelectedFolderItems([]);
    };

    /**
     * DOUBLE-CLICK HANDLERS
     * 
     * Handles double-clicking of items and routes the intent
     * to the appropriate handler.
     */
    const handleItemDoubleClick = (item) => {
        if (item.type === ItemType.FOLDER) {
            handleFolderDoubleClick(item.key);
        } else if (item.type === ItemType.FILE) {
            handleFileDoubleClick(item.key);
        } else if (item.type === ItemType.EVENT) {
            handleEventDoubleClick(item);
        } else if (item.type === ItemType.STORY) {
            handleStoryDoubleClick(item);
        } else if (item.type === ItemType.OBJECT) {
            handleObjectDoubleClick(item.key);
        }
    };

    // Folder
    const handleFolderDoubleClick = (key) => {
        const folder = profileFolders.find((folder) => folder.key === key);
        setSelectedFolder(folder);
    };

    // Files
    const handleFileDoubleClick = (key) => {
        const file = profileFiles.find((file) => file.key === key);
        window.open(file.url, '_blank', 'noopener,noreferrer');
    };

    // Event
    const handleEventDoubleClick = (item) => {
        const event = profileEvents.find((event) => event.key === item.key);
        setSelectedEvent(event);
        setEventVisible(true);
    };

    // Object
    const handleObjectDoubleClick = (key) => {
        const object = profileObjects.find((obj) => obj.key === key);
        setSelectedObject(object);
        setEditObjectOpen(true);
    };

    // Story
    const handleStoryDoubleClick = (item) => {
        const story = profileStories.find((story) => story.key === item.key);
        setSelectedStory(story);
        setStoryOpen(true);
    };

    /**
     * TOOLBAR BUTTON HANDLERS
     */

    // Settings
    const handleSettingsClicked = async () => {
        setEditFolderOpen(true);
    }

    // Share
    const handleShareClicked = async () => {
        setSelectedShared(true);
    };

    // Unshare
    const handleUnshareClicked = async () => {
        setSelectedShared(false);
    };

    /**
     * Utility for saving shared status of selected items.
     */
    const setSelectedShared = async (share) => {
        for (const item of selectedFolderItems) {
            const collectionName = getCollectionName(item.type);
            await dataManager.update(
                collectionName,
                profile.key,
                item.key,
                { shared: share }
            );
        }
    };

    // Copy
    const handleCopyClicked = () => {

    };

    // Cut
    const handleCutClicked = () => {

    };

    // Paste
    const handlePasteClicked = () => {

    };

    // Delete Clicked
    const handleDeleteClicked = (event) => {
        event.stopPropagation();
        // Show a delete confirmation dialog
        setDeleteConfirmOpen(true);
    };

    // Delete
    const handleDelete = async () => {
        // Delete the item
        setDeleteConfirmOpen(false);

        showProgress(`Deleting ${selectedFolderItems.length} items...`);

        for (const item of selectedFolderItems) {
            console.log(item);

            // NEED TO CALL A FUNCTION TO BATCH DELETE HERE!
            if (item.type === ItemType.FOLDER) {
                // Delete the folder and all its contents
                await folderManager.deleteFolder(profile.key, item);
            } else {
                const collectionName = getCollectionName(item.type);
                await dataManager.delete(collectionName, profile.key, item.key);
            }
        }

        setSelectedFolderItems([]);

        hideProgress();
    };

    /**
     * Handles dropping of photos into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        handleCanvasDrop(event);
    };

    /**
     * Handles adding of files to a folder via drag/drop.
     * 
     * @param {event} event - Event object.
     */
    const handleCanvasDrop = async (event) => {

        if (selectedFolder) {
            showProgress(`Adding files to ${selectedFolder.title}...`);
        } else {
            showProgress(`Adding files...`);
        }

        const folderPath = `spaces/${profile.key}/files/${getStringPathFromKeys(profileFolders, pathKeys)}`;

        console.log("Folder Path: ", folderPath);

        // Handle the drop of all the files
        await folderManager.handleFileDrop(event, folderPath, selectedFolder, profile);

        fileInputRef.current.value = "";

        hideProgress();
    };

    return (
        <div ref={containerRef}
            title="Drop files here."
            className="folder-container"
            style={{
                backgroundColor: 'transparent',
                borderColor: theme.backgroundColorFaded,
                borderWidth: isDragging ? '2px' : '1px',
            }}
            onClick={handleContainerClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onDragEnter={(event) => handleDragEnter(event, dragCounter, setIsDragging)}
            onDragLeave={(event) => handleDragLeave(event, dragCounter, setIsDragging)}>

            {/* TOOLBAR */}
            <FolderToolbar
                folderItems={folderItems}
                pathKeys={pathKeys}
                getShareCount={getShareCount}
                onDropItem={(key, type, targetFolderKey) => // Inject additional params into the handler
                    handleDropItem(key, type, targetFolderKey, profile, selectedFolderItems)
                }
                onCopyClicked={handleCopyClicked}
                onCutClicked={handleCutClicked}
                onPasteClicked={handlePasteClicked}
                onDeleteClicked={handleDeleteClicked}
                onShareClicked={handleShareClicked}
                onUnshareClicked={handleUnshareClicked}
                onSettingsClicked={handleSettingsClicked}
            />

            {/* CONTENT */}
            <div className={isMobile ? "folder-grid-container-mobile" : "folder-grid-container"} >
                <Grid
                    items={folderItems}
                    renderItem={(item) => (

                        <FolderItem
                            key={item.key}
                            item={item}
                            pathKeys={pathKeys}
                            selected={selectedFolderItems.some((selected) => selected.key === item.key)}
                            onClick={(event, item) => handleItemClick(event, item)}
                            onDoubleClick={() => handleItemDoubleClick(item)}
                            onDragStart={(event) => handleDragStart(event, item, selectedFolderItems, setSelectedFolderItems)}
                            onDropItem={(key, type, targetFolderKey) =>  // Inject additional params into the handler
                                handleDropItem(key, type, targetFolderKey, profile, selectedFolderItems)
                            }
                        />

                    )}
                    loading={false}
                    minCols={2}
                    colWidth={isMobile ? 100 : 200}
                />
            </div>

            {/* ADD BUTTON */}
            <div className={isMobile ? "folder-add-button-mobile" : "folder-add-button"}>
                <FolderAddButton
                    onSearch={handleSearchClick}
                    onAddFolder={handleAddFolderClick}
                    onAddFiles={handleAddFilesClick}
                    onAddEvent={handleAddEventClick}
                    onAddStory={handleAddStoryClick}
                    onAddObject={handleAddObjectClick}
                />
            </div>

            {/* FILE INPUT */}
            <input
                ref={fileInputRef}
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={(event) => handleCanvasDrop(event)}
                style={{ display: 'none' }}
                aria-label="File Upload"
                multiple
            />

            {/* EVENT FORM */}
            <EventForm
                date={new Date()}
                folderKey={selectedFolder ? selectedFolder.key : null}
            />

            {/* SEARCH FORM */}
            <Search
                onSearch={handleSearch}
                isVisible={addSearchOpen}
                setVisible={setAddSearchOpen}
            />

            {/* OBJECT FORM */}
            <Form
                isOpen={editObjectOpen}
                setOpen={setEditObjectOpen}
            />

            {/* STORY FORM */}
            <StoryForm
                isOpen={storyOpen}
                setOpen={setStoryOpen}
            />

            {/* STORY */}
            <Story
                isOpen={viewStoryOpen}
                setOpen={setViewStoryOpen}
            />

            {/* ADD OBJECT */}
            <ObjectAdd
                isOpen={addObjectOpen}
                setOpen={setAddObjectOpen}
            />

            {/* ADD */}
            <FolderAdd
                isOpen={addFolderOpen}
                setOpen={setAddFolderOpen}
                folderKey={selectedFolder && selectedFolder.key || null}
            />

            {/* FOLDER EDIT */}
            <FolderEdit
                folder={selectedFolder}
                isOpen={editFolderOpen}
                setOpen={setEditFolderOpen}
            />

            {/* CONFIRM */}
            <Confirm
                title="Delete Item"
                message="Are you sure you want to delete the selected item(s)?"
                isOpen={deleteConfirmOpen}
                setOpen={setDeleteConfirmOpen}
                onConfirm={handleDelete}
            />

        </div>
    );
};

export default Folder;
