import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './LoginInput.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginInput Component
 * 
 * This component renders an input or a select element for login form fields.
 * 
 * @param {string} type - The type of the input ("text", "password", "email", or "select").
 * @param {function} onChange - The function to call with updated value.
 * @param {boolean} required - Whether the input is required.
 * @param {Array} options - The options for a select input. Each option is an object with "value" and "label".
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginInput = ({ type, onChange, required, options = [] }) => {
  
  // Theme
  const { theme } = useTheme();

  // Global
  const { 
    coreTheme,
    profile, 
  } = useContext(Global);

  // Common styles
  const inputStyles = {
    color: coreTheme.foregroundColor,
    backgroundColor: profile ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded,
  };

  return type === 'select' ? (
    <select
      className="login-input-container"
      style={inputStyles}
      onChange={onChange}
      required={required}>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  ) : (
    <input
      type={type}
      className="login-input-container"
      style={inputStyles}
      onChange={onChange}
      required={required}
    />
  );
};

export default LoginInput;
