import React, { useContext, useState, useEffect, useRef } from "react";

// Global
import { Global } from '../../../../Global';

// Styles
import "./RoleChart.css";

// Theme
import { useTheme } from "../../../../ThemeContext";

// Components
import RoleAdd from '../roleadd/RoleAdd';
import RoleDelete from '../roledelete/RoleDelete';
import RoleEdit from '../roleedit/RoleEdit';

// Constants
const nodeWidth = 110; // Node width
const minSpacing = 20; // Minimum spacing between sibling nodes
const topPosition = 0; // Top of the tree
const depth = 120; // Height of each level in the tree

const calculatePositions = (tree) => {
  const calculateSubtreeWidth = (node) => {
    if (!node.children || node.children.length === 0) {
      return nodeWidth;
    }

    const childWidths = node.children.map(calculateSubtreeWidth);
    return (
      childWidths.reduce((total, width) => total + width, 0) +
      (node.children.length - 1) * minSpacing
    );
  };

  const positions = [];

  const calculateNodePosition = (node, x, y, parentX, parentY) => {
    positions.push({
      x,
      y,
      title: node.title,
      description: node.description,
      isCreator: node.isCreator,
      isPublic: node.isPublic,
      parentX,
      parentY,
      node
    });

    if (node.children) {
      const subtreeWidth = calculateSubtreeWidth(node);
      let childX = x - subtreeWidth / 2;

      node.children.forEach((child) => {
        const childWidth = calculateSubtreeWidth(child);
        calculateNodePosition(child, childX + childWidth / 2, y + depth, x, y);
        childX += childWidth + minSpacing;
      });
    }
  };

  calculateNodePosition(tree, 600, topPosition, null, null);
  return positions;
};

const buildHierarchy = (profileRoles) => {
  const roleMap = {};
  const root = [];

  // Map roles by their keys
  profileRoles.forEach((role) => {
    roleMap[role.key] = { ...role, children: [] };
  });

  // Build the tree structure
  profileRoles.forEach((role) => {
    if (role.parentKey) {
      if (roleMap[role.parentKey]) {
        roleMap[role.parentKey].children.push(roleMap[role.key]);
      }
    } else {
      root.push(roleMap[role.key]); // Root node
    }
  });

  // Return the first root node (assuming a single tree root)
  return root.length > 0 ? root[0] : null;
};

const RoleChart = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const { profileRoles } = useContext(Global);

  // Local State
  const [tree, setTree] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [addVisible, setAddVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  // References
  const scrollerRef = useRef(null);

  // Update the tree hierarchy whenever roles change
  useEffect(() => {
    setAddVisible(false);
    setEditVisible(false);
    setDeleteVisible(false);

    if (profileRoles && profileRoles.length > 0) {
      const newTree = buildHierarchy(profileRoles);
      setTree(newTree);
    }
  }, [profileRoles]);

  useEffect(() => {
    const scroller = scrollerRef.current;
    if (scroller) {
      scroller.scrollLeft = (scroller.scrollWidth - 600) / 2;
    }
  }, []);

  const positions = tree ? calculatePositions(tree) : [];

  const handleRemoveNode = (event, node) => {
    event.stopPropagation();
    const role = profileRoles.find((r) => r.key === node.key)
    setSelectedRole(role);
    setDeleteVisible(true);
  };

  const handleAddClick = (event, node) => {
    event.stopPropagation();
    setSelectedNode(node);
    setAddVisible(true);
  };

  const handleNodeClick = (node) => {
    if (node.isPublic) return;
    const role = profileRoles.find((r) => r.key === node.key)
    setSelectedRole(role);
    setEditVisible(true);
  };

  return (
    <>
      <div className="role-chart-scroller" ref={scrollerRef}>
        <svg
          width="1200"
          height="800"
        >
          {positions.map(
            (pos, index) =>
              pos.parentX !== null && (
                <line
                  key={index}
                  x1={pos.parentX}
                  y1={pos.parentY + 25}
                  x2={pos.x}
                  y2={pos.y}
                  stroke={theme.backgroundColorFaded}
                  strokeWidth="1"
                />
              )
          )}

          {positions.map((pos, index) => (
            <foreignObject
              key={index}
              x={pos.x - (nodeWidth / 2)}
              y={pos.y}
              width={nodeWidth}
              height="100"
            >
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                className="role-chart-node">

                {!pos.isCreator && !pos.isPublic && (
                  <div
                    className="role-chart-remove"
                    style={{
                      color: theme.foregroundColor,
                      left: `${nodeWidth / 2 - 15}px`,
                    }}
                    onClick={(event) =>
                      handleRemoveNode(
                        event,
                        pos.node
                      )
                    }>
                    -
                  </div>
                )}

                <div
                  className="role-chart-node-contents"
                  onClick={() => handleNodeClick(pos.node)}
                  title={pos.description}
                  style={{
                    backgroundColor: theme.highlightBackgroundColor,
                  }}>
                  <div
                    className="role-chart-title"
                    style={{
                      color: theme.highlightForegroundColor,
                    }}>
                    {pos.title}
                  </div>
                  {!pos.isPublic &&
                    <div
                      className="role-chart-add"
                      style={{
                        color: theme.foregroundColor,
                        left: `${nodeWidth / 2 - 15}px`,
                      }}
                      onClick={(event) => handleAddClick(event, pos.node)}>
                      +
                    </div>
                  }
                </div>
              </div>
            </foreignObject>
          ))}
        </svg>
      </div>

      <RoleAdd
        parentKey={selectedNode && selectedNode.key}
        isVisible={addVisible}
        setVisible={setAddVisible}
      />

      <RoleEdit
        role={selectedRole && selectedRole}
        isOpen={editVisible}
        setOpen={setEditVisible}
      />

      <RoleDelete
        role={selectedRole && selectedRole}
        isOpen={deleteVisible}
        setOpen={setDeleteVisible}
      />
    </>
  );
};

export default RoleChart;
