import React from 'react';

const BackIcon = ({ color = "white", width = "11", height = "20" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M640-93.85 253.85-480 640-866.15l56.77 56.77L367.38-480l329.39 329.38L640-93.85Z" />
    </svg>

);

export default BackIcon;
