import React from 'react';

// Styles
import './BarcodePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const BarcodePreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="barcode-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div className="barcode-preview-label"
                style={{ color: theme.foregroundColorFaded }}>
                {placeholder}
            </div>
            <div className="barcode-preview-field-container">
                <div
                    className="barcode-preview-input"
                    style={{
                        color: theme.foregroundColor,
                    }}>
                    4901234567890
                </div>
                <button
                    className="barcode-preview-button"
                    style={{
                        borderColor: theme.highlightBackgroundColor,
                        color: theme.foregroundColor,
                        backgroundColor: theme.highlightBackgroundColor
                    }}>
                    Scan
                </button>
            </div>
        </div>
    );
};

export default BarcodePreview;
