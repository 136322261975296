import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './ProfileTitle.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ProfileDot from '../components/profilebar/dot/ProfileDot';

const ProfileTitle = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile
    } = useContext(Global);

    // State Variables
    const [profilePhoto, setProfilePhoto] = useState(null);

    return (
        <>
            {/* CONTAINER */}
            <div className="profile-title-container">

                {/* DOT */}
                <ProfileDot
                    item={profile}
                    size={24}
                />

                {/* TITLE */}
                <div
                    className="profile-title-name"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: profile && profile.fontFamily
                    }}>
                    {`${profile.firstName} ${profile.lastName}`}
                </div>
            </div>
        </>
    );
};

export default ProfileTitle;
