import React, { useContext, useRef } from 'react';

// Global
import { Global } from '../../../../../Global';

// Utilities
import { getUserDisplayValue } from '../../../../../common/utilities/Users';

// Styles
import './UserNameSummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const UserNameSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();
    const {
        profileMembers,
        profile,
    } = useContext(Global);

    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    const user = profileMembers.find((member) => member.key === object.userKey);

    return (
        <div
            className="user-photo-summary-container"
            ref={containerRef}
            style={{ ...processed, height }}>
            {getUserDisplayValue(profile.displayKey, user)}
        </div>
    );
};

export default UserNameSummary;
