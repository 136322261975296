import React, { useCallback, useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Drag/Drop
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Styles
import './TileDesigner.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import AddRowIcon from '../../common/svg/AddRowIcon';
import AlignLeftIcon from '../../common/svg/AlignLeftIcon';
import AlignCenterIcon from '../../common/svg/AlignCenterIcon';
import AlignRightIcon from '../../common/svg/AlignRightIcon';
import DeleteIcon from '../../common/svg/DeleteIcon';
import SaveIcon from '../../common/svg/SaveIcon';
import SplitVerticalIcon from '../../common/svg/SplitVerticalIcon';

// Components

// Components
import Modal from '../../common/components/modal/Modal';
import Properties from './properties/Properties';
import TileCanvasButton from './canvas/canvasbuttons/TileCanvasButton';
import TileCanvasRow from './canvas/canvasrow/TileCanvasRow';
import TileListElements from './list/listelements/TileListElements';

// Managers
import DataManager from '../../common/managers/DataManager';

const dataManager = new DataManager();

// Utility to create a new block
const createBlock = (content = []) => ({
    id: generateKey(),
    content: content,  // Accept content to allow splitting
    align: 'left',
});

const TileDesigner = ({ open, setOpen, collection }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        selectedTileElement,
        setSelectedTileElement,
        setSelectedModel,
    } = useContext(Global);

    // Local State
    const [rows, setRows] = useState([]);
    //const [rows, setRows] = useState([{ id: generateKey(), blocks: [createBlock()] }]);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        if (!collection) return;
        if (collection.rows) {
            setRows(collection.rows);
        } else {
            setRows([{ id: generateKey(), blocks: [createBlock()] }]);
        }
    }, [collection]);

    // Clears selections
    const clearSelections = useCallback(() => {
        setSelectedRow(null);
        setSelectedBlock(null);
        setSelectedTileElement(null);
    }, [setSelectedTileElement]);

    // Handles selecting a block
    const handleBlockSelect = (rowId, blockId) => {
        clearSelections();
        setSelectedBlock({ rowId, blockId });
    };

    // Handle selecting a row
    const handleRowSelect = async (rowId) => {
        await clearSelections();
        setSelectedRow(rowId);
    };

    // Handle selecting an element inside a block
    const handleElementSelect = async (element) => {
        await clearSelections();
        setSelectedTileElement(element);
    };

    // Split the selected block into two blocks
    const handleSplitBlock = (rowId, blockId) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                const newBlocks = row.blocks.map(block => {
                    if (block.id === blockId) {
                        // Ensure each new block gets a unique key
                        return [createBlock(block.content), createBlock([])]; // Split into two blocks
                    }
                    return block;
                }).flat();
                return { ...row, blocks: newBlocks };
            }
            return row;
        }));
    };

    // Delete the selected row
    const handleDeleteRow = useCallback(() => {
        setRows(rows.filter(row => row.id !== selectedRow))
        clearSelections();
    }, [clearSelections, rows, selectedRow]);

    // Delete the selected block or the entire row if it's the last block
    const handleDeleteBlock = useCallback(() => {
        const { rowId, blockId } = selectedBlock;

        setRows(prevRows =>
            prevRows.map(row => {
                if (row.id === rowId) {
                    // Check if the row only has one block
                    if (row.blocks.length === 1) {
                        return null; // Return null to indicate this row should be removed
                    }
                    // Remove the block with the matching blockId from the blocks array
                    return {
                        ...row,
                        blocks: row.blocks.filter(block => block.id !== blockId)
                    };
                }
                return row; // Return the row unchanged if it's not the target row
            }).filter(row => row !== null) // Remove rows where we returned null (i.e., rows with only one block)
        );

        clearSelections();
    }, [clearSelections, selectedBlock]);

    // Delete the selected element by its key
    const handleDeleteElement = useCallback(() => {
        const { key } = selectedTileElement;  // Extract the key from the passed element

        // Iterate over the rows to find the row, block, and element by key
        setRows(prevRows => prevRows.map(row => {
            return {
                ...row,
                blocks: row.blocks.map(block => {
                    const newContent = block.content.filter(item => item.key !== key); // Remove the element by key
                    return { ...block, content: newContent };
                })
            };
        }));

        clearSelections();
    }, [clearSelections, selectedTileElement]);

    // Delete the selected block or entire row if it's the last block
    const handleCanvasClick = () => {
        clearSelections();
    }

    // Align block content
    const handleAlignBlock = (rowId, blockId, alignType) => {
        setRows(rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    blocks: row.blocks.map(block =>
                        block.id === blockId ? { ...block, align: alignType } : block
                    ),
                };
            }
            return row;
        }));
    };

    // Move a row from one position to another
    const handleMoveRow = (dragIndex, hoverIndex) => {
        const newRows = [...rows];
        const [draggedRow] = newRows.splice(dragIndex, 1); // Remove the dragged row
        newRows.splice(hoverIndex, 0, draggedRow); // Insert it in the new position
        setRows(newRows); // Update the state with reordered rows
    };

    // Add a new row
    const addRow = () => {
        setRows([...rows, { id: generateKey(), blocks: [createBlock()] }]);
    };

    // Update block content when an element is dropped, appending instead of replacing
    const handleDrop = (rowId, blockId, element) => {

        // Clear any existing selections
        clearSelections();

        // Destructure the element object and omit the 'icon' property
        const { icon, ...elementWithoutIcon } = element;

        // When an element is dropped, generate a unique key for the instance
        const newBlockElement = { ...elementWithoutIcon, key: generateKey() };

        // Update the rows state with the new element
        setRows(rows.map(row => {
            if (row.id === rowId) {
                return {
                    ...row,
                    blocks: row.blocks.map(block =>
                        block.id === blockId
                            ? { ...block, content: [...block.content, newBlockElement] }
                            : block
                    ),
                };
            }
            return row;
        }));

        setSelectedTileElement(newBlockElement);
    };

    /**
     * Method to save the design to the model record
     */
    const handleSave = async () => {

        // Save the data to the database
        await dataManager.update(
            collections.models,
            profile.key,
            collection.key,
            { rows: rows });

        // Update the selected model in state with the new elements
        setSelectedModel(prev => ({ ...prev, rows }));

        // Close the designer
        //setOpen(false);

    };

    // Keyboard shortcuts
    useEffect(() => {
        // Function to handle key press events
        const handleKeyPress = (event) => {

            // Check if the active element is an input, textarea, or other focusable element
            const activeElement = document.activeElement;
            const isInputFocused = activeElement.tagName === 'INPUT' ||
                activeElement.tagName === 'TEXTAREA' ||
                activeElement.isContentEditable;

            // If an input is focused, do not remove or move the selected element
            if (isInputFocused) return;

            if (event.key === 'Backspace' || event.key === 'Delete') {
                // Check if an element is selected
                if (selectedTileElement) {
                    handleDeleteElement(selectedTileElement);
                }
                // Check if a block is selected
                else if (selectedBlock) {
                    handleDeleteBlock(selectedBlock.rowId, selectedBlock.blockId);
                }
                // Check if a row is selected
                else if (selectedRow) {
                    handleDeleteRow(selectedRow);
                }
            }
        };

        // Attach the event listener for keydown
        window.addEventListener('keydown', handleKeyPress);

        // Cleanup the event listener when the component unmounts or dependencies change
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [selectedRow, selectedBlock, selectedTileElement, handleDeleteRow, handleDeleteBlock, handleDeleteElement]);

    if (!collection) return null;

    return (
        <Modal
            title={`Tile Design - ${collection.title}`}
            isOpen={open}
            onClose={() => setOpen(false)}
            height="90%"
            width="90%">
            <DndProvider backend={HTML5Backend}>
                <div className="tile-designer-container">

                        {/* ELEMENTS PANEL */}
                        <div className="tile-designer-elements"
                            style={{
                                backgroundColor: theme.backgroundColor,
                            }}>
                            <TileListElements useDrag={useDrag} />
                        </div>

                        <div className="tile-designer-canvas-wrapper">

                            {/* TOP BUTTONS */}
                            <div className="tile-designer-buttons">


                                {/* ADD ROW */}
                                <TileCanvasButton
                                    title="Add Row"
                                    icon={AddRowIcon}
                                    onClick={addRow}
                                />

                                {/* DELETE ROW */}
                                {selectedRow && (
                                    <TileCanvasButton
                                        title="Delete Row"
                                        icon={DeleteIcon}
                                        onClick={handleDeleteRow}
                                    />
                                )}

                                {/* SELECTED BLOCK */}
                                {selectedBlock && (
                                    <>
                                        <TileCanvasButton
                                            title="Split Block"
                                            icon={SplitVerticalIcon}
                                            onClick={() => handleSplitBlock(selectedBlock.rowId, selectedBlock.blockId)}
                                        />
                                        <TileCanvasButton
                                            title="Remove Block"
                                            icon={DeleteIcon}
                                            onClick={() => handleDeleteBlock(selectedBlock.rowId, selectedBlock.blockId)}
                                        />
                                        <TileCanvasButton
                                            title="Left"
                                            icon={AlignLeftIcon}
                                            onClick={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'left')}
                                        />
                                        <TileCanvasButton
                                            title="Center"
                                            icon={AlignCenterIcon}
                                            onClick={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'center')}
                                        />
                                        <TileCanvasButton
                                            title="Right"
                                            icon={AlignRightIcon}
                                            onClick={() => handleAlignBlock(selectedBlock.rowId, selectedBlock.blockId, 'right')}
                                        />
                                    </>
                                )}

                                {/* SELECTED ELEMENT */}
                                {selectedTileElement && (
                                    <TileCanvasButton
                                        title="Remove Element"
                                        icon={DeleteIcon}
                                        onClick={() => handleDeleteElement(selectedTileElement)}
                                    />
                                )}

                            </div>

                            {/* MAIN CANVAS */}
                            <div className="tile-designer-canvas"
                                onClick={handleCanvasClick}>
                                {rows.map((row, index) => (
                                    <TileCanvasRow
                                        key={row.id}
                                        index={index}
                                        row={row}
                                        selectedRow={selectedRow}
                                        selectedBlock={selectedBlock}
                                        selectedTileElement={selectedTileElement}
                                        onMoveRow={handleMoveRow}
                                        onRowSelect={handleRowSelect}
                                        onBlockSelect={handleBlockSelect}
                                        onDrop={handleDrop}
                                        onElementSelect={handleElementSelect}
                                        useDrag={useDrag}
                                        useDrop={useDrop}
                                    />
                                ))}
                            </div>

                            {/* BOTTOM BUTTONS */}
                            <div className="tile-designer-buttons">

                                <TileCanvasButton
                                    title="Save Changes"
                                    icon={SaveIcon}
                                    onClick={handleSave}
                                />

                            </div>

                        </div>

                        {/* PROPERTIES */}
                        <div className="tile-designer-properties">
                            <Properties
                                rows={rows}
                                setRows={setRows}
                            />
                        </div>

                    </div>
                    
            </DndProvider>

        </Modal>
    );
};

export default TileDesigner;

