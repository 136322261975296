import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserFieldsVisibility.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsVisibility = ({ visible }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
  } = useContext(Global);

  return (
    <div className="user-fields-visibility-container"
      style={{
        color: theme.foregroundColorFaded,
        fontFamily: profile.fontFamily,
      }}>
      ({visible ? "Visible to other users" : "Not visible to other users"})
    </div>
  );
};

export default UserFieldsVisibility;
