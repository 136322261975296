import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './RoleAdd.css';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/buttons/ModalButton';
import ModalButtons from '../../../components/modal/buttons/ModalButtons';
import ModalInput from '../../../components/modal/input/ModalInput';
import ModalLabel from '../../../components/modal/label/ModalLabel';
import ModalSection from '../../../components/modal/section/ModalSection';
import ModalTextarea from '../../../components/modal/textarea/ModalTextarea';
import ModalSelect from '../../../components/modal/select/ModalSelect';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * RoleAdd Component
 * 
 * This component allows the user to add a role and optionally assign a parent role.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const RoleAdd = ({ parentKey, isVisible, setVisible }) => {

    // Global
    const {
        currentUser,
        profileRoles,
        profile,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedParentKey, setSelectedParentKey] = useState('');

    // Preselect parentKey if provided
    useEffect(() => {
        setSelectedParentKey(parentKey || '');
    }, [parentKey]);

    const handleAdd = async () => {
        // Require title
        if (!title || title.trim() === '') {
            alert('Please enter a group name.');
            return;
        }

        // Require description
        if (!description || description.trim() === '') {
            alert('Please enter a description of the role.');
            return;
        }

        // Generate key
        const key = generateKey();

        // Determine the priority level
        let priorityLevel = 1; // Default for root roles

        let currentParentKey = selectedParentKey;

        while (currentParentKey) {
            priorityLevel++;

            let parentRole = null;
            // Use a simple for loop without inline functions
            for (let i = 0; i < profileRoles.length; i++) {
                if (profileRoles[i].key === currentParentKey) {
                    parentRole = profileRoles[i];
                    break;
                }
            }

            currentParentKey = parentRole ? parentRole.parentKey : null;
        }

        // Create record
        const data = {
            key: key,
            profileKey: profile.key,
            userKey: currentUser.userKey,
            title: title,
            description: description,
            parentKey: selectedParentKey || null, // Save null if no parent is selected
            priorityLevel: priorityLevel, // Assign calculated priorityLevel
        };

        // Add to database
        await dataManager.add(
            collections.roles,
            profile.key,
            key,
            data
        );

        // Clear the form
        setTitle('');
        setDescription('');
        setSelectedParentKey('');

        // Close the modal
        setVisible(false);
    };

    const handleParentChange = (key) => {
        setSelectedParentKey(key);
    };

    // Map roles to options for ModalSelect
    const roleOptions = profileRoles.map(role => ({
        key: role.key,
        title: role.title,
    }));

    return (
        <>
            {/* MODAL */}
            <Modal
                title="New Role"
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                width="330px"
                height="auto"
            >
                {/* NAME */}
                <ModalSection>
                    <ModalLabel text="Title" />
                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />
                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>
                    <ModalLabel text="Description" />
                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />
                </ModalSection>

                {/* PARENT ROLE */}
                <ModalSection>
                    <ModalLabel text="Parent" />
                    <ModalSelect
                        options={roleOptions}
                        value={selectedParentKey}
                        onChange={handleParentChange}
                    />
                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>
                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Add Role"
                    />
                </ModalButtons>
            </Modal>
        </>
    );
};

export default RoleAdd;
