import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Managers
import NotificationManager from '../../common/managers/NotificationManager';

const notificationManager = new NotificationManager();

/**
 * Custom hook to load notifications for a specific user.
 */
const usePreloadUserNotifications = () => {

  // Global
  const {
    currentUser,
    setUserNotifications,
  } = useContext(Global);

  useEffect(() => {

    if (!currentUser) {
      setUserNotifications([]);
      return;
    }

    // Function to load notifications
    const loadNotifications = () => {
      const handleUpdate = (items) => {
        setUserNotifications(items);
      };

      const unsubscribe = notificationManager.listAndSubscribe(
        currentUser.userKey,
        handleUpdate,
      );

      return unsubscribe;
    };

    // Subscribe to notifications
    const unsubscribeNotifications = loadNotifications();

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeNotifications) {
        unsubscribeNotifications();
      }
    };

  }, [currentUser, setUserNotifications]);

};

export default usePreloadUserNotifications;
