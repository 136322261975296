import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { Section } from '../../../../common/utilities/Enums';

// Styles
import './NewMessage.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const NewMessage = ({ notification }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    conversations,
    setCurrentSection,
    setSelectedConversation,
  } = useContext(Global);

  /**
   * Sets the notification as read when clicked.
   */
  const handleClick = async () => {

      const conversationKey = notification.conversationKey;
      const conversation = conversations.find(conversation => conversation.key === conversationKey);
      setSelectedConversation(conversation);
      setCurrentSection(Section.MESSAGES)

  }

  return (

      <div className="notification-container"
        onClick={handleClick}
        style={{
          borderBottomColor: theme.backgroundColorFaded,
        }}>

        {/* TITLE */}
        <div className="notification-title"
          style={{
            color: theme.foregroundColor,
          }}>
          {notification.title}
        </div>

        {/* DESCRIPTION */}
        <div className="notification-description"
          style={{
            color: theme.foregroundColorFaded,
          }}>
          {notification.description}
        </div>

      </div>

  );
};

export default NewMessage;
