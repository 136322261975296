import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../../../../../Global';

// Styles
import './RateModal.css';

// Theme
import { useTheme } from '../../../../../../../ThemeContext';

// Images
import RateOffIcon from '../../../../../../svg/RateOffIcon';
import RateOnIcon from '../../../../../../svg/RateOnIcon';

// Components
import Modal from '../../../../../../components/modal/Modal';

// Managers
import ObjectManager from '../../../../../../managers/ObjectManager';

const objectManager = new ObjectManager();

const RateModal = ({ isOpen, setOpen, currentRating, objectKey, modelKey, onRatingUpdate }) => {
    const { theme } = useTheme();
    const { currentUser } = useContext(Global);
    const [selectedRating, setSelectedRating] = useState(currentRating);

    useEffect(() => {
        setSelectedRating(currentRating); // Initialize with current user's rating
    }, [currentRating]);

    const handleStarClick = async (rating) => {
        setSelectedRating(rating);

        // Call ObjectManager to update rating in the object itself
        const updatedData = await objectManager.updateRating(
            currentUser.profileKey,
            modelKey,
            objectKey,
            rating,
            currentUser.userKey
        );

        if (updatedData) {
            onRatingUpdate(updatedData); // Update the parent component with new rating data
        }
        setOpen(false);
    };

    return (
        <Modal
            title="Rate"
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            width="300px">
            <div className="rate-modal-container">
                {[1, 2, 3, 4, 5].map((star) => (
                    <div
                        key={star}
                        className="rate-modal-star"
                        onClick={() => handleStarClick(star)}
                    >
                        {star <= selectedRating ? (
                            <RateOnIcon color={theme.highlightBackgroundColor} width="30" height="30" />
                        ) : (
                            <RateOffIcon color={theme.highlightBackgroundColor} width="30" height="30" />
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default RateModal;
