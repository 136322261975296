import React, { useEffect, useRef, useState } from 'react';

// Utilities
import { hexToRgba } from '../../../../common/utilities/Colors';

// Styles
import './VideoGallery.css';

// Images
import BackIcon from '../../../../common/svg/BackIcon';
import ForwardIcon from '../../../../common/svg/ForwardIcon';
import VideoIcon from '../../../../common/svg/VideoIcon';
import PlusIcon from '../../../../common/svg/PlusIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldMenu from '../../fieldmenu/FieldMenu';

/**
 * VideoGallery Component
 * 
 * This component renders a gallery of videos.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const VideoGallery = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {
    const { theme } = useTheme();

    // Local State
    const [value, setValue] = useState([]); // Default ADD mode value
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    // References
    const dragCounter = useRef(0);
    const fileInputRef = useRef(null);
    const containerRef = useRef(null);

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || []);
    }, [object, field.key]);

    /**
     * Handles adding of videos to the gallery.
     * 
     * @param {event} event - Event object.
     */
    const handleAddVideos = (event) => {
        const files = event.target.files || event.dataTransfer.files;
        if (!files.length) return;

        const newVideos = Array.from(files).map((file) => ({
            url: URL.createObjectURL(file),
        }));

        const updatedValue = [...value, ...newVideos];
        setValue(updatedValue);

        // Call onUpdate since we have added new videos with "blob:" URLs
        onUpdate(field, updatedValue);

        fileInputRef.current.value = "";
    };

    /**
     * Handles removal of the current video from the gallery.
     */
    const handleRemoveVideo = () => {
        const updatedValue = value.filter((_, index) => index !== currentIndex);
        setValue(updatedValue);
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));

        // Call onUpdate since a video has been removed
        onUpdate(field, updatedValue);
    };

    /**
     * Handles navigating to the previous video.
     */
    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    /**
     * Handles navigating to the next video.
     */
    const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, value.length - 1));
    };

    /**
     * Handles click of the add button.
     */
    const handleAddVideosClick = () => {
        fileInputRef.current.click();
    };

    /**
     * Cancels events when dragging files into the component.
     */
    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    /**
     * Handles dropping of photos into the component.
     * 
     * @param {event} event - Event object.
     */
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDragging(false);
        dragCounter.current = 0;
        handleAddVideos(event);
    };

    /**
     * Handles drag enter of the gallery while dragging files.
     * 
     * @param {event} event - Event object.
     */
    const handleDragEnter = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current += 1;
        setIsDragging(true);
    };

    /**
     * Handles drag leave of the gallery while dragging files.
     * 
     * @param {event} event - Event object.
     */
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        dragCounter.current -= 1;
        if (dragCounter.current === 0) {
            setIsDragging(false);
        }
    };

    /**
     * Menu Options
     */
    const menuOptions = [
        {
            text: 'Add Video(s)',
            icon: PlusIcon,
            onClick: () => {
                handleAddVideosClick();
            }
        },
        // Conditionally include the "Remove Video" option
        ...(value[currentIndex]?.url ? [{
            text: 'Remove Item',
            icon: RemoveIcon,
            onClick: () => {
                handleRemoveVideo();
            }
        }] : []),
    ];

    return (
        <>
            <div className="video-gallery-outer-container">

                {/* CONTAINER */}
                <div
                    ref={containerRef}
                    className="video-gallery-container"
                    style={{
                        backgroundColor: 'transparent',
                        borderColor: theme.backgroundColorFaded,
                        borderWidth: isDragging ? '2px' : '1px',
                    }}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}>

                    {/* GALLERY */}
                    <div className="video-gallery-wrapper">
                        {value[currentIndex]?.url ? (
                            <video controls
                                src={value[currentIndex].url}
                                className="video-gallery-video"
                                style={{ width: '100%' }}
                            />
                        ) : (
                            <div onClick={handleAddVideosClick} style={{
                                opacity: 0.5,
                            }}>
                                <VideoIcon
                                    color={theme.foregroundColorFaded}
                                    width="100"
                                    height="100"
                                />
                            </div>
                        )}

                        {/* FILE INPUT */}
                        <input
                            ref={fileInputRef}
                            id="video-upload"
                            type="file"
                            accept="video/*"
                            onChange={(event) => handleAddVideos(event)}
                            style={{ display: 'none' }}
                            aria-label="Video Upload"
                            multiple
                        />

                    </div>

                    {/* PREVIOUS BUTTON */}
                    {currentIndex > 0 && (
                        <div
                            className="video-gallery-nav-button previous-button"
                            style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                            onClick={handlePrevious}>
                            <BackIcon
                                color={theme.foregroundColor}
                                width="20"
                                height="20"
                            />
                        </div>
                    )}

                    {/* NEXT BUTTON */}
                    {currentIndex < value.length - 1 && (
                        <div
                            className="video-gallery-nav-button next-button"
                            style={{ backgroundColor: hexToRgba(theme.backgroundColor, 0.5) }}
                            onClick={handleNext}>
                            <ForwardIcon
                                color={theme.foregroundColor}
                                width="20"
                                height="20"
                            />
                        </div>
                    )}

                    {/* FIELD MENU */}
                    {showFieldMenu &&
                        <div
                            className="video-gallery-field-menu">
                            <FieldMenu
                                field={field}
                                menuOptions={menuOptions}
                            />
                        </div>
                    }

                    {/* VIDEO COUNT */}
                    {value.length > 1 &&
                        <div className="video-gallery-video-count"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: hexToRgba(theme.backgroundColor, 0.5)
                            }}>
                            {value.length > 0 && `${currentIndex + 1} of ${value.length}`}
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default VideoGallery;
