import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { Permission, ObjectsView } from '../utilities/Enums';
import { hasCollectionPermission } from '../utilities/Permissions';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Objects.css';

// Components
import AddButton from '../components/addbutton/AddButton';
import Form from '../form/Form';
import ObjectAdd from './addobject/ObjectAdd';
import ObjectsToolbar from './toolbar/ObjectsToolbar';
import ObjectSummaries from './summaries/ObjectSummaries';
import ObjectTiles from './tiles/ObjectTiles';
import Table from '../../desktop/table/Table';
import TagSelector from '../components/tagselector/TagSelector';

// Managers
import ProfileManager from '../managers/ProfileManager';

const profileManager = new ProfileManager();

const Objects = () => {

    // Global
    const {
        profileObjects,
        profile,
        currentUser,
        modelView,
        selectedModel,
        selectedObjectTags,
        setCurrentUser,
        setSelectedObject,
        setSelectedObjectTags,
        setSortField,
    } = useContext(Global);

    // Local State
    const [addVisible, setAddVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);

    // Set the default view mode to the title
    useEffect(() => {
        if (!selectedModel) return;
        setSortField(selectedModel.titleFieldKey);
    }, [selectedModel, setSortField]);

    // Handle fetching objects and real-time updates
    /*
    useEffect(() => {
        if (!profileModels || profileModels.length === 0 || !selectedModel) {
            setProfileObjects([]);
            return;
        }

        const handleUpdate = (items) => {
            items.sort((a, b) => {
                let valueA, valueB;
                switch (sortField) {
                    case 'CREATED':
                        valueA = a.dateCreated?.toMillis() || 0; // Convert Timestamp to milliseconds
                        valueB = b.dateCreated?.toMillis() || 0;
                        break;
                    case 'MODIFIED':
                        valueA = a.dateModified?.toMillis() || 0;
                        valueB = b.dateModified?.toMillis() || 0;
                        break;
                    default:
                        valueA = (a[sortField]?.toLowerCase() || ''); // String comparison for non-date fields
                        valueB = (b[sortField]?.toLowerCase() || '');
                }

                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return sortDirection === 'ASC'
                        ? valueA - valueB
                        : valueB - valueA; // Numeric comparison
                }

                return sortDirection === 'ASC'
                    ? valueA.localeCompare(valueB) // String comparison
                    : valueB.localeCompare(valueA);
            });

            setProfileObjects(prevObjects =>
                JSON.stringify(prevObjects) !== JSON.stringify(items) ? items : prevObjects
            );
        };

        // Subscribe to real-time updates, optionally filtered by tags
        const unsubscribe = objectManager.listAndSubscribe(
            profile.key,
            selectedModel.key,
            handleUpdate,
            selectedObjectTags
        );

        // Cleanup: Ensure that unsubscribe is a function before calling it
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };

    }, [selectedModel, selectedObject, sortField, sortDirection, profileModels, profile.key, setProfileObjects, selectedObjectTags]);
    */

    const handleAddClick = () => {
        setSelectedObject(null);
        setAddVisible(true);
    };
    
    // Handle tag selection updates and save preferences for the selected model
    const handleTagSelection = async (tags) => {
        setSelectedObjectTags(tags); // Update local state
    };

    const handleObjectClick = (object) => {
        setSelectedObject(object);
        setEditVisible(true);
    };

    return (
        <>

            <div
                className={isMobile || isTablet ? "objects-container-mobile" : "objects-container"}
                style={{
                    backgroundColor: "transparent",
                    height: "100%",
                    width: "100%"
                }}>
                {/* TOOLBAR */}
                <ObjectsToolbar />

                {/* TAG SELECTOR */}
                {selectedModel?.tags && selectedModel.tags.length > 0 &&
                    <div className="objects-tag-selector">
                        <TagSelector
                            tags={selectedModel?.tags || []}
                            selectedTags={selectedObjectTags}
                            onTagClick={handleTagSelection}
                        />
                    </div>
                }

                {/* RENDER LIST OF OBJECTS */}
                {modelView === ObjectsView.TABLE &&
                    <Table />
                }

                {profileObjects && modelView === ObjectsView.TILES &&
                    <ObjectTiles />
                }

                {profileObjects && modelView === ObjectsView.GRID &&
                    <ObjectSummaries
                        onObjectClick={handleObjectClick}
                    />
                }

                {/* ADD BUTTON */}
                {selectedModel &&
                    hasCollectionPermission(profile, currentUser, selectedModel, Permission.CREATE) && (
                        <div className={isMobile ? "objects-add-button-mobile" : "objects-add-button"}>
                            <AddButton onClick={handleAddClick} />
                        </div>
                    )}
            </div>

            {/* ADD MODAL */}
            <ObjectAdd
                isOpen={addVisible}
                setOpen={setAddVisible}
            />

            {/* FORM */}
            <Form
                isOpen={editVisible}
                setOpen={setEditVisible}
            />
        </>
    );
};

export default Objects;

