import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { MenuType } from '../../common/utilities/Enums';

// Styles
import './MenuHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DockIcon from '../svg/DockIcon';

// Managers
import ProfileManager from '../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

const MenuHeader = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        profile,
        setCurrentUser,
        setMenuType,
    } = useContext(Global);

    const handleMenuHideClick = async () => {
        setMenuType(MenuType.TOP);
    };

    return (

        <div className="menu-header-container">

            <div className="menu-header-dock-up-button"
                title="Hide Menu"
                onClick={handleMenuHideClick}>
                <DockIcon
                    color={theme.foregroundColorFaded}
                    width="20"
                    height="20"
                />
            </div>

        </div>
    );
};

export default MenuHeader;
