import React from 'react';

// Styles
import './PhonePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const PhonePreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="phone-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div className="phone-preview-label"
                style={{ 
                    color: theme.foregroundColorFaded 
                }}>
                {placeholder}
            </div>
            <div
                className="phone-preview-input"
                style={{
                    color: theme.foregroundColor
                }}>
                (201) 867-5309
            </div>
        </div>
    );
};

export default PhonePreview;
