// Firebase
import { httpsCallable } from 'firebase/functions';
import { arrayUnion, collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { collections, db, functions, messaging } from '../../firebaseConfig';
import { getToken } from "firebase/messaging";

import DataManager from '../../common/managers/DataManager';
import ProfileManager from '../../common/managers/ProfileManager';

const dataManager = new DataManager();
const profileManager = new ProfileManager();

class NotificationManager {

  /**
   * Sends a notification to a recipient.
   *
   * @param {string} type - The notification type.
   * @param {string} profileKey - The key of the app.
   * @param {string} userKey - The user key of the recipient.
   * @param {string} title - The title of the notification.
   * @param {string} body - The body of the notification.
   * @param {string} icon - The path to the icon for the notification.
   * 
   * @returns {Promise<Object>} - Returns a promise with the response from the server.
   */
  async sendNotification(type, profileKey, userKey, title, body, icon = '/icons/chat.png', domain = '') {
    if (!userKey || !title || !body) {
      throw new Error('All parameters (senderKey, recipientKey, title, body) are required.');
    }

    try {
      // Call the Firebase function to send the notification
      const sendNotificationFunc = httpsCallable(functions, 'sendNotification');
      const response = await sendNotificationFunc({
        userKey,
        title,
        body,
        icon,
        domain
      });

      return response.data; // Return the response for further handling if needed
    } catch (error) {
      throw error; // Re-throw the error to handle it upstream
    }
  }

  /**
   * Saves a notification to the database.
   *
   * @param {string} type - The notification type.
   * @param {string} profileKey - The key of the app.
   * @param {string} notificationKey - The key of the notification.
   * @param {string} userKey - The user key of the recipient.
   * @param {string} data - The data to save with the notification. This may vary by type.
   */
  async saveNotification(type, profileKey, notificationKey, userKey, additionalData) {
    
    if (!userKey || !additionalData) {
      throw new Error('All parameters are required.');
    }

    try {

      const data = {
        type: type,
        profileKey: profileKey,
        userKey: userKey,
        key: notificationKey,
        read: false,
        ...additionalData,
      };

      await dataManager.add(collections.notifications, profileKey, notificationKey, data);

    } catch (error) {
      console.error('Error saving notification:', error);
      throw error; // Re-throw the error to handle it upstream
    }
  }

  async requestNotificationPermission(combinedUser) {
    try {

      // Request permission first
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {

        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;

        // Check existing subscription
        const existingSubscription = await registration.pushManager.getSubscription();
        if (existingSubscription) {
          // Use the existing subscription without re-subscribing
          return existingSubscription;
        }

        // No existing subscription, create a new one
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
          serviceWorkerRegistration: registration,
        });

        if (token) {
          // Save the token in Firestore
          const data = {
            tokens: arrayUnion(token),
          };
          await profileManager.update(combinedUser.userKey, data);
        } else {
          console.warn('No registration token available.');
        }
      } else {
        console.warn('Notification permission denied or not yet granted.');
      }
    } catch (error) {
      console.error('Error during notification setup:', error);
    }
  }

  listAndSubscribe(userKey, onUpdate) {
    try {
      const notificationsCollection = collection(db, collections.notifications);
  
      const q = query(
        notificationsCollection,
        where("userKey", "==", userKey),
        orderBy("dateCreated", "desc")
      );
  
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const notifications = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
  
          if (onUpdate) {
            onUpdate(notifications);
          }
        },
        (error) => {
          console.error("Error fetching notifications:", error);
        }
      );
  
      return unsubscribe; // This is the cleanup function.
    } catch (error) {
      console.error("Error setting up real-time updates:", error);
      throw error;
    }
  }  

}

export default NotificationManager;
