import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global.js';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ForYou.css';

// Theme
import { useTheme } from '../../ThemeContext.js';

// Components
import ForYouItem from './item/ForYouItem.js';
import Grid from '../components/grid/Grid.js';
import Story from '../story/view/Story.js';

// Hooks
import useSetForYouEvents from './hooks/SetForYouEvents.js';
import useSetForYouFiles from './hooks/SetForYouFiles.js';

const ForYou = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        foryouEvents,
        foryouFiles
    } = useContext(Global);

    // Local State
    const [combinedItems, setCombinedItems] = useState([]);
    const [viewStoryOpen, setViewStoryOpen] = useState(false);

    /**
     * Determines the contents of the foryou when it is selected.
     */
    useSetForYouEvents();
    useSetForYouFiles();

    useEffect(() => {
        const items = [
            ...foryouEvents, 
            ...foryouFiles
        ];
        setCombinedItems(items);
    }, [foryouEvents, foryouFiles]);

    /**
     * Handles single click on an item.
     */
    const handleItemClick = (event, item) => {
        event.stopPropagation();

    };

    return (
        <div 
            className="foryou-container"
            style={{
                backgroundColor: 'transparent',
                borderColor: theme.backgroundColorFaded,
            }}>

            {/* CONTENT */}
            <div className={isMobile ? "foryou-grid-container-mobile" : "foryou-grid-container"} >
                <Grid
                    items={combinedItems}
                    renderItem={(item) => (

                        <ForYouItem
                            key={item.key}
                            item={item}
                            onClick={(event, item) => handleItemClick(event, item)}
                        />

                    )}
                    loading={false}
                    minCols={2}
                    colWidth={isMobile ? 100 : 200}
                />
            </div>

            {/* STORY */}
            <Story
                isOpen={viewStoryOpen}
                setOpen={setViewStoryOpen}
            />

        </div>
    );
};

export default ForYou;
