import React from 'react';

// Styles
import './ButtonSummary.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import BookmarkButton from './buttons/BookmarkButton';
import CartButton from './buttons/CartButton';
import CommentButton from './buttons/CommentButton';
import DislikeButton from './buttons/DislikeButton';
import DownloadButton from './buttons/DownloadButton';
import DownvoteButton from './buttons/DownvoteButton';
import EmojiButton from './buttons/EmojiButton';
import FavoriteButton from './buttons/FavoriteButton';
import FollowButton from './buttons/FollowButton';
import LikeButton from './buttons/LikeButton';
import PrintButton from './buttons/PrintButton';
import PriorityButton from './buttons/PriorityButton';
import RateButton from './buttons/rate/RateButton';
import ShareButton from './buttons/ShareButton';
import UpvoteButton from './buttons/UpvoteButton';

const ButtonSummary = ({ element, object }) => {
    const { getProcessedStyles } = useTheme();

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    const componentMap = {
        bookmark: BookmarkButton,
        cart: CartButton,
        comment: CommentButton,
        dislike: DislikeButton,
        download: DownloadButton,
        downvote: DownvoteButton,
        emoji: EmojiButton,
        favorite: FavoriteButton,
        follow: FollowButton,
        like: LikeButton,
        print: PrintButton,
        priority: PriorityButton,
        rate: RateButton,
        share: ShareButton,
        upvote: UpvoteButton,
    };

    // Get the corresponding component from the component map
    const Component = componentMap[element.buttonType.toLowerCase()];

    // Return null if no component is found
    if (!Component) {
        return null;
    }

    return (
        <div className="button-summary-container"
            style={processed}>
            <Component
                element={element}
                object={object}
            />
        </div>
    );
};

export default ButtonSummary;
