import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Circle Loader
import { CircleLoader } from 'react-spinners';

// Enums
import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './Progress.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import gradient from '../../../site/gradient.jpg';

/**
 * Progress Component
 * 
 * This component renders a masked progress indicator with conditional styles.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Progress = () => {
  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    profile,
    progressMessage,
    progressVisible,
  } = useContext(Global);

  if (!progressVisible) return null;

  const overlayStyle = {
    backgroundColor: theme.backgroundColor,
    backgroundImage: profile
      ? currentThemeMode === ThemeMode.DARK
        ? `url(${profile.wallpaperDark || 'none'})`
        : `url(${profile.wallpaperLight || 'none'})`
      : `url(${gradient})`, // Fallback if profile is null
    backgroundSize: 'cover', // Ensures the image covers the entire screen
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
    backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
    height: '100vh', // Ensures it takes up the full viewport height
    width: '100vw', // Ensures it takes up the full viewport width
    left: 0,
    opacity: 0.8,
    position: 'fixed',
    top: 0,
    zIndex: 100, // Behind the content
  };

  return (
    <>
      {/* MASK */}
      <div
        className="progress-mask"
        style={{
          ...overlayStyle, // Gradient image background
        }}
      ></div>

      {/* CONTENT */}
      <div
        className="progress-content"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 200, // Above the mask
        }}>

        {/* CIRCLE LOADER */}
        <div className="progress-circle">
          <CircleLoader
            size={40}
            color={theme.highlightBackgroundColor}
            loading={true}
          />
        </div>

        {/* MESSAGE */}
        <div
          className="progress-status-message"
          style={{
            color: theme.foregroundColor,
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          {progressMessage}
        </div>

      </div>
    </>
  );
};

export default Progress;
