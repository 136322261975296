import React, { } from 'react';

// Styles
import './SiteListGroup.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * SiteListGroup Component
 * 
 * This component displays a group wrapper around elements in the list.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SiteListGroup = ({ title, children }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      <div className="site-list-group-title"
        style={{ color: theme.foregroundColorFaded }}>
        {title}
      </div>
      <div className="site-list-group-items">
        {children}
      </div>
    </>
  );

};

export default SiteListGroup;
