import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../firebaseConfig';


// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ItemType } from '../../../common/utilities/Enums';
import { generateKey } from '../../../common/utilities/Keys';
import { uploadPhotoGallery, uploadVideoGallery } from '../../../common/utilities/Media';

// Styles
import './EventForm.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import EventDates from './EventDates';
import EventFieldSelector from './EventFieldSelector';
import EventTitle from './EventTitle';
import EventDivider from './EventDivider';
import EventObjectSelector from './EventObjectSelector';
import FormField from '../../form/FormField';
import Modal from '../../../common/components/modal/Modal';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ObjectGridItem from '../../folder/item/object/ObjectGridItem';
import TagSelector from '../../../common/components/tagselector/TagSelector';

// Managers
import DataManager from '../../../common/managers/DataManager';

const dataManager = new DataManager();

// Inline Styles
const InlineStyles = useStyle`
  *::placeholder {
    color: ${(props) => props.placeholderColor};
    opacity: .2; 
  }
`;

const EventForm = ({ date, folderKey = null, onSave }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    defaultEventTag,
    eventCollection,
    eventObject,
    eventVisible,
    selectedEvent,
    setEventCollection,
    setEventObject,
    setEventVisible,
    setSelectedObject,
  } = useContext(Global);

  // Local State
  const [title, setTitle] = useState('');
  const [defaultStart, setDefaultStart] = useState(null);
  const [defaultEnd, setDefaultEnd] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [modalTitle, setModalTitle] = useState('Add Event');
  const [fields, setFields] = useState([]); // Stores event fields
  const [formData, setFormData] = useState({}); // Stores [field.key]: values
  const [highlightedEventObject, setHighlightedEventObject] = useState(null);

  // Tags (for ADD mode)
  const [selectedTags, setSelectedTags] = useState([]);

  // Object
  const [eventObjects, setEventObjects] = useState([]);

  // Set the selected tags when the calendar changes
  useEffect(() => {
    if (!selectedEvent) return;
    setSelectedTags(selectedEvent.tags || []);
  }, [selectedEvent]);

  // Fields
  const handleFieldAdd = (field) => {
    if (field.type === 'checklist') {
      // Copy the field.value to the formData object
      setFormData(prev => ({ ...prev, [field.key]: field.value }));
    }

    setFields(prevFields => [...prevFields, field]);
  };

  // Initialize state based on whether we are adding or editing
  useEffect(() => {
    if (!eventVisible) return;

    if (selectedEvent) {
      // Editing an existing event
      setModalTitle('Edit Event');
      setTitle(selectedEvent.title);
      const start = selectedEvent.startDate.toDate();
      const end = selectedEvent.endDate.toDate();
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries(selectedEvent.attachments || []);
      setFields(selectedEvent.fields || []);
      setEventObjects(selectedEvent.eventObjects || []);
      setFormData(selectedEvent.formData || {});
    } else {
      // Adding a new event
      setModalTitle('Add Event');
      setTitle('');
      const start = new Date(date);
      const end = new Date(start);
      end.setHours(end.getHours() + 1);
      setDefaultStart(start);
      setDefaultEnd(end);
      setStartDate(start);
      setStartTime(start);
      setEndDate(end);
      setEndTime(end);
      setSelectedEntries([]);
      setFields([]);
      setEventObjects([]);
      setFormData({});

      // Set the default tag if one is provided
      if (defaultEventTag) {
        setSelectedTags([defaultEventTag]);
      }
    }

    // If an object is specified, automatically add it to the eventObjects array
    if (eventObject) {
      setEventObjects(prevObjects => [...prevObjects, eventObject]);
    }

  }, [eventObject, eventVisible, selectedEvent, date, defaultEventTag]);

  const handleStartDateChange = (d) => {
    setStartDate(d);
  };

  const handleStartTimeChange = (t) => {
    setStartTime(t);
  };

  const handleEndDateChange = (d) => {
    setEndDate(d);
  };

  const handleEndTimeChange = (t) => {
    setEndTime(t);
  };

  /**
   * Method to handle saving of the event information.
   * 
  */
  const handleSave = async () => {
    if (title.trim() === '') {
      setTitle('(No title)');
    }

    const start = new Date(startDate);
    start.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);
    const end = new Date(endDate);
    end.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);

    const key = selectedEvent ? selectedEvent.key : generateKey();

    let startTimestamp = Timestamp.fromDate(start);
    let endTimestamp = Timestamp.fromDate(end);

    await prepareSpecialFields(key);

    const data = {
      profileKey: profile.key,
      key: key,
      type: ItemType.EVENT,
      tags: selectedTags,
      title: title,
      startDate: startTimestamp,
      endDate: endTimestamp,
      attachments: selectedEntries,
      fields: fields,
      eventObjects: eventObjects,
      formData: formData,
      folderKey: folderKey
    };

    if (selectedEvent) {
      await dataManager.update(collections.events, profile.key, key, data);
    } else {
      await dataManager.add(collections.events, profile.key, key, data);
    }

    if (onSave) {
      onSave(data);
    }

    setEventCollection(null);
    setEventObject(null);
    setSelectedObject(null);
    setHighlightedEventObject(null);
    setEventVisible(false);
  };


  /**
  * Prepares special fields for saving or adding.
  */
  const prepareSpecialFields = async (eventKey) => {
    for (const field of fields) {
      // Handle gallery fields
      if (field.type === 'gallery') {
        await savePhotoGallery(field, eventKey);
      }

      // Handle videogallery fields
      if (field.type === 'videogallery') {
        await saveVideoGallery(field, eventKey);
      }
    }
  };

  /**
  * Saves photo gallery and uploads files if necessary.
  */
  const savePhotoGallery = async (field, eventKey) => {

    const galleryItems = formData[field.key] || [];

    const folderPath = `galleries/${profile.key}/events/${eventKey}/`;

    const uploadedImages = await uploadPhotoGallery(profile.key, folderPath, galleryItems);

    // Update the formData with the new URLs and captions
    formData[field.key] = uploadedImages;
  };

  /**
   * Saves video gallery data and uploads files if necessary.
   */
  const saveVideoGallery = async (field, eventKey) => {

    const galleryItems = formData[field.key] || [];

    const folderPath = `videogalleries/${profile.key}/events/${eventKey}/`;

    const uploadedGallery = await uploadVideoGallery(profile.key, folderPath, galleryItems);

    // Update the formData with the new URLs
    formData[field.key] = uploadedGallery;
  };

  /**
   * Method to handle the removal of an event.
   * 
  */
  const handleRemoveEvent = async () => {
    await dataManager.delete(collections.events, profile.key, selectedEvent.key);

    setEventCollection(null);
    setEventObject(null);
    setHighlightedEventObject(null);
    setSelectedObject(null);
    setEventVisible(false);
  };

  /**
   * Method to handle the selection of an object.
   * 
   * @param {string} entry - The selected search index entry.
   */
  const handleObjectSelect = async (entry) => {
    const object = entry.object;

    // Update the eventObjects state only if the object does not already exist
    setEventObjects(prevObjects => {
      // Check if the object with the same key already exists
      const isDuplicate = prevObjects.some(existingObject => existingObject.key === object.key);

      // If it's not a duplicate, add the new object, otherwise return the same array
      return isDuplicate ? prevObjects : [...prevObjects, object];
    });
  };

  const handleFieldUpdate = (field, value) => {
    // Add the field value pair to the fieldObject
    setFormData(prev => ({ ...prev, [field.key]: value }));
  };

  const handleObjectClick = (object) => {
    setHighlightedEventObject(object);
  };

  const handleClose = () => {
    setEventCollection(null);
    setEventObject(null);
    setHighlightedEventObject(null);
    setSelectedObject(null);
    setEventVisible(false)
  }

  const handleRemoveHighlightedItem = () => {
    if (highlightedEventObject) {
      setEventObjects(prevObjects =>
        prevObjects.filter(obj => obj.key !== highlightedEventObject.key)
      );
    }
  };

  return (
    <>
      <InlineStyles
        placeholderColor={theme.foregroundColorFaded}
      />

      <Modal
        title={modalTitle}
        isOpen={eventVisible}
        onClose={handleClose}
        width={isMobile ? "100%" : "460px"} 
        height={isMobile ? "100%" : "90%"}>

        <div className="event-form-container">

          <div className="event-form-sections">
            {/* EVENT TITLE */}
            <EventTitle
              title={title}
              setTitle={setTitle}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* DATES AND TIMES */}
            <EventDates
              defaultStart={defaultStart}
              defaultEnd={defaultEnd}
              onStartDateChange={handleStartDateChange}
              onStartTimeChange={handleStartTimeChange}
              onEndDateChange={handleEndDateChange}
              onEndTimeChange={handleEndTimeChange}
            />

            {/* DIVIDER */}
            <EventDivider />

            {/* TAGS 
            {selectedCalendar && selectedCalendar.tags && selectedCalendar.tags.length > 0 && (
              <>
                <TagSelector
                  tags={(selectedCalendar && selectedCalendar.tags) || []}
                  selectedTags={selectedTags}
                  onTagClick={setSelectedTags}
                />

                <EventDivider />
              </>
            )}
            */}

            {/* FORM FIELDS */}
            {fields.map((field, index) => (
              <FormField
                object={formData}
                key={index}
                field={field}
                onUpdate={handleFieldUpdate}
                readOnly={false}
                showFieldMenu={false}
              />
            ))}

            {/* OBJECTS */}
            <div className="event-form-objects">
              {eventObjects.map((object) => (
                <div key={object.key}>
                  <ObjectGridItem
                    item={object}
                    onClick={() => handleObjectClick(object)}
                    selected={highlightedEventObject && highlightedEventObject.key === object.key}
                  />
                </div>
              ))}
            </div>

            < EventDivider />

            {/* EVENT OBJECT BUTTON */}
            <EventObjectSelector
              onObjectSelect={handleObjectSelect}
              focusedCollection={eventCollection}
            />

            {/* FIELD SELECTOR */}
            <EventFieldSelector
              fields={fields}
              onFieldAdd={handleFieldAdd}
            />

          </div>

        </div>

        {/* BUTTONS */}
        <ModalButtons>

          <div className="event-form-buttons-left">

            {/* REMOVE ITEM BUTTON */}
            {highlightedEventObject &&
              <ModalButton
                onClick={handleRemoveHighlightedItem}
                label={"Remove Item"}
              />
            }

          </div>

          <div className="event-form-buttons-right">

            {/* SAVE BUTTON */}
            <ModalButton
              onClick={handleSave}
              label={selectedEvent ? "Save" : "Add"}
              highlighted={true}
              width="100px"
            />

            {selectedEvent &&
              <>
                {/* DELETE BUTTON */}
                <ModalButton
                  onClick={handleRemoveEvent}
                  label={"Delete"}
                  width="100px"
                />
              </>
            }

          </div>

        </ModalButtons>

      </Modal >
    </>
  );
};

export default EventForm;
