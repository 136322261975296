// Firebase
import { collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

// Utilities
import { generateKey } from '../utilities/Keys';
import { chunkArray } from '../utilities/Arrays';

class MemberManager {

    /**
     * Method to add a new app user.
     * 
     * @param {string} profileKey - The app key.
     * @param {string} memberKey - The new key of the member.
     * @param {string} data - App data
     * @returns {member} - New member record.
    */
    async add(profileKey, memberKey, data) {

        await setDoc(doc(db, collections.appusers, memberKey), data);

        activity.log(profileKey, 'writes', 1);

        return data;
    }

    /**
      * Fetches app users and subscribes to real-time updates.
      * 
      * @param {string} profileKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(profileKey, onUpdate) {
        try {
            // Create a reference to the members collection
            const membersCollection = collection(db, collections.appusers);

            // Create a query to find app users by profileKey and sort them by username
            const q = query(membersCollection, where("profileKey", "==", profileKey), orderBy("username"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const memberList = snapshot.docs.map(doc => ({
                    id: doc.id, 
                    ...doc.data() 
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(memberList);
                    activity.log(profileKey, 'reads', memberList.length);
                }
            }, error => {
                console.error("Error fetching app users:", error);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error; 
        }
    }

    /**
     * Creates a unique relationship between an object and a user if it doesn't already exist.
     * 
     * @param {string} profileKey - App key.
     * @param {string} objectKey - The key of the object.
     * @param {string} userKey - The key of the app user.
     * @param {string} fieldKey - Key of the source field.
     * @returns {Promise<void>} A promise that resolves if the operation is successful.
     */
    async createRelationship(profileKey, objectKey, userKey, fieldKey) {
        // Reference to the relationships collection in the database
        const relationshipsRef = collection(db, collections.userrelationships);

        // Query to find existing relationships that match the provided source and target
        const querySnapshot = await getDocs(query(
            relationshipsRef,
            where(`objectKey`, '==', objectKey),
            where(`userKey`, '==', userKey),
            where(`fieldKey`, '==', fieldKey)
        ));

        activity.log(profileKey, 'reads', querySnapshot.docs.length);

        // Check if any documents are returned by the query (indicating an existing relationship)
        if (!querySnapshot.empty) {
            return;
        }

        // If no existing relationship is found, proceed to create a new one
        const relationshipKey = generateKey();
        const relationshipDocRef = doc(relationshipsRef, relationshipKey);
        const relationshipData = {
            key: relationshipKey,
            profileKey: profileKey,
            objectKey: objectKey,
            userKey: userKey,
            fieldKey: fieldKey,
        };

        try {
            // Attempt to create the new relationship document in the relationships collection
            await setDoc(relationshipDocRef, relationshipData);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Lists app users related to an object.
     * 
     * @param {string} profileKey - App key.
     * @param {string} objectKey - Object key.
     * @param {string} fieldKey - Field key.
     * 
     * @returns {array} - App user list.
    */
    async listRelatedMembers(profileKey, objectKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);
        const querySnapshot = await getDocs(query(relationshipsRef,
            where(`objectKey`, '==', objectKey),
            where(`fieldKey`, '==', fieldKey),
        ));

        activity.log(profileKey, 'reads', querySnapshot.docs.length);

        const targetKeys = querySnapshot.docs.map(doc => doc.data()[`userKey`]);

        return this.fetchMembersWithKeys(profileKey, targetKeys);
    }

    /**
     * Fetches app users with specific keys, considering the 10-item limit
     * 
     * @param {string} profileKey - App key.
     * @param {string} keys - Keys of the objects to fetch.
    */
    async fetchMembersWithKeys(profileKey, keys) {
        // Ensure keys are valid (non-empty, non-undefined)
        const validKeys = keys.filter(key => key !== undefined && key !== '');
        if (validKeys.length === 0) {
            return [];
        }

        const ref = collection(db, collections.appusers);
        const chunks = chunkArray(validKeys, 10); // Split the keys into chunks of 10 to comply with Firestore's limit
        const members = []; // Array to hold the fetched documents

        try {
            for (const chunk of chunks) {
                // Ensure the chunk has elements to avoid invalid queries
                if (chunk.length > 0) {
                    const q = query(ref,
                        where('profileKey', '==', profileKey), 
                        where('userKey', 'in', chunk));
                    const snapshot = await getDocs(q);

                    // Log the fetch operation with the number of documents fetched

                    activity.log(profileKey, 'reads', snapshot.docs.length);

                    snapshot.forEach(doc => {
                        // Add each document to the members array
                        members.push({ id: doc.id, ...doc.data() });
                    });
                }
            }
            return members; // Return the fetched members
        } catch (error) {
            console.error("Error fetching members with keys:", error);
            throw error;
        }
    }

    /**
     * Deletes a relationship between a user and an object.
     * 
     * @param {string} profileKey - App key.
     * @param {string} objectKey - Object key.
     * @param {string} userKey -  User key.
     * @param {string} fieldKey -  Field key.
    */
    async deleteRelationship(profileKey, objectKey, userKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);
        const relationshipQuery = query(relationshipsRef,
            where(`objectKey`, "==", objectKey),
            where(`userKey`, "==", userKey),
            where(`fieldKey`, "==", fieldKey));

        try {
            const querySnapshot = await getDocs(relationshipQuery);

            activity.log(profileKey, 'reads', querySnapshot.docs.length);

            if (querySnapshot.empty) {
                return;
            }

            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

        } catch (error) {
            console.error(`Error deleting relationship:`, error);
            throw error;
        }
    }

    /**
     * Fetches users of an app.
     * 
     * @param {string} profileKey - App key to get users for.
     * @returns {Promise<Array>} A promise that resolves to an array of user details.
     */
    async fetchMembers(profileKey) {
        try {
            const membersCollection = collection(db, collections.appusers);
            const usersCollection = collection(db, collections.users);

            // Query app users based on the profileKey only
            const q = query(membersCollection, where("profileKey", "==", profileKey));

            // Fetch the documents from the query
            const membersSnapshot = await getDocs(q);
            activity.log(profileKey, 'reads', membersSnapshot.docs.length);

            // Extract user keys from the fetched documents
            const userKeys = membersSnapshot.docs.map(doc => doc.data().userKey);

            // Fetch user details for each user key
            const userDetailsPromise = userKeys.map(userKey =>
                getDocs(query(usersCollection, where("key", "==", userKey)))
            );

            // Wait for all user detail fetches to complete
            const userDetailsResults = await Promise.all(userDetailsPromise);

            // Combine app user data with user details
            const combinedResults = membersSnapshot.docs.map((doc, index) => {
                if (userDetailsResults[index].empty) {
                    console.error(`No user details found for userKey: ${doc.data().userKey}`);
                    return null; // or handle the error as needed
                }
                const userData = userDetailsResults[index].docs[0].data();

                return {
                    id: doc.id,
                    profileKey: doc.data().profileKey,
                    userKey: doc.data().userKey,
                    dateJoined: doc.data().dateJoined,
                    ...userData, // Spread the full user details here
                    key: doc.data().key
                };
            }).filter(result => result !== null); // Filter out the null results

            return combinedResults;
        } catch (error) {
            console.error("Error fetching app users:", error);
            throw error;
        }
    }

    /**
 * Returns an app user related to an object.
 * 
 * @param {string} profileKey - App key.
 * @param {string} objectKey - Object key.
 * @param {string} fieldKey - Field key.
 * 
 * @returns {object|null} - App user object or null if not found.
 */
    async fetchRelatedMember(profileKey, objectKey, fieldKey) {
        const relationshipsRef = collection(db, collections.userrelationships);

        // Query to fetch documents where the objectKey matches the objectKey and fieldKey matches the fieldKey
        const querySnapshot = await getDocs(query(relationshipsRef,
            where('objectKey', '==', objectKey),
            where('fieldKey', '==', fieldKey)
        ));

        // Log the number of documents read
        activity.log(profileKey, 'reads', querySnapshot.docs.length);

        // Check if there is at least one document returned from the query
        if (!querySnapshot.empty) {
            // Get the first document from the query
            const firstDoc = querySnapshot.docs[0];

            // Extract the userKey from the document data
            const key = firstDoc.data().userKey;

            // Check if key exists
            if (key) {
                // Fetch the app user using the userKey and profileKey
                const member = await this.get(key);

                // Return the fetched app user
                if (member) {
                    return member;
                }
            }
        }

        // Return null if no valid userKey is found or app user does not exist
        return null;
    }

    /**
     * Fetches an app user document where userKey and profileKey match the provided parameters.
     * 
     * @param {string} key - User key.
     * @returns {object|null} The document data if found, otherwise null.
    */
    async get(key) {
        try {
            const membersCollection = collection(db, collections.appusers);

            const q = query(membersCollection, where('key', '==', key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching app user:", error);
            throw error;
        }
    }

    /**
     * Fetches an app user document where userKey and profileKey match the provided parameters.
     * 
     * @param {string} userKey - User key.
     * @param {string} profileKey - App key.
     * @returns {object|null} The document data if found, otherwise null.
    */
    async fetchMember(userKey, profileKey) {
        try {
            const membersCollection = collection(db, collections.appusers);

            const q = query(membersCollection, where('userKey', '==', userKey), where('profileKey', '==', profileKey));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching app user:", error);
            throw error;
        }
    }

    /**
     * Updates an member's rating in the Firestore database.
     * 
     * @param {string} profileKey - The app key.
     * @param {string} userKey - The user key.
    */
    async updateMemberRating(profileKey, userKey, rating) {
        try {
            const membersCollection = collection(db, collections.appusers);
            const q = query(membersCollection, where("profileKey", "==", profileKey), where("userKey", "==", userKey));
            const snapshot = await getDocs(q);

            // Check if the document exists and update it
            if (!snapshot.empty) {
                const docRef = snapshot.docs[0].ref;  // Get the reference of the first document
                await updateDoc(docRef, { rating });  // Update the rating
                activity.log(profileKey, 'writes', 1);  // Log the activity using profileKey
            } else {
                console.error("No matching document found to update.");
                throw new Error("No matching document found to update.");
            }
        } catch (error) {
            console.error("Error updating app user rating:", error);
            throw error;
        }
    }

    /**
     * Updates the user's rating for an app in the Firestore database.
     *
     * @param {string} profileKey - The key (document ID) of the app to update.
     * @param {string} userKey - The key (document ID) of the user providing the rating.
     * @param {number} rating - The rating value provided by the user.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async updateUserRating(profileKey, userKey, rating) {

        const q = query(
            collection(db, collections.appusers),
            where('profileKey', '==', profileKey),
            where('userKey', '==', userKey)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const memberDocRef = querySnapshot.docs[0].ref;
            await updateDoc(memberDocRef, { rating });
        }
    }

    /**
     * Removes an member from the Firestore database.
     * 
     * @param {string} profileKey - The app key.
     * @param {string} member - The app user.
    */
    async removeMember(profileKey, member) {
        try {
            const docRef = doc(db, collections.appusers, member.key);

            await deleteDoc(docRef);

            activity.log(profileKey, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting member:", error);
            throw error;
        }
    }
    
    /**
     * Updates the name of a user in the _members collection.
     * 
     * @param {string} profileKey - Key of the app.
     * @param {string} userKey - Key of the user being updated.
     * @param {string} date -Data to update.
     */
    async updateUserInfo(profileKey, userKey, data) {
        try {
            // Reference to the _members collection
            const membersRef = collection(db, collections.appusers);

            // Query to find all member records where the userKey matches
            const q = query(membersRef, where("userKey", "==", userKey));

            // Get all matching documents
            const querySnapshot = await getDocs(q);

            // Create a batch to update multiple documents
            const batch = writeBatch(db);

            querySnapshot.forEach((docSnapshot) => {
                // For each document, update the username
                batch.update(docSnapshot.ref, data);
            });

            activity.log(profileKey, 'updates', batch.size);

            // Commit the batch update
            await batch.commit();

        } catch (error) {
            console.error("Error updating app user records: ", error);
            alert('Error updating app user records.');
        }
    }

}

export default MemberManager;
