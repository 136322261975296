import React from 'react';

// Styles
import './AppWelcome.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * AppWelcome Component
 * 
 * This component renders an app welcome message.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppWelcome = () => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="app-welcome-container"
      style={{
        backgroundColor: theme.backgroundColorFaded,
        color: theme.foregroundColor
      }}>
      <div className="app-welcome-title"
        style={{
          color: theme.foregroundColor
        }}>
        Welcome to your new app!
      </div>
      <div className="app-welcome-description"
        style={{
          color: theme.foregroundColorFaded
        }}>

        <span>
          Customize your app.
        </span>

        <span>
          Browse the help section.
        </span>
      </div>
    </div>
  );
};

export default AppWelcome;

