import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { adjustTextareaHeight } from '../../../../common/utilities/Forms';

// Styles
import './Text.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

/**
 * Text Component
 * 
 * This component renders a multi-line text input field.
 * 
 * - Default # lines: 6.
 * - Default maximum length: 10000 characters.
 * 
 * @param {object} object - The object containing field values.
 * @param {object} field - The field metadata.
 * @param {function} onUpdate - The function to call with updated values.
 * @param {boolean} showFieldMenu - Indicates whether the menu should be shown.
 * @param {integer} lines - Number of lines.
 * @param {integer} maxLength - Maximum text length.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Text = ({
    object,
    field,
    onUpdate,
    lines = 1,
    maxLength = 10000
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        profileModels,
        setChangesMade
    } = useContext(Global);

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value
    const [isTitle, setIsTitle] = useState(false);

    // References
    const inputRef = useRef(null);

    /**
     * Initializes the field
     */
    useEffect(() => {
        const m = profileModels.find(model => model.key === field.modelKey);
        if (m && m.titleFieldKey === field.key) {
            setIsTitle(true);
        }
    }, [field.key, profileModels]);

    /**
     * Initializes the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    /**
     * Adusts the height of the input depending upon the length of the text.
     */
    useEffect(() => {
        if (inputRef.current) {
            adjustTextareaHeight(inputRef);
        }
    }, [value]);

    /**
     * Method to focus the input when the container is clicked.
     * 
     * @param {string} e - Click event.
     */
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    /**
     * Method to handle the blur of the field, which sends the update.
     */
    const handleBlur = () => {
        onUpdate(field, value);
    };

    /**
     * Method to handle the change in value.
     * 
     * @param {string} e - Change event.
     */
    const handleChange = (e) => {
        setValue(e.target.value);
        setChangesMade(true);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={false}
                />

                {/* INPUT */}
                <textarea
                    ref={inputRef}
                    className="text-input"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: profile.fontFamily,
                        fontWeight: isTitle ? 'bold' : 'normal',
                        fontSize: isTitle ? '16pt' : '11pt',
                    }}
                    value={value}
                    onChange={(e) => {
                        handleChange(e);
                        adjustTextareaHeight(inputRef);
                    }}
                    onBlur={handleBlur}
                    maxLength={maxLength}
                    rows={lines}
                />

            </FieldContainer>
        </>

    );
};

export default Text;
