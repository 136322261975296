import React, { useEffect, useState } from 'react';

// Styles
import './AppSettingsTheme.css';

// Components
import AppSettingsThemeEditor from './AppSettingsThemeEditor';

const AppSettingsTheme = ({ themes, onUpdate }) => {

    // Local state to manage the temporary themes
    const [tempThemes, setTempThemes] = useState(themes);

    useEffect(() => {
        if (!themes) return;
        setTempThemes(themes);
    }, [themes]);

    // Handle theme changes
    const handleThemeChange = (themeType, updatedTheme) => {
        const newThemes = {
            ...tempThemes,
            [themeType]: updatedTheme
        };
        setTempThemes(newThemes);
        onUpdate(newThemes);  // Pass the updated themes to the parent component
    };

    return (
        <div className="app-settings-theme-container">
            <div className="app-settings-theme-column">
                <AppSettingsThemeEditor
                    title="Dark Mode Colors"
                    adjustTheme={tempThemes.dark}
                    onThemeChange={(updatedTheme) => handleThemeChange('dark', updatedTheme)}
                />
            </div>
            <div className="app-settings-theme-column">
                <AppSettingsThemeEditor
                    title="Light Mode Colors"
                    adjustTheme={tempThemes.light}
                    onThemeChange={(updatedTheme) => handleThemeChange('light', updatedTheme)}
                />
            </div>
        </div>
    );
};

export default AppSettingsTheme;
