import React, { useState } from 'react';

// Translation
import { useTranslation } from 'react-i18next';

// Utilities
import { LoginMode } from '../../common/utilities/Enums';

// Styles
import './Login.css';

// Components
import LoginForm from './loginform/LoginForm';
import LoginLink from './link/LoginLink';
import Modal from '../components/modal/Modal';
import SignupForm from './signupform/SignupForm';

/**
 * Login Component
 * 
 * This component renders a modal dialog that allows the user to 
 * log in or sign up for an account
 * 
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} setOpen - Function to open or close the modal.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Login = ({ isOpen, setOpen }) => {

  // Local State
  const [loginMode, setLoginMode] = useState(LoginMode.LOGIN);

  // Translation
  const { t } = useTranslation('Login');

  return (

    <Modal
      title={loginMode === LoginMode.LOGIN ? t('Login:title_log_in') : t('Login:title_sign_up')}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      width="432px"
      height="auto"
      enableClose={false}>

      {/* CONTAINER */}
      <div className="login-container">

        {/* FORM */}
        {loginMode === LoginMode.LOGIN ? (
          <LoginForm />
        ) : (
          <SignupForm />
        )}

        {/* LOGIN/SIGNUP TOGGLE LINK */}
        <LoginLink
          loginMode={loginMode}
          setLoginMode={setLoginMode}
        />

      </div>

    </Modal>

  );
};

export default Login;
