import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../firebaseConfig';

// Utilities
import { generateKey } from '../../utilities/Keys';

// Styles
import './SpaceAdd.css';

// Components
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalInput from '../../components/modal/input/ModalInput';
import ModalLabel from '../../components/modal/label/ModalLabel';
import ModalSection from '../../components/modal/section/ModalSection';
import ModalTextarea from '../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * SpaceAdd Component
 * 
 * This component allows the user to add a space.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SpaceAdd = () => {

    // Global
    const {
        spaceAddOpen,
        currentUser,
        hideProgress,
        profileSpaces,
        profile,
        setSpaceAddOpen,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a space title.');
            return;
        }

        showProgress("Adding space...");

        setSpaceAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const profileKey = profile.key;

        const newSpaceKey = generateKey();  // Generate key for the new space

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each space to find the highest sort number
        profileSpaces.forEach(space => {
            const sortNumber = space.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        // Create the space data
        const spaceData = {
            key: newSpaceKey,
            profileKey: profileKey,
            userKey: userKey,
            username: username,
            title: title,
            description: description,
            sort: highestSortNumber + 1,
            tags: tags,
            dateCreated: now,
            dateModified: now,
        };

        // Add the space to the database first
        await dataManager.add(
            collections.spaces,
            profileKey, 
            newSpaceKey, 
            spaceData
        );

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Hide the progress panel
        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Space"
                isOpen={spaceAddOpen}
                onClose={() => setSpaceAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Space Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* AI FIELD GENERATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Generate Fields"
                        value={autogen}
                        onChange={() => setAutogen(!autogen)}
                    />

                </ModalSection>
                */}

                {/* AI DATA POPULATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Populate Data"
                        value={autopop}
                        onChange={() => setAutopop(!autopop)}
                    />

                </ModalSection>
                */}

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal >
        </>
    );
};

export default SpaceAdd;
