import React from 'react';

// Styles
import './TileCanvasRow.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DragIcon from '../../../../common/svg/DragIcon';

// Components
import TileCanvasBlock from '../canvasblock/TileCanvasBlock';

// Row container for managing drag-and-drop reordering and blocks within a row
const TileCanvasRow = ({ row, index, selectedRow, selectedBlock, selectedTileElement, onMoveRow, onRowSelect, onBlockSelect, onDrop, onElementSelect, useDrag, useDrop }) => {

    // Theme
    const { theme } = useTheme();

    // References
    const ref = React.useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: 'ROW',
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        }),
        hover(item, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) return;

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

            onMoveRow(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'ROW',
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`tile-canvas-row-container ${isDragging ? 'dragging' : ''}`}
            data-handler-id={handlerId}
            style={{
                opacity: isDragging ? 0.5 : 1,
                borderColor: selectedRow === row.id ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                borderStyle: selectedRow === row.id ? "solid" : "dotted",
            }}
            onClick={() => onRowSelect(row.id)}>
            {row.blocks.map((block) => (
                <TileCanvasBlock
                    key={block.id} // Unique key is necessary here
                    block={block}
                    rowId={row.id}
                    selected={selectedBlock && selectedBlock.blockId === block.id && selectedBlock.rowId === row.id}
                    onSelect={() => onBlockSelect(row.id, block.id)}
                    onDrop={onDrop}
                    onElementSelect={onElementSelect}
                    selectedTileElement={selectedTileElement}
                    useDrop={useDrop}
                />
            ))}
            <DragIcon
                color={selectedRow === row.id ? theme.highlightBackgroundColor : theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
    
};

export default TileCanvasRow;

