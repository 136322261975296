import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Config
import { menuItemHeight } from '../../../Config';

// Styles
import './MenuGroup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ChevronDownIcon from '../../../common/svg/ChevronDownIcon';
import ChevronUpIcon from '../../../common/svg/ChevronUpIcon';

// Components
import MenuGroupAddButton from './addbutton/MenuGroupAddButton';

const MenuGroup = ({ title, children, showAdd = true, onAddClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { 
        profile 
    } = useContext(Global);

    // Local State
    const [isOpen, setIsOpen] = useState(true);
    const [contentHeight, setContentHeight] = useState('0px');

    const normalizedChildren = React.Children.toArray(children); // Normalize children to an array

    // Toggle accordion open/close
    const toggleAccordion = () => {
        setIsOpen((prev) => !prev);
    };

    // Recalculate height whenever `isOpen` or `normalizedChildren.length` changes
    useEffect(() => {
        if (isOpen) {
            const menuItemHeightWithMargin = menuItemHeight + 6; // Add 2px margin to height
            const calculatedHeight = normalizedChildren.length * menuItemHeightWithMargin; // Calculate height
            setContentHeight(`${calculatedHeight}px`);
        } else {
            setContentHeight('0px');
        }
    }, [isOpen, normalizedChildren.length]);

    // Recalculate height whenever the app changes
    useEffect(() => {
        if (isOpen) {
            const menuItemHeightWithMargin = menuItemHeight + 6; // Add 2px margin to height
            const calculatedHeight = normalizedChildren.length * menuItemHeightWithMargin; // Recalculate height for new app
            setContentHeight(`${calculatedHeight}px`);
        }
    }, [profile, isOpen, normalizedChildren.length]);

    const handleAddClick = (e) => {
        e.stopPropagation();
        onAddClick();
    };

    // Verify profile
    if (!profile) return null;

    return (
        <div className="menu-group-section">
            <div
                className="menu-group-header"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: profile.fontFamily,
                }}
                onClick={toggleAccordion}
            >
                {/* TITLE */}
                <div className="menu-group-header-left">
                    {title}
                    <div className="menu-group-header-chevron">
                        {isOpen ? (
                            <ChevronDownIcon
                                color={theme.foregroundColorFaded}
                                width="14"
                                height="14"
                            />
                        ) : (
                            <ChevronUpIcon
                                color={theme.foregroundColorFaded}
                                width="14"
                                height="14"
                            />
                        )}
                    </div>
                </div>

                {/* ADD BUTTON */}
                {showAdd && <MenuGroupAddButton onClick={handleAddClick} />}
            </div>
            <div
                className={`menu-group-content-container ${isOpen ? 'open' : ''}`}
                style={{
                    borderColor: theme.backgroundColorFaded,
                    height: contentHeight,
                    transition: 'height 0.2s ease',
                }}
            >
                <div className="menu-group-content">{children}</div>
            </div>
        </div>
    );
};

export default MenuGroup;
