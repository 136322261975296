// Firebase
import { collection, deleteDoc, doc, getDocs, onSnapshot, orderBy, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class DataManager {

    /**
     * Adds a new collection record.
     * 
     * @param {string} collectionName - Collection name.
     * @param {string} profileKey - Key of the app.
     * @param {string} recordKey - The new record key.
     * @param {string} data - Record data.
     * @returns {object} - New object.
     */
    async add(collectionName, profileKey, recordKey, data) {

        // Add timestamps
        const now = Timestamp.now();
        data.dateCreated = now;
        data.dateModified = now;

        await setDoc(doc(db, collectionName, recordKey), data);

        activity.log(profileKey, 'writes', 1);

        return data;
    }

    /**
     * Fetches a record from a collection.
     * 
     * @param {string} collectionName - Collection name.
     * @param {string} profileKey - Key of the app.
     * @param {string} recordKey - Record key.
     * @returns {Promise<Object|null>} A promise that resolves to the record if found, or null if not found.
    */
    async get(collectionName, profileKey, recordKey) {
        try {
            const coll = collection(db, collectionName);
            const q = query(coll, where("key", "==", recordKey));
            const snapshot = await getDocs(q);

            activity.log(profileKey, 'reads', 1);

            if (!snapshot.empty) {
                return snapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    /**
     * Fetches records for a user with optional filters and sorting.
     * 
     * @param {string} collectionName - Collection name.
     * @param {string} profileKey - App key.
     * @param {Array} [params] - Array of query parameters for filtering (optional).
     * @param {Array} [sortFields] - Array of fields to sort by (optional).
     * 
     * @returns {Promise<Array>} A list of records matching the query.
     * 
     * Usage:
     * 
     * const params = [
     *          { field: "status", operator: "==", value: "active" }, // Filter for active users
     *          { field: "age", operator: ">", value: 18 } // Filter for users older than 18
     *      ];
     * 
     * const sortFields = [
     *          { field: "name", order: "asc" } // Sorting by name in ascending order
     *      ];
     * 
     * const records = await dataManager.list(collections.users, profileKey, params, sortFields);
     * 
     */
    async list(collectionName, profileKey, params = [], sortFields = []) {
        try {
            // Base query reference
            let q = query(collection(db, collectionName), where("profileKey", "==", profileKey));

            // Add additional query parameters (e.g., filters) dynamically
            params.forEach(param => {
                if (param.field && param.operator && param.value) {
                    q = query(q, where(param.field, param.operator, param.value));
                }
            });

            // Add sorting to the query
            sortFields.forEach(field => {
                if (typeof field === "string") {
                    q = query(q, orderBy(field)); // ascending by default
                } else if (field.field && field.order) {
                    q = query(q, orderBy(field.field, field.order)); // custom order (asc/desc)
                }
            });

            // Fetch records from Firestore
            const snapshot = await getDocs(q);

            // Log the read activity
            activity.log(profileKey, 'reads', snapshot.docs.length);

            // Return the list of records
            return snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error("Error fetching records:", error);
            return { success: false, message: "Error fetching records.", error };
        }
    }

    /**
     * Fetches records and subscribes to real-time updates.
     * 
     * @param {string} collectionName - The name of the collection to query.
     * @param {string} profileKey - The app key.
     * @param {function} onUpdate - Callback function that handles the update.
     * @param {Array} [params] - An optional array of parameters.
     * @param {Array} [sortFields] - An optional array of fields to sort by.
     * 
     * Usage:
     * 
     * const params = [
     *          { field: "status", operator: "==", value: "active" }, // Filter for active users
     *          { field: "age", operator: ">", value: 18 } // Filter for users older than 18
     *      ];
     * 
     * const sortFields = [
     *          "title", // ascending by default
     *          { field: "title", order: "desc" } // descending order
     *      ];  
     * 
     * listAndSubscribe(profileKey, collections.users, params, sortFields, (results) => {
     *      console.log(results);
     * });
     */
    listAndSubscribe(collectionName, profileKey, onUpdate, params = [], sortFields = [], global = false) {
        try {
            if (!profileKey || !collectionName || !onUpdate) {
                throw new Error("Invalid parameters provided.");
            }
    
            const coll = collection(db, collectionName);
    
            // Build query constraints
            const queryConstraints = [];

            if (!global) {
                queryConstraints.push(where("profileKey", "==", profileKey));
            }
    
            // Add filtering based on params
            params.forEach(param => {
                if (param.field && param.operator && param.value !== undefined) { // Allow null
                    queryConstraints.push(where(param.field, param.operator, param.value));
                }
            });            
    
            // Add sorting to the query
            sortFields.forEach(field => {
                if (typeof field === "string") {
                    queryConstraints.push(orderBy(field)); // ascending by default
                } else if (field.field && field.order) {
                    queryConstraints.push(orderBy(field.field, field.order));
                }
            });
    
            // Combine all query constraints
            const q = query(coll, ...queryConstraints);
    
            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const results = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
    
                activity.log(profileKey, 'reads', results.length);
    
                // Call the onUpdate callback
                onUpdate(results);
            }, error => {
                console.error("Error fetching records:", error);
            });
    
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            return { success: false, message: "Error setting up real-time updates.", error };
        }
    }    

    /**
     * Deletes records from the database with optional filters.
     * 
     * @param {string} collectionName - Collection name.
     * @param {string} profileKey - Key of the app.
     * @param {string} [recordKey] - The key of the record (optional).
     * @param {Array} [params] - Array of query parameters for filtering (optional).
     * 
     * @returns {Promise<Object>} Success or failure message.
     * 
     * Usage:
     * 
     * const params = [
     *          { field: 'profileKey', operator: '==', value: profile.key },
     *          { field: 'userKey', operator: '==', value: currentUser.userKey },
     *          { field: 'objectKey', operator: '==', value: object.key }
     *      ];
     * 
     *      const result = await dataManager.delete(
     *          collections.bookmarks,
     *          profile.key,
     *          null,
     *          params
     *      );
     */
    async delete(collectionName, profileKey, recordKey = null, params = []) {
        try {
            // Start with a reference to the collection
            let coll = collection(db, collectionName);

            // If no specific recordKey is provided, add filters from params
            let q = query(coll);

            if (recordKey) {
                // If a recordKey is provided, query for that specific document
                q = query(coll, where('key', '==', recordKey));
            }

            // Add any additional filtering conditions from params
            params.forEach(param => {
                if (param.field && param.operator && param.value) {
                    q = query(q, where(param.field, param.operator, param.value));
                }
            });

            // Fetch the matching records
            const querySnapshot = await getDocs(q);

            // If no records are found
            if (querySnapshot.empty) {
                return { success: false, message: "No matching records found." };
            }

            // Delete all matching documents
            const deletePromises = querySnapshot.docs.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);

            // Log the delete activity
            activity.log(profileKey, 'deletes', querySnapshot.size);

            // Return success message
            return { success: true, message: `${querySnapshot.size} record(s) deleted successfully.` };
        } catch (error) {
            console.error("Error deleting records:", error);
            return { success: false, message: "Error deleting records.", error };
        }
    }

    /**
     * Updates a record's data.
     * 
     * @param {string} collectionName - The name of the collection.
     * @param {string} profileKey - The key of the app.
     * @param {string} recordKey - The key of the record.
     * @param {string} data - Data to update.
     * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async update(collectionName, profileKey, recordKey, data) {
        try {

            // Update the document
            await updateDoc(doc(db, collectionName, recordKey), data);

            activity.log(profileKey, 'writes', 1);

            // Return a success message
            return { success: true, message: "Record updated successfully." };
        } catch (error) {
            console.error('Error updating record:', error);
            // Return an error message
            return { success: false, message: "Error updating record." };
        }
    }

}

export default DataManager;
