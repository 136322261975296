import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Messages.css';

// Images

// Components
import Conversations from './Conversations';
import Conversation from './Conversation';

// Managers

const Messages = () => {
  const {
    selectedConversation
  } = useContext(Global);

  return (
    <>
      <div className={(isMobile || isTablet) ? "messages-container-mobile" : "messages-container"}>
        {isMobile ? (
          <>
            {selectedConversation ? (
              <div className="messages-conversation">
                <Conversation />
              </div>
            ) : (
              <div className="messages-conversations">
                <Conversations />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="messages-conversations"
              style={{
                width: selectedConversation ? "50%" : "100%",
              }}>
              <Conversations />
            </div>
            {selectedConversation &&
              <div className="messages-conversation">
                <Conversation />
              </div>
            }
          </>
        )}



      </div>
    </>
  );
};

export default Messages;
