import React from 'react';

// Styles
import './NumberPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const NumberPreview = ({
    placeholder = '',
    selected = false
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="number-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div
                className="number-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <div
                className="number-preview-input"
                style={{
                    color: theme.foregroundColor
                }}>
                3.1415926535897
            </div>
        </div>
    );
};

export default NumberPreview;
