import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './MemberRoleSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * MemberRoleSelector Component
 * 
 * Displays a list of roles and allows selection for an app user.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MemberRoleSelector = ({ readonly = false, onRoleClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { 
        profileRoles, 
        profile, 
        selectedMember 
    } = useContext(Global);

    // Check if the profile being viewed is the app's creator
    const isAppCreator = selectedMember?.userKey === profile?.userKey;

    // Handle role click in editable mode
    const handleRoleClick = (role) => {

        if (readonly || isAppCreator) return; // Do nothing in readonly mode or for the app creator

        const isSelected = selectedMember.roles.includes(role.key);

        // Prevent deselecting the last remaining role
        if (isSelected && selectedMember.roles.length === 1) return;

        // Update roles based on selection state
        const updatedRoles = isSelected
            ? selectedMember.roles.filter(key => key !== role.key) // Deselect role
            : [...selectedMember.roles, role.key]; // Select role

        onRoleClick(updatedRoles);
    };

    // Render roles
    const renderRoles = () => {
        // Determine which roles to display
        const rolesToDisplay = isAppCreator
            ? profileRoles.filter(role => role.isCreator) // Show only the creator role if the user is the app creator
            : readonly
                ? selectedMember.roles
                    .map(roleKey => profileRoles.find(role => role.key === roleKey)) // Map roles assigned to the user
                    .filter(role => role && !role.isCreator) // Exclude undefined roles and creator roles
                : profileRoles.filter(role => !role.isCreator); // Exclude creator role in editable mode
    
        // Render each role as a styled div
        return rolesToDisplay.map(role => (
            <div
                key={role.key}
                className="member-role-selector-role"
                onClick={() => handleRoleClick(role)}
                style={{
                    backgroundColor: readonly || isAppCreator
                        ? theme.backgroundColorFaded
                        : (selectedMember?.roles.includes(role.key) ? theme.highlightBackgroundColor : theme.backgroundColorFaded),
                    color: readonly || isAppCreator
                        ? theme.foregroundColor
                        : (selectedMember?.roles.includes(role.key) ? theme.highlightForegroundColor : theme.foregroundColor),
                    cursor: readonly || isAppCreator ? 'default' : 'pointer',
                }}
            >
                {role.title}
            </div>
        ));
    };    

    if (!profileRoles || !selectedMember) {
        return <div>No roles available</div>;
    }

    return (
        <div>
            <div className="member-role-selector-container">
                {renderRoles()}
            </div>
        </div>
    );
};

export default MemberRoleSelector;
