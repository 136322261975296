import React from 'react';

// Styles
import './TileCanvasBlock.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import ButtonSummaryPreview from '../previews/ButtonSummaryPreview';
import CreatedSummaryPreview from '../previews/CreatedSummaryPreview';
import GallerySummaryPreview from '../previews/GallerySummaryPreview';
import RatingSummaryPreview from '../previews/RatingSummaryPreview';
import TagSummaryPreview from '../previews/TagSummaryPreview';
import TextSummaryPreview from '../previews/TextSummaryPreview';
import UserNameSummaryPreview from '../previews/UserNameSummaryPreview';
import UserPhotoSummaryPreview from '../previews/UserPhotoSummaryPreview';
import VideoGallerySummaryPreview from '../previews/VideoGallerySummaryPreview';
import ViewCountSummaryPreview from '../previews/ViewCountSummaryPreview';

// Droppable Block
const TileCanvasBlock = ({ block, rowId, selected, onSelect, onDrop, onElementSelect, selectedTileElement, useDrop }) => {

    // Theme
    const { theme } = useTheme();

    // References
    const ref = React.useRef(null); // Create a ref for the block

    const [{ isOver }, drop] = useDrop({
        accept: 'ELEMENT',
        drop: (item) => onDrop(rowId, block.id, item.element),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    drop(ref); // Ensure the ref is passed to the drop function

    return (
        <div
            ref={ref} // Attach the ref here to the block div
            className="tile-canvas-block-container"
            onClick={(e) => {
                e.stopPropagation();  // Prevent row selection when block is clicked
                onSelect();           // Select the block
            }}
            style={{
                justifyContent: block.align, // Use justify-content for alignment since flex is used
                borderColor: isOver ? theme.foregroundColorFaded : selected ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                borderStyle: selected ? "solid" : "dotted",
            }}>
            {block.content.length > 0
                ? block.content.map((element) => (
                    <div key={element.key}
                        className="tile-canvas-block-element"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent block selection
                            onElementSelect(element);
                        }}
                        style={{
                            color: theme.foregroundColor,
                            justifyContent: 'center',
                            borderColor: selectedTileElement?.key === element.key ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                            borderStyle: selectedTileElement?.key === element.key ? "solid" : "dotted",
                            margin: '2px',
                        }}>

                        {/* FIELDS */}
                        {element.type === 'field' && element.field &&
                            <>
                                {['country', 'currency', 'day', 'lookup', 'number', 'phone', 'state', 'text', 'time', 'year'].includes(element.field.type) ?
                                    <TextSummaryPreview element={element} /> : null}
                                {element.field.type === 'gallery' ? <GallerySummaryPreview element={element} /> : null}
                                {element.field.type === 'videogallery' ? <VideoGallerySummaryPreview element={element} /> : null}
                            </>
                        }

                        {/* ELEMENTS */}
                        {element.type === 'static' &&
                            <>
                                {/* DATE CREATED */}
                                {element.elementType === 'created' &&
                                    <CreatedSummaryPreview element={element} />
                                }

                                {/* TAGS */}
                                {element.elementType === 'tags' &&
                                    <TagSummaryPreview element={element} />
                                }

                                {/* VIEW COUNT */}
                                {element.elementType === 'viewcount' &&
                                    <ViewCountSummaryPreview element={element} />
                                }

                                {/* USER PHOTO */}
                                {element.elementType === 'userphoto' &&
                                    <UserPhotoSummaryPreview element={element} />
                                }

                                {/* USER NAME */}
                                {element.elementType === 'username' &&
                                    <UserNameSummaryPreview element={element} />
                                }
                            </>
                        }

                        {element.type === 'button' &&
                            <>
                                {element.buttonType === 'rate' ? (
                                    <RatingSummaryPreview element={element} />
                                ) : (
                                    <ButtonSummaryPreview element={element} />
                                )}
                            </>
                        }

                    </div>
                )
                ) : (
                    <div className="tile-canvas-block-empty"
                        style={{
                            color: theme.backgroundColorFaded,
                        }}>
                        DROP ITEMS HERE
                    </div>
                )
            }
        </div>
    );
};

export default TileCanvasBlock;

