import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Drag/Drop
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Utilities
import { defaultFont } from '../../../common/utilities/Defaults.js';

// Styles
import './FormDesigner.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DeleteIcon from '../../../common/svg/DeleteIcon';
import DragIcon from '../../../common/svg/DragIcon';
import SaveIcon from '../../../common/svg/SaveIcon';

// Components
import AccordionSection from '../../components/accordion/AccordionSection.js';
import Modal from '../../../common/components/modal/Modal';
import FieldSelector from '../../../common/form/fieldselector/FieldSelector';
import FormDesignerButton from './button/FormDesignerButton';

// Managers
import DataManager from '../../../common/managers/DataManager';
import { generateKey } from '../../../common/utilities/Keys';

// Import the item types we need
import {
    DRAGGABLE_NEW_FIELD_TYPE
} from '../../../common/form/fieldselector/FieldSelector';

const dataManager = new DataManager();

// Existing fields reordering
const DRAGGABLE_FIELD_TYPE = 'FIELD';

const DraggableField = ({
    field,
    index,
    moveField,
    theme,
    addNewFieldAtIndex, // function to add a new field if we detect a new field was dropped
    onFieldClick,
    selectedFormField
}) => {
    const ref = useRef(null);

    // Drop hook
    const [{ handlerId }, drop] = useDrop({
        accept: [DRAGGABLE_FIELD_TYPE, DRAGGABLE_NEW_FIELD_TYPE],
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) return;

            const dragIndex = item.index; // might be undefined for new fields
            const hoverIndex = index;

            // If it's a *new* field (coming from FieldSelector), it won't have `item.index`
            if (item.isNew) {
                // We can insert the new field at hoverIndex, but let's do it on "drop" to avoid repeated inserts.
                return;
            }

            // ==========================
            // REORDERING EXISTING FIELDS
            // ==========================
            // Avoid reordering with self
            if (dragIndex === hoverIndex) {
                return;
            }

            // This logic ensures we only move after half of the hovered item
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Perform the reordering of existing fields
            moveField(dragIndex, hoverIndex);

            // Update the drag index so it doesn't cause issues from multiple hovers
            item.index = hoverIndex;
        },
        drop(item, monitor) {
            // We handle creation of a new field on drop instead of hover.
            if (item.isNew) {
                // Insert the new field at the current index
                addNewFieldAtIndex(item.fieldType, item.fieldName, item.modelKey, item.checklistModelKey, index);
            }
        },
    });

    // Drag hook
    const [{ isDragging }, drag] = useDrag({
        type: DRAGGABLE_FIELD_TYPE,
        item: () => ({
            id: field.key,
            index,
            isNew: false, // existing field
        }),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0.4 : 1;

    return (
        <div
            ref={ref}
            data-handler-id={handlerId}
            title="Drag to reorder"
            className="form-designer-field"
            onClick={() => onFieldClick(field)}
            style={{
                opacity,
            }}>
            <div className="form-designer-field-wrapper"
                style={{
                    borderColor: (selectedFormField && selectedFormField.key === field.key) ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                    borderStyle: (selectedFormField && selectedFormField.key === field.key) ? "solid" : "dotted",
                    color: theme.foregroundColor,
                    opacity,
                }}>
                <div className="form-designer-field-title"
                    style={{
                        color: theme.foregroundColor,
                    }}>
                    {field.title}
                </div>
                <div className="form-designer-field-type"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    {field.type}
                </div>
            </div>

            <DragIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

const FormDesigner = ({ open, setOpen }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileFields,
        profile,
        selectedModel,
        selectedFormField,
        setSelectedFormField
    } = useContext(Global);

    // Local State
    const [fields, setFields] = useState([]);
    const [addedFields, setAddedFields] = useState([]);
    const [removedFields, setRemovedFields] = useState([]);
    const [formTitle, setFormTitle] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [fieldTitle, setFieldTitle] = useState("");
    const [fieldDescription, setFieldDescription] = useState("");

    /**
     * Update the form title and form description when model is selected.
     */
    useEffect(() => {
        if (!selectedModel) return;
        setFormTitle(selectedModel.title || "");
        setFormDescription(selectedModel.description || "");
    }, [selectedModel]);

    /**
     * Update the field title and field description when a new field is selected.
     */
    useEffect(() => {
        if (!selectedFormField) return;
        setFieldTitle(selectedFormField.title || "");
        setFieldDescription(selectedFormField.description || "");
    }, [selectedFormField]);

    // Sorts fields by the order in selectedModel.fieldSort.
    const sortFieldsByCollectionOrder = (unsortedFields, fieldSortArray) => {
        if (!fieldSortArray || !Array.isArray(fieldSortArray)) {
            return unsortedFields;
        }
        const sortIndexMap = fieldSortArray.reduce((acc, key, idx) => {
            acc[key] = idx;
            return acc;
        }, {});
        return unsortedFields.sort((a, b) => {
            const aIndex = sortIndexMap[a.key] ?? Infinity;
            const bIndex = sortIndexMap[b.key] ?? Infinity;
            return aIndex - bIndex;
        });
    };

    useEffect(() => {
        if (!selectedModel) return;
        const relevantFields = profileFields.filter(
            (f) => f.modelKey === selectedModel.key
        );
        const sorted = sortFieldsByCollectionOrder(
            relevantFields,
            selectedModel.fieldSort
        );
        setFields(sorted);
    }, [selectedModel, profileFields]);

    /**
     * Reorders the existing fields
     */
    const moveField = (dragIndex, hoverIndex) => {
        setFields((prevFields) => {
            const updatedFields = [...prevFields];
            const [removed] = updatedFields.splice(dragIndex, 1);
            updatedFields.splice(hoverIndex, 0, removed);
            return updatedFields;
        });
    };

    /**
     * Inserts a newly created field into the array at a specific index.
     */
    const addNewFieldAtIndex = (fieldType, fieldName, modelKey, checklistModelKey, index) => {
        if (!selectedModel) return;

        // Build the new field object
        const newField = {
            key: generateKey(),
            title: fieldName,    // for now, just use the fieldName as the title
            type: fieldType,
        };

        // Add related 'modelKey' only if it is not null or undefined
        if (modelKey != null) {
            newField.modelKey = modelKey;
        }

        // Add 'checklistModelKey' only if it is not null or undefined
        if (checklistModelKey != null) {
            newField.checklistModelKey = checklistModelKey;
        }

        // Insert into local state
        setFields((prevFields) => {
            const updated = [...prevFields];
            updated.splice(index, 0, newField);
            return updated;
        });

        // Insert into addedFields array
        setAddedFields((prevFields) => {
            const updated = [...prevFields];
            updated.push(newField);
            return updated;
        });

        // Select the new field
        setSelectedFormField(newField);
    };

    const handleDelete = () => {
        // Remove the field from the fields array
        const updatedFormFields = fields.filter(f => f.key !== selectedFormField.key);

        // If the field exists in the addedFields array, remove it
        const addedFieldIndex = addedFields.findIndex(f => f.key === selectedFormField.key);
        if (addedFieldIndex > -1) {
            const updatedAddedFields = [...addedFields];
            updatedAddedFields.splice(addedFieldIndex, 1);
            setAddedFields(updatedAddedFields);
        } else {
            // Insert the field into removedFields array
            setRemovedFields((prevFields) => {
                const updated = [...prevFields];
                updated.push(selectedFormField);
                return updated;
            });
        }

        setFields(updatedFormFields);
        setSelectedFormField(null);
    };

    /**
     * Saves the updated fields, extracting the new fieldSort array.
     */
    const handleSave = async () => {

        // Iterate through the addedFields and add every field
        for (let i = 0; i < addedFields.length; i++) {
            const field = addedFields[i];
            field.profileKey = profile.key;
            field.modelKey = selectedModel.key;

            await dataManager.add(collections.fields, profile.key, field.key, field);
        }

        // Iterate through the removedFields and delete every field
        for (let i = 0; i < removedFields.length; i++) {
            const field = removedFields[i];
            const fieldKey = field.key;

            field.profileKey = profile.key;
            field.modelKey = selectedModel.key;

            await dataManager.delete(collections.fields, profile.key, fieldKey);
        }

        // Need to iterate all the fields and update the fieldSort array
        const fieldSort = fields.map(f => f.key);

        // Update the model
        const updatedModel = {
            ...selectedModel,
            fieldSort: fieldSort,
            title: formTitle,
            description: formDescription
        };
        await dataManager.update(collections.models, profile.key, selectedModel.key, updatedModel);

        // Update the remaining fields
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            field.profileKey = profile.key;
            field.modelKey = selectedModel.key;

            await dataManager.update(collections.fields, profile.key, field.key, field);
        }

        // Close the modal
        setOpen(false);
    };

    /**
     * Handles field selection.
     */
    const handleFieldClick = (field) => {
        setSelectedFormField(field);
    };

    /**
     * Handles field title change.
     */
    const handleFieldTitleChange = (e) => {
        setFieldTitle(e.target.value);
        updateField('title', e.target.value);
    };

    /**
     * Handles field description change.
     */
    const handleFieldDescriptionChange = (e) => {
        setFieldDescription(e.target.value);
        updateField('description', e.target.value);
    };

    /**
     * Handles form title change.
     */
    const handleFormTitleChange = (e) => {
        setFormTitle(e.target.value);
    };

    /**
     * Handles form description change.
     */
    const handleFormDescriptionChange = (e) => {
        setFormDescription(e.target.value);
    };

    /**
     * Updates a field in the fields array.
     * 
     * @param {string} name - The field name to update.
     * @param {string} value - The new value.
     * 
     * @returns {void}
     **/
    const updateField = (name, value) => {
        // Update the field in the formFields state
        const updatedFormFields = fields.map(item =>
            item.key === selectedFormField.key ? { ...item, [name]: value } : item
        );

        setFields(updatedFormFields);
    };

    // Verify profile
    if (!profile) return null;

    return (
        <Modal
            title={`Form Design - ${selectedModel?.title || ''}`}
            isOpen={open}
            onClose={() => setOpen(false)}
            height="90%"
            width="90%">
            <DndProvider backend={HTML5Backend}>
                <div className="form-designer-container">
                    {/* FIELDS PANEL */}
                    <div
                        className="form-designer-selector-wrapper"
                        style={{
                            borderRightColor: theme.backgroundColorFaded,
                        }}>

                        <AccordionSection title="Add Fields" expanded={true}>

                            <FieldSelector size="LARGE" />

                        </AccordionSection>
                    </div>

                    {/* CANVAS PANEL */}
                    <div className="form-designer-canvas-wrapper">

                        {/* TOP BUTTONS */}
                        <div className="form-designer-buttons">

                            {/* REMOVE */}
                            {selectedFormField && selectedModel.titleFieldKey !== selectedFormField.key &&
                                <FormDesignerButton
                                    title="Remove Field"
                                    icon={DeleteIcon}
                                    onClick={handleDelete}
                                />
                            }

                            {/* SAVE */}
                            <FormDesignerButton
                                title="Save Changes"
                                icon={SaveIcon}
                                onClick={handleSave}
                            />

                        </div>

                        {/* MAIN CANVAS WITH DRAGGABLE FIELDS */}
                        <div className="form-designer-canvas">
                            {fields.map((field, index) => (
                                <DraggableField
                                    key={field.key}
                                    field={field}
                                    index={index}
                                    moveField={moveField}
                                    theme={theme}
                                    addNewFieldAtIndex={addNewFieldAtIndex}
                                    onFieldClick={handleFieldClick}
                                    selectedFormField={selectedFormField}
                                />
                            ))}
                        </div>
                    </div>

                    {/* SETTINGS PANEL */}
                    <div
                        className="form-designer-settings"
                        style={{
                            borderLeftColor: theme.backgroundColorFaded,
                        }}>

                        <AccordionSection title="Form Properties" expanded={true}>

                            {/* FORM TITLE */}
                            <div className="form-designer-label-container"
                                style={{
                                    color: theme.foregroundColorFaded
                                }}>
                                Form Title
                            </div>
                            <input
                                type="text"
                                className="form-designer-input-container"
                                value={formTitle}
                                onChange={handleFormTitleChange}
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: theme.backgroundColorFaded,
                                    color: theme.foregroundColor
                                }}
                                maxLength="100"
                                autoFocus={true}
                            />

                            {/* FORM DESCRIPTION */}
                            <div className="form-designer-label-container"
                                style={{
                                    color: theme.foregroundColorFaded
                                }}>
                                Form Description
                            </div>
                            <textarea
                                className="form-designer-textarea-container"
                                style={{
                                    color: theme.foregroundColor,
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: theme.backgroundColorFaded,
                                    fontFamily: profile.fontFamily || defaultFont
                                }}
                                value={formDescription}
                                onChange={handleFormDescriptionChange}
                                lines="3"
                                maxLength="255"></textarea>

                        </AccordionSection>

                        {selectedFormField &&

                            <AccordionSection title="Selected Field Properties" expanded={true}>

                                {/* FIELD TITLE */}
                                <div className="form-designer-label-container"
                                    style={{
                                        color: theme.foregroundColorFaded
                                    }}>
                                    Field Title
                                </div>
                                <input
                                    type="text"
                                    className="form-designer-input-container"
                                    value={fieldTitle}
                                    onChange={handleFieldTitleChange}
                                    style={{
                                        backgroundColor: theme.backgroundColorFaded,
                                        borderColor: theme.backgroundColorFaded,
                                        color: theme.foregroundColor
                                    }}
                                    maxLength="100"
                                    autoFocus={true}
                                />

                                {/* FIELD DESCRIPTION */}
                                <div className="form-designer-label-container"
                                    style={{
                                        color: theme.foregroundColorFaded
                                    }}>
                                    Field Description
                                </div>
                                <textarea
                                    className="form-designer-textarea-container"
                                    style={{
                                        color: theme.foregroundColor,
                                        backgroundColor: theme.backgroundColorFaded,
                                        borderColor: theme.backgroundColorFaded,
                                        fontFamily: profile.fontFamily || defaultFont
                                    }}
                                    value={fieldDescription}
                                    onChange={handleFieldDescriptionChange}
                                    lines="3"
                                    maxLength="255"></textarea>

                            </AccordionSection>
                        }
                    </div>
                </div>
            </DndProvider>
        </Modal>
    );
};

export default FormDesigner;
