import React from 'react';

// Styles
import './GridItemButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * GridItemButton
 * 
 * Button for the footer of a grid item.
 * 
 * @param {Object} icon - Icon component.
 * @param {String} text - Text to display.
 * @param {Boolean} selected - Whether the button is selected.
 * 
 * @returns {JSX.Element}
 */
const GridItemButton = ({ icon: IconComponent, text = "", selected = false }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div className="grid-item-button-container"
            style={{
                color: selected ? theme.foregroundColor : theme.foregroundColorFaded,
            }}>
            <IconComponent
                color={selected ? theme.foregroundColor : theme.foregroundColorFaded}
                width="14"
                height="14"
            />
            {text}
        </div>

    );
};

export default GridItemButton;
