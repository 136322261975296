import React from 'react';

// Styles
import './StoryGridItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import StoryIcon from '../../../svg/StoryIcon';

// Components
import GridItem from '../../../components/grid/griditem/GridItem';
import GridItemPrivacy from '../../../components/grid/griditem/privacy/GridItemPrivacy';
import GridItemTitle from '../../../components/grid/griditem/title/GridItemTitle';

/**
 * StoryGridItem
 * 
 * A grid item for displaying stories.
 * 
 * @param {Object} item The story item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} StoryGridItem component.
 */
const StoryGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart }) => {
    // Theme
    const { theme } = useTheme();

    // Find the first image URL from the blocks
    const firstImageUrl = item.blocks
        ?.find(block => block.type === "PHOTOS" && block.content?.[0]?.url)
        ?.content[0]?.url;

    return (
        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(story) => onClick(story, item)} // Forward the story and item
            onDoubleClick={() => onDoubleClick(item)}
            onDragStart={(event) => onDragStart(event, item)}>

            <div
                className="story-grid-item-container"
                title={item.title}>

                {/* ICON OR IMAGE */}
                {firstImageUrl ? (
                    <div
                        className="story-grid-item-image-wrapper">
                        <img
                            src={firstImageUrl}
                            alt={item.title}
                            className="story-grid-item-image"
                        />
                    </div>
                ) : (
                    <div
                        className="story-grid-item-icon-wrapper"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <StoryIcon
                            color={theme.highlightBackgroundColor}
                            width="70"
                            height="70"
                        />
                    </div>
                )}

                {/* PRIVACY */}
                <GridItemPrivacy
                    selected={selected}
                    shared={item.shared}
                    label="Private"
                />

                {/* TITLE */}
                <GridItemTitle
                    title={item.title}
                    selected={selected}
                />

            </div>

        </GridItem>
    );
};

export default StoryGridItem;
