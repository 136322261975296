// Firebase
import { collection, query, orderBy, onSnapshot, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from './ActivityManager';

class ConversationManager {

    /**
      * Fetches conversations and subscribes to real-time updates.
      * 
      * @param {string} profileKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listAndSubscribe(profileKey, onUpdate) {

        try {
            // Create a reference to the conversations collection
            const conversationsCollection = collection(db, collections.conversations);

            // Create a query to find conversations by profileKey and sort them by title
            const q = query(conversationsCollection, where("profileKey", "==", profileKey), orderBy("dateCreated", "desc"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                // Create an array of conversations from the snapshot
                const conversationList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Activity logging needs to occur in onUpdate

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    activity.log(profileKey, 'reads', conversationList.length);
                    onUpdate(conversationList);
                }

            }, error => {
                console.error("Error fetching conversations:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
      * Fetches messages and subscribes to real-time updates.
      * 
      * @param {string} profileKey - App key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    listMessagesAndSubscribe(profileKey, conversationKey, onUpdate) {

        try {
            // Create a reference to the messages collection
            const messagesCollection = collection(db, collections.messages);

            // Create a query to find conversations by profileKey and sort them by title
            const q = query(messagesCollection, where("conversationKey", "==", conversationKey), orderBy("dateCreated", "desc"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                // Create an array of conversations from the snapshot
                const messageList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    activity.log(profileKey, 'reads', messageList.length);
                    onUpdate(messageList);
                }

            }, error => {
                console.error("Error fetching messages:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Filters conversations to find those that start, end, or span across a specific date.
     * 
     * @param {Array} conversations - Array of conversation objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} date - The date to check the conversations against.
     * @returns {Array} Array of conversations that match the criteria.
     */
    getConversationsOnDate(conversations, date) {
        // Create start and end times for the date at midnight and just before midnight
        const dayStart = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const dayEnd = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

        const conversationsOnDate = conversations.filter(conversation => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = conversation.startDate.toDate();
            const endDate = conversation.endDate.toDate();

            return (
                // Check if the conversation starts or ends on the given date
                (startDate >= dayStart && startDate <= dayEnd) ||
                (endDate >= dayStart && endDate <= dayEnd) ||
                // Check if the conversation spans across the given date
                (startDate <= dayStart && endDate >= dayEnd)
            );
        });

        return conversationsOnDate;
    }

    /**
     * Filters conversations to find those that start, end, or span across the specified date and the two following days.
     * 
     * @param {Array} conversations - Array of conversation objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} activeDate - The starting date for the three-day period to check the conversations against.
     * @returns {Array} Array of conversations that match the criteria for the three-day span.
     */
    getThreeDayConversations(conversations, activeDate) {
        // Create start time for the active date at midnight
        const periodStart = new Date(activeDate.getFullYear(), activeDate.getMonth(), activeDate.getDate());

        // Create end time two days after the active date, just before midnight
        const periodEnd = new Date(activeDate.getFullYear(), activeDate.getMonth(), activeDate.getDate() + 2, 23, 59, 59, 999);

        const conversationsInThreeDays = conversations.filter(conversation => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = conversation.startDate.toDate();
            const endDate = conversation.endDate.toDate();

            return (
                // Check if the conversation starts or ends within the three-day period
                (startDate >= periodStart && startDate <= periodEnd) ||
                (endDate >= periodStart && endDate <= periodEnd) ||
                // Check if the conversation spans across the entire period
                (startDate <= periodStart && endDate >= periodEnd)
            );
        });

        return conversationsInThreeDays;
    }

    /**
     * Filters conversations to find those that start, end, or span across the specified date and the two following days.
     * 
     * @param {Array} conversations - Array of conversation objects with startDate and endDate as Firestore Timestamps.
     * @param {Date} startDate - The starting date for the week to check the conversations against.
     * @param {Date} endDate - The ending date for the week to check the conversations against.
     * @returns {Array} Array of conversations that match the criteria for the three-day span.
     */
    getConversationsBetweenDates(conversations, startDate, endDate) {
        // Create start time for the active date at midnight
        const periodStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

        // Create end time two days after the active date, just before midnight
        const periodEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);

        const conversationsInSpan = conversations.filter(conversation => {
            // Convert Firestore Timestamps to JavaScript Date objects
            const startDate = conversation.startDate.toDate();
            const endDate = conversation.endDate.toDate();

            return (
                // Check if the conversation starts or ends within the three-day period
                (startDate >= periodStart && startDate <= periodEnd) ||
                (endDate >= periodStart && endDate <= periodEnd) ||
                // Check if the conversation spans across the entire period
                (startDate <= periodStart && endDate >= periodEnd)
            );
        });

        return conversationsInSpan;
    }

}

export default ConversationManager;
