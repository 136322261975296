import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../../Global';

/**
 * Determines the contents of the folder when it is selected.
 */
const useSetFolderItems = ({ selectedFolder, setFolderItems }) => {

    // Global
    const {
        profileItems
    } = useContext(Global);

    useEffect(() => {
        // Shw desktop if the selectedFolder is null
        if (!selectedFolder) {
            // Filter down to set the folder items
            const combinedItems = profileItems.filter((item) => item.folderKey === null);
            setFolderItems(combinedItems);
        } else {
            // Filter down to set the folder items
            const combinedItems = profileItems.filter((item) => item.folderKey === selectedFolder.key);
            setFolderItems(combinedItems);
        }

    }, [selectedFolder, profileItems]);

};

export default useSetFolderItems;
