// CalendarWeekHour.js
import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Drag/Drop
import { useDrop } from 'react-dnd';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Styles
import './CalendarWeekHour.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Managers
import EventManager from '../../../managers/EventManager';

const eventManager = new EventManager();

const CalendarWeekHour = ({ date }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { 
        profile,
        setSelectedEvent,
        setSelectedTime,
        setEventVisible,
    } = useContext(Global);

    // Drop functionality
    const [, drop] = useDrop(() => ({
        accept: 'weekevent',
        drop: (item) => handleDrop(item),
    }));

    // Handles drop of an event on an hour.
    const handleDrop = async (item) => {

        const newStart = new Date(date.getTime());
        const originalDuration = item.event.endDate.toDate() - item.event.startDate.toDate();
        const newEnd = new Date(newStart.getTime() + originalDuration);

        const updatedEventData = {
            ...item.event,
            startDate: Timestamp.fromDate(newStart),
            endDate: Timestamp.fromDate(newEnd),
        };

        setSelectedEvent(null);

        try {
            await eventManager.update(profile.key, item.event.key, updatedEventData);
        } catch (error) {
            console.error("Error updating event: ", error);
        }
    };

    // Handles hour click
    const handleHourClick = async (date) => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Create a new date object from activeDate
        const selected = new Date(date.getTime());
        //selected.setHours(hourIndex);
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Display the event form
        setEventVisible(true);
    }

    return (
        <div
            ref={drop}
            className="calendar-week-hour-container"
            style={{
                borderColor: theme.backgroundColorFaded,
            }}
            onClick={() => handleHourClick(date)}>
            {/* Placeholder for events */}
        </div>
    );
};

export default CalendarWeekHour;
