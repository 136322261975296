import React, { createContext, useState, useContext, useEffect } from 'react';

// Enums
import { ThemeMode } from './common/utilities/Enums';

// Styled Components
import { createGlobalStyle } from 'styled-components';
export const useStyle = createGlobalStyle;

// Create a context for the theme
const ThemeContext = createContext();

// Create a provider component
export const ThemeProvider = ({ profile, children }) => {

    /**
     * Default themes for the app (both dark/light modes). These can be overridden 
     * by the app's themes.
     */
    const defaultThemes = {
        dark: {
            backgroundColor: '#000000',
            backgroundColorFaded: '#222222',
            foregroundColor: '#ffffff',
            foregroundColorFaded: '#aaaaaa',
            highlightBackgroundColor: '#fa4617', //'#5E3023',
            highlightForegroundColor: '#ffffff'
        },
        light: {
            backgroundColor: '#ffffff',
            backgroundColorFaded: '#f0f0f0',
            foregroundColor: '#000000',
            foregroundColorFaded: '#555555',
            highlightBackgroundColor: '#fa4617', //'#5E3023',
            highlightForegroundColor: '#ff0000'
        }
    };

    /**
     * State to keep track of the current theme setting. This can be set by the app or
     * by the user's preference (e.g., dark mode).
     * 
     * The default theme is 'DARK'.
     * 
     * If the selected app has a currentThemeMode, use that. Otherwise, use the default.
     */
    const [currentThemeMode, setCurrentThemeMode] = useState(ThemeMode.DARK);
    useEffect(() => {
        if (profile?.currentThemeMode) {
            setCurrentThemeMode(profile.currentThemeMode);
        }
    }, [profile]);

    /**
     * Uses the current theme based on the selected app. If the app has custom themes,
     * use those. Otherwise, use the default themes.
     */
    const themes = profile?.themes || defaultThemes;
    const theme = currentThemeMode === ThemeMode.DARK ? themes.dark : themes.light;

    /** 
     * Processes styles and replaces color tokens with actual colors.
     * 
     * @param {object} styles - The styles to process.
     * 
     * @returns {object} - The processed styles.
     */
    const getProcessedStyles = (styles) => {
        const processed = Object.entries(styles).reduce((acc, [key, value]) => {
            if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
                acc[key] = getColorFromToken(value);
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
        return processed;
    };

    /** 
     * Maps color tokens to theme colors.
     * 
     * @param {string} token - The token to map.
     * 
     * @returns {string} - The color value.
     */
    const getColorFromToken = (token) => {
        switch (token) {
            case '[foregroundColor]':
                return theme.foregroundColor;
            case '[foregroundColorFaded]':
                return theme.foregroundColorFaded;
            case '[highlightBackgroundColor]':
                return theme.highlightBackgroundColor;
            case '[highlightForegroundColor]':
                return theme.highlightForegroundColor;
            case '[backgroundColorFaded]':
                return theme.backgroundColorFaded;
            case '[backgroundColor]':
                return theme.backgroundColor;
            case '[transparent]':
                return 'transparent';
            default:
                console.warn(`Unknown color token: ${token}`);
                return theme.foregroundColor; // Fallback color
        }
    };

    /** 
     * Default field styles for various field types.
     */
    const fieldDefaults = {
        checkbox: {
            width: '34px',
            height: '34px'
        },
        checklist: {
            width: '20%',
            height: '20px',
            color: theme.foregroundColor,
        },
        event: {
            width: '150px',
            height: '40px',
            color: theme.foregroundColor,
        },
        gallery: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        image: {
            width: '80px',
            height: '80px',
            backgroundColor: 'transparent'
        },
        lookup: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        phone: {
            width: '150px',
            height: '23px',
            color: theme.foregroundColor,
        },
        text: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
            fontSize: '14pt',
            fontStyle: 'normal',
            fontWeight: 'normal',
            textAlign: 'left',
            textDecoration: 'none',
            letterSpacing: '0px',
            lineHeight: '1.5em',
            textTransform: 'none',
            textWrap: 'wrap',
            wordSpacing: '0px',
            zIndex: '0'
        },
        year: {
            width: '150px',
            height: '25px',
            color: theme.foregroundColor,
        },
    };

    return (
        <ThemeContext.Provider value={{
            defaultThemes,
            theme,
            currentThemeMode,
            fieldDefaults,
            setCurrentThemeMode,
            getColorFromToken,
            getProcessedStyles,
        }}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the ThemeContext
export const useTheme = () => useContext(ThemeContext);