import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section } from '../../utilities/Enums';
import { hasAppPermission, hasCollectionPermission } from '../../utilities/Permissions';

// Styles
import './Collections.css';

// Images
import DataIcon from '../../../common/svg/DataIcon';

// Components
import CollectionAdd from '../../collection/add/CollectionAdd';
import MenuGroup from '../menugroup/MenuGroup';
import MenuItem from '../menuitem/MenuItem';

// Managers
import ProfileManager from '../../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * Collections Component
 * 
 * This component renders a collection-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Collections = () => {

    // Global
    const {
        profileModels,
        profilePublicRole,
        profile,
        currentUser,
        resetSelections,
        selectedModel,
        setModelAddOpen,
        setCurrentSection,
        setCurrentUser,
        setMenuVisible,
        setSelectedModel,
    } = useContext(Global);

    /**
     * Handles a collection click event.
     * 
     * @param {object} collection - Collection that was clicked on.
     */
    const handleCollectionClick = async (model) => {
        resetSelections();

        setCurrentSection(Section.COLLECTIONS)
        setSelectedModel(model);
    };

    /**
     * Handles an add collection button click event.
     */
    const handleAddCollectionClick = () => {
        setModelAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            <MenuGroup
                title="Forms"
                showAdd={profile && hasAppPermission(profile, currentUser, Permission.MANAGE_MODELS)}
                onAddClick={handleAddCollectionClick}>

                {/* COLLECTIONS */}
                {profileModels.map(model => (
                    <React.Fragment key={model.key}>
                        {hasCollectionPermission(profile, currentUser, model, Permission.READ, (!currentUser && profilePublicRole?.key) || null) &&
                            <MenuItem
                                key={model.key}
                                title={model.title}
                                icon={DataIcon}
                                isActive={selectedModel && selectedModel.key === model.key}
                                onClick={() => handleCollectionClick(model)} />
                        }
                    </React.Fragment>
                ))}

            </MenuGroup>

            {/* FORM */}
            <CollectionAdd />

        </>
    );
};

export default Collections;
