import React, { useContext, useEffect, useRef, useState } from "react";

// Global
import { Global } from "../../../../Global";

// Device Detection
import { isMobile } from "react-device-detect";

// Utilities
import { ThemeMode } from '../../../utilities/Enums';

// Styles
import "./ObjectTile.css";

// Theme
import { useTheme } from "../../../../ThemeContext";

// Components
import ObjectRow from "../../summaries/summary/ObjectSummaryRow";

/**
 * Object Component
 * 
 * This component renders an object in list view.
 * 
 * @param {object} object - The object containing field values.
 * @param {function} onClick - The function to call onClick.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ObjectTile = ({ object, onClick }) => {

  // Theme
  const { currentThemeMode } = useTheme();

  // Global
  const {
    profileModels,
    setChangesMade,
    setSelectedObject,
  } = useContext(Global);

  // Local State
  const [rows, setRows] = useState([]);
  const containerRef = useRef(null);
  const rowsRef = useRef(null);

  /**
   * Initializes the model.
   */
  useEffect(() => {
    const appCollection = profileModels.find(
      (appCollection) => appCollection.key === object.modelKey
    );

    // Only update state if values differ
    if (appCollection) {
      if (appCollection.rows !== rows) setRows(appCollection.rows);
    }
  }, [object, profileModels, rows]);

  /**
   * Adjusts the container height to match the rows.
   */
  useEffect(() => {
    const adjustHeight = () => {
      if (containerRef.current && rowsRef.current) {
        containerRef.current.style.height = `${rowsRef.current.offsetHeight}px`;
      }
    };

    // Adjust height on component mount and rows update
    adjustHeight();

    // Optional: Add resize observer for dynamic changes
    const resizeObserver = new ResizeObserver(adjustHeight);
    if (rowsRef.current) {
      resizeObserver.observe(rowsRef.current);
    }

    return () => {
      if (rowsRef.current) {
        resizeObserver.unobserve(rowsRef.current);
      }
    };
  }, [rows]);

  /**
   * Handles click on the object.
   */
  const handleClick = async () => {
    // Set the selected object
    setSelectedObject(object);

    // Initialize changes made to false
    setChangesMade(false);
  };

  return (
    <>
      {/* CONTAINER */}
      <div
        ref={containerRef}
        className={isMobile ? "object-tile-container-mobile" : "object-tile-container"}
        key={object.key}
        onClick={onClick ? onClick : handleClick}
      >
        {/* MASK */}
        <div
          className="object-tile-container-mask"
          style={{
            backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
          }}
        ></div>

        {/* ROWS */}
        <div className="object-tile-container-rows" ref={rowsRef}>
          {rows &&
            rows.map((row, index) => (
              <ObjectRow key={row.id} object={object} row={row} />
            ))}
        </div>
        
      </div>
    </>
  );
};

export default ObjectTile;
