import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ModalTags.css';

// Images
import PlusIcon from '../../../../common/svg/PlusIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ModalTags Component
 * 
 * Handles the display, addition, and removal of tags for an item.
 * 
 * @param {Array} initialTags - Initial list of tags.
 * @param {Function} onTagsChange - Callback to notify parent of tag changes.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalTags = ({
    initialTags = [],
    onTagsChange
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [tags, setTags] = useState([]);
    const [newTag, setNewTag] = useState('');

    useEffect(() => {
        setTags(initialTags);
    }, [initialTags]);

    // Handle adding a new tag
    const handleAddTag = () => {
        if (newTag && !tags.includes(newTag)) {
            const updatedTags = [...tags, newTag];
            setTags(updatedTags);
            onTagsChange(updatedTags); // Notify parent of the new tag list
            setNewTag(''); // Clear the input after adding
        }
    };

    // Handle removing a tag
    const handleRemoveTag = (tagToRemove) => {
        const updatedTags = tags.filter(tag => tag !== tagToRemove);
        setTags(updatedTags);
        onTagsChange(updatedTags); // Notify parent of the updated tag list
    };

    return (
        <div>

            {/* TAGS CONTAINER */}
            {tags.length > 0 &&
                <div className="modal-tags-container">
                    {tags.map(tag => (
                        <div
                            key={tag}
                            className="modal-tag-item"
                            style={{
                                backgroundColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor
                            }}>
                            <span className="modal-tag-item-text">{tag}</span>
                            <div
                                onClick={() => handleRemoveTag(tag)}
                                className="modal-remove-tag-button">
                                <RemoveIcon color={theme.foregroundColorFaded} width="14" height="14" />
                            </div>
                        </div>
                    ))}
                </div>
            }

            {/* ADD TAG */}
            <div className="modal-add-tag-section">
                <input
                    type="text"
                    className="modal-new-tag-input"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add a tag"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        borderColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor,
                        fontFamily: profile.fontFamily
                    }}
                />
                <div className="modal-add-tag-button" onClick={handleAddTag}>
                    <PlusIcon color={theme.highlightForegroundColor} width="14" height="14" />
                </div>
            </div>
        </div>
    );
};

export default ModalTags;
