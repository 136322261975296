import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Firebase
import { auth } from '../../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

// Utilities
import { isValidEmail } from '../../../common/utilities/Validators';

// Styles
import './LoginForm.css';

// Components
import LoginButton from '../button/LoginButton';
import LoginError from '../error/LoginError';
import LoginInput from '../input/LoginInput';
import LoginLabel from '../label/LoginLabel';

// Managers
import NotificationManager from '../../managers/NotificationManager';
import ProfileManager from '../../managers/ProfileManager';

const notificationManager = new NotificationManager();
const profileManager = new ProfileManager();

/**
 * LoginForm Component
 * 
 * This component renders a login form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function LoginForm() {
    // Global
    const {
        hideProgress,
        setProfile,
        showProgress,
    } = useContext(Global);

    // Translation
    const { t } = useTranslation('Login');

    // Local State
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [formValid, setFormValid] = useState(false);

    /**
     * Hook to validate the form.
     */
    useEffect(() => {
        setFormValid(
            email.length > 0 &&
            isValidEmail(email) &&
            password.length > 0
        );
    }, [email, password]);

    /**
     * Method to handle submit of the login form.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        // If the form isn't valid, return
        if (!formValid) return;

        let userRecord = null;

        try {
            showProgress(t('progress_logging_in'));

            // Get the user record
            userRecord = await profileManager.getProfileWithEmail(email);

            // Sign in if user record exists
            if (userRecord?.email) {

                // Attempt to sign in to Firebase Authentication
                await signInWithEmailAndPassword(auth, userRecord.email, password);

                // Set the profile
                const fetchedUser = await profileManager.getProfile(auth.currentUser.uid);
                setProfile(fetchedUser);

                // Request notification permission after successful login
                await notificationManager.requestNotificationPermission(fetchedUser);

            } else {

                setErrorMessage(t('error_no_user'));

            }
        } catch (error) {

            setErrorMessage(t('error_failed'));

        } finally {
            hideProgress();
        }
    };

    /**
     * Method to handle email change.
     * 
     * @param {string} value - The current email value.
     */
    const handleEmailChange = (value) => {
        setEmail(value);
        setErrorMessage(null);
    };

    /**
     * Method to handle password change.
     * 
     * @param {string} value - The current password value.
     */
    const handlePasswordChange = (value) => {
        setPassword(value);
        setErrorMessage(null);
    };

    return (

        <div className="login-form-container">

            {/* EMAIL */}
            <div className="login-field-container">

                {/* LABEL */}
                <LoginLabel
                    text={t('Login:label_email')}
                />

                {/* INPUT */}
                <LoginInput
                    type="text"
                    onChange={(e) => handleEmailChange(e.target.value.trim())}
                    required={true}
                />

            </div>

            {/* PASSWORD */}
            <div className="login-field-container">

                {/* LABEL */}
                <LoginLabel
                    text={t('Login:label_password')}
                />

                {/* INPUT */}
                <LoginInput
                    type="password"
                    onChange={(e) => handlePasswordChange(e.target.value.trim())}
                    required={true}
                />

            </div>

            {/* ERROR */}
            {errorMessage && (
                <LoginError
                    text={errorMessage}
                />
            )}

            {/* SUBMIT */}
            <LoginButton
                label={t('Login:button_log_in')}
                enabled={formValid}
                onClick={handleSubmit}
            />

        </div>

    );
}

export default LoginForm;
