import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumPostFormButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ForumPostFormButton = ({ onClick, text }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      profile,
  } = useContext(Global);

  return (
    <div className="forum-post-form-button-container"
      style={{
        backgroundColor: theme.backgroundColorFaded,
        color: theme.foregroundColor,
        fontFamily: profile.fontFamily
      }}
      onClick={onClick}>
      {text}
    </div>
  );
};

export default ForumPostFormButton;
