import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Enums
import { ThemeMode } from '../common/utilities/Enums';

// Styles
import './MobileHeader.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import MenuIcon from '../common/svg/MenuIcon';

// Components
import BackIcon from '../common/svg/BackIcon';
import PromptModal from '../common/prompt/PromptModal';
import ThemeToggle from '../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../common/components/userbutton/UserButton';


const MobileHeader = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    backButtonAction,
    backVisible,
    menuVisible,
    profile,
    setMenuVisible
  } = useContext(Global);

  const [promptOpen, setPromptOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(null);

  useEffect(() => {
    if (!profile) return;

    setLogoSrc(currentThemeMode === ThemeMode.DARK ? profile.logoDark : profile.logoLight);

  }, [profile, currentThemeMode]);

  /**
   * Toggles the menu visibility.
   */
  const toggleMenu = async () => {
    setMenuVisible(!menuVisible);
  };

  /**
   * Handles back button click.
   */
  const handleBack = () => {
    if (backButtonAction) {
      backButtonAction();  // Execute the passed action
    }
  };

  return (
    <>
      <div className="mobile-header-container">

        <div className="mobile-header-left">

          {/* BUTTON */}
          <div className="mobile-header-button"
            onClick={backVisible ? handleBack : toggleMenu}>

            {backVisible ? (

              <BackIcon
                color={theme.foregroundColor}
                width="20"
                height="20"
              />

            ) : (

              <>
                {logoSrc ? (

                  <div className="mobile-header-icon-wrapper">
                    <img
                      src={logoSrc}
                      alt="App Logo"
                      className="mobile-header-icon"
                    />
                  </div>

                ) : (

                  <MenuIcon
                    color={theme.foregroundColor}
                    width="25"
                    height="25"
                  />

                )}
              </>

            )}

          </div>

          {/* APP TITLE */}
          <div
            className="mobile-header-app-title"
            onClick={!backVisible && toggleMenu}
            style={{
              color: theme.foregroundColor,
              fontFamily: profile.fontFamily
            }}>
            {profile.title}
          </div>

        </div>

        <div className="mobile-header-right">

          {/* THEME */}
          <div className="mobile-header-theme-toggle">
            {profile &&
              <ThemeToggle />
            }
          </div>

          <UserButton />
        </div>

      </div>

      {/* PROMPT MODAL */}
      <PromptModal
        isOpen={promptOpen}
        setOpen={setPromptOpen}
      />

    </>
  );
};

export default MobileHeader;
