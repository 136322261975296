import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppSettingsDomain.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const AppSettingsDomain = ({ domain, setDomain, onBlur }) => {
    const { theme } = useTheme();
    const {
        profile
    } = useContext(Global);

    const [currentCount, setCurrentCount] = useState(0);
    const maxCount = 40;

    useEffect(() => {
        if (!profile) return;
        setCurrentCount(domain.length);
    }, [domain, profile]);

    const handleChange = (e) => {
        const text = e.target.value;
        setDomain(text);
        setCurrentCount(text.length);
    };

    const handleBlur = (e) => {
        const text = e.target.value;
        if (onBlur) onBlur(text);
    };

    return (

        <div className="app-settings-domain-container"
            style={{
                width: isMobile ? '100%' : '350px'
            }}>

            {/* LABEL */}
            <div className="app-settings-domain-label"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: profile.fontFamily
                }}>
                Domain
            </div>

            {/* DOMAIN */}
            <div className="app-settings-domain-wrapper">

                <div className="app-settings-domain-prefix"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: profile.fontFamily
                    }}>
                    https://appdeck.ai/
                </div>

                <div className="app-settings-domain-suffix">

                    <input
                        type="text"
                        name="domain"
                        className="app-settings-domain-input"
                        style={{
                            color: theme.foregroundColor,
                            backgroundColor: theme.backgroundColorFaded,
                            borderColor: theme.backgroundColorFaded,
                            fontFamily: profile.fontFamily || 'Open Sans',
                        }}
                        maxLength={maxCount}
                        value={domain}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="domain"
                    />

                    <div
                        className="app-settings-domain-count"
                        style={{
                            color: theme.foregroundColorFaded,
                            fontFamily: profile.fontFamily
                        }}>
                        {`${currentCount}/${maxCount}`}
                    </div>

                </div>

            </div>

        </div>

    );
};

export default AppSettingsDomain;
