import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Formatter
import { formatCurrency } from '../../../../common/utilities/Formatters';

// Styles
import './Currency.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Currency = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile
    } = useContext(Global);

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value

    // References
    const inputRef = useRef(null);

    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    // Focus the input when the container is clicked
    const handleContainerClick = (e) => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleBlur = () => {
        // Apply more specific formatting on blur
        const formattedValue = formatCurrency(value);
        setValue(formattedValue);
        onUpdate(field, formattedValue);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        // Light formatting to remove non-numeric characters, allowing for decimal points
        const formattedValue = value.replace(/[^\d.]/g, '');
        setValue(formattedValue);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}
                onClick={handleContainerClick}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                />

                {/* INPUT */}
                <input
                    type="text"
                    ref={inputRef}
                    className="currency-input"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: profile.fontFamily,
                    }}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

            </FieldContainer>
        </>
    );
};

export default Currency;
