import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountEmail.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import ProfileManager from '../../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * AccountEmail Component
 * 
 * This component renders an input for a new user's email address.
 * 
 * @param {function} onChange - The function to call with updated values.
 * @param {string} defaultValue - The default email value.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountEmail = ({ onChange, defaultValue = '' }) => {
  const { theme } = useTheme();
  const { profile, coreTheme } = useContext(Global);

  // Local State
  const [emailError, setEmailError] = useState('');
  const [value, setValue] = useState('');

  /**
   * Initializes the field value.
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * Method to validate email format.
   * @param {string} email - Email to validate.
   * @returns {boolean} - Whether the email is valid.
   */
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation
    return emailRegex.test(email);
  };

  /**
   * Method to handle email input changes.
   * 
   * @param {Event} e - Change event
   */
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setValue(email); // Update state for controlled input
    onChange(email, false); // Notify parent of change (but not validity)
  };

  /**
   * Method to handle email validation onBlur.
   * 
   * @param {Event} e - Blur event
   */
  const handleEmailBlur = async (e) => {
    const email = e.target.value.trim(); // Remove extra spaces

    if (email.length === 0) return;

    // Validate email presence
    if (!email) {
      setEmailError('A valid email address is required.');
      onChange(email, false); // Notify parent of invalid email
      return;
    }

    // Validate email format
    if (!isValidEmail(email)) {
      setEmailError('Please enter a valid email address.');
      onChange(email, false); // Notify parent of invalid email
      return;
    }

    // Check if the email already exists asynchronously
    const exists = await profileManager.emailExists(email);
    if (exists) {
      setEmailError('An account with this email already exists.');
      onChange(email, false); // Notify parent of invalid email
      return;
    }

    // If the email is valid and doesn't exist, clear the error
    setEmailError('');
    onChange(email, true); // Notify parent of valid email
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="account-email-container">

        {/* EMAIL LABEL */}
        <div
          className="account-email-label"
          style={{
            color: profile ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
          }}>
          Email Address
        </div>

        {/* EMAIL INPUT */}
        <input
          type="email"
          className="account-email-input"
          style={{
            color: profile ? theme.foregroundColor : coreTheme.foregroundColor,
            backgroundColor: profile ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded,
          }}
          value={value} // Controlled input value from state
          onChange={handleEmailChange} // Update state and notify parent
          onBlur={handleEmailBlur} // Perform validation onBlur
          required
        />

        {/* ERROR MESSAGE */}
        {emailError && (
          <div
            className="account-email-error"
            style={{
              color: profile ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor,
            }}>
            {emailError}
          </div>
        )}
      </div>
    </>
  );
};

export default AccountEmail;
