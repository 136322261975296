import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

/**
 * Custom hook to keep track of all space items in memory:
 * - Folders
 * - Files
 * - Events
 * - Stories
 * - Objects
 */
const usePreloadProfileItems = () => {

  // Global
  const {
    setProfileItems,
    profileEvents,
    profileFiles,
    profileFolders,
    profileObjects,
    profileStories,
  } = useContext(Global);

  useEffect(() => {

    const combinedItems = [
      ...profileFolders,
      ...profileFiles,
      ...profileEvents,
      ...profileObjects,
      ...profileStories
    ];

    setProfileItems(combinedItems);

  }, [profileFolders, profileFiles, profileEvents, profileObjects, profileStories, setProfileItems]);

};

export default usePreloadProfileItems;
