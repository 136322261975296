import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Styles
import './NewsfeedEdit.css';

// Components
import NewsfeedTags from '../tags/NewsfeedTags';
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalInput from '../../components/modal/input/ModalInput';
import ModalLabel from '../../components/modal/label/ModalLabel';
import ModalSection from '../../components/modal/section/ModalSection';
import ModalTextarea from '../../components/modal/textarea/ModalTextarea';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * NewsfeedEdit Component
 * 
 * This component allows the user to edit a newsfeed.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NewsfeedEdit = ({ newsfeed, isOpen, setOpen }) => {

    // Global
    const {
        profile,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    // Load the newsfeed attributes
    useEffect(() => {
        if (!newsfeed) return;
        setTitle(newsfeed.title);
        setDescription(newsfeed.description || '');
        setTags(newsfeed.tags || []);
    }, [newsfeed]);

    const handleSave = async () => {

        const data = {
            title: title,
            description: description,
            tags: tags
        };

        await dataManager.update(collections.newsfeeds, profile.key, newsfeed.key, data);

        setOpen(false);
    };

    if (!newsfeed) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Newsfeed Settings"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Newsfeed Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <NewsfeedTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default NewsfeedEdit;
