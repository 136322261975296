import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { MenuType, Section, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import ProfileBar from '../common/components/profilebar/ProfileBar.js';
import AppSettings from '../common/appsettings/AppSettings';
import AppWelcome from '../common/components/appwelcome/AppWelcome';
import Members from '../common/members/Members';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channel/chat/Chat';
import Favorites from '../common/favorites/Favorites';
import Folder from '../common/folder/Folder';
import Forum from '../common/channel/forum/Forum';
import ForYou from '../common/foryou/ForYou';
import Menu from '../common/menu/Menu';
import MenuBar from '../common/menu/menubar/MenuBar';
import Messages from '../common/messages/Messages';
import Newsfeed from '../common/newsfeed/Newsfeed';
import Notifications from '../common/notifications/Notifications';
import Objects from '../common/collection/Objects';
import TitleBar from '../common/titlebar/TitleBar';
import VideoChat from '../common/channel/videochat/VideoChat';

// Dynamic Styles
import { DesktopStyles } from './DesktopStyles';

// Images
const gradient = 'https://appdeck.ai/wallpapers/gradient.jpg';

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    accountAboutVisible,
    profile,
    currentSection,
    menuType,
  } = useContext(Global);

  const renderContent = () => {

    if (currentSection === Section.BOOKMARKS) return <Bookmarks />;
    if (currentSection === Section.CALENDARS) return <Calendar />;
    if (currentSection === Section.CHAT) return <Chat />;
    if (currentSection === Section.FAVORITES) return <Favorites />;
    if (currentSection === Section.FOLDERS) return <Folder />;
    if (currentSection === Section.FORUM) return <Forum />;
    if (currentSection === Section.FORYOU) return <ForYou />;
    if (currentSection === Section.MEMBERS) return <Members />;
    if (currentSection === Section.MESSAGES) return <Messages />;
    if (currentSection === Section.NEWSFEEDS) return <Newsfeed />;
    if (currentSection === Section.NOTIFICATIONS) return <Notifications />;
    if (currentSection === Section.SETTINGS) return <AppSettings />;
    if (currentSection === Section.VIDEOCHAT) return <VideoChat />;
    if (currentSection === Section.WELCOME) return <AppWelcome />;

    if (currentSection === Section.ACCOUNT) return (
      <>
        <AccountMenu />
        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
      </>
    );

    return (
      <>
        {/* OBJECTS */}
        <Objects />
      </>
    );
  };

  useEffect(() => {
    if (!profile) return;
    loadFont(profile.fontFamily || defaultFont);
  }, [profile]);

  // Verify profile
  if (!profile) return null;

  return (
    <>
      <DesktopStyles
        fontFamily={(profile && profile.fontFamily) || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: profile
            ? currentThemeMode === ThemeMode.DARK
              ? `url(${profile.wallpaperDark || 'none'})`
              : `url(${profile.wallpaperLight || 'none'})`
            : `url(${gradient})`, // Fallback if profile is null
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>

        {/* TITLE BAR */}
        {profile && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}

        <div className="desktop-main">

          {/* PROFILE BAR */}
          <div className="desktop-left">
            <ProfileBar />
          </div>

          <div className="desktop-right">

            <div className="desktop-middle"
              style={{
                height: menuType === MenuType.LEFT ? "calc(100vh - 50px)" : "calc(100vh - 100px)"
              }}>

              {/* MENU */}
              <div
                className={`desktop-menu-wrapper ${menuType === MenuType.LEFT ? 'menu-visible' : 'menu-hidden'}`}>
                <Menu />
              </div>

              {/* CONTENT */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
