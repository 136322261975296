import React from 'react';

// Styles
import './TileListElement.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const TileListElement = ({ element, icon: IconComponent, title, useDrag }) => {

  // Theme
  const { theme } = useTheme();

  const [, drag] = useDrag({
    type: 'ELEMENT',
    item: { element },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      {/* CONTAINER */}
      <div ref={drag}
        className="tile-list-element-container"
        title={title}>

        {/* ICON */}
        <div className="tile-list-element-icon">
          <IconComponent
            color={theme.foregroundColor}
            width="14"
            height="14"
          />
        </div>

        {/* TITLE */}
        <div className="tile-list-element-title"
          style={{
            color: theme.foregroundColor,
          }}>
          {title}
        </div>

      </div>
    </>
  );

};

export default TileListElement;
