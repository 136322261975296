import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppSettingsTitle.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const AppSettingsTitle = ({title, setTitle, onBlur}) => {
    const { theme } = useTheme();
    const {
        profile
    } = useContext(Global);

    const [currentCount, setCurrentCount] = useState(0);
    const maxCount = 40;

    useEffect(() => {
        if (!profile) return;
        setCurrentCount(title.length);
    }, [title, profile]);

    const handleChange = (e) => {
        const text = e.target.value;
        setTitle(text);
        setCurrentCount(text.length);
    };

    const handleBlur = (e) => {
        const text = e.target.value;
        if (onBlur) onBlur(text);
    };

    return (

        <div className="app-settings-title-container"
            style={{
                width: isMobile ? '100%' : '350px'
            }}>
            <div 
                className="app-settings-title-label" 
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: profile.fontFamily
                }}>
                Title
            </div>
            <input
                type="text"
                name="title"
                className="app-settings-title-input"
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    fontFamily: profile.fontFamily || 'Open Sans',
                }}
                maxLength={maxCount}
                value={title}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Title"
            />
            <div 
                className="app-settings-title-count" 
                style={{ 
                    color: theme.foregroundColorFaded, 
                    fontFamily: profile.fontFamily
                }}>
                {`${currentCount}/${maxCount}`}
            </div>
        </div>

    );
};

export default AppSettingsTitle;
