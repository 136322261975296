import React, { useContext, useEffect, useState } from 'react';

// Translations
import i18n from './i18n';

// Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Custom Hooks
import usePreloadUserNotifications from './main/hooks/PreloadUserNotifications.js';
import usePreloadUserApps from './main/hooks/PreloadUserApps.js';

// Utilities
import { Screen } from './common/utilities/Enums';

// Navigation
import { useNavigate } from 'react-router-dom';

// Contexts
import { UserProvider } from './UserContext';
import { ThemeProvider } from './ThemeContext';
import { GlobalProvider, Global } from './Global';

// Components
import Root from './root/Root';

// Styles
import './App.css';

// Managers
import AppManager from './common/managers/AppManager';
import MemberManager from './common/managers/MemberManager';

const appManager = new AppManager();
const memberManager = new MemberManager();

function App() {

  i18n.changeLanguage("en");

  return (
    <GlobalProvider>
      <UserProvider>
        <Router>
          <div className="App-root">
            <ThemeConsumer>
              <Routes>
                <Route path="/" element={<Root />} />
                <Route path="*" element={<Root />} />
              </Routes>
            </ThemeConsumer>
          </div>
        </Router>
      </UserProvider>
    </GlobalProvider>
  );
}

// Provides the theme context based on the current app
const ThemeConsumer = ({ children }) => {
  const { profile } = useContext(Global);

  return (
    <ThemeProvider profile={profile}>
      {children}
    </ThemeProvider>
  );
};

export default App;
