import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Firebase
import { collections } from '../../../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../../../../common/utilities/Keys';

// Styles
import './FavoriteButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import FavoriteIcon from '../../../../../../common/svg/FavoriteIcon';
import FavoriteOnIcon from '../../../../../../common/svg/FavoriteOnIcon';

// Managers
import DataManager from '../../../../../../common/managers/DataManager';

const dataManager = new DataManager();

const FavoriteButton = ({ element, object }) => {
    const { theme } = useTheme();
    const {
        memberFavorites,
        currentUser,
        profile,
    } = useContext(Global);

    // Local State
    const [selected, setSelected] = useState(false);

    /** 
     * Checks if the user has selected the element.
     */
    useEffect(() => {

        const favorite = memberFavorites.find(b =>
            b.userKey === currentUser.userKey &&
            b.objectKey === object.key);

        setSelected(favorite && favorite !== undefined);

    }, [memberFavorites, object.key, currentUser.userKey]);

    /**
     * Handles the click event for the favorite button.
     * @param {event} event 
     */
    const handleClick = async (event) => {
        event.stopPropagation();

        const newValue = !selected;

        setSelected(newValue);

        // Update the favorite
        if (newValue) {

            const key = generateKey();

            const data = {
                profileKey: profile.key,
                userKey: currentUser.userKey,
                objectKey: object.key,
                object: object
            };

            // Add
            await dataManager.add(
                collections.favorites,
                profile.key,
                key,
                data
            );

        } else {

            // Delete the favorite with filters (profileKey, userKey, objectKey)
            const params = [
                { field: 'profileKey', operator: '==', value: profile.key },
                { field: 'userKey', operator: '==', value: currentUser.userKey },
                { field: 'objectKey', operator: '==', value: object.key }
            ];

            // Delete
            const result = await dataManager.delete(
                collections.favorites,
                profile.key,
                null,
                params
            );

            // Handle the result
            if (!result.success) {
                console.error(result.message);
            }

        }
    };

    return (
        <div className="favorite-button-container"
            title={selected ? "Remove Favorite" : "Add Favorite"}
            onClick={handleClick}>
            {selected ? (
                <FavoriteOnIcon
                    color={theme.highlightBackgroundColor}
                    width="19"
                    height="19"
                />
            ) : (
                <FavoriteIcon
                    color={theme.foregroundColorFaded}
                    width="19"
                    height="19"
                />
            )}
        </div>
    );
};

export default FavoriteButton;
