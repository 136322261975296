import React from 'react';

const TableIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31ZM450-371.92H200v159.61q0 5.39 3.46 8.85t8.85 3.46H450v-171.92Zm60 0V-200h237.69q5.39 0 8.85-3.46t3.46-8.85v-159.61H510Zm-60-60v-172.7H200v172.7h250Zm60 0h250v-172.7H510v172.7ZM200-664.61h560v-83.08q0-5.39-3.46-8.85t-8.85-3.46H212.31q-5.39 0-8.85 3.46t-3.46 8.85v83.08Z" />
  </svg>

);

export default TableIcon;
