import React, { useEffect, useState } from 'react';

// Styles
import './Year.css';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import Select from '../../../../common/components/select/Select';

const Year = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value
    const [options, setOptions] = useState([]);

    /**
     * Initialize the field value and sets the options list.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');

        // Generate a list of years from 1900 to 20 years into the future
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 1900; year <= currentYear + 20; year++) {
            years.push({ value: year, label: year });
        }
        setOptions(years);

    }, [object, field.key]);

    /**
     * Method to handle the change in value.
     * 
     * @param {string} val - New value.
     */
    const handleChange = (val) => {
        setValue(val);
        onUpdate(field, val);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    showFieldMenu={showFieldMenu}
                />

                {/* INPUT */}
                <Select
                    options={options}
                    value={value}
                    onChange={(value) => handleChange(value)}
                />

            </FieldContainer>
        </>
    );
};

export default Year;
