import React from 'react';

// Styles
import './GridItemFooter.css';

// Images
import CommentIcon from '../../../../svg/CommentIcon';
import FavoriteIcon from '../../../../svg/FavoriteIcon';
import GridItemButton from '../button/GridItemButton';

/**
 * GridItemFooter
 * 
 * Footer for a grid item.
 * 
 * @param {Boolean} selected - Whether the item is selected.
 * @param {Number} comments - Number of comments.
 * @param {Number} favorites - Number of favorites.
 * 
 * @returns {JSX.Element}
 */
const GridItemFooter = ({ selected = false, comments = 0, favorites = 0 }) => {

    return (

        <div className="grid-item-footer-container">

            {/* COMMENTS */}
            <GridItemButton
                icon={CommentIcon}
                text={`${comments} Comments`}
                selected={selected}
            />

            {/* FAVORITES */}
            <GridItemButton
                icon={FavoriteIcon}
                text={`${favorites} Favorites`}
                selected={selected}
            />

        </div>

    );
};

export default GridItemFooter;
