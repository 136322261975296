import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { CalendarType } from '../utilities/Enums';

// Styles
import './Calendar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import CalendarAll from './CalendarAll';
import CalendarCollection from './views/collection/CalendarCollection';
import CalendarDay from './views/day/CalendarDay';
import CalendarHeader from './header/CalendarHeader';
import CalendarMonth from './views/month/CalendarMonth';
import CalendarSchedule from './views/schedule/CalendarSchedule';
import CalendarStoryline from './views/storyline/CalendarStoryline';
import CalendarWeek from './views/week/CalendarWeek';
import EventForm from './event/EventForm';
import TagSelector from '../../common/components/tagselector/TagSelector';

const Calendar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedCalendarType,
        selectedTime,
    } = useContext(Global);

    return (
        <div className="calendar-container"
            style={{
                borderLeftColor: theme.backgroundColorFaded,
            }}>

            {/* HEADER */}
            <CalendarHeader />

            {/* TAGS 
            {selectedCalendar && selectedCalendar.tags && selectedCalendar.tags.length > 0 && (
                <div className="calendar-tag-selector">
                    <TagSelector 
                        tags={(selectedCalendar && selectedCalendar.tags) || []}
                        selectedTags={selectedCalendarTags}
                        onTagClick={setSelectedCalendarTags}
                        nowrap={true}
                    />
                </div>
            )}
                */}

            {/* SCHEDULE */}
            {selectedCalendarType === CalendarType.SCHEDULE && (
                <CalendarSchedule />
            )}

            {/* STORYLINE */}
            {selectedCalendarType === CalendarType.STORYLINE && (
                <>
                    <CalendarStoryline /> 
                </>
            )}

            {/* COLLECTION */}
            {selectedCalendarType === CalendarType.COLLECTION && (
                <CalendarCollection />
            )}

            {/* ALL */}
            {selectedCalendarType === CalendarType.ALL && (
                <CalendarAll />
            )}

            {/* MONTH */}
            {selectedCalendarType === CalendarType.MONTH && (
                <CalendarMonth />
            )}

            {/* WEEK */}
            {selectedCalendarType === CalendarType.WEEK && (
                <CalendarWeek />
            )}

            {/* DAY */}
            {selectedCalendarType === CalendarType.DAY && (
                <CalendarDay />
            )}
            
            {/* FORM */}
            <EventForm
                date={selectedTime}
            />

        </div>
    );
};

export default Calendar;
