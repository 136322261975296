import React from 'react';

// Config
import { imageFileTypes } from '../../../../Config';

// Utilities
import { truncateFileName } from '../../../utilities/Strings';
import { formatFileSize } from '../../../utilities/Formatters';

// Styles
import './FileGridItem.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PdfIcon from '../../../svg/PdfIcon';

// Components
import GridItem from '../../../components/grid/griditem/GridItem';
import GridItemField from '../../../components/grid/griditem/field/GridItemField';
import GridItemPrivacy from '../../../components/grid/griditem/privacy/GridItemPrivacy';
import GridItemTitle from '../../../components/grid/griditem/title/GridItemTitle';

/**
 * FileGridItem
 * 
 * A grid item for displaying files.
 * 
 * @param {Object} item The file item.
 * @param {Boolean} selected If the item is selected.
 * @param {Function} onClick The click event handler.
 * @param {Function} onDoubleClick The double click event handler.
 * @param {Function} onDragStart The drag start event handler.
 * 
 * @returns {JSX.Element} FileGridItem component.
 */
const FileGridItem = ({ item, selected = false, onClick, onDoubleClick, onDragStart }) => {

    // Theme
    const { theme } = useTheme();

    const isImageFile = imageFileTypes.includes(item.extension.toLowerCase());

    return (

        <GridItem
            selected={selected}
            shared={item.shared}
            onClick={(event) => onClick(event, item)}
            onDoubleClick={() => onDoubleClick(item.link)}
            onDragStart={(event) => onDragStart(event, item)}>

            <div
                className="file-grid-item-container"
                title={item.title}>

                {/* PREVIEW */}
                {isImageFile && (
                    <div className="file-grid-item-image-wrapper">
                        <img
                            src={item.url}
                            alt={item.title}
                            className="file-grid-item-image"
                        />
                    </div>
                )}

                {/* PDF */}
                {item.extension === 'pdf' && (
                    <div className="file-grid-item-icon-container"
                        style={{
                            backgroundColor: theme.backgroundColorFaded,
                        }}>
                        <PdfIcon
                            color={theme.highlightBackgroundColor}
                            width="70"
                            height="70"
                        />
                    </div>
                )}

                {/* PRIVACY */}
                <GridItemPrivacy
                    selected={selected}
                    shared={item.shared}
                    label="Private"
                />

                {/* TITLE */}
                <GridItemTitle
                    title={truncateFileName(item.title, 20)}
                    selected={selected}
                />

                {/* FILE SIZE */}
                <GridItemField
                    value={formatFileSize(item.size)}
                    selected={selected}
                />

            </div>

        </GridItem>
    );
};

export default FileGridItem;
