import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../../../Global';

// Styles
import './ChatPostFormObject.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CubeIcon from '../../../../../svg/CubeIcon';

const ChatPostFormObject = ({ object }) => {
  const { theme } = useTheme();
  const {
      profileModels
  } = useContext(Global);

  // Local State
  const [objectTitle, setObjectTitle] = useState('');

  useEffect(() => {
    if (!object) return;
    const appCollection = profileModels.find(appCollection => appCollection.key === object.modelKey);
    const title = object[appCollection.titleFieldKey];

    setObjectTitle(title);
  }, [profileModels, object]);

  return (
    <div className="chat-post-form-object-container"
      style={{
        backgroundColor: theme.backgroundColorFaded
      }}>
      <CubeIcon
        color={theme.highlightBackgroundColor}
        width="14"
        height="14"
      />
      <div
        className="chat-post-form-object-objectname"
        style={{
          color: theme.foregroundColor
        }}>
        {objectTitle}
      </div>
    </div>
  );
};

export default ChatPostFormObject;
