import React from 'react';

// Styles
import './ConditionList.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * ConditionList Component
 * 
 * This component renders a list of conditions for a permission crud item.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ConditionList = ({ model, type, isChecked }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="condition-list-container"
            style={{
                color: theme.foregroundColor
            }}>
            <div className="condition-list-condition">
                {isChecked ? '' : 'NOT'} ALLOWED to {type} {model.title.toLowerCase()}.
            </div>
        </div>
    );
};

export default ConditionList;
