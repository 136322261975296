export const Screen = {
    SPLASH: 'SPLASH',
    MAIN: 'MAIN',
    WELCOME: 'WELCOME',
    RUN: 'RUN',
    PUBLIC: 'PUBLIC',
};

export const Section = {
    ACCOUNT: 'ACCOUNT',
    BOOKMARKS: 'BOOKMARKS',
    CALENDARS: 'CALENDARS',
    CHAT: 'CHAT',
    COLLECTIONS: 'COLLECTIONS',
    FAVORITES: 'FAVORITES',
    FOLDERS: 'FOLDERS',
    FORUM: 'FORUM',
    FORYOU: 'FORYOU',
    MEMBERS: 'MEMBERS',
    MESSAGES: 'MESSAGES',
    NEWSFEEDS: 'NEWSFEEDS',
    NEWSFEEDS: 'NEWSFEEDS',
    NOTIFICATIONS: 'NOTIFICATIONS',
    SETTINGS: 'SETTINGS',
    SPACES: 'SPACES',
    VIDEOCHAT: 'VIDEOCHAT',
    WELCOME: 'WELCOME',
};

export const ThemeMode = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
};

export const ItemType = {
    FOLDER: 'FOLDER',
    FILE: 'FILE',
    EVENT: 'EVENT',
    OBJECT: 'OBJECT',
    STORY: 'STORY',
};

export const CalendarType = {
    COLLECTION: 'COLLECTION',
    ALL: 'ALL',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
    DAY: 'DAY',
    SCHEDULE: 'SCHEDULE',
    STORYLINE: 'STORYLINE',
};

export const ChannelType = {
    TEXT: 'TEXT',
    FORUM: 'FORUM',
    VOICE: 'VOICE',
    VIDEO: 'VIDEO',
};

export const MemberThumbDirection = {
    LTR: 'LTR',
    RTL: 'RTL',
};

export const LoginMode = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP'
};

export const MenuType = {
    LEFT: 'LEFT',
    TOP: 'TOP',
};

export const PostMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const MenuSection = {
    CALENDARS: 'CALENDARS',
    CHANNELS: 'CHANNELS',
    COLLECTIONS: 'COLLECTIONS',
    FOLDERS: 'FOLDERS',
    NEWSFEEDS: 'NEWSFEEDS',
    SETTINGS: 'SETTINGS',
    SPACES: 'SPACES',
};

export const ObjectsView = {
    TILES: 'TILES',
    GRID: 'GRID',
    TABLE: 'TABLE',
};

export const Permission = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    MANAGE_CHANNELS: 'MANAGE_CHANNELS',
    MANAGE_CALENDARS: 'MANAGE_CALENDARS',
    MANAGE_MODELS: 'MANAGE_MODELS',
    MANAGE_FOLDERS: 'MANAGE_FOLDERS',
    MANAGE_NEWSFEEDS: 'MANAGE_NEWSFEEDS',
};