import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './ChatUsers.css';

// Components
import ChatUser from './ChatUser';

const ChatUsers = () => {
  const {
    profileMembers
  } = useContext(Global);

  return (
    <div className="chat-users-container">

      {profileMembers.map((member) => (
        <ChatUser
          key={member.key}
          member={member}
        />
      ))}

    </div>
  );
};

export default ChatUsers;
