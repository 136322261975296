import { useEffect, useContext, useRef } from 'react';

// Global
import { Global } from '../../Global';

// Managers
import AppManager from '../../common/managers/AppManager';

const appManager = new AppManager();

/**
 * Custom hook to load userSpaces for a specific user.
 */
const usePreloadUserApps = () => {
  // Global
  const {
    currentUser,
    setUserSpaces,
  } = useContext(Global);

  // Ref to track whether userSpaces have been initialized
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!currentUser) {
      setUserSpaces([]);
      isInitialized.current = false; // Reset initialization flag
      return;
    }

    // Prevent loading userSpaces if already initialized
    if (isInitialized.current) {
      return;
    }

    // Function to load userSpaces
    const loadApps = () => {
      const handleUpdate = (items) => {
        setUserSpaces(items);
      };

      const unsubscribe = appManager.listUserAppsAndSubscribe(
        currentUser.userKey,
        handleUpdate
      );

      return unsubscribe;
    };

    // Subscribe to userSpaces
    const unsubscribeApps = loadApps();
    isInitialized.current = true; // Mark as initialized

    // Cleanup when component unmounts or dependencies change
    return () => {
      if (unsubscribeApps) {
        unsubscribeApps();
      }
    };
  }, [currentUser, setUserSpaces]); // userSpaces is intentionally excluded
};

export default usePreloadUserApps;
