import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { Section } from '../../../utilities/Enums';

// Styles
import './NewMember.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const NewMember = ({ notification }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profileMembers,
    setCurrentSection,
    setSelectedMember,
  } = useContext(Global);

  /**
   * Sets the notification as read when clicked.
   */
  const handleClick = async () => {

      const memberKey = notification.memberKey;
      const member = profileMembers.find(member => member.userKey === memberKey);
      setSelectedMember(member);
      setCurrentSection(Section.MEMBERS)

  }

  return (

    <div className="notification-container"
      onClick={handleClick}
      style={{
        borderBottomColor: theme.backgroundColorFaded,
      }}>

      {/* TITLE */}
      <div className="notification-title"
        style={{
          color: theme.foregroundColor,
        }}>
        {notification.title}
      </div>

      {/* DESCRIPTION */}
      <div className="notification-description"
        style={{
          color: theme.foregroundColorFaded,
        }}>
        {notification.description}
      </div>

    </div>

  );
};

export default NewMember;
