import React from 'react';

// Styles
import './CalendarCollectionHour.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * CalendarCollectionHour Component
 * 
 * This component renders a column of hours in a daily calendar.
 * 
 * @param {integer} hour - The hour of the day to display.
 * @param {integer} index - The index of the hour in the day.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarCollectionHour = ({ hour }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="calendar-collection-hour-container">

            {/* LABEL */}
            <div className="calendar-collection-hour-label"
                style={{
                    color: theme.foregroundColor,
                }}>
                {hour}
            </div>
            
        </div>
    );
};

export default CalendarCollectionHour;