import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { MenuType } from '../../common/utilities/Enums';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Menu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ProfileBar from '../components/profilebar/ProfileBar';
import MenuHeader from './MenuHeader';
import MenuItems from './MenuItems';

const Menu = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        menuType,
    } = useContext(Global);

    return (
        <div className={isMobile ? "menu-container-mobile" : "menu-container"}
            style={{
                borderRightColor: theme.backgroundColor,
                height: isMobile ? 'calc(100vh - 56px)' : '100%',
            }}>

            {/* APP BAR */}
            {currentUser && isMobile &&
                <ProfileBar />
            }

            <div className="menu-right">

                {/* HEADER */}
                {(!isMobile && menuType === MenuType.LEFT) &&
                    <MenuHeader />
                }

                {/* MENU ITEMS */}
                <MenuItems />

            </div>

        </div>
    );
};

export default Menu;
