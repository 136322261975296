import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './TimePreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const TimePreview = ({
    placeholder = '',
    selected = false,
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="time-preview-container"
            style={{ border: borderStyle, backgroundColor: theme.backgroundColorFaded }}>
            <div className="time-preview-label"
                style={{ color: theme.foregroundColorFaded }}
            >
                {placeholder}
            </div>
            <select
                className="time-preview-input"
                disabled
                style={{
                    color: theme.foregroundColor,
                    fontFamily: profile.fontFamily,
                }}
                tabIndex={-1}>
                <option value="EXAMPLE">10:00 PM</option>
            </select>
        </div>
    );
};

export default TimePreview;
