import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// ReactDOM
import ReactDOM from 'react-dom';

// Utilities
import { MenuSection } from '../../utilities/Enums';

// Styles
import './MenuBarButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ChevronDownIcon from '../../svg/ChevronDownIcon';

// Components
import MenuBarAddButton from './MenuBarAddButton';
import MenuBarButtonOption from './MenuBarButtonOption';

/**
 * MenuBarButton Component
 * 
 * This component represents a button with a dropdown menu, allowing the user to select
 * from a list of options. 
 * 
 * @returns {JSX.Element} The rendered component
 */
const MenuBarButton = ({ section, title, options, onClick, onSelect, onAdd, showAddButton = true, }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        setSelectedChannel,
        setSelectedModel,
        setChannelAddOpen,
        setModelAddOpen
    } = useContext(Global);

    // Local State
    const [optionsVisible, setOptionsVisible] = useState(false);

    // References
    const optionsRef = useRef(null);
    const buttonRef = useRef(null);

    /**
     * Toggles the visibility of the options dropdown
     */
    const handleClick = () => {
        setOptionsVisible(!optionsVisible);
        if (onClick) {
            onClick();
        }
    };

    /**
     * Determines the appropriate icon based on the section and option type
     * 
     * @param {object} option - The option item to get the icon for
     * @returns {JSX.Element|null} The icon component or null if no match
     */
    const getIcon = (option) => {
        if (section === MenuSection.SETTINGS) {
            if (option.icon) {
                return option.icon;
            } else {
                return null;
            }
        }
        return null;
    };

    /**
     * Closes the dropdown if clicking outside the dropdown or button area
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                optionsRef.current && !optionsRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)
            ) {
                setOptionsVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    /**
     * Handles click on an add button.
     */
    const handleAddClick = () => {
        switch (section) {
            case MenuSection.CALENDARS:
                setSelectedChannel(null);
                setSelectedModel(null);
                break;
            case MenuSection.CHANNELS:
                setChannelAddOpen(true);
                break;
            case MenuSection.COLLECTIONS:
                setModelAddOpen(true);
                break;
            case MenuSection.FOLDERS:
                onAdd(MenuSection.FOLDERS);
                break;
            default:
                break;
        }

        // Hide the options
        setOptionsVisible(false);
    };

    /**
     * Renders dropdown options, with hover and click functionality
     */
    const dropDownOptions = (
        <div
            ref={optionsRef}
            className="menu-bar-button-options"
            style={{
                backgroundColor: theme.backgroundColorFaded,
                position: 'absolute',
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : 0
            }}>
            {options && options.map((option, index) => {
                const IconComponent = getIcon(option);
                return (
                    <MenuBarButtonOption
                        key={index}
                        option={option}
                        icon={IconComponent}
                        onSelect={() => {
                            onSelect(option);
                            setOptionsVisible(false);
                        }}
                    />
                );
            })}

            {showAddButton && (
                <MenuBarAddButton
                    onClick={handleAddClick}
                />
            )}
        </div>
    );

    return (
        <div ref={buttonRef}
            className="menu-bar-button-container"
            onClick={handleClick}>
            <div
                className="menu-bar-button-text"
                style={{
                    color: theme.foregroundColor
                }}>
                {title}
            </div>
            {options &&
                <>
                    <div className="menu-bar-button-icon">
                        <ChevronDownIcon
                            color={theme.foregroundColor}
                            width="14"
                            height="14"
                        />
                    </div>
                    {optionsVisible &&
                        ReactDOM.createPortal(
                            dropDownOptions,
                            document.getElementById('portal-root')
                        )
                    }
                </>
            }
        </div>
    );
};

export default MenuBarButton;
