import React from 'react';

const ArchiveIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="m480-240 160-160-56-56-64 64v-168h-80v168l-64-64-56 56 160 160ZM200-640v440h560v-440H200Zm0 520q-33 0-56.5-23.5T120-200v-499q0-14 4.5-27t13.5-24l50-61q11-14 27.5-21.5T250-840h460q18 0 34.5 7.5T772-811l50 61q9 11 13.5 24t4.5 27v499q0 33-23.5 56.5T760-120H200Zm16-600h528l-34-40H250l-34 40Zm264 300Z" />
  </svg>

);

export default ArchiveIcon;
