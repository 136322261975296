import React, { useState } from 'react';

// Styles
import './ToolbarButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const ToolbarButton = ({ icon: IconComponent, title, onClick, selected = false }) => {
    
    // Theme
    const { theme } = useTheme();

    // Local State
    const [isHovered, setIsHovered] = useState(false);

    // Determine dynamic styles based on hover and selection state
    const backgroundColor = isHovered ? theme.highlightBackgroundColor : 'transparent';
    const iconColor = isHovered
        ? theme.highlightForegroundColor
        : selected
        ? theme.foregroundColor
        : theme.foregroundColorFaded;

    return (
        <div
            className="toolbar-button-container"
            title={title}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                backgroundColor,
            }}>
            <IconComponent
                color={iconColor} // Dynamically styled based on hover/selection state
                width="22"
                height="22"
            />
        </div>
    );
};

export default ToolbarButton;
