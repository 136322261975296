import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Styles
import './ChannelDelete.css';

// Managers
import { activity } from '../../managers/ActivityManager';

// Components
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/buttons/ModalButton';
import ModalButtons from '../../components/modal/buttons/ModalButtons';
import ModalMessage from '../../components/modal/message/ModalMessage';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

/**
 * ChannelDelete Component
 * 
 * This component confirms a delete prior to deleting the channel.
 * 
 * @param {object} channel - The channel object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelDelete = ({ channel, isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        resetSelections,
        profile,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the channel.
     */
    const handleDelete = async () => {

        showProgress("Deleting channel...");

        try {

            await dataManager.delete(
                collections.channels, 
                profile.key, 
                channel.key
            );

            resetSelections();

            // Log the activity
            activity.log(profile.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting channel: ", error);
        }

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Channel"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={400}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="This will permanently delete all messages in this channel. Continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default ChannelDelete;
