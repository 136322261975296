import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './AccountMenuToolbar.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AccountMenuToolbar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    return (

        <div className="account-menu-toolbar-container">
            <div className="account-menu-toolbar-left"
                style={{ 
                    color: theme.foregroundColorFaded,
                    fontFamily: profile && profile.fontFamily,
                }}>
                My Account
            </div>
            <div className="account-menu-toolbar-right" >
                
            </div>
        </div>

    );
};

export default AccountMenuToolbar;
