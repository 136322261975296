import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './VideoChatUsers.css';

// Components
import VideoChatUser from './VideoChatUser';

const VideoChatUsers = () => {
  const {
    profileMembers
  } = useContext(Global);

  return (
    <div className="video-chat-users-container">

      {profileMembers.map((member) => (
        <VideoChatUser
          key={member.key}
          member={member}
        />
      ))}

    </div>
  );
};

export default VideoChatUsers;
