import React from 'react';

const DataIcon = ({ color = "white", width = "24", height = "24" }) => (
    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M480-160q-140.23 0-230.12-35.73Q160-231.46 160-287.69V-680q0-49.85 93.58-84.92Q347.15-800 480-800t226.42 35.08Q800-729.85 800-680v392.31q0 56.23-89.88 91.96Q620.23-160 480-160Zm0-444.38q85.92 0 173.23-23.97 87.31-23.96 104.46-52.19-16.38-29.77-102.81-54.61Q568.46-760 480-760q-87.15 0-174.65 23.96t-104.58 52.42q16.31 30 103.04 54.62 86.73 24.62 176.19 24.62Zm0 201.3q41.23 0 81-4t76.04-11.88q36.27-7.89 67.38-19.66 31.12-11.76 55.58-26.53V-629q-24.46 14.77-55.58 26.54-31.11 11.77-67.38 19.65-36.27 7.89-76.04 11.89-39.77 4-81 4-42.77 0-83.15-4.39-40.39-4.38-76.27-12.27-35.89-7.88-66.5-19.27Q223.46-614.23 200-629v163.85q23.46 14.77 54.08 26.15 30.61 11.38 66.5 19.27 35.88 7.88 76.27 12.27 40.38 4.38 83.15 4.38ZM480-200q51.38 0 97.73-5.85 46.35-5.84 83.27-16.57 36.92-10.73 62.77-25.62 25.85-14.88 36.23-32.19v-144.92q-24.46 14.77-55.58 26.53-31.11 11.77-67.38 19.66-36.27 7.88-76.04 11.88-39.77 4-81 4-42.77 0-83.15-4.38-40.39-4.39-76.27-12.27-35.89-7.89-66.5-19.27-30.62-11.38-54.08-26.15V-280q10.38 18.08 36.12 32.46 25.73 14.39 62.65 25.12t83.38 16.57Q428.62-200 480-200Z" />
    </svg>
);

export default DataIcon;
