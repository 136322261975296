import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './Favorites.css';

// Components
import FavoritesToolbar from './FavoritesToolbar';
import Divider from '../components/divider/Divider';
import ObjectTile from '../collection/tiles/tile/ObjectTile';

const Favorites = () => {

  // Global
  const {
    memberFavorites,
    profileModels,
    resetSelections,
    setSelectedModel,
    setSelectedObject,
  } = useContext(Global);

  const handleObjectClick = async (object) => {
    resetSelections();
    const appCollection = profileModels.find(appCollection => appCollection.key === object.modelKey);
    setSelectedModel(appCollection);
    setSelectedObject(object);
  };

  // Split into columns only for non-mobile devices
  const leftColumn = [];
  const rightColumn = [];

  if (!isMobile) {
    memberFavorites.forEach((favorite, index) => {
      if (index % 2 === 0) {
        leftColumn.push(favorite);
      } else {
        rightColumn.push(favorite);
      }
    });
  }

  return (

    <div className="favorites-container">

      {/* TOOLBAR */}
      <FavoritesToolbar />

      <div className={isMobile ? "favorites-wrapper-mobile" : "favorites-wrapper"}>
        {isMobile ? (

          // Single column for mobile
          <div className="favorites-column-mobile">
            {memberFavorites.map((favorite) => (
              <div key={favorite.id}
                className="favorites-object">

                {/* OBJECT */}
                <ObjectTile
                  object={favorite.object}
                  onClick={() => handleObjectClick(favorite.object)}
                />

                {/* DIVIDER */}
                <Divider />
              </div>
            ))}
          </div>

        ) : (

          // Two columns for desktop
          <>
            <div className="favorites-column">
              {leftColumn.map((favorite) => (
                <div key={favorite.id}
                  className="favorites-object" >

                  {/* OBJECT */}
                  <ObjectTile
                    object={favorite.object}
                    onClick={() => handleObjectClick(favorite.object)}
                  />

                  {/* DIVIDER */}
                  <Divider />
                </div>
              ))}
            </div>
            <Divider direction="vertical" />
            <div className="favorites-column">
              {rightColumn.map((favorite) => (
                <div key={favorite.id}
                  className="favorites-object" >

                  {/* OBJECT */}
                  <ObjectTile
                    object={favorite.object}
                    onClick={() => handleObjectClick(favorite.object)}
                  />

                  {/* DIVIDER */}
                  <Divider />
                </div>
              ))}
            </div>
          </>

        )}
      </div>
    </div>
  );
};

export default Favorites;
