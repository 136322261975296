import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Device Detection
import { isMobile } from 'react-device-detect';

import { getCollectionName } from '../../utilities/Folders';

// Styles
import './ProfileBar.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarIcon from '../../svg/CalendarIcon';
import DataIcon from '../../svg/DataIcon';
import MessagesIcon from '../../svg/MessagesIcon';
import SearchIcon from '../../svg/SearchIcon';
import TrashIcon from '../../svg/TrashIcon';

// Components
import Account from '../../../account/Account';
import ProfileBarItem from './item/ProfileBarItem';
import ProfileBarButton from './button/ProfileBarButton';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

const ProfileBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
        hideProgress,
        memberNotifications,
        selectedFolderItems,
        setMenuVisible,
        setProfileAddVisible,
        showProgress,
        userSpaces,
    } = useContext(Global);

    // Local State
    const [accountOpen, setAccountOpen] = React.useState(false);

    // Translation
    const { t } = useTranslation('ProfileBar');

    // Function to calculate unread notifications for a specific app
    /*
    const getUnreadNotificationCount = (profileKey) => {
        return memberNotifications.filter(notification =>
            notification.profileKey === profileKey && !notification.read
        ).length;
    };
    */

    /**
     * Handles click on the current user's profile, which displays
     * their account information.
     */
    const handleCurrentProfileClick = async () => {
        setAccountOpen(true);
    };

    /**
     * Handles dropping of items into the trash.
     * 
     * @param {event} event - Event object.
     * @param {object} item - Item to trash.
     */
    const handleDrop = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const draggedItemKey = event.dataTransfer.getData("key");
        const draggedItemType = event.dataTransfer.getData("type");

        showProgress(`Adding ${selectedFolderItems.length} items to the trash...`);

        if (draggedItemKey) {
            for (const item of selectedFolderItems) {
                const collectionName = getCollectionName(item.type);
                await dataManager.update(
                    collectionName,
                    profile.key,
                    item.key,
                    { trashed: true }
                );
            }

            hideProgress();
        } else {
            console.error("No key found in dataTransfer");
        }

    };

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* CONTAINER */}
            <div className="profile-bar-container"
                style={{
                    backgroundColor: isMobile ? theme.backgroundColor : "transparent",
                    borderRightColor: theme.backgroundColorFaded
                }}>

                {/* TOP */}
                <div className="profile-bar-top"
                    style={
                        isMobile
                            ? {
                                overflowY: "scroll",
                                msOverflowStyle: "none", // IE and Edge
                                scrollbarWidth: "none"  // Firefox
                            }
                            : {}
                    }>

                    {/* SEARCH */}
                    <ProfileBarButton
                        title={t('ProfileBar:button_search')}
                        icon={SearchIcon}
                        onClick={() => console.log("Search")}
                    />

                    {/* CALENDAR */}
                    <ProfileBarButton
                        title={t('ProfileBar:button_calendar')}
                        icon={CalendarIcon}
                        onClick={() => console.log("Calendar")}
                    />

                    {/* MESSAGES */}
                    <ProfileBarButton
                        title={t('ProfileBar:button_messages')}
                        icon={MessagesIcon}
                        onClick={() => console.log("Calendar")}
                    />

                    {/* MESSAGES */}
                    <ProfileBarButton
                        title={t('ProfileBar:button_database')}
                        icon={DataIcon}
                        onClick={() => console.log("Database")}
                    />

                    {/* RELATED PROFILES 
                    {userSpaces.map(app => {
                        const unreadCount = getUnreadNotificationCount(app.key);

                        return (
                            <ProfileBarItem
                                key={app.key}
                                app={app}
                                count={unreadCount}
                            />
                        );
                    })}
                    */}

                </div>

                {/* BOTTOM */}
                <div className="profile-bar-bottom">

                    {/* TRASH */}
                    <div
                        onDrop={handleDrop}
                        onDragOver={(event) => {
                            event.preventDefault(); // Allow dropping
                        }}>
                        <ProfileBarButton
                            title={t('ProfileBar:button_trash')}
                            icon={TrashIcon}
                            onClick={() => console.log("Trash")}
                        />
                    </div>

                </div>

            </div>

            {/* ACCOUNT */}
            <Account
                isOpen={accountOpen}
                setOpen={setAccountOpen}
            />

        </>
    );
};

export default ProfileBar;
