import React from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Enums
import { ThemeMode } from '../utilities/Enums';

// Styles
import './AppSettingsWallpaper.css';

// Components
import AppWallpaperEdit from '../appwallpaper/AppWallpaperEdit';

/**
 * AppSettingsWallpaper Component
 * 
 * Renders a component that allows the user to set the dark and light mode wallpapers.
 * 
 * @param {function} onDarkSelect - The function to call when the dark mode icon is selected.
 * @param {function} onLightSelect - The function to call when the light mode icon is selected.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettingsWallpaper = ({ onDarkSelect, onLightSelect, onDarkClear, onLightClear }) => {

    return (
        <>
            {/* CONTAINER */}
            <div className="app-settings-wallpaper-container">

                {/* TOP */}
                <div className={isMobile ?
                    'app-settings-wallpaper-top-mobile' :
                    'app-settings-wallpaper-top'
                }>
                    <div className="app-settings-wallpaper-top-column">

                        {/* DARK */}
                        <AppWallpaperEdit
                            title="Dark Mode"
                            mode={ThemeMode.DARK}
                            onSelect={onDarkSelect}
                            onClear={onDarkClear}
                        />

                    </div>
                    <div className="app-settings-wallpaper-top-column">

                        {/* LIGHT */}
                        <AppWallpaperEdit
                            title="Light Mode"
                            mode={ThemeMode.LIGHT}
                            onSelect={onLightSelect}
                            onClear={onLightClear}
                        />

                    </div>

                </div>

            </div>
        </>
    );
};

export default AppSettingsWallpaper;
