import React, { useEffect, useState } from 'react';

// Styles
import './FieldMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DotsVerticalIcon from '../../../common/svg/DotsVerticalIcon';

// Components
import ContextMenuButton from '../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../common/components/contextmenu/ContextMenuPopup';
import FieldRemove from './FieldRemove';
import FieldRename from './FieldRename';

const FieldMenu = ({ field, menuOptions = [] }) => {
    const { theme } = useTheme();

    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [fieldTitle, setFieldTitle] = useState('');

    const toggleMenu = (event) => {
        event.stopPropagation();
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ left: event.clientX, top: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (!field) return;
        setFieldTitle(field.title);
    }, [field]);

    return (
        <>
            {/* BUTTON */}
            <div className="field-menu-button">
                <ContextMenuButton
                    onToggle={toggleMenu}
                    color={theme.foregroundColorFaded}
                    hoverColor={theme.foregroundColor}
                    icon={DotsVerticalIcon}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={180}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={field.title}>

                    {/* ADDITIONAL OPTIONS */}
                    {menuOptions.length > 0 && menuOptions.map((option, index) => (
                        <ContextMenuOption
                            key={index}
                            icon={option.icon || DotsVerticalIcon}
                            text={option.text}
                            onClick={(event) => {
                                event.stopPropagation();
                                option.onClick(event);
                                setMenuVisible(false);
                            }}
                        />
                    ))}

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* RENAME MODAL */}
            <FieldRename
                field={field}
                fieldTitle={fieldTitle}
                setFieldTitle={setFieldTitle}
                renameOpen={renameOpen}
                setRenameOpen={setRenameOpen}
            />

            {/* REMOVE MODAL */}
            <FieldRemove
                field={field}
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
            />

        </>
    );
};

export default FieldMenu;
