import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Components
import ColorSelector from '../components/colorselector/ColorSelector';

// Styles
import './AppSettingsThemeEditor.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppSettingsThemeEditor = ({
    title,
    adjustTheme,
    onThemeChange
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    const handleColorChange = (key, color) => {
        onThemeChange({ ...adjustTheme, [key]: color });
    };

    const themeOptions = [
        { key: 'backgroundColor', label: 'Background Color' },
        { key: 'backgroundColorFaded', label: 'Background Color (Faded)' },
        { key: 'foregroundColor', label: 'Foreground Color' },
        { key: 'foregroundColorFaded', label: 'Foreground Color (Faded)' },
        { key: 'highlightBackgroundColor', label: 'Highlight Background Color' },
        { key: 'highlightForegroundColor', label: 'Highlight Foreground Color' }
    ];

    return (
        <div className="app-settings-theme-editor-container">
            <div
                className="app-settings-theme-editor-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: profile.fontFamily
                }}>
                {title}
            </div>
            <br />
            {themeOptions.map(option => (
                <div key={option.key} className="app-settings-color">
                        <ColorSelector
                            color={adjustTheme && adjustTheme[option.key]}
                            onUpdate={color => handleColorChange(option.key, color)}
                        />
                    <div key={option.key} className="app-settings-color-right">
                        <div className="app-settings-theme-editor-label-title"
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: profile.fontFamily
                            }}>
                            {option.label}
                        </div>
                        <div className="app-settings-theme-editor-label-hex"
                            style={{
                                color: theme.foregroundColorFaded,
                                fontFamily: profile.fontFamily
                            }}>
                            {adjustTheme && adjustTheme[option.key]}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AppSettingsThemeEditor;
