import React from 'react';

const TileIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M172.31-140Q142-140 121-161q-21-21-21-51.31v-535.38Q100-778 121-799q21-21 51.31-21h615.38Q818-820 839-799q21 21 21 51.31v535.38Q860-182 839-161q-21 21-51.31 21H172.31Zm0-60h615.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46v-535.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H172.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46v535.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm113.08-95.39h389.22q12.77 0 21.39-8.61 8.61-8.62 8.61-21.39 0-12.76-8.61-21.38-8.62-8.61-21.39-8.61H285.39q-12.77 0-21.39 8.61-8.61 8.62-8.61 21.38 0 12.77 8.61 21.39 8.62 8.61 21.39 8.61Zm0-154.61h87.69q12.77 0 21.38-8.62 8.62-8.61 8.62-21.38v-154.61q0-12.77-8.62-21.39-8.61-8.61-21.38-8.61h-87.69q-12.77 0-21.39 8.61-8.61 8.62-8.61 21.39V-480q0 12.77 8.61 21.38 8.62 8.62 21.39 8.62Zm235.38 0h153.84q12.77 0 21.39-8.62 8.61-8.61 8.61-21.38T696-501.38q-8.62-8.62-21.39-8.62H520.77q-12.77 0-21.38 8.62-8.62 8.61-8.62 21.38t8.62 21.38Q508-450 520.77-450Zm0-154.62h153.84q12.77 0 21.39-8.61 8.61-8.62 8.61-21.38 0-12.77-8.61-21.39-8.62-8.61-21.39-8.61H520.77q-12.77 0-21.38 8.61-8.62 8.62-8.62 21.39 0 12.76 8.62 21.38 8.61 8.61 21.38 8.61ZM160-200v-560 560Z" />
  </svg>

);

export default TileIcon;
