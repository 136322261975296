import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ObjectSummaries.css';

// Components
import ObjectGridItem from '../../../common/folder/item/object/ObjectGridItem';
import Grid from '../../components/grid/Grid';

const ObjectSummaries = ({ onObjectClick }) => {

    // Global
    const {
        profileObjects,
        setSelectedObject
    } = useContext(Global);

    const handleContainerClick = () => {
        setSelectedObject(null);
    };

    return (
        <div className={isMobile ? "object-summaries-container-mobile" : "object-summaries-container"}
            onClick={handleContainerClick}>
            <Grid
                items={profileObjects}
                renderItem={(object) => (
                    <ObjectGridItem
                        key={object.key}
                        item={object}
                        onClick={() => onObjectClick(object)}
                    />
                )}
                loading={false}
                minCols={2}
                colWidth={isMobile ? 100 : 200}
            />
        </div>
    );
};

export default ObjectSummaries;
