import React, { useState, useEffect, useRef } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Grid.css';

/**
 * Grid
 * 
 * A responsive grid component.
 * 
 * @param {Array} items The items to display.
 * @param {Function} renderItem The render item function.
 * @param {Boolean} loading If the grid is loading.
 * @param {Number} minCols The minimum number of columns.
 * @param {Number} colWidth The column width.
 * 
 * @returns {JSX.Element} Grid component.
 */
const Grid = ({ items, renderItem, loading, minCols = 2, colWidth = 280 }) => {

    // Local State
    const [columns, setColumns] = useState([]); // columns will be an array of arrays

    // References
    const containerRef = useRef(null);

    // Calculate the number of columns based on container width
    const calculateColumns = (width) => {
        const maxPossibleCols = Math.max(minCols, Math.floor(width / colWidth));
        return maxPossibleCols;
    };

    // Distribute items into columns
    const distributeItemsIntoColumns = (itemsArray, numCols) => {
        if (!itemsArray || itemsArray.length === 0) return [];
        const newColumns = Array.from({ length: numCols }, () => []);
        itemsArray.forEach((item, index) => {
            const colIndex = index % numCols;
            newColumns[colIndex].push(item);
        });
        return newColumns;
    };

    // Use ResizeObserver to detect changes in container width
    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                let numCols = calculateColumns(width);
                if (isMobile) { // Hardcode 2 columns on mobile
                    numCols = 2;
                }
                setColumns(distributeItemsIntoColumns(items, numCols));
            }
        });

        observer.observe(container);

        return () => {
            if (container) observer.unobserve(container);
        };
    }, [items]);

    // Initially, set up columns when items or container load
    useEffect(() => {
        if (!containerRef.current || !items || items.length === 0) return;
        const width = containerRef.current.getBoundingClientRect().width;
        let numCols = calculateColumns(width);
        if (isMobile) { // Hardcode 2 columns on mobile
            numCols = 2;
        }
        setColumns(distributeItemsIntoColumns(items, numCols));
    }, [items]);

    if (loading) {
        return <div className="grid-loading">Loading...</div>;
    }

    return (
        <div ref={containerRef}
            className="grid-container">

            {columns.map((colItems, colIndex) => (

                <div key={`column-${colIndex}`}
                    className="grid-column">
                    {colItems.map((item) => renderItem(item))}
                </div>
                
            ))}

        </div >
    );
};

export default Grid;
