import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Styles
import './ColorPopup.css';

const ColorPopup = ({ theme, value, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedToken, setSelectedToken] = useState(value || "[foregroundColor]");
    const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
    const popupRef = useRef(null);

    useEffect(() => {
        setSelectedToken(value || "[foregroundColor]");
    }, [value]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const handleColorClick = (token) => {
        setSelectedToken(token);
        setIsOpen(false);
        if (onSelect) {
            onSelect(token);
        }
    };

    const handleSquareClick = (event) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const popupWidth = 200; // Approximate width of the popup
        const popupHeight = 150; // Approximate height of the popup

        let x = event.clientX;
        let y = event.clientY;

        if (x + popupWidth > viewportWidth) {
            x = viewportWidth - popupWidth;
        }
        if (y + popupHeight > viewportHeight) {
            y = viewportHeight - popupHeight;
        }

        setPopupPosition({ x, y });
        setIsOpen(!isOpen);
    };

    const colors = [
        { title: 'Foreground Color', token: '[foregroundColor]', color: theme.foregroundColor },
        { title: 'Foreground Color (Faded)', token: '[foregroundColorFaded]', color: theme.foregroundColorFaded },
        { title: 'Highlight Color (Background)', token: '[highlightBackgroundColor]', color: theme.highlightBackgroundColor },
        { title: 'Highlight Color (Foreground)', token: '[highlightForegroundColor]', color: theme.highlightForegroundColor },
        { title: 'Background Color (Faded)', token: '[backgroundColorFaded]', color: theme.backgroundColorFaded },
        { title: 'Background Color', token: '[backgroundColor]', color: theme.backgroundColor },
        { title: 'Transparent', token: '[transparent]', color: "transparent" },
    ];

    const getColorFromToken = (token) => {
        switch (token) {
            case '[foregroundColor]':
                return theme.foregroundColor;
            case '[foregroundColorFaded]':
                return theme.foregroundColorFaded;
            case '[highlightBackgroundColor]':
                return theme.highlightBackgroundColor;
            case '[highlightForegroundColor]':
                return theme.highlightForegroundColor;
            case '[backgroundColorFaded]':
                return theme.backgroundColorFaded;
            case '[backgroundColor]':
                return theme.backgroundColor;
            case '[transparent]':
                return "transparent";
            default:
                return theme.foregroundColor;
        }
    };

    const popupContent = (
        <div
            className="color-popup"
            ref={popupRef}
            style={{
                top: `${popupPosition.y}px`,
                left: `${popupPosition.x}px`,
                backgroundColor: theme.backgroundColorFaded,
            }}
        >
            {colors.map((item) => (
                <div
                    key={item.title}
                    className="color-option"
                    onClick={() => handleColorClick(item.token)}
                >
                    <div
                        className="color-square"
                        style={{ backgroundColor: item.color }}
                    />
                    <div className="color-label"
                        style={{ color: theme.foregroundColor }}
                    >
                        {item.title}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="color-popup-container"
            style={{ 
                borderColor: theme.backgroundColorFaded 
            }}>
            <div
                className="selected-color-square"
                style={{ 
                    backgroundColor: getColorFromToken(selectedToken)
                }}
                onClick={handleSquareClick}
            />
            {isOpen && ReactDOM.createPortal(popupContent, document.body)}
        </div>
    );
};

export default ColorPopup;
