import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { Section } from '../../utilities/Enums';

// Styles
import './NavBar.css';

// Images
import NotificationIcon from '../../../common/svg/NotificationIcon';
import SearchIcon from '../../../common/svg/SearchIcon';
import UserIcon from '../../../common/svg/UserIcon';

// Components
import NavButton from './NavButton';
import PromptModal from '../../../common/prompt/PromptModal';
import Search from '../../../common/search/Search';

// Managers
import ObjectManager from '../../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

/**
 * NavBar Component
 * 
 * This component renders a nav button bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavBar = () => {

  // Global
  const {
    profileModels,
    profile,
    currentSection,
    currentUser,
    memberBookmarks,
    memberFavorites,
    memberNotifications,
    resetSelections,
    setScreen,
    setProfile,
    setCurrentSection,
    setSelectedModel,
    setSelectedObject,
  } = useContext(Global);

  // Local State
  const [promptOpen, setPromptOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  useEffect(() => {
    // Set the unread notifications
    const unread = memberNotifications.filter(notification => notification.read === false);
    setUnreadNotifications(unread);
  }, [memberNotifications]);

  const handleSearch = async (entry) => {

    // Reset the visibility
    resetSelections();

    // Find the model in the profileModels array and set it to active
    const appCollection = profileModels.find(appCollection => appCollection.key === entry.modelKey);
    setSelectedModel(appCollection);

    // Fetch the selected object and set it to active
    const object = await objectManager.fetch(profile.key, entry.objectKey);
    setSelectedObject(object);

    // Close the search dialog
    setSearchVisible(false);
  };

  const handleProfileClick = () => {

  };

  const showWebsite = () => {
    const url = `/${profile.domain}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <div className="nav-bar-container">

        {/* MAIN */}
        <NavButton
          icon={UserIcon}
          title="Profile"
          size={isMobile ? 24 : 24}
          selected={false}
          onClick={handleProfileClick}
        />

        {/* NOTIFICATIONS */}
        <NavButton
          icon={NotificationIcon}
          title="Notifications"
          size={isMobile ? 21 : 21}
          selected={currentSection === Section.NOTIFICATIONS}
          count={unreadNotifications.length}
          onClick={() => setCurrentSection(Section.NOTIFICATIONS)}
        />

        {/* BOOKMARKS 
        {memberBookmarks.length > 0 &&
          <NavButton
            icon={BookmarksIcon}
            title="Bookmarks"
            size={isMobile ? 23 : 23}
            selected={currentSection === Section.BOOKMARKS}
            onClick={() => setCurrentSection(Section.BOOKMARKS)}
          />
        }
        */}

        {/* FAVORITES 
        {memberFavorites.length > 0 &&
          <NavButton
            icon={FavoriteIcon}
            title="Favorites"
            size={isMobile ? 24 : 24}
            selected={currentSection === Section.FAVORITES}
            onClick={() => setCurrentSection(Section.FAVORITES)}
          />
        }
        */}

        {/* SEARCH */}
        <NavButton
          icon={SearchIcon}
          title="Search"
          size={isMobile ? 25 : 25}
          selected={searchVisible}
          onClick={() => setSearchVisible(true)}
        />

        {/* MESSAGES 
        <NavButton
          icon={ForumIcon}
          title="Direct Messages"
          size={isMobile ? 21 : 21}
          selected={currentSection === Section.MESSAGES}
          onClick={() => setCurrentSection(Section.MESSAGES)}
        />
        */}

        {/* MEMBERS 
        <NavButton
          icon={UsersIcon}
          title="Members"
          size={isMobile ? 24 : 24}
          selected={currentSection === Section.MEMBERS}
          onClick={() => setCurrentSection(Section.MEMBERS)}
        />
        */}

        {/* QUICK NOTE 
        <NavButton
          icon={WandIcon}
          title="Quick Note"
          size={isMobile ? 18 : 18}
          selected={false}
          onClick={() => setPromptOpen(true)}
        />
        */}

      </div>

      {/* SEARCH FORM */}
      <Search
        onSearch={handleSearch}
        isVisible={searchVisible}
        setVisible={setSearchVisible}
      />

      {/* PROMPT MODAL */}
      <PromptModal
        isOpen={promptOpen}
        setOpen={setPromptOpen}
      />

    </>
  );
};

export default NavBar;

