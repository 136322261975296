import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Utilities
import { LoginMode } from '../../utilities/Enums';

// Styles
import './LoginLink.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginLink Component
 * 
 * This component renders a link to sign up a new user.
 * 
 * @param {LoginMode} loginMode - The current login mode.
 * @param {function} setLoginMode - The function to set the login mode.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginLink = ({ loginMode, setLoginMode }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    coreTheme
  } = useContext(Global);

  // Translation
  const { t } = useTranslation('Login');

  const handleClick = () => {
    loginMode === LoginMode.LOGIN ?
      setLoginMode(LoginMode.SIGNUP) :
      setLoginMode(LoginMode.LOGIN);
  };

  return (

    <div
      className="login-link-container"
      onClick={handleClick}
      style={{
        color: profile ? theme.foregroundColor : coreTheme.foregroundColor
      }}>
      {loginMode === LoginMode.LOGIN ? t('button_sign_up') : t('button_log_in')}
    </div>

  );
};

export default LoginLink;
