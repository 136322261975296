import React from 'react';

// Utilities
import { ItemType } from '../../utilities/Enums.js';

// Styles
import './ForYouItem.css';

// Components
import EventGridItem from '../../folder/item/event/EventGridItem.js';
import FileGridItem from '../../folder/item/file/FileGridItem.js';
import StoryGridItem from '../../folder/item/story/StoryGridItem.js';
import ObjectGridItem from '../../folder/item/object/ObjectGridItem.js';

const COMPONENT_MAP = {
    [ItemType.FILE]: FileGridItem,
    [ItemType.EVENT]: EventGridItem,
    [ItemType.STORY]: StoryGridItem,
    [ItemType.OBJECT]: ObjectGridItem,
};

/**
 * ForYouItem
 * 
 * Item that represents a foryou, file, event, story, or object.
 * 
 * @param {Object} item - The item to render.
 * @param {Array} pathKeys - The keys of the items in the path.
 * @param {Boolean} selected - Whether the item is selected.
 * @param {Function} onClick - Function to call when the item is clicked.
 * @param {Function} onDoubleClick - Function to call when the item is double-clicked.
 * @param {Function} onDragStart - Function to call when the item is dragged.
 * @param {Function} onDropItem - Function to call when an item is dropped on the item.
 * 
 * @returns {Component} The foryou item.
 */
const ForYouItem = ({ item, pathKeys, selected = false, onClick, onDoubleClick, onDragStart, onDropItem }) => {
    const Component = COMPONENT_MAP[item.type];

    if (!Component) return null; // Handle undefined item types gracefully

    return (
        <Component
            key={item.key}
            item={item}
            pathKeys={pathKeys}
            selected={selected}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onDragStart={onDragStart}
            onDropItem={item.type === ItemType.FOLDER ? onDropItem : undefined} // Only pass onDropItem for foryous
        />
    );
};

export default ForYouItem;
