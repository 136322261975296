import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global.js';

// Utilities
import { defaultFont } from '../../../utilities/Defaults.js';

// Styles
import './ModalTextarea.css';

// Theme
import { useTheme } from '../../../../ThemeContext.js';

/**
 * ModalTextarea Component
 * 
 * This component renders a textarea input field for a modal dialog.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalTextarea = ({ value, onChange, maxLength, lines }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      profile,
  } = useContext(Global);

  return (
    <textarea
      className="modal-textarea-container"
      style={{
        color: theme.foregroundColor,
        backgroundColor: theme.backgroundColorFaded,
        borderColor: theme.backgroundColorFaded,
        fontFamily: profile.fontFamily || defaultFont
      }}
      value={value}
      onChange={onChange}
      lines={lines}
      maxLength={maxLength}></textarea>
  );
};

export default ModalTextarea;
