import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './YearPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const YearPreview = ({ 
    placeholder = '', 
    selected = false
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profile,
    } = useContext(Global);

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor :"1px solid transparent";

    return (
        <div className="year-preview-container"
            style={{border: borderStyle, backgroundColor: theme.backgroundColorFaded}}>
            <div 
                className="year-preview-label"
                style={{color: theme.foregroundColorFaded}}>
                {placeholder}
            </div>
            <select
                className="year-preview-input"
                disabled
                style={{
                    color: theme.foregroundColor,
                    fontFamily: profile.fontFamily,
                }}
                value="2025"
                tabIndex={-1}>
                <option value="2025">2025</option>
            </select>
        </div>
    );
};

export default YearPreview;
