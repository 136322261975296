import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { uploadPhotoGallery, uploadVideoGallery } from '../../common/utilities/Media';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Form.css';

// Components
import FormDeleteConfirmation from './FormDeleteConfirmation';
import FormField from './FormField';
import FormFooter from './FormFooter';
import Modal from '../../common/components/modal/Modal';
import ModalButtons from '../../common/components/modal/buttons/ModalButtons';
import ModalButton from '../../common/components/modal/buttons/ModalButton';
import TagSelector from '../../common/components/tagselector/TagSelector';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

const Form = ({ isOpen, setOpen }) => {

    // Global
    const {
        profileFields,
        profileModels,
        hideProgress,
        profile,
        selectedObject,
        setChangesMade,
        setSelectedObject,
        showProgress,
    } = useContext(Global);

    // Model
    const [model, setModel] = useState(null);

    // Initialize fields as an array
    const [fields, setFields] = useState([]);

    // Create a temporary object for editing
    const [temporaryObject, setTemporaryObject] = useState({});

    // Visibility of the delete confirm dialog
    const [confirmOpen, setConfirmOpen] = useState(false);

    // Object tags
    const [selectedTags, setSelectedTags] = useState([]);

    // References
    const scrollerRef = useRef(null);

    /**
     * When the object changes, prepare the component.
     **/
    useEffect(() => {
        if (!selectedObject) return;

        const model = profileModels.find(c => c.key === selectedObject.modelKey);
        setModel(model);

        // Set the selected tags when the object changes
        setSelectedTags(selectedObject.tags || []);

        // Load the selectedObject into a temporaryObject
        setTemporaryObject(selectedObject);

    }, [selectedObject]);

    /**
     * Once the model is set, determine the fields for the model, sort them, 
     * and set the form fields.
     **/
    useEffect(() => {
        if (!model) return;

        // Filter fields for the current model
        const filtered = profileFields.filter(f => f.modelKey === model.key);

        // Try to sort fields according to the order in model.fieldSort
        if (model.fieldSort && Array.isArray(model.fieldSort) &&
            model.fieldSort !== undefined) {
            const sorted = filtered.sort((a, b) => {
                const indexA = model.fieldSort.indexOf(a.key);
                const indexB = model.fieldSort.indexOf(b.key);

                // Fields not found in fieldSort will appear at the end
                return indexA - indexB;
            });
            setFields(sorted);
        } else {
            setFields(filtered);
        }

    }, [model, profileFields]);

    /**
     * Set the changes made flag when the tags change.
     */
    useEffect(() => {
        setChangesMade(true);
    }, [selectedTags, setChangesMade]);

    /**
     * When the modal closes, de-select the object.
     */
    useEffect(() => {
        if (!isOpen) {
            setSelectedObject(null);
        }
    }, [isOpen]);

    /**
     * When changes are made to a field, updates the field's value 
     * in the temporaryObject.
     */
    const onUpdate = useCallback((field, value) => {
        setChangesMade(true);
        setTemporaryObject(prev => ({ ...prev, [field.key]: value }));
    }, [setChangesMade]);

    /**
     * Saves changes to the currently selected record.
     */
    const handleSave = async () => {
        if (!validateTitleField()) return;

        showProgress("Saving...");

        // Handle special fields
        await prepareSpecialFields(selectedObject.key);

        // Attach the selected tags to the current object
        temporaryObject.tags = selectedTags;

        // Proceed with saving the updated object
        await objectManager.update(
            profile.key,
            model.key,
            profileModels,
            selectedObject.key,
            temporaryObject
        );

        setChangesMade(false);

        setSelectedTags([]);

        hideProgress();

        setOpen(false);
    };

    /**
    * Prepares special fields for saving or adding.
    */
    const prepareSpecialFields = async (objectKey) => {
        for (const field of fields) {
            // Handle gallery fields
            if (field.type === 'gallery') {
                await savePhotoGallery(field, objectKey);
            }

            // Handle videogallery fields
            if (field.type === 'videogallery') {
                await saveVideoGallery(field, objectKey);
            }
        }
    };

    /**
    * Saves photo gallery and uploads files if necessary.
    */
    const savePhotoGallery = async (field, objectKey) => {

        const galleryItems = temporaryObject[field.key] || [];

        const folderPath = `galleries/${profile.key}/${model.key}/${field.key}/${objectKey}/`;

        const uploadedImages = await uploadPhotoGallery(profile.key, folderPath, galleryItems);

        // Update the temporaryObject with the new URLs and captions
        temporaryObject[field.key] = uploadedImages;
    };

    /**
     * Saves video gallery data and uploads files if necessary.
     */
    const saveVideoGallery = async (field, objectKey) => {

        const galleryItems = temporaryObject[field.key] || [];

        const folderPath = `videogalleries/${profile.key}/${model.key}/${field.key}/${objectKey}/`;

        const uploadedGallery = await uploadVideoGallery(profile.key, folderPath, galleryItems);

        // Update the temporaryObject with the new URLs
        temporaryObject[field.key] = uploadedGallery;
    };

    /**
     * Displays a delete confirmation dialog.
     */
    const handleShowConfirm = async () => {
        setConfirmOpen(true);
    };

    /**
     * Delete the currently selected record.
     */
    const handleDelete = async () => {
        deleteObject();
    };

    /**
     * Handles a cancel response in the confirm dialog.
     */
    const handleCancel = async () => {
        setConfirmOpen(false);
    };

    /**
     * Deletes an object.
     */
    const deleteObject = async () => {
        setConfirmOpen(false);

        showProgress("Deleting...");

        if (!selectedObject) {
            alert('No object selected!');
            return;
        }

        const modelKey = selectedObject.modelKey;
        await objectManager.delete(profile.key, modelKey, selectedObject, fields);

        setSelectedObject(null);

        hideProgress();
    };

    /**
     * Validates that the title field is non-empty.
     */
    const validateTitleField = () => {
        let title = temporaryObject[model.titleFieldKey]
        if (!title || title.length === 0) {
            const field = fields.find(f => f.key === model.titleFieldKey);
            alert(`Please enter a ${field.title}.`);
            return false;
        }
        return true;
    }

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Edit"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? '100%' : '500px'}
                height={isMobile ? '100%' : '90%'}>

                <div className={(isMobile || isTablet) ?
                    "form-container-mobile" :
                    "form-container"}>

                    <div ref={scrollerRef}
                        className="form-scroller">

                        {/* FIELDS */}
                        {fields.map((field) => (
                            <FormField
                                object={selectedObject}
                                key={field.id}
                                field={field}
                                onUpdate={onUpdate}
                            />
                        ))}

                        {(model && model.tags && model.tags.length > 0) &&
                            <div className="form-tag-selector-wrapper">
                                <TagSelector
                                    tags={(model && model.tags) || []}
                                    selectedTags={selectedTags}
                                    onTagClick={setSelectedTags}
                                />
                            </div>
                        }

                        {/* FOOTER */}
                        <FormFooter />

                    </div>

                    {/* BUTTONS */}
                    <ModalButtons>

                        <div className="form-buttons-left">

                            {/* REMOVE ITEM BUTTON */}
                            <ModalButton
                                onClick={handleShowConfirm}
                                label={"Delete"}
                                width="100px"
                            />

                        </div>
                        <div className="form-buttons-right">

                            <ModalButton
                                label="Save"
                                onClick={handleSave}
                                highlighted={true}
                                width="100px"
                            />

                        </div>

                    </ModalButtons>

                    {/* DELETE CONFIRMATION */}
                    <FormDeleteConfirmation
                        isOpen={confirmOpen}
                        setIsOpen={setConfirmOpen}
                        onConfirm={handleDelete}
                        onCancel={handleCancel}
                    />

                </div>

            </Modal>
        </>

    );
};

export default Form;
