import React from 'react';

// Styles
import './ProfileBarButton.css';

// Themes
import { useTheme } from '../../../../ThemeContext';

/**
 * ProfileBarButton Component
 * 
 * This component renders a button to the app bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileBarButton = ({ title, icon: IconComponent, onClick }) => {
    
    // Theme
    const { theme } = useTheme();

    return (
        <>
            {/* CONTAINER */}
            <div className="profile-bar-button-container"
                title={title}>

                {/* CIRCLE */}
                <div className="profile-bar-button-circle"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}
                    onClick={onClick}>

                    {/* ICON */}
                    <IconComponent
                        color={theme.foregroundColorFaded}
                        width="23"
                        height="23"
                    />

                </div>
            </div>
        </>
    );
};

export default ProfileBarButton;
