import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Config
import { menuItemHeight } from '../../../Config';

// Styles
import './MenuItem.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Inline Styles
const InlineStyles = useStyle`
    .menu-item-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * MenuItem Component
 * 
 * This component renders an item in the menu.
 * 
 * @param {string} title - The title of the menu item.
 * @param {object} icon - The icon to display with the menu item.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MenuItem = ({ title, icon: IconComponent, isActive, onClick }) => {
    const { theme } = useTheme();
    const {
        profile,
    } = useContext(Global);

    // Verify profile
    if (!profile) return null;

    return (
        <>
            {/* STYLES */}
            <InlineStyles hoverColor={theme.backgroundColorFaded} />

            {/* CONTAINER */}
            <div
                className="menu-item-container"
                style={{
                    backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent',
                    height: `${menuItemHeight}px`,
                }}>

                <div className="menu-item-left"
                    onClick={onClick}>
                    <IconComponent
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="14"
                        height="14"
                    />
                    <div className="menu-item-text"
                        style={{
                            color: isActive ? theme.highlightForegroundColor : theme.foregroundColor,
                            fontFamily: profile.fontFamily,
                        }}>
                        {title}
                    </div>
                </div>

            </div>
        </>
    );
};

export default MenuItem;
