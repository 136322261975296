/**
 * Method to validate email format.
 * 
 * @param {string} email - Email to validate.
 * 
 * @returns {boolean} - Whether the email is valid.
 */
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex for validation
  return emailRegex.test(email);
};

/**
 * Method to validate password requirements.
 * 
 * - at least 8 characters long
 * - contain an uppercase letter
 * - contain a lowercase letter
 * - contain a number
 * - contain a special character (!@#$%^&*)
 * 
 * @param {string} password - Password to validate.
 * 
 * @returns {boolean} - True if the password meets all requirements, false otherwise.
 */
const isValidPassword = (password) => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
  return regex.test(password);
};