// Firebase
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Managers
import { activity } from '../../common/managers/ActivityManager';

// Config
import { maxPhotoDimension, maxVideoSizeMB } from '../../Config';

/**
 * Uploads any local blob-based images within the provided array to Firebase Storage,
 * resizes them to a maximum dimension, and returns a fully remote URL-based array of image objects.
 * 
 * @param {string} profileKey - The application key for activity logging and folder organization.
 * @param {string} folderPath - The folder path in Firebase Storage where images will be uploaded.
 * @param {Array} currentArray - The current array of image items. 
 *                               Each item should have a `url` and optionally a `caption`.
 * @returns {Promise<Array>} - A promise that resolves to an array of image objects with fully remote URLs.
 */
export async function uploadPhotoGallery(
    profileKey,
    folderPath,
    currentArray,
) {
    // Will hold all uploaded (or retained) image objects
    const uploadedImages = [];

    // Promise array for any uploads needed
    const uploadPromises = [];

    for (const item of currentArray) {
        // Check if the item is currently a local blob
        if (item.url.startsWith('blob:')) {
            const uploadPromise = new Promise((resolve, reject) => {
                const image = new Image();
                
                // When the image is loaded, resize and upload
                image.onload = async () => {
                    try {
                        // Create a canvas and get its 2D context
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        // Calculate the resize ratio to maintain aspect ratio
                        const ratio = Math.min(maxPhotoDimension / image.width, maxPhotoDimension / image.height);

                        // Set canvas dimensions based on the ratio
                        canvas.width = image.width * ratio;
                        canvas.height = image.height * ratio;

                        // Draw the image onto the canvas with the new size
                        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

                        // Generate a unique key (filename) for this file
                        const key = generateKey();
                        const fileExtension = 'jpeg'; // Using JPEG for simplicity

                        // Convert the canvas content back to a blob
                        canvas.toBlob(async (blob) => {
                            try {
                                // Optionally log the file size (if known and if activity logging is desired)
                                if (activity && typeof activity.log === 'function') {
                                    activity.log(profileKey, 'uploads', blob.size);
                                }

                                // Create a reference path within Firebase Storage
                                // folderPath should end with a slash, e.g.:
                                // "galleries/<profileKey>/<collectionKey>/<fieldKey>/<objectKey>/"
                                const storageRef = ref(storage, `${folderPath}${key}.${fileExtension}`);

                                // Upload the resized image blob
                                await uploadBytes(storageRef, blob);

                                // Retrieve the download URL for the newly uploaded image
                                const fileUrl = await getDownloadURL(storageRef);

                                // Store the remote URL and caption
                                uploadedImages.push({
                                    url: fileUrl,
                                    caption: item.caption || ''
                                });
                                resolve();
                            } catch (error) {
                                console.error("Error uploading file:", error);
                                reject(error);
                            }
                        }, `image/${fileExtension}`);
                    } catch (error) {
                        console.error("Error processing image:", error);
                        reject(error);
                    }
                };

                // Trigger image loading
                image.src = item.url;
            });

            uploadPromises.push(uploadPromise);
        } else {
            // Already a remote URL, just keep it as is
            uploadedImages.push(item);
        }
    }

    // Wait for all uploads to complete before returning
    await Promise.all(uploadPromises);

    // Return the array of fully remote image objects
    return uploadedImages;
}

/**
 * Uploads any local blob-based videos within the provided array to Firebase Storage,
 * and returns a fully remote URL-based array of video objects.
 * 
 * @param {string} folderPath - The folder path in Firebase Storage where videos will be uploaded.
 * @param {Array} currentArray - The current array of video items. 
 * @returns {Promise<Array>} - A promise that resolves to an array of video objects with fully remote URLs.
 */
export async function uploadVideoGallery(
    profileKey,
    folderPath,
    currentArray,
) {
    // Will hold all uploaded (or retained) video objects
    const uploadedVideos = [];

    // Promise array for any uploads needed
    const uploadPromises = [];

    for (const item of currentArray) {
        // Check if the item is currently a local blob
        if (item.url.startsWith('blob:')) {
            // If it's a local blob, we need to upload it
            const uploadPromise = new Promise(async (resolve, reject) => {
                try {
                    // Fetch the blob file from its local URL
                    const response = await fetch(item.url);
                    const file = await response.blob();

                    // Extract file extension from mime-type (e.g., 'video/mp4')
                    const fileExtension = file.type.split('/').pop();

                    // Generate a unique key (filename) for this file
                    const key = generateKey();

                    // Check the file size before upload
                    const fileSizeMB = file.size / (1024 * 1024);
                    if (fileSizeMB > maxVideoSizeMB) {
                        alert(`File size exceeds ${maxVideoSizeMB} MB. Please select a smaller file.`);
                        return reject(`File size exceeds ${maxVideoSizeMB} MB.`);
                    }

                    // Log the file size in bytes if activity logging is provided
                    if (activity && typeof activity.log === 'function') {
                        activity.log(profileKey, 'uploads', file.size);
                    }

                    // Create a reference path within Firebase Storage
                    // folderPath should end with a slash, e.g.:
                    // "videogalleries/<profileKey>/<collectionKey>/<fieldKey>/<objectKey>/"
                    const storageRef = ref(storage, `${folderPath}${key}.${fileExtension}`);

                    // Perform the upload to Firebase Storage
                    await uploadBytes(storageRef, file);

                    // Retrieve the download URL for the newly uploaded video
                    const fileUrl = await getDownloadURL(storageRef);

                    // Push the new remote video object (with caption if provided)
                    uploadedVideos.push({
                        url: fileUrl,
                        caption: item.caption || ''
                    });

                    resolve();
                } catch (error) {
                    console.error("Error during file upload:", error);
                    reject(error);
                }
            });

            uploadPromises.push(uploadPromise);
        } else {
            // If this video's URL is already remote (not a 'blob:'), just keep it as is
            uploadedVideos.push(item);
        }
    }

    // Wait for all uploads to complete before returning
    await Promise.all(uploadPromises);

    // Return the array of fully remote video objects
    return uploadedVideos;
}
