import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './LoginError.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginError Component
 * 
 * This component renders a login form error message.
 * 
 * @param {string} text - The text of the label.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginError = ({ text }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    profile,
    coreTheme
  } = useContext(Global);

  return (

    <div
      className="login-error-container"
      style={{
        color: profile ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded,
        borderColor: profile ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor
      }}>
      {text}
    </div>

  );
};

export default LoginError;
