import React from 'react';

// Styles
import './TextPreview.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

const TextPreview = ({
    placeholder = '',
    selected = false,
    value = ''
}) => {
    const { theme } = useTheme();

    const borderStyle = selected ? "1px solid " + theme.highlightBackgroundColor : "1px solid transparent";

    return (
        <div className="text-preview-container"
            style={{
                border: borderStyle,
                backgroundColor: theme.backgroundColorFaded
            }}>
            <div
                className="text-preview-label"
                style={{
                    color: theme.foregroundColorFaded
                }}>
                {placeholder}
            </div>
            <div
                className="text-preview-input"
                style={{
                    color: theme.foregroundColor
                }}>
                {placeholder}
            </div> 
        </div>
    );
};

export default TextPreview;
