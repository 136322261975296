import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { ObjectsView } from '../../../common/utilities/Enums';

// Styles
import './CollectionViewBar.css';

// Images
import DashboardIcon from '../../../common/svg/DashboardIcon';
import FormIcon from '../../../common/svg/FormIcon';
import TilesIcon from '../../../common/svg/TilesIcon';
import TableIcon from '../../../common/svg/TableIcon';

// Components
import FormDesigner from '../../../common/form/designer/FormDesigner';

// Managers
import ProfileManager from '../../../common/managers/ProfileManager';
import ToolbarButton from '../../components/toolbarbutton/ToolbarButton';

const profileManager = new ProfileManager();

const CollectionViewBar = () => {

  // Global
  const {
    profile,
    currentUser,
    modelView,
    setCurrentUser,
    setModelView,
  } = useContext(Global);

  const [formDesignerOpen, setFormDesignerOpen] = useState(false);

  const handleDashboardClick = () => {
    setModelView(ObjectsView.GRID);
  };

  const handleTilesClick = () => {
    setModelView(ObjectsView.TILES);
  };

  const handleTableClick = () => {
    setModelView(ObjectsView.TABLE);
  };

  const handleFormClick = () => {
    setFormDesignerOpen(true);
  };

  return (
    <>
      <div className="collection-view-bar-container">

        {/* DASHBOARD */}
        <ToolbarButton
          icon={DashboardIcon}
          title="View Summaries"
          onClick={handleDashboardClick}
          selected={modelView === ObjectsView.DASHBOARD}
        />

        {/* TILES */}
        <ToolbarButton
          icon={TilesIcon}
          title="View Tiles"
          onClick={handleTilesClick}
          selected={modelView === ObjectsView.TILES}
        />

        {/* TABLE */}
        <ToolbarButton
          icon={TableIcon}
          title="View Table"
          onClick={handleTableClick}
          selected={modelView === ObjectsView.TABLE}
        />

        {/* FORM */}
        <ToolbarButton
          icon={FormIcon}
          title="Form Design"
          onClick={handleFormClick}
          selected={false}
        />

      </div>

      {/* FORM DESIGNER */}
      <FormDesigner
        open={formDesignerOpen}
        setOpen={setFormDesignerOpen}
      />

    </>

  );
};

export default CollectionViewBar;
