import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load folders for a specific app.
 */
const usePreloadProfileFolders = () => {

  // Global
  const {
    profile,
    setProfileFolders
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!profile || !profile.key) {
      setProfileFolders([]);
      return;
    }

    // Function to load models
    const loadFolders = (profileKey) => {
      const handleUpdate = (items) => {
        setProfileFolders(items);
        activity.log(profileKey, 'reads', items.length);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        collections.folders,
        profileKey,
        handleUpdate,
        [],
        []
      );

      return unsubscribe;
    };

    // Subscribe to models
    const unsubscribeFolders = loadFolders(profile.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeFolders) {
        unsubscribeFolders();
      }
    };
  }, [profile, setProfileFolders]);

};

export default usePreloadProfileFolders;
