import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './UserList.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Icons
import ForwardIcon from '../../../../common/svg/ForwardIcon';

// Components
import MemberSummary from '../../../../common/members/summary/MemberSummary';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import UserSelector from '../../../components/userselector/UserSelector';

const UserList = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { 
        profileMembers, 
        setProfileMembersVisible, 
        setSelectedMember, 
        setSelectedModel, 
        setSelectedObject 
    } = useContext(Global);

    // Local State
    const [relatedMembers, setRelatedMembers] = useState([]);
    const [userSelectorOpen, setUserSelectorOpen] = useState(false);

    /** 
     * Initializes relatedMembers based on the object data when component mounts.
     */
    useEffect(() => {
        if (!object || !object[field.key]) return;

        const initializeRelatedUsers = () => {
            const existingUserKeys = object[field.key];
            const existingUsers = profileMembers.filter(user => existingUserKeys.includes(user.userKey));
            setRelatedMembers(existingUsers);
        };

        initializeRelatedUsers();
    }, [object, profileMembers, field.key]);

    /** 
     * Handles user selection updates from UserSelector.
     */
    const handleUserSelect = (selectedUsers) => {
        setRelatedMembers(selectedUsers); // Update the selected users state

        // Extract user keys to update the parent component
        const selectedUserKeys = selectedUsers.map(user => user.userKey);
        onUpdate(field, selectedUserKeys); // Notify parent component of the updated selection
    };

    /** 
     * Function to handle the forward action (for navigation or user details).
     */
    const handleForward = (e, member) => {
        e.stopPropagation();

        setProfileMembersVisible(true);
        setSelectedMember(member);
        setSelectedObject(null);
        setSelectedModel(null);
    };

    const handleSelectClick = (e) => {
        e.stopPropagation();
        setUserSelectorOpen(true);
    };

    return (
        <FieldContainer
            field={field}
            onClick={handleSelectClick}>
            <FieldHeader
                field={field}
                showFieldMenu={showFieldMenu}
            />

            {/* Display selected users in list format */}
            <div className="user-list-items">
                {relatedMembers.map((member, index) => (
                    <div key={member.userKey || index} className="user-list-item">
                        <div className="user-list-item-summary" onClick={(e) => handleForward(e, member)}>
                            <MemberSummary member={member} />
                        </div>
                        <div className="user-list-item-forward" onClick={(e) => handleForward(e, member)}>
                            <ForwardIcon color={theme.foregroundColor} width="16" height="16" />
                        </div>
                    </div>
                ))}
            </div>

            {/* NO USERS */}
            {relatedMembers.length === 0 &&
                <div
                    className="user-list-summary-no-users"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    {relatedMembers.length === 0 && 'No members selected'}
                </div>
            }

            {/* USER SELECTOR */}
            <UserSelector
                selectedUsers={relatedMembers} // Pass selected users directly as objects
                isOpen={userSelectorOpen}
                onClose={() => setUserSelectorOpen(false)}
                onSelect={handleUserSelect}
            />

        </FieldContainer>
    );
};

export default UserList;
