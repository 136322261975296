import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../firebaseConfig';

// Utilities
import { Section } from '../../utilities/Enums';
import { generateKey } from '../../utilities/Keys';
import { getUserDisplayValue, userIsCreator } from '../../utilities/Users';

// Styles
import './MemberProfile.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import ForumIcon from '../../../common/svg/ForumIcon';

// Components
import AccountAboutHeaderPhoto from '../../../account/about/AccountAboutHeaderPhoto';
import AccountAboutPhoto from '../../../account/about/AccountAboutPhoto';
import MemberMenu from '../menu/MemberMenu';
import MemberRoleSelector from '../roleselector/MemberRoleSelector';

// Managers
import MemberManager from '../../managers/MemberManager';
import DataManager from '../../managers/DataManager';

const memberManager = new MemberManager();
const dataManager = new DataManager();

const MemberProfile = () => {
    const { theme } = useTheme();
    const {
        profileRoles,
        currentUser,
        profile,
        selectedMember,
        setBackButtonAction,
        setBackVisible,
        setCurrentSection,
        setSelectedConversation,
        setSelectedMember,
    } = useContext(Global);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedMember(null);
                setBackVisible(false);
            };
        });

        if (selectedMember) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedMember, setSelectedMember, setBackButtonAction, setBackVisible]);

    // Function to format date
    const formatDate = (date) => {
        // Check if the date is a Firebase Timestamp object
        if (date && date.toDate) {
            date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
        }

        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleRoleSelection = async (selectedRoles) => {

        // Update roles in the database
        const updatedUser = {
            ...selectedMember,
            roles: selectedRoles
        };

        setSelectedMember(updatedUser);

        try {
            await dataManager.update(
                collections.appusers,
                profile.key,
                selectedMember.key,
                updatedUser
            );
        } catch (error) {
            console.error('Error updating roles:', error);
        }
    };

    const handleUserRemove = async () => {
        // Call to remove user from backend or database
        await memberManager.removeMember(profile.key, selectedMember);

        setSelectedMember(null);
    };

    const handleMessageClick = async () => {

        const selectedUsers = [];

        // Add the users
        selectedUsers.push(currentUser);
        selectedUsers.push(selectedMember);

        const now = Timestamp.now();

        const key = generateKey();

        const data = {
            profileKey: profile.key,
            key: key,
            participants: selectedUsers,
            lastMessageRead: false,
            dateCreated: now
        };

        await dataManager.add(collections.conversations, profile.key, key, data);

        // Set the selected conversation
        setCurrentSection(Section.MESSAGES);
        setSelectedConversation(data);

    };

    if (!selectedMember) return null;

    return (

        <div className="member-profile-container">

            <div className="member-profile-top">

                {/* HEADER */}
                <div className="member-profile-header-wrapper">
                    <AccountAboutHeaderPhoto
                        user={selectedMember}
                    />
                </div>

                {/* USER PHOTO */}
                <div className="member-profile-photo-wrapper"
                    style={{
                        borderColor: theme.backgroundColorFaded
                    }}>
                    <AccountAboutPhoto
                        user={selectedMember}
                        size="135"
                    />
                </div>

                {/* MENU */}
                {profile.userKey === currentUser.userKey &&
                    <div className="member-profile-menu">
                        <MemberMenu
                            onRemove={handleUserRemove}
                        />
                    </div>
                }

                {/* NAME */}
                <div className="member-profile-name"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {getUserDisplayValue(profile.displayKey, selectedMember)}
                </div>

                {/* JOINED */}
                <div className="member-profile-joined"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    Joined {formatDate(selectedMember.dateJoined)}
                </div>

                {/* BUTTONS */}
                <div className="member-profile-buttons"
                    title={`Message ${getUserDisplayValue(profile.displayKey, selectedMember)}`}>

                    {/* SEND MESSAGE */}
                    {currentUser.userKey !== selectedMember.userKey &&
                        <div className="member-profile-button"
                            onClick={handleMessageClick}>

                            <ForumIcon
                                color={theme.foregroundColor}
                                width="22"
                                height="22"
                            />

                        </div>
                    }

                </div>

            </div>

            <div className="member-profile-bottom">

                {userIsCreator(currentUser, profileRoles) ? (

                    <div className="member-profile-roles"
                        style={{
                            borderColor: theme.backgroundColorFaded
                        }}>

                        <div className="member-profile-roles-label"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            Set Role(s):
                        </div>

                        <MemberRoleSelector
                            onRoleClick={handleRoleSelection}
                        />

                    </div>

                ) : (

                    <div className="member-profile-selected-roles">

                        <MemberRoleSelector
                            readonly={true}
                        />

                    </div>

                )}

                {/**
                    {profile.userKey !== user.key &&
                        <div className="member-profile-delete-button-container">
                            <button type="button" onClick={handleRemove}
                                className="member-profile-delete-button"
                                style={{
                                    backgroundColor: theme.highlightBackgroundColor,
                                    color: theme.highlightForegroundColor
                                }}>
                                {currentUser.userKey === user.key ? (
                                    <>
                                        Leave App
                                    </>
                                ) : (
                                    <>
                                        Remove from App
                                    </>
                                )}
                            </button>
                        </div>
                    }

                     */}

            </div>

        </div>

    );
};

export default MemberProfile;
