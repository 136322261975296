import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { MemberThumbDirection } from '../utilities/Enums';
import { getUserDisplayValue } from '../utilities/Users';

// Styles
import './ConversationToolbar.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ConversationMenu from './menu/ConversationMenu';
import MemberThumbs from '../components/memberthumbs/MemberThumbs';

// Managers
import DataManager from '../managers/DataManager';

const dataManager = new DataManager();

const ConversationToolbar = () => {
    const { theme } = useTheme();
    const {
        profile,
        selectedConversation,
        setBackButtonAction, // Navigation
        setBackVisible, // Navigation
        setSelectedConversation,
        currentUser
    } = useContext(Global);

    // When this component is rendered, the selectedConversation is already set. So
    // if we're on mobile, we'll display the back button to allow the user to go back.
    useEffect(() => {
        if (isMobile && selectedConversation) {

            setBackButtonAction(() => {
                return () => {
                    setSelectedConversation(null);
                    setBackVisible(false);
                };
            });

            setBackVisible(true);

            return () => { // Reset on unmount
                setBackVisible(false);
                setBackButtonAction(null);
            };
        }
    }, [
        selectedConversation, 
        setBackButtonAction, 
        setBackVisible, 
        setSelectedConversation
    ]);

    /**
     * Deletes the selected conversation.
     */
    const handleDelete = async () => {
        await dataManager.delete(collections.conversations, profile.key, selectedConversation.key);
        setSelectedConversation(null);
    };

    // Render title and participants dynamically
    const renderTitle = () => {
        if (!selectedConversation) return 'No Conversation Selected';

        const nonCurrentUserParticipants = selectedConversation.participants?.filter(
            (p) => p.userKey !== currentUser.userKey
        ) || [];

        const displayedParticipants = nonCurrentUserParticipants.slice(0, 2);
        const additionalParticipantsCount = nonCurrentUserParticipants.length - displayedParticipants.length;

        const participantNames = displayedParticipants.map((participant) =>
            getUserDisplayValue(profile.displayName, participant)
        );

        return participantNames.length > 0
            ? `${participantNames.join(', ')}${additionalParticipantsCount > 0 ? ` +${additionalParticipantsCount}` : ''}`
            : 'No Participants';
    };

    return (
        <div className="conversation-toolbar-container">

            <div className="conversation-toolbar-left">
                <div
                    className="conversation-toolbar-title"
                    style={{ color: theme.foregroundColorFaded }}>
                    {renderTitle()}
                </div>

                <MemberThumbs
                    members={selectedConversation?.participants}
                    direction={MemberThumbDirection.LTR}
                    borderWidth={1}
                    size={24}
                    offset={20}
                    showActive={false}
                />

                {selectedConversation?.participants?.filter(
                    (p) => p.userKey !== currentUser.userKey
                ).length > 2 && (
                        <span style={{ marginLeft: 8, color: theme.foregroundColorFaded }}>
                            +{selectedConversation.participants.filter(
                                (p) => p.userKey !== currentUser.userKey
                            ).length - 2}
                        </span>
                    )}
            </div>
            <div className="conversation-toolbar-right">

                <ConversationMenu
                    onDelete={handleDelete}
                />

            </div>
        </div>
    );
};

export default ConversationToolbar;
