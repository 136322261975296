import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './PermissionsDialog.css';

// Components
import CollectionPermissions from '../../appsettings/permissions/collectionpermissions/CollectionPermissions';
import Modal from '../modal/Modal';

/**
 * PermissionsDialog Component
 * 
 * This component allows the user to edit permissions for a:
 * - Calendar
 * - Channel
 * - Collection
 * 
 * @returns {JSX.Element} The rendered component.
 */
const PermissionsDialog = ({ isOpen, setOpen, model = null, channel = null, collection = null }) => {

    // Global
    const {
        selectedCalendar,
        selectedChannel,
        selectedModel,
    } = useContext(Global);

    if (!selectedModel) return null;

    const getTitle = () => {
        if (selectedCalendar) return `${selectedCalendar.title} Permissions`;
        if (selectedChannel) return `${selectedChannel.title} Permissions`;
        if (selectedModel) return `${selectedModel.title} Permissions`;
        return 'Permissions';
    };

    return (
        <>
            {/* MODAL */}
            <Modal
                title={getTitle()}
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={isMobile ? '100%' : '500px'}
                height="90%">

                <div className="permissions-dialog-container">

                    {model &&
                        <CollectionPermissions
                            model={selectedModel}
                            showHeader={false}
                        />
                    }

                </div>

            </Modal>
        </>
    );
};

export default PermissionsDialog;
