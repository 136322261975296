import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './PlaceholderCell.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import OpenIcon from '../../../common/svg/OpenIcon';

/**
 * TextCell Component
 * 
 * This component renders an input field that accepts text values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const PlaceholderCell = ({ onClick }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      profile
  } = useContext(Global);

  /**
   * Handles button click.
   * 
   * This function sets the background and foreground colors to highlight colors
   * when the input is focused.
   * 
   * @param {Object} e - The event object.
   */
  const handleClick = (e) => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className="placeholder-cell-container"
      onClick={handleClick}
      style={{
        borderRightColor: theme.backgroundColorFaded,
        borderBottomColor: theme.backgroundColorFaded,
        color: theme.backgroundColorFaded,
        fontFamily: profile.fontFamily
      }}>
      <OpenIcon
        color={theme.foregroundColorFaded}
        width="22"
        height="22"
      />
    </div>
  );
};

export default PlaceholderCell;
