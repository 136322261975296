import React from 'react';

// Styles
import './FormDeleteConfirmation.css';

// Components
import Modal from '../../common/components/modal/Modal';
import ModalButton from '../../common/components/modal/buttons/ModalButton';
import ModalButtons from '../../common/components/modal/buttons/ModalButtons';
import ModalMessage from '../../common/components/modal/message/ModalMessage';

const FormDeleteConfirmation = ({ isOpen, setIsOpen, onConfirm, onCancel }) => {

    return (

        <>
            {isOpen &&

                <Modal
                    title="Delete"
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    width="340px">

                    {/* CONFIRM MESSAGE */}
                    <ModalMessage text="Are you sure you want to permanently delete this record?" />

                    {/* BUTTONS */}
                    <ModalButtons>

                        {/* CANCEL BUTTON */}
                        <ModalButton
                            onClick={onCancel}
                            label="No"
                        />

                        {/* CONFIRM BUTTON */}
                        <ModalButton
                            onClick={onConfirm}
                            label="Yes"
                        />

                    </ModalButtons>
                    
                </Modal>

            }
        </>
    );
};

export default FormDeleteConfirmation;
