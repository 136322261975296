import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

// Styles
import './StylePicker.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

const StylePicker = ({ value, styleName, options = [], onChange }) => {
    const { theme } = useTheme();

    const [isOpen, setIsOpen] = useState(false);

    // Find the initial style object based on the provided value
    const initialOption = options.find(option => option.value === value) || options[0];
    const [selectedOption, setSelectedOption] = useState(initialOption);

    const [popupPosition, setPopupPosition] = useState({ left: 0, top: 0 });
    const popupRef = useRef(null);

    // Update the selected option when the value prop changes
    useEffect(() => {
        // Find the new selected option based on the new value
        const newSelectedOption = options.find(option => option.value === value) || options[0];
        setSelectedOption(newSelectedOption);
    }, [value, options]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mouseup', handleClickOutside);
        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);

    const handleStyleSelect = (style) => {
        const option = options.find(w => w.value === style);
        setSelectedOption(option);
        setIsOpen(false);
        onChange(style);
    };

    const handleStyleClick = (event) => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const popupWidth = 200; // Approximate width of the popup

        // Declare the constants here
        const optionHeight = 30; // Approximate height of each option (adjust as needed)
        const maxVisibleOptions = 5; // Maximum number of options to show without scrolling

        // Calculate the height based on the number of options
        const popupHeight = Math.min(options.length * optionHeight, maxVisibleOptions * optionHeight);

        let x = event.clientX;
        let y = event.clientY;

        if (x + popupWidth > viewportWidth) {
            x = viewportWidth - popupWidth;
        }
        if (y + popupHeight > viewportHeight) {
            y = viewportHeight - popupHeight;
        }

        setPopupPosition({ x, y });
        setIsOpen(!isOpen);
    };

    const popupContent = (
        <div
            className="font-style-picker-popup"
            ref={popupRef}
            style={{
                top: `${popupPosition.y}px`,
                left: `${popupPosition.x}px`,
                backgroundColor: theme.backgroundColorFaded,
                maxHeight: `${5 * 30}px`, // Ensure the popup does not exceed max height
                overflowY: options.length > 5 ? 'scroll' : 'visible', // Enable scrolling if necessary
            }}>
            {options.map((option) => (
                <div
                    key={option.value}
                    className="font-style-picker-popup-option"
                    style={{
                        color: theme.foregroundColor
                    }}
                    onClick={() => handleStyleSelect(option.value)}>
                    <div className="font-style-picker-popup-label"
                        style={{
                            [styleName]: option.value
                        }}>
                        {option.label}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="font-style-picker-container"
            style={{
                backgroundColor: theme.backgroundColorFaded,
                borderColor: theme.backgroundColorFaded
            }}
            onClick={handleStyleClick}>
            <div
                className="font-style-picker-selected-style"
                style={{
                    color: theme.foregroundColor,
                    [styleName]: selectedOption.value,
                    textTransform: ""
                }}>
                {selectedOption.label}
            </div>
            {isOpen && ReactDOM.createPortal(popupContent, document.body)}
        </div>
    );
};

export default StylePicker;
