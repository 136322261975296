import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { ObjectsView, Permission } from '../../utilities/Enums';
import { hasAppPermission, hasCollectionPermission } from '../../utilities/Permissions';

// Styles
import './CollectionMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DashboardIcon from '../../svg/DashboardIcon';
import DateIcon from '../../svg/DateIcon';
import DownIcon from '../../svg/DownIcon';
import DeleteIcon from '../../svg/DeleteIcon';
import DotsVerticalIcon from '../../svg/DotsVerticalIcon';
import FieldIcon from '../../svg/FieldIcon';
import LockIcon from '../../svg/LockIcon';
import SettingsIcon from '../../svg/SettingsIcon';
import TableIcon from '../../svg/TableIcon';
import TilesIcon from '../../svg/TilesIcon';
import UpIcon from '../../svg/UpIcon';

// Components
import CollectionDelete from '../delete/CollectionDelete';
import CollectionEdit from '../edit/CollectionEdit';
import ContextMenuButton from '../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';
import PermissionsDialog from '../../components/permissionsdialog/PermissionsDialog';

// Managers
import ProfileManager from '../../managers/ProfileManager';

const profileManager = new ProfileManager();

const CollectionMenu = () => {
  const { theme } = useTheme();
  const {
    profileFields,
    profileObjects,
    profile,
    currentUser,
    selectedModel,
    setCurrentUser,
    setSortDirection,
    setSortField,
    setModelView,
    sortDirection
  } = useContext(Global);

  // Local State
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dynamicSortOptions, setDynamicSortOptions] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [permissionsOpen, setPermissionsOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });

  /**
   * Effect to generate dynamic sort options based on the selected model's fields
   */
  useEffect(() => {

    const sortTypes = [
      'countries',
      'currency',
      'day',
      'lookup',
      'month',
      'number',
      'phone',
      'states',
      'text',
      'time',
      'year'
    ];

    if (!profileObjects || profileObjects.length === 0) return;

    const modelFields = profileFields
      .filter(field => field.modelKey === selectedModel.key && sortTypes.includes(field.type));

    let newDynamicSortOptions = [];

    for (var i = 0; i < modelFields.length; i++) {
      const { key, title, type } = modelFields[i];

      if (sortTypes.includes(type)) {
        newDynamicSortOptions.push({ title, key: key, direction: 'ASC' });
      }

      setDynamicSortOptions(newDynamicSortOptions);
    }
  }, [profileObjects, profileFields, selectedModel.key]);

  // Toggle visibility function
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ left: event.clientX, top: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }

  const handleAscending = () => {
    setSortDirection("ASC");
    setMenuVisible(false);
  }

  const handleDescending = () => {
    setSortDirection("DESC");
    setMenuVisible(false);
  }

  const handleFieldSelection = (key) => {
    setMenuVisible(false);
    setSortField(key);
    setSortDirection(sortDirection);
  };

  const handleListClick = () => {
    setModelView(ObjectsView.TILES);
    setMenuVisible(false);
  };

  const handleGridClick = () => {
    setModelView(ObjectsView.GRID);
    setMenuVisible(false);
  };

  const handleTableClick = () => {
    setModelView(ObjectsView.TABLE);
    setMenuVisible(false);
  };

  const handleEditCollection = () => {
    setEditOpen(true);
    setMenuVisible(false);
  };

  const handleEditPermissions = () => {
    setPermissionsOpen(true);
    setMenuVisible(false);
  };

  const handleDeleteCollection = () => {
    setDeleteOpen(true);
    setMenuVisible(false);
  };

  return (
    <div>

      {/* BUTTON */}
      <ContextMenuButton
        title={`${selectedModel.title} Options`}
        onToggle={toggleMenu}
        icon={DotsVerticalIcon}
      />

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={240}
        position={menuPosition}>

        {/* MANAGE SECTION */}
        {profile && hasAppPermission(profile, currentUser, Permission.MANAGE_MODELS) &&
          <ContextMenuGroup title={selectedModel.title}>

            {/* EDIT MODEL */}
            {hasCollectionPermission(profile, currentUser, selectedModel, Permission.UPDATE) &&
              <ContextMenuOption
                icon={SettingsIcon}
                text="Settings"
                onClick={handleEditCollection}
                iconSize="11"
              />
            }

            {/* PERMISSIONS */}
            {hasCollectionPermission(profile, currentUser, selectedModel, Permission.UPDATE) &&
              <ContextMenuOption
                icon={LockIcon}
                text="Permissions"
                onClick={handleEditPermissions}
                iconSize="11"
              />
            }

            {/* DELETE MODEL */}
            {hasCollectionPermission(profile, currentUser, selectedModel, Permission.DELETE) &&
              <ContextMenuOption
                icon={DeleteIcon}
                text="Delete Collection"
                onClick={handleDeleteCollection}
                iconSize="11"
              />
            }

          </ContextMenuGroup>
        }

        {/* VIEW SECTION */}
        <ContextMenuGroup title="View">

          {/* GRID */}
          <ContextMenuOption
            icon={DashboardIcon}
            text="Summaries"
            onClick={handleGridClick}
          />

          {/* CARDS */}
          <ContextMenuOption
            icon={TilesIcon}
            text="Tiles"
            onClick={handleListClick}
          />

          {/* TABLE */}
          <ContextMenuOption
            icon={TableIcon}
            text="Table" 
            onClick={handleTableClick}
            iconSize="12"
          />

        </ContextMenuGroup>

        {/* SORT BY SECTION */}
        <ContextMenuGroup title="Sort By">

          {/* DYNAMIC FIELDS */}
          {dynamicSortOptions.map((option) => (

            <ContextMenuOption
              key={option.key}
              icon={FieldIcon}
              text={option.title}
              onClick={() => handleFieldSelection(option.key)}
              iconSize="15"
            />

          ))}

          {/* DATE CREATED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Date Added"
            onClick={() => handleFieldSelection('CREATED')}
          />

          {/* DATE MODIFIED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Last Modified"
            onClick={() => handleFieldSelection('MODIFIED')}
          />

        </ContextMenuGroup>

        {/* SORT DIRECTION SECTION */}
        <ContextMenuGroup title="Sort Direction">

          {/* ASCENDING */}
          <ContextMenuOption
            icon={UpIcon}
            text="Ascending"
            onClick={handleAscending}
            iconSize="11"
          />

          {/* DESCENDING */}
          <ContextMenuOption
            icon={DownIcon}
            text="Descending"
            onClick={handleDescending}
            iconSize="11"
          />

        </ContextMenuGroup>

      </ContextMenuPopup>

      {/* COLLECTION EDIT */}
      <CollectionEdit
        isOpen={editOpen}
        setOpen={setEditOpen}
      />

      {/* COLLECTION PERMISSIONS */}
      <PermissionsDialog
        isOpen={permissionsOpen}
        setOpen={setPermissionsOpen}
        model={selectedModel}
      />

      {/* COLLECTION DELETE */}
      <CollectionDelete
        isOpen={deleteOpen}
        setOpen={setDeleteOpen}
      />

    </div>
  );
};

export default CollectionMenu;
