import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Newsfeed.css';

// Components
import NewsfeedToolbar from './toolbar/NewsfeedToolbar';
import NewsfeedGridItem from './item/NewsfeedGridItem';
import Grid from '../../common/components/grid/Grid';

const Newsfeed = () => {

    // Global
    const {
        selectedNewsfeed,
    } = useContext(Global);

    // Local State
    const [items, setNewsItems] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchNewsAPINews = async (searchQuery) => {
        try {
            setLoading(true);

            const response = await fetch(
                `https://us-central1-appdeckmain.cloudfunctions.net/fetchNewsAPINews?q=${encodeURIComponent(searchQuery)}`
            );

            if (!response.ok) {
                throw new Error(`Error fetching news from NewsAPI: ${response.status}`);
            }

            const fetchedItems = await response.json();
            setNewsItems(fetchedItems);
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedNewsfeed) return;
        fetchNewsAPINews(selectedNewsfeed.title);
    }, [selectedNewsfeed]);

    const handleItemClick = (url) => {
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <div className="newsfeed-container">
            {/* TOOLBAR */}
            <NewsfeedToolbar />

            {/* CONTENT */}
            <div className={isMobile ? "newsfeed-grid-container-mobile" : "newsfeed-grid-container"} >
                <Grid
                    items={items}
                    renderItem={(item) => (
                        <NewsfeedGridItem
                            key={item.guid}
                            item={item}
                            onClick={handleItemClick}
                        />
                    )}
                    loading={loading}
                    minCols={2}
                    colWidth={280}
                />
            </div>

        </div>
    );
};

export default Newsfeed;
