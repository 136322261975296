import React from 'react';

// Styles
import './GridItemTitle.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * GridItemTitle
 * 
 * Component to display the title of a grid item.
 * 
 * @param {string} title - The title of the grid item.
 * @param {boolean} selected - Whether the item is selected.
 * 
 * @returns {JSX.Element} GridItemTitle component.
 */
const GridItemTitle = ({ title = "", selected = false }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div 
            className="grid-item-title-container"
            style={{
                color: selected ? theme.highlightForegroundColor : theme.foregroundColor,
                fontFamily: theme.fontFamily,
            }}>
            {title}
        </div>

    );
};

export default GridItemTitle;
