import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section } from '../../utilities/Enums';
import { hasAppPermission } from '../../utilities/Permissions';

// Styles
import './Channels.css';

// Images
import ForumIcon from '../../../common/svg/ForumIcon';
import HashtagIcon from '../../../common/svg/HashtagIcon';
import UsersIcon from '../../../common/svg/UsersIcon';
import VideoIcon from '../../../common/svg/VideoIcon';

// Components
import ChannelAdd from '../../../common/channel/add/ChannelAdd';
import MenuGroup from '../menugroup/MenuGroup';
import MenuItem from '../menuitem/MenuItem';

// Utilities
import { ChannelType } from '../../utilities/Enums';

// Managers
import ProfileManager from '../../../common/managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * Channels Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Channels = () => {

    // Global
    const {
        profileChannels,
        profile,
        currentUser,
        resetSelections,
        selectedChannel,
        setChannelAddOpen,
        setCurrentSection,
        setMenuVisible,
        setSelectedChannel,
        setSelectedModel,
    } = useContext(Global);

    /**
     * Handles a channel click event.
     * 
     * @param {object} channel - Channel that was clicked on.
     */
    const handleChannelClick = async (channel) => {
        resetSelections();

        setSelectedModel(null);
        setSelectedChannel(channel);

        if (channel.type === ChannelType.TEXT) { setCurrentSection(Section.CHAT) }
        if (channel.type === ChannelType.FORUM) { setCurrentSection(Section.FORUM) }
        if (channel.type === ChannelType.VIDEO) { setCurrentSection(Section.VIDEOCHAT) }
        
    };

    /**
     * Gets the appropriate icon for the channel type.
     **/
    const getIcon = (channel) => {
        if (channel.type === 'TEXT') return HashtagIcon;
        if (channel.type === 'FORUM') return ForumIcon;
        if (channel.type === 'VIDEO') return VideoIcon;
    };

    /**
     * Handles an add channel button click event.
     */
    const handleAddChannelClick = () => {
        setChannelAddOpen(true);
        setMenuVisible(false);
    };

    /**
     * Handles clicking on the members item.
     */
    const handleMembersClick = () => {
        resetSelections();
        setCurrentSection(Section.MEMBERS);
    };

    /**
     * Handles clicking on the messages item.
     */
    const handleMessagesClick = () => {
        resetSelections();
        setCurrentSection(Section.MESSAGES);
    };

    return (
        <>
            <MenuGroup
                title="Shared Spaces"
                showAdd={profile && hasAppPermission(profile, currentUser, Permission.MANAGE_CHANNELS)}
                onAddClick={handleAddChannelClick}>

                {/* MEMBERS 
                <MenuItem
                    key="MEMBERS"
                    title="Members"
                    icon={UsersIcon}
                    isActive={currentSection === Section.MEMBERS}
                    onClick={handleMembersClick}
                />
                */}

                {/* DIRECT MESSAGES 
                <MenuItem
                    key="MESSAGES"
                    title="Direct Messages"
                    icon={ForumIcon}
                    isActive={currentSection === Section.MESSAGES}
                    onClick={handleMessagesClick}
                />
                */}

                {profileChannels.map(channel => (
                    <MenuItem
                        key={channel.key}
                        title={channel.title}
                        icon={getIcon(channel)}
                        isActive={selectedChannel && selectedChannel.key === channel.key}
                        onClick={() => handleChannelClick(channel)}
                    />
                ))}

            </MenuGroup>

            {/* FORM */}
            <ChannelAdd />

        </>
    );
};

export default Channels;
