import React from 'react';

const MessagesIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M824.62-187.69 701.54-310.77H320q-25.38 0-43.46-18.08-18.08-18.07-18.08-43.46v-9.23h430.77q26.65 0 45.64-18.98 18.98-18.98 18.98-45.63V-720h9.23q25.38 0 43.46 18.08 18.08 18.07 18.08 43.46v470.77ZM175.38-435.31l66.24-66.23h367.61q10.77 0 17.69-6.92 6.93-6.92 6.93-17.69v-249.23q0-10.77-6.93-17.7Q620-800 609.23-800H200q-10.77 0-17.69 6.92-6.93 6.93-6.93 17.7v340.07Zm-40 96.85v-436.92q0-26.66 18.99-45.64Q173.35-840 200-840h409.23q26.65 0 45.64 18.98 18.98 18.98 18.98 45.64v249.23q0 26.65-18.98 45.63-18.99 18.98-45.64 18.98H258.46L135.38-338.46Zm40-187.69V-800-526.15Z" />
  </svg>

);

export default MessagesIcon;
