import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Translation
import { useTranslation } from 'react-i18next';

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../../firebaseConfig';

// Utilities
import { MenuSection, Section } from '../../utilities/Enums';

// Styles
import './MenuBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AppDelete from '../../appsettings/appdelete/AppDelete';
import FormDesigner from '../../form/designer/FormDesigner';
import MenuBarButton from './MenuBarButton';
import SiteDesigner from '../../../desktop/sitedesigner/SiteDesigner';

const MenuBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileSpaces,
        resetSelections,
        setCurrentSection,
        setSelectedFolder,
        setSelectedSetting,
        settings
    } = useContext(Global);

    // State Variables
    const [deleteVisible, setDeleteVisible] = useState('');
    const [siteDesignerVisible, setSiteDesignerVisible] = useState(false);
    const [formDesignerOpen, setFormDesignerOpen] = useState(false);

    // Translation
    const { t } = useTranslation('MenuBar');

    const handleSpaceSelect = async () => {
        setCurrentSection(Section.SPACES);
    }


    const handleCalendarClick = async () => {
        setCurrentSection(Section.CALENDARS);
    }

    /**
     * Sets the current section to FOLDERS and resets the selected folder to
     * null, allowing the folder DESKTOP to be displayed.
     */
    const handleFoldersClick = async () => {
        setCurrentSection(Section.FOLDERS);
        setSelectedFolder(null);
    }

    const handleLogout = async () => {
        await signOut(auth);
    };

    const handleSettingSelect = async (opt) => {

        if (opt.key === "DELETE") {
            handleDelete();
            return;
        }

        if (opt.key === "WEBSITE") {
            handleWebsite();
            return;
        }

        if (opt.key === "SIGNOUT") {
            handleLogout();
            return;
        }

        resetSelections();
        setSelectedSetting(settings.find(option => option.key === opt.key));
        setCurrentSection(Section.SETTINGS);
    }

    /**
     * Method to handle deleting an app.
     */
    const handleDelete = async () => {
        setDeleteVisible(true);
    };

    /**
     * Method to handle deleting an app.
     */
    const handleWebsite = async () => {
        setSiteDesignerVisible(true);
    };

    const handleForYouSelect = async () => {
        setCurrentSection(Section.FORYOU);
    };

    return (
        <>
            <div className="menu-bar-container">

                {/* SECTION BUTTONS */}
                <div className="menu-bar-buttons">

                    {/* FOLDERS */}
                    <MenuBarButton
                        section={MenuSection.FOLDERS}
                        title={t('MenuBar:button_my_files')}
                        onClick={handleFoldersClick}
                    />

                    {/* SPACES */}
                    <MenuBarButton
                        section={MenuSection.SPACES}
                        title={t('MenuBar:button_shared_spaces')}
                        options={profileSpaces}
                        onSelect={handleSpaceSelect}
                    />

                    {/* FOR YOU */}
                    <MenuBarButton
                        section={MenuSection.FORYOU}
                        title={t('MenuBar:button_for_you')}
                        onClick={handleForYouSelect}
                    />

                    {/* MY PROFILE */}
                    <MenuBarButton
                        section={MenuSection.SETTINGS}
                        title={t('MenuBar:button_my_profile')}
                        options={settings}
                        onSelect={handleSettingSelect}
                        showAddButton={false}
                    />

                </div>

            </div>

            {/* DELETE */}
            <AppDelete
                isOpen={deleteVisible}
                setOpen={setDeleteVisible}
            />

            {/* WEBSITE */}
            <SiteDesigner
                open={siteDesignerVisible}
                setOpen={setSiteDesignerVisible}
            />

            {/* FORM DESIGNER */}
            <FormDesigner
                open={formDesignerOpen}
                setOpen={setFormDesignerOpen}
            />

        </>
    );
};

export default MenuBar;
