import React from 'react';

// Styles
import './ModalInput.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ModalInput Component
 * 
 * This component renders a text input field for a modal dialog.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalInput = ({ value, onChange, maxLength, autoFocus = false }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <input 
      type="text"
      className="modal-input-container"
      value={value}
      onChange={onChange}
      style={{
        backgroundColor: theme.backgroundColorFaded,
        borderColor: theme.backgroundColorFaded,
        color: theme.foregroundColor
      }}
      maxLength={maxLength}
      autoFocus={autoFocus}
    />
  );
};

export default ModalInput;

