import React from 'react';

// Styles
import './ForumPostThumb.css';

const ForumPostThumb = ({ url }) => {

    return (
        <div className="forum-post-thumb-container">
            <img
                src={url}
                alt=""
                className="forum-post-thumb"
            />
        </div>
    );
};

export default ForumPostThumb;
