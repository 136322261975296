import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { Permission } from '../../../../common/utilities/Enums';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './CollectionPermissions.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../../common/svg/CheckOnIcon';
import ChevronDownIcon from '../../../../common/svg/ChevronDownIcon';
import ChevronUpIcon from '../../../../common/svg/ChevronUpIcon';

// Components
import CollectionPermission from './permission/CollectionPermission';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

const CollectionPermissions = ({ model, showHeader = true }) => {
    const { theme } = useTheme();
    const { profile, profileRoles } = useContext(Global);

    // Permissions state
    const [expanded, setExpanded] = useState(true);
    const [permissions, setPermissions] = useState({});
    const [creatorOnly, setCreatorOnly] = useState(false);

    // Initialize permissions and creatorOnly
    useEffect(() => {
        if (!model) return;
        setPermissions(model.permissions || {});
        setCreatorOnly(model.permissions?.creatorOnly || false);
    }, [model]);

    // Save permissions to Firestore
    const savePermissions = async (updatedPermissions) => {
        await dataManager.update(
            collections.models,
            profile.key,
            model.key,
            { permissions: updatedPermissions }
        );
    };

    // Handle permission change
    const handlePermissionChange = (roleKey, type, isEnabled) => {
        const updatedPermissions = {
            ...permissions,
            roles: {
                ...permissions.roles,
                [roleKey]: {
                    ...permissions.roles?.[roleKey],
                    [type]: isEnabled,
                },
            },
        };

        // Update state
        setPermissions(updatedPermissions);

        // Save the updated permissions
        savePermissions(updatedPermissions);
    };

    // Toggle creatorOnly flag
    const toggleCreatorOnly = () => {
        const updatedCreatorOnly = !creatorOnly;

        const updatedPermissions = {
            ...permissions,
            creatorOnly: updatedCreatorOnly,
        };

        setCreatorOnly(updatedCreatorOnly);
        setPermissions(updatedPermissions);

        // Save the updated permissions
        savePermissions(updatedPermissions);
    };

    if (!model) return null;

    return (
        <div className="collection-permissions-container">

            {/* HEADER */}
            {showHeader && (
                <div className="collection-permissions-model-header">
                    {/* CHEVRON */}
                    <div
                        className="collection-permissions-model-chevron"
                        onClick={() => setExpanded(!expanded)}
                    >
                        {expanded ? (
                            <ChevronUpIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />
                        ) : (
                            <ChevronDownIcon
                                color={theme.foregroundColorFaded}
                                width="20"
                                height="20"
                            />
                        )}
                    </div>

                    {/* MODEL TITLE */}
                    <div
                        className="collection-permissions-model-title"
                        onClick={() => setExpanded(!expanded)}
                        style={{
                            color: theme.foregroundColor,
                        }}
                    >
                        {model.title}
                    </div>
                </div>
            )}

            {/* CONTENTS */}
            {expanded && (
                <div className="collection-permissions-model-contents">
                    {/* CREATOR ONLY */}
                    <div
                        className="collection-permissions-creator-only-container"
                        onClick={toggleCreatorOnly}
                    >
                        <div className="collection-permissions-creator-only-checkbox">
                            {creatorOnly ? (
                                <CheckOnIcon
                                    color={theme.highlightBackgroundColor}
                                    checkColor={theme.highlightForegroundColor}
                                    width="16"
                                    height="16"
                                />
                            ) : (
                                <CheckOffIcon
                                    color={theme.highlightBackgroundColor}
                                    width="16"
                                    height="16"
                                />
                            )}
                        </div>
                        <div
                            className="collection-permissions-creator-only-label"
                            style={{
                                color: theme.foregroundColor,
                            }}
                        >
                            Restrict updates and deletions to record creators.
                        </div>
                    </div>

                    {/* ROLE PERMISSIONS */}
                    {profileRoles &&
                        profileRoles
                            .slice()
                            .sort((a, b) => a.priorityLevel - b.priorityLevel)
                            .map((role) => (
                                <React.Fragment key={role.key}>
                                    {!role.isCreator && (
                                        <div
                                            className="collection-permissions-role-container"
                                            style={{
                                                borderColor: theme.backgroundColorFaded,
                                            }}
                                        >
                                            <div
                                                className="collection-permissions-role-title"
                                                style={{
                                                    color: theme.foregroundColor,
                                                }}
                                            >
                                                {role.title}
                                            </div>

                                            {/* CRUD PERMISSIONS */}
                                            <CollectionPermission
                                                model={model}
                                                type={Permission.CREATE}
                                                role={role}
                                                title="Create"
                                                isChecked={permissions?.roles?.[role.key]?.[Permission.CREATE] ?? true}
                                                onChange={handlePermissionChange}
                                            />
                                            <CollectionPermission
                                                model={model}
                                                type={Permission.READ}
                                                role={role}
                                                title="Read"
                                                isChecked={permissions?.roles?.[role.key]?.[Permission.READ] ?? true}
                                                onChange={handlePermissionChange}
                                            />
                                            <CollectionPermission
                                                model={model}
                                                type={Permission.UPDATE}
                                                role={role}
                                                title="Update"
                                                isChecked={permissions?.roles?.[role.key]?.[Permission.UPDATE] ?? true}
                                                onChange={handlePermissionChange}
                                            />
                                            <CollectionPermission
                                                model={model}
                                                type={Permission.DELETE}
                                                role={role}
                                                title="Delete"
                                                isChecked={permissions?.roles?.[role.key]?.[Permission.DELETE] ?? true}
                                                onChange={handlePermissionChange}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                </div>
            )}
        </div>
    );
};

export default CollectionPermissions;
