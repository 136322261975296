import React, { useEffect, useContext, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Utilities
import { getElapsedTime } from '../../utilities/DateTime';
import { generateKey } from '../../utilities/Keys';
import { getMember, getUserDisplayValue } from '../../utilities/Users';

// Styles
import './StoryComments.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserThumb from '../../components/userthumb/UserThumb';

// Managers
import CommentManager from '../../managers/CommentManager';

const commentManager = new CommentManager();

const StoryComments = ({
    post
}) => {
    const { theme } = useTheme();
    const {
        profileMembers,
        currentUser,
        profile,
    } = useContext(Global);

    const [newComment, setNewComment] = useState('');
    const [addCommentVisible, setAddCommentVisible] = useState(false);
    const [comments, setComments] = useState([]);
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [showReplies, setShowReplies] = useState({});

    const [mainReplyVisible, setMainReplyVisible] = useState(false);

    const mainReplyRef = useRef(null);

    useEffect(() => {
        if (mainReplyVisible && mainReplyRef.current) {
            mainReplyRef.current.focus();
        }
    }, [mainReplyVisible]);

    useEffect(() => {
        if (!post) return;

        async function fetchComments() {
            try {
                const results = await commentManager.fetchPostComments(post.key);

                // Sort the results by dateCreated from most recent to oldest
                const sortedResults = results.sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate());

                setComments(sortedResults);
            } catch (error) {
                console.error('Failed to fetch comments:', error);
            }
        }
        fetchComments();
    }, [post]);

    const handleMainAdd = async (event) => {
        setMainReplyVisible(true);
    }

    const handleAdd = async (event, parentKey = null) => {
        event.stopPropagation();

        setMainReplyVisible(false);

        // Don't allow empty text
        if (parentKey) {
            if (replyText.trim() === "") return;
        } else {
            if (newComment.trim() === "") return;
        }

        // Generate a comment key
        const key = generateKey();

        // Current timestamp
        const now = Timestamp.now();

        // Create a comment record
        const comment = {
            key: key,
            profileKey: profile.key,
            postKey: post.key,
            userKey: currentUser.userKey,
            username: currentUser.username,
            parentKey: parentKey,
            text: parentKey ? replyText : newComment,
            dateCreated: now
        };

        // Add the comment to the database
        await commentManager.addComment(key, comment);

        // Update the comments list to include the new comment
        setComments([...comments, comment]);

        // Clear the input fields after adding the comment
        if (parentKey) {
            setReplyText('');
            setReplyingTo(null);
        } else {
            setNewComment('');
            setAddCommentVisible(false);
        }
    };

    const handleShowReply = (event, comment) => {
        event.stopPropagation();
        setReplyingTo(comment.key);
        setReplyText('');
    };

    const handleToggleReplies = (commentKey) => {
        setShowReplies(prev => ({ ...prev, [commentKey]: !prev[commentKey] }));
    };

    const renderComments = (parentKey = null) => {
        return comments.filter(comment => comment.parentKey === parentKey).map(comment => (
            <div key={comment.key} className="story-comment-container">
                <UserThumb
                    user={comment}
                    size="30"
                />
                <div className="story-comment-right">
                    <div className="story-comment-header">
                        <div className="story-comment-name"
                            style={{
                                color: theme.foregroundColor
                            }}>
                            {getUserDisplayValue(profile.displayKey, getMember(profileMembers, comment.userKey))}
                        </div>
                        <div className="story-comment-timestamp"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>•  {getElapsedTime(comment.dateCreated)}
                        </div>
                    </div>
                    <div className="story-comment-text"
                        style={{
                            color: theme.foregroundColor
                        }} >
                        {comment.text}
                    </div>
                    <div className="story-comment-actions">
                        {replyingTo === comment.key ? (
                            <div className="story-comment-reply-panel">
                                <textarea
                                    name="reply"
                                    className="story-comments-reply-input"
                                    style={{
                                        backgroundColor: theme.backgroundColorFaded,
                                        borderColor: theme.backgroundColorFaded,
                                        color: theme.foregroundColor,
                                        resize: 'none', // Prevent manual resizing
                                        overflowY: 'auto', // Hide overflow text
                                        fontFamily: profile.fontFamily
                                    }}
                                    placeholder="Add a reply..."
                                    value={replyText}
                                    onChange={(e) => setReplyText(e.target.value)}
                                />
                                <div className="story-comments-reply-buttons">
                                    <div className="story-comments-add-cancel-button"
                                        style={{
                                            color: theme.foregroundColorFaded
                                        }}
                                        onClick={() => setReplyingTo(null)}>
                                        Cancel
                                    </div>
                                    <div className="story-comments-add-comment-button"
                                        style={{
                                            backgroundColor: theme.highlightBackgroundColor,
                                            color: theme.highlightForegroundColor
                                        }}
                                        onClick={(event) => handleAdd(event, comment.key)}>
                                        Reply
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="story-comment-show-reply-button"
                                style={{
                                    color: theme.foregroundColorFaded
                                }}
                                onClick={(event) => handleShowReply(event, comment)}>
                                Reply
                            </div>
                        )}
                    </div>
                    {comments.some(c => c.parentKey === comment.key) && (
                        <div className="story-comment-show-replies-button"
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor
                            }}
                            onClick={() => handleToggleReplies(comment.key)}>
                            {showReplies[comment.key] ? 'Hide Replies' : 'Show Replies'}
                        </div>
                    )}
                    {showReplies[comment.key] && (
                        <div className="story-comment-replies">
                            {renderComments(comment.key)}
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    return (
        <div className="story-comments-container">
            <div className="story-comments-right">
                <div className="story-comments-add-container">
                    {mainReplyVisible ? (
                        <textarea
                            name="comment"
                            ref={mainReplyRef} // Reference to the input element
                            className="story-comments-add-input"
                            placeholder="Add a comment..."
                            onFocus={() => setAddCommentVisible(true)}
                            style={{
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded,
                                color: theme.foregroundColor,
                                resize: 'none', // Prevent manual resizing
                                overflow: 'hidden', // Hide overflow text
                                fontFamily: profile.fontFamily
                            }}
                            value={newComment}
                            rows={1} // Initial number of rows
                            onChange={(e) => {
                                setNewComment(e.target.value);
                                e.target.style.height = 'auto'; // Reset the height
                                e.target.style.height = `${e.target.scrollHeight}px`; // Adjust the height based on scroll height
                            }}
                        />
                    ) : (
                        <div className="story-comments-add-main-comment-button"
                            style={{
                                color: theme.foregroundColorFaded
                            }}
                            onClick={(event) => handleMainAdd(event)}>
                            Add Comment
                        </div>
                    )}
                </div>
                {addCommentVisible &&
                    <div className="story-comments-buttons">
                        <div className="story-comments-add-cancel-button"
                            style={{
                                color: theme.foregroundColorFaded
                            }}
                            onClick={() => {
                                setNewComment('');
                                setAddCommentVisible(false);
                                setMainReplyVisible(false);
                            }}>
                            Cancel
                        </div>
                        <div className="story-comments-add-comment-button"
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                color: theme.highlightForegroundColor
                            }}
                            onClick={(event) => handleAdd(event)}>
                            Comment
                        </div>
                    </div>
                }
                <div className="story-comments-list">
                    {renderComments()}
                </div>
            </div>
        </div>
    );
};

export default StoryComments;
