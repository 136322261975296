import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './FolderAddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarIcon from '../../../common/svg/CalendarIcon';
import DataIcon from '../../../common/svg/DataIcon';
import FilesIcon from '../../../common/svg/FilesIcon';
import FolderIcon from '../../../common/svg/FolderIcon';
import SearchIcon from '../../../common/svg/SearchIcon';
import StoryIcon from '../../../common/svg/StoryIcon';

// Components
import AddButton from '../../../common/components/addbutton/AddButton';
import ContextMenuGroup from '../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../common/components/contextmenu/ContextMenuPopup';

const FolderAddButton = ({ onSearch, onAddFolder, onAddFiles, onAddEvent, onAddObject, onAddStory }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        profileModels,
    } = useContext(Global);

    // Local State
    const [addMenuVisible, setAddMenuVisible] = useState(false);

    /**
     * Handles click on the add button to toggle the menu.
     */
    const handleAddClick = () => {
        setAddMenuVisible(!addMenuVisible);
    };

    const handleSearchClick = () => {
        setAddMenuVisible(false);
        onSearch();
    };

    const handleAddFolderClick = () => {
        setAddMenuVisible(false);
        onAddFolder();
    };

    const handleAddFilesClick = () => {
        setAddMenuVisible(false);
        onAddFiles();
    };

    const handleAddEventClick = () => {
        setAddMenuVisible(false);
        onAddEvent();
    };

    const handleAddObjectClick = (collection) => {
        setAddMenuVisible(false);
        onAddObject(collection);
    };

    const handleAddStoryClick = () => {
        setAddMenuVisible(false);
        onAddStory();
    };

    return (
        <>
            {/* MENU */}
            <ContextMenuPopup
                visible={addMenuVisible}
                setVisible={setAddMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                position={{ right: 20, bottom: 90 }}
                width={170}>

                {/* EXISTING */}
                <ContextMenuGroup title={`Add Existing...`}>

                    {/* SEARCH */}
                    <ContextMenuOption
                        key="SEARCH"
                        icon={SearchIcon}
                        text="Search"
                        onClick={handleSearchClick}
                    />

                </ContextMenuGroup>

                {/* NEW */}
                <ContextMenuGroup title={`Add New...`}>

                    {/* FOLDER */}
                    <ContextMenuOption
                        key="FOLDER"
                        icon={FolderIcon}
                        text="Collection"
                        onClick={handleAddFolderClick}
                    />

                    {/* FILES */}
                    <ContextMenuOption
                        key="FILES"
                        icon={FilesIcon}
                        text="Files"
                        onClick={handleAddFilesClick}
                    />

                    {/* STORY */}
                    <ContextMenuOption
                        key="STORY"
                        icon={StoryIcon}
                        text="Story"
                        onClick={handleAddStoryClick}
                    />

                    {/* EVENT */}
                    <ContextMenuOption
                        key="EVENT"
                        icon={CalendarIcon}
                        text="Event"
                        onClick={handleAddEventClick}
                    />

                    {/* COLLECTIONS */}
                    {profileModels.map((collection) => (
                        <ContextMenuOption
                            key={collection.key}
                            icon={DataIcon}
                            text={collection.singular || collection.title}
                            onClick={() => handleAddObjectClick(collection)}
                        />
                    ))}

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* ADD BUTTON */}
            <AddButton onClick={handleAddClick} />

        </>

    );
};

export default FolderAddButton;
