import React, { useState, useRef, useEffect } from 'react';

// Style
import './Slider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const Slider = ({ value, onChange, onBlur }) => {
    const { theme } = useTheme();

    const [isDragging, setIsDragging] = useState(false);
    const [tempValue, setTempValue] = useState(value || 1.0);
    const sliderRef = useRef(null);

    useEffect(() => {
        setTempValue(value);
    }, [value]);

    useEffect(() => {
        if (value > 1) setTempValue(1);
    }, [tempValue, value]);

    useEffect(() => {

        const updateOpacity = (clientX) => {
            const rect = sliderRef.current.getBoundingClientRect();
            const offsetX = clientX - rect.left;
            const newValue = Math.min(1, Math.max(0, offsetX / rect.width));
            onChange(newValue);
            setTempValue(newValue);
        };

        const handleMouseMove = (event) => {
            if (!isDragging) return;
            updateOpacity(event.clientX);
        };

        const handleTouchMove = (event) => {
            if (!isDragging) return;
            updateOpacity(event.touches[0].clientX);
        };
    
        const handleMouseUp = (event) => {
            setIsDragging(false);
            updateOpacity(event.clientX);
            if (onBlur) onBlur(tempValue);
        };

        const handleTouchEnd = () => {
            setIsDragging(false);
            if (onBlur) onBlur(tempValue);
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('touchmove', handleTouchMove);
            window.addEventListener('touchend', handleTouchEnd);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging, onBlur, onChange, tempValue]);

    const handleThumbMouseDown = () => {
        setIsDragging(true);
    };

    const handleThumbTouchStart = () => {
        setIsDragging(true);
    };

    const handleTrackClick = (event) => {
        updateOpacity(event.clientX);
    };

    const handleTrackTouch = (event) => {
        updateOpacity(event.touches[0].clientX);
    };

    const updateOpacity = (clientX) => {
        const rect = sliderRef.current.getBoundingClientRect();
        const offsetX = clientX - rect.left;
        const newValue = Math.min(1, Math.max(0, offsetX / rect.width));
        onChange(newValue);
        setTempValue(newValue);
    };

    return (
        <div className="slider-container" style={{ color: theme.foregroundColor }}>
            <div
                className="slider-track"
                ref={sliderRef}
                style={{
                    background: theme.highlightBackgroundColor,
                }}
                onClick={handleTrackClick}
                onTouchStart={handleTrackTouch}>
                <div
                    className="slider-thumb"
                    style={{
                        left: `${tempValue * 100}%`,
                        backgroundColor: theme.foregroundColor,
                    }}
                    onMouseDown={handleThumbMouseDown}
                    onTouchStart={handleThumbTouchStart}
                />
            </div>
        </div>
    );
};

export default Slider;
