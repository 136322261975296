import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './LoginButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * LoginButton Component
 * 
 * This component renders a button to attempt a user login.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginButton = ({ label, enabled = false, onClick }) => {
  const { theme } = useTheme();
  const {
    coreTheme,
    profile,
  } = useContext(Global);

  console.log("enabled: ", enabled);

  return (

    <div className="login-button-container"
      onClick={onClick}
      disabled={!enabled}
      style={{
        color: enabled
          ? (profile ? theme.highlightForegroundColor : coreTheme.highlightForegroundColor)
          : (profile ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded),
        backgroundColor: enabled
          ? (profile ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor)
          : (profile ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded)
      }}>
      {label}
    </div>

  );
};

export default LoginButton;
