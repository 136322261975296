import React from 'react';

// Styles
import './ContextMenuButton.css';

// Images
import DotsIcon from '../../svg/DotsIcon';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ContextMenuButton Component
 * 
 * This component renders a button for opening a context menu.
 * 
 * @param {string} title - The title of the button.
 * @param {object} icon - The icon component.
 * @param {integer} iconSize - The size of the icon.
 * @param {function} onToggle - The function to call when the button is clicked.
 * @param {string} color - The base color of the dots icon.
 * @param {string} hoverColor - The color of the dots icon on hover.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuButton = ({ title, icon: IconComponent = DotsIcon, iconSize = 16, onToggle, color, hoverColor }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      {/* CONTAINER */}
      <div 
        className="context-menu-button-container"
        onClick={onToggle}>

        {/* ICON WRAPPER */}
        <div
          className="context-menu-button-icon"
          title={title}>
          <IconComponent
            color={theme.foregroundColor}
            width={iconSize}
            height={iconSize}
          />
        </div>

      </div>
    </>
  );
};

export default ContextMenuButton;
