import React from 'react';

// Styles
import './CommentButton.css';

// Theme
import { useTheme } from '../../../../../../ThemeContext';

// Images
import CommentIcon from '../../../../../../common/svg/CommentIcon';

const CommentButton = ({ element, object }) => {
    const { theme } = useTheme();

    return (
        <div className="comment-button-container">
            <CommentIcon
                color={theme.foregroundColorFaded}
                width="16"
                height="16"
            />
        </div>
    );
};

export default CommentButton;
