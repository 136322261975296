import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { MemberThumbDirection } from '../../../common/utilities/Enums';
import { getUserDisplayValue } from '../../../common/utilities/Users';

// Styles
import './MemberThumbs.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserThumb from '../userthumb/UserThumb';

const MemberThumbs = ({
  members,
  direction = MemberThumbDirection.LTR,
  size = 32, // Default thumbnail size
  borderWidth = 4, // Default border width
  offset = 10,
  showActive = false
}) => {
  const { theme } = useTheme();
  const { profile } = useContext(Global);

    // Verify profile
  if (!profile) return null;

  return (
    <div className={`member-thumbs-container ${direction}`}>
      {members.map((member, index) => (
        <div
          className="member-thumb"
          key={member.key}
          title={getUserDisplayValue(profile.displayKey, member)}
          style={{
            borderColor: theme.backgroundColor,
            borderWidth: `${borderWidth}px`,
            width: `${size + 2 * borderWidth}px`, // Adjust for the border
            height: `${size + 2 * borderWidth}px`, // Adjust for the border
            zIndex: members.length - index, // Ensure the correct stacking order
            transform: `translateX(${direction === MemberThumbDirection.LTR ? index * offset : -index * offset}px)`
          }}>

          {/* THUMB */}
          <UserThumb
            user={member}
            size={size.toString()} // Pass the thumbnail size
          />

          {/* ACTIVE */}
          {showActive &&
            <div className="member-thumb-active"></div>
          }
        </div>
      ))}
    </div>
  );
};

export default MemberThumbs;
