import React from 'react';

// Styles
import './ModalLabel.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * ModalLabel Component
 * 
 * This component renders a label for a modal item.
 * 
 * @param {string} text - The text of the label.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalLabel = ({ text }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <div className="modal-label-container"
      style={{
        color: theme.foregroundColorFaded
      }}>
      {text}
    </div>
  );
};

export default ModalLabel;
