import React from 'react';

// Styles
import './ModalSwitch.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Switch from '../../switch/Switch';

/**
 * ModalSwitch Component
 * 
 * This component renders a switch input field for a modal dialog.
 * 
 * @param {string} label The checkbox label.
 * @param {boolean} value The checkbox value.
 * @param {function} onChange The checkbox change handler.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalSwitch = ({ label, value, onChange }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* TOP SECTION */}
            <div className="modal-switch-container">

                {/* LABEL */}
                <div className="modal-switch-label"
                    style={{ color: theme.foregroundColor }}>
                    {label}
                </div>

                {/* SWITCH */}
                <Switch
                    initialChecked={value}
                    onChange={onChange}
                />

            </div>
        </>
    );
};

export default ModalSwitch;
