import React, { useEffect, useRef, useState } from 'react';

import ResizeObserver from 'resize-observer-polyfill';

// Styles
import './AccordionSection.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const AccordionSection = ({ title, children, expanded = false }) => {
    const { theme } = useTheme();

    const [isOpen, setIsOpen] = useState(expanded);
    const contentRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('0px');

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const updateContentHeight = () => {
            if (!contentRef.current) return; // Ensure contentRef.current is valid
            setContentHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
        };

        // Check initial conditions before updating height
        if (contentRef.current) {
            updateContentHeight();
        }

        const resizeObserver = new ResizeObserver(updateContentHeight);

        // Observe the current contentRef element
        if (contentRef.current) {
            resizeObserver.observe(contentRef.current);
        }

        // Cleanup function to disconnect the observer
        return () => {
            resizeObserver.disconnect();
        };
    }, [isOpen]);

    useEffect(() => {
        setContentHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    return (
        <div className="accordion-section">
            <div
                className="accordion-header"
                style={{ 
                    backgroundColor: theme.backgroundColorFaded, 
                    borderColor: theme.backgroundColorFaded 
                }}
                onClick={toggleAccordion}>
                <div
                    className="accordion-header-title"
                    style={{ color: theme.foregroundColorFaded }}
                >{title}</div>
            </div>
            <div
                ref={contentRef}
                className={`accordion-content-container ${isOpen ? 'open' : ''}`}
                style={{ 
                    borderColor: theme.backgroundColorFaded,
                    height: contentHeight
                }}
                >
                <div className="accordion-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionSection;
