// Firebase
import { collection, onSnapshot, deleteDoc, doc, getDocs, query, orderBy, setDoc, updateDoc, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';

class StoryManager {

    /**
     * Method to add a new forum post.
     * 
     * @param {string} profileKey - App key.
     * @param {string} key - New post key.
     * @param {object} data - Forum post object.
     * 
     * @returns {post} - New forum post record.
    */
    async add(profileKey, key, data) {

        await setDoc(doc(db, collections.forumposts, key), data);

        activity.log(profileKey, 'writes', 1);

        return data;
    }

    /**
 * Method to fetch all forum posts from the channel, optionally filtered by tags.
 * 
 * @param {string} profileKey - App key.
 * @param {string} channelKey - Channel key.
 * @param {Array<string>} tags - Array of tags to filter by (optional).
 * @returns {Promise<Object>} - A promise that resolves to the fetched objects.
 */
    async list(profileKey, channelKey, tags = []) {

        try {
            const ref = collection(db, collections.forumposts);

            let queryRef;

            // If tags are provided, filter by tags using 'array-contains-any'
            if (tags.length > 0) {
                queryRef = query(
                    ref,
                    where("channelKey", "==", channelKey),
                    where("tags", "array-contains-any", tags)  // Filter by tags
                );
            } else {
                // No tags, just filter by channelKey
                queryRef = query(ref, where("channelKey", "==", channelKey));
            }

            // Execute the query and get the documents
            const snapshot = await getDocs(queryRef);

            // Log the activity (optional)
            activity.log(profileKey, 'reads', snapshot.docs.length);

            // Map the documents into objects
            const objects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            return objects;

        } catch (error) {
            console.error("Error fetching forum posts:", error);
            throw error;
        }
    }

    /**
      * Fetches posts in a channel and subscribes to real-time updates.
      * 
      * @param {string} profileKey - App key.
      * @param {string} channelKey - Channel key.
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchAndSubscribe(profileKey, channelKey, onUpdate) {

        try {
            const postsCollection = collection(db, collections.forumposts);

            const q = query(postsCollection,
                where("profileKey", "==", profileKey),
                where("channelKey", "==", channelKey),
                orderBy("dateCreated", "desc"));

            const unsubscribe = onSnapshot(q, snapshot => {
                const posts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                if (onUpdate) {
                    onUpdate(posts);
                    activity.log(profileKey, 'reads', posts.length);
                }
            }, error => {
                console.error("Error fetching posts:", error);
            });

            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Deletes a post.
     * 
     * @param {string} key - Post key.
     */
    async delete(key) {
        try {

            await deleteDoc(doc(db, collections.forumposts, key));

        } catch (error) {
            console.error('Error deleting forum post:', error);
            return { success: false, message: "Error deleting post." };
        }
    }

    /**
     * Updates an post.
     * 
     * @param {string} profileKey - App key.
     * @param {string} key - The document ID for the existing forum post.
     * @param {object} data - Post data.
     */
    async update(profileKey, key, data) {

        // Update the event document in Firestore
        await updateDoc(doc(db, collections.forumposts, key), data);

        activity.log(profileKey, 'writes', 1);

        // Return success message
        return data;
    }

}

export default StoryManager;
