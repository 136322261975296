import React from 'react';

const TilesIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    height={height}
    viewBox="0 -960 960 960"
    width={width}>
    <path fill={color} d="M212.31-527.69q-30.31 0-51.31-21-21-21-21-51.31v-135.38q0-30.31 21-51.31 21-21 51.31-21h535.38q30.31 0 51.31 21 21 21 21 51.31V-600q0 30.31-21 51.31-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85Q760-595.38 760-600v-135.38q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85Q200-740 200-735.38V-600q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85Zm0 435.38q-30.31 0-51.31-21-21-21-21-51.31V-360q0-30.31 21-51.31 21-21 51.31-21h535.38q30.31 0 51.31 21 21 21 21 51.31v135.38q0 30.31-21 51.31-21 21-51.31 21H212.31Zm0-60h535.38q4.62 0 8.46-3.85Q760-220 760-224.62V-360q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H212.31q-4.62 0-8.46 3.85Q200-364.62 200-360v135.38q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85ZM200-747.69v160-160Zm0 375.38v160-160Z" />
  </svg>

);

export default TilesIcon;
