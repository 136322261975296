import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section } from '../../utilities/Enums';
import { hasAppPermission } from '../../utilities/Permissions';

// Styles
import './Spaces.css';

// Images
import NewsIcon from '../../svg/NewsIcon';

// Components
import SpaceAdd from '../../space/add/SpaceAdd';
import MenuGroup from '../menugroup/MenuGroup';
import MenuItem from '../menuitem/MenuItem';

// Managers
import ProfileManager from '../../managers/ProfileManager';

const profileManager = new ProfileManager();

/**
 * Spaces Component
 * 
 * This component renders a space-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Spaces = () => {

    // Global
    const {
        profileSpaces,
        profile,
        currentUser,
        resetSelections,
        selectedSpace,
        setSpaceAddOpen,
        setCurrentSection,
        setMenuVisible,
        setSelectedSpace,
    } = useContext(Global);

    /**
     * Handles a space click event.
     * 
     * @param {object} space - Space that was clicked on.
     */
    const handleSpaceClick = async (space) => {
        resetSelections();

        setCurrentSection(Section.NEWSFEEDS)
        setSelectedSpace(space);
    };

    /**
     * Handles an add space button click event.
     */
    const handleAddSpaceClick = () => {
        setSpaceAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            <MenuGroup
                title="News"
                showAdd={profile && hasAppPermission(profile, currentUser, Permission.MANAGE_NEWSFEEDS)}
                onAddClick={handleAddSpaceClick}>

                {/* COLLECTIONS */}
                {profileSpaces.map(space => (
                    <React.Fragment key={space.key}>
                        <MenuItem
                            key={space.key}
                            title={space.title}
                            icon={NewsIcon}
                            isActive={selectedSpace && selectedSpace.key === space.key}
                            onClick={() => handleSpaceClick(space)} />
                    </React.Fragment>
                ))}

            </MenuGroup>

            {/* FORM */}
            <SpaceAdd />

        </>
    );
};

export default Spaces;
