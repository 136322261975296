import React from 'react';

// Styles
import './ObjectSummaryRow.css';

// Components
import ObjectSummaryBlock from './ObjectSummaryBlock';

const ObjectSummaryRow = ({ object, row }) => {

    return (
        <div
            className="object-summary-row-container">
            {row.blocks.map((block) => (
                <ObjectSummaryBlock
                    key={block.id} // Unique key is necessary here
                    object={object}
                    block={block}
                />
            ))}
        </div>
    );
    
};

export default ObjectSummaryRow;

