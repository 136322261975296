import React from 'react';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './NavButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * NavButton Component
 * 
 * This component renders a nav button.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavButton = ({ icon: IconComponent, title, size, selected = false, count = 0, onClick }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      <div className="nav-button-container"
        onClick={onClick}
        title={title}>

        {/* ICON */}
        <IconComponent
          color={isMobile ? theme.foregroundColor : selected ? theme.foregroundColor : theme.foregroundColorFaded}
          width={size}
          height={size}
        />

        {/* COUNT */}
        {count > 0 &&
          <div className="nav-button-count"
            style={{
              backgroundColor: theme.highlightBackgroundColor,
              borderColor: theme.backgroundColor,
              color: theme.highlightForegroundColor
            }}>
            {count}
          </div>
        }
      </div>
    </>
  );
};

export default NavButton;

