import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Select.css';
import { useStyle, useTheme } from '../../../ThemeContext';

// Styled Components
const InlineStyles = useStyle`
    .select-option:hover {
        background-color: ${(props) => props.hoverBackgroundColor};
        color: ${(props) => props.hoverForegroundColor};
    }
    .select-option.selected {
        color: ${(props) => props.selectedTextColor};
    }
`;

/**
 * Select Component
 * 
 * This component replaces the HTML <select>.
 * 
 * @param {array} options - Array of options to display.
 * @param {string} value - Selected value.
 * @param {function} onChange - The function to call when the selection changes.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Select = ({
    options,
    value = '',
    onChange,
    isModal = false
}) => {
    // Theme
    const { theme } = useTheme();

    // Local State
    const [isOpen, setIsOpen] = useState(false);

    // References
    const dropdownRef = useRef(null);
    const triggerRef = useRef(null);

    // Dropdown Position State
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });

    /**
     * Toggles the dropdown open and closed.
     */
    const toggleDropdown = () => {
        if (!isOpen && triggerRef.current) {
            const rect = triggerRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            // Estimate dropdown height for positioning
            const estimatedDropdownHeight = dropdownRef.current?.offsetHeight || (options.length * 30 + 50); // Fallback estimate
            const wouldFallOffBottom = rect.bottom + estimatedDropdownHeight > viewportHeight;

            setDropdownPosition({
                top: wouldFallOffBottom
                    ? rect.top + window.scrollY - estimatedDropdownHeight // Position above
                    : rect.bottom + window.scrollY, // Position below
                left: rect.left + window.scrollX,
                width: rect.width
            });
        }
        setIsOpen(!isOpen);
    };

    /**
     * Handles the selection of an option.
     */
    const handleSelect = (option) => {
        onChange(option.value);
        setIsOpen(false);
    };

    /**
     * Handles clicking outside the dropdown.
     */
    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            triggerRef.current &&
            !triggerRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    // Effect to add the event listener for clicking outside the dropdown
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Find the label for the currently selected value
    const selectedLabel = options.find(option => option.value === value)?.label || 'Select an option';

    // Dropdown Options Render
    const dropDownOptions = (
        <div
            className="select-options"
            ref={dropdownRef}
            style={{
                position: 'absolute',
                top: dropdownPosition.top,
                left: dropdownPosition.left,
                width: dropdownPosition.width,
                backgroundColor: theme.backgroundColorFaded,
                color: theme.foregroundColor,
                zIndex: 1000
            }}
        >
            {options.map((option) => (
                <div
                    key={option.value}
                    className={`select-option ${option.value === value ? 'selected' : ''}`}
                    style={{
                        fontSize: isModal ? "10pt" : "12pt",
                    }}
                    onClick={() => handleSelect(option)}>
                    {option.label}
                </div>
            ))}
        </div>
    );

    return (
        <>
            <InlineStyles
                hoverBackgroundColor={theme.highlightBackgroundColor}
                hoverForegroundColor={theme.highlightForegroundColor}
                selectedTextColor={theme.foregroundColorFaded}
            />

            {/* CONTAINER */}
            <div className="select-container">

                {/* TRIGGER */}
                <div
                    className="select-trigger"
                    ref={triggerRef}
                    style={{
                        color: theme.foregroundColor,
                        fontSize: isModal ? "10pt" : "12pt",
                    }}
                    onClick={toggleDropdown}
                >
                    {selectedLabel}
                </div>

                {/* OPTIONS */}
                {isOpen &&
                    ReactDOM.createPortal(
                        dropDownOptions,
                        document.getElementById('portal-root')
                    )}
            </div>
        </>
    );
};

export default Select;
