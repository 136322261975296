import React from 'react';

// Styles
import './GridItemField.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

/**
 * GridItemField
 * 
 * Component to display a field of a grid item.
 * 
 * @param {string} value - The value of the field.
 * @param {boolean} selected - Whether the item is selected.
 * 
 * @returns {JSX.Element} GridItemField component.
 */
const GridItemField = ({ value = "", selected = false }) => {

    // Theme
    const { theme } = useTheme();

    return (

        <div
            className="grid-item-field-container"
            style={{
                color: selected ? theme.highlightForegroundColor : theme.foregroundColorFaded
            }}>
            {value}
        </div>

    );
};

export default GridItemField;
