import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { LoginMode } from '../../common/utilities/Enums';

// Firebase
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

// Styles
import './AccountMenuBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AccountMenuBar = () => {
    const { theme } = useTheme();
    const {
        resetSelections,
        setAccountAboutVisible,
        setAccountBillingVisible,
        setLoginMode,
        setScreen,
        setSelectedModel,
        setSelectedObject,
        setAccountOpen,
    } = useContext(Global);

    const [accountPage, setAccountPage] = useState("ABOUT");

    useEffect(() => {

        async function setSelectedAccountPage() {

            if (accountPage === "ABOUT") {
                setAccountAboutVisible(true);
                setAccountBillingVisible(false);
            }

            if (accountPage === "BILLING") {
                setAccountAboutVisible(false);
                setAccountBillingVisible(true);
            }

            if (accountPage === "LOGOUT") {

                try {
                    await signOut(auth);

                    resetSelections();
                    setScreen(Screen.WELCOME);
                    setLoginMode(LoginMode.LOGIN);
                    setAccountOpen(false);
                    
                } catch (error) {
                    console.error('Error signing out:', error);
                }
            }
        }
        
        setSelectedAccountPage();

    }, [
        accountPage, 
        resetSelections,
        setAccountAboutVisible, 
        setAccountBillingVisible,
        setLoginMode,
        setScreen,
        setSelectedModel,
        setSelectedObject,
        setAccountOpen
    ]);

    const handleItemClick = async (option) => {
        setAccountPage(option);
    };

    return (
        <>
            <div className="account-menu-bar-container"
                style={{
                    borderBottomColor: theme.backgroundColorFaded,
                }}>
                    <div className="account-menu-bar-option"
                        onClick={() => handleItemClick("ABOUT")}
                        style={{
                            color: accountPage === "ABOUT" ? theme.highlightBackgroundColor : theme.foregroundColor,
                        }}>
                        About Me
                    </div>
                    <div className="account-menu-bar-option"
                        onClick={() => handleItemClick("BILLING")}
                        style={{
                            color: accountPage === "BILLING" ? theme.highlightBackgroundColor : theme.foregroundColor,
                        }}>
                        Billing
                    </div>
                    <div className="account-menu-bar-option"
                        onClick={() => handleItemClick("LOGOUT")}
                        style={{
                            color: theme.foregroundColor,
                        }}>
                        Sign Out
                    </div>
            </div>
        </>
    );
};

export default AccountMenuBar;
